import { BaseV2Model } from '@/models/base-v2.model';

export interface Ask {
	indexCounter?: number;

	id: string,
	category_id: string,
	uid: string,
	title: string,
	desc: string,
	show: boolean,
	answer_state: boolean,
	answer_date: string,
	answer: string,
	landing_url: string,
	created_at: string,
	updated_at: string,
	deleted_at: string,
	user: AskUser,
	askCategory:AskCategory,
}

export type AskUser = {
	'user_id': string,
	'uid': string,
	'nick_name': string,
	'email_address': string,
	'avatar': string
}

export type AskCategory = {
	order_number: number,
	status: boolean,
	thumbnail: string,
	name: string,
	sub_name: string,
	id: string,
}

class AskModel extends BaseV2Model {
	protected collectionName = 'ask'

	private static _instance: AskModel

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}
}

export default AskModel.Instance;
