
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const fishingResultList = ref(null);
    const { page = 0 } = route.query;
    const pageSize = ref(30);
    const total = ref(0);
    const totalPage = ref(0);
    const currentPage = ref(page);
    onMounted(async () => {
      fishingResultList.value = await getFishingResultList();
      console.log(fishingResultList.value);
    });
    const getFishingResultList = async () => {
      const response = await apiService.get('/partners/fishing-result', {
        page: currentPage.value,
        size: pageSize.value,
      });
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };
    const viewDetail = (id: string) => {
      console.log(id);
      router.push(`/partners/fishing-result/detail/${id}`);
    };
    const loadPage = async (page: number) => {
      console.log('loadPage', page);
      currentPage.value = page;
      fishingResultList.value = await getFishingResultList();
      router.push({
        path: '/commerce/order',
        query: {
          page,
        },
      });
    };
    return {
      fishingResultList,
      viewDetail,
      currentPage,
      pageSize,
      totalPage,
      loadPage,
    };
  },
};
