
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { key } from '../../store';

export default defineComponent({
  setup() {
    const store = useStore(key);
    const router = useRouter();
    const permissions = ref<string[]>([]);
    onMounted(async () => {
      if (store.state.adminAccounts.permissions) {
        permissions.value = store.state.adminAccounts.permissions;
      }
    });
    const activeClass = ref('c-active');
    const inactiveClass = ref('');
    const goHome = () => {
      router.push('/');
    };
    return {
      activeClass,
      inactiveClass,
      permissions,
      goHome,
    };
  },
});

document.addEventListener('DOMContentLoaded', () => {
  const headers = document.querySelectorAll<HTMLElement>(
    '.c-sidebar-nav-header',
  );

  headers.forEach((header, index) => {
    const nextUl = header.nextElementSibling as HTMLElement | null;

    if (nextUl && nextUl.tagName === 'UL') {
      // 로컬 스토리지 키 생성
      const storageKey = `headerState-${index}`;

      // 로컬 스토리지 적용
      const storedState = localStorage.getItem(storageKey);
      if (storedState === 'true') {
        header.classList.add('on');
        nextUl.classList.remove('hidden');
      } else {
        header.classList.remove('on');
        nextUl.classList.add('hidden');
      }

      header.addEventListener('click', () => {
        header.classList.toggle('on');
        nextUl.classList.toggle('hidden');

        // 로컬 스토리지 저장
        const isOn = header.classList.contains('on');
        localStorage.setItem(storageKey, isOn.toString());
      });
    }
  });
});
