import { UserJoinStateCode, UserPaidCode } from '@/config/constant.config';

export default class ElementStyleHelper {
  /**
   * 파트너스 검색 - 회원 상태 Select Option 스타일
   * @param userStateCode
   */
  static partnersJoinStateStyle = (userStateCode: string) => ({
    [UserJoinStateCssStyle.APPROVE]: userStateCode === UserJoinStateCode.APPROVE,
    [UserJoinStateCssStyle.REJECT]: userStateCode === UserJoinStateCode.REJECT,
    [UserJoinStateCssStyle.JOIN]: userStateCode === UserJoinStateCode.JOIN,
    'text-white': userStateCode === UserJoinStateCode.APPROVE || userStateCode === UserJoinStateCode.REJECT || userStateCode === UserJoinStateCode.JOIN,
  });

  /**
   * 파트너스 검색 - 유/무료 Select Option 스타일
   * @param userStateCode
   */
  static partnersPaidCodeStyle = (piadCode: string) => ({
    [UserPaidCssStyle.PAID]: piadCode === UserPaidCode.PAID,
    [UserPaidCssStyle.FREE]: piadCode === UserPaidCode.FREE,
    'text-white': piadCode === UserPaidCode.PAID || piadCode === UserPaidCode.FREE,
  });
}

/**
 * 파트너스 회원 상태 버튼 스타일
 */
export enum UserJoinStateCssStyle {
  JOIN='bg-github',
  APPROVE='bg-success',
  REJECT='bg-danger'
}

/**
 * 파트너스 유/무료 버튼 스타일
 */
export enum UserPaidCssStyle {
  PAID='bg-gradient-warning',
  FREE='bg-dark',
}

/**
 * 어신에게 묻다 - Show 버튼 스타일
 */
export enum AskShowStateCssStyle {
  SHOW='bg-success',
  HIDE='bg-danger'
}

/**
 * 어신에게 묻다 - 답변 여부 버튼 스타일
 */
export enum AskAnswerStateCssStyle {
  ANSWER='bg-success',
  NOT_ANSWER='bg-danger'
}
