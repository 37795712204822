import { BaseV2Model } from '@/models/base-v2.model';

export interface JinCategory {
	id?: string,
	name: string,
	sub_name: string,
	status: boolean,
	order_number: number,
	thumbnail_home: string,
	thumbnail_phone: string,
	thumbnail_tablet: string,
	deleted_at: string | null,
}

class JinCategoriesModel extends BaseV2Model {
	protected collectionName = 'jin-categories'

	private static _instance: JinCategoriesModel

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}
}

export default JinCategoriesModel.Instance;
