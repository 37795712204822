<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2 v-if="id === 'new'">
        셀러 등록
      </h2>
      <h2 v-else-if="id !== 'new' && isDetail">
        셀러 상세
      </h2>
      <h2 v-else>
        셀러 수정
      </h2>
    </div>
    <div class="col-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleInputEmail1">셀러 코드</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="미발행"
                  v-model="seller.data_cd"
                  disabled
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">셀러이름(상호)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="셀러이름(상호)"
                  v-model="seller.name"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">사업자등록번호</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="사업자등록번호"
                  v-model="seller.user_id"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">대표</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="대표이름"
                  v-model="seller.ceo_name"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">주소</label>
                <div class="d-flex flex-row">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="주소"
                    v-model="seller.address"
                    :disabled="id !== 'new' && isDetail"
                  />
                  <button
                    class="btn btn-primary admin-btn"
                    :disabled="id !== 'new' && isDetail"
                    @click="openSearchAddress"
                  >
                    검색
                  </button>
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="상세주소"
                  v-model="seller.address_detail"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">업태</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="업태"
                  v-model="seller.conditions"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">업종</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="업종"
                  v-model="seller.business_category"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">연락처(대표번호)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="- 없이, 숫자만 입력"
                  v-model="seller.contact"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">노출여부</label>
                <select
                  class="form-control"
                  v-model="seller.is_active"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option :value="true">활성</option>
                  <option :value="false">비활성</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group float-right">
                <button
                  v-if="id !== 'new'"
                  class="btn btn-sm btn-primary admin-btn-sm"
                  :disabled="isDetail"
                  @click="updateCharge('PRODUCT', seller.product_charge)"
                >
                  담당자 수정
                </button>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >제품 및 배송 관리 담당자명</label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="김어신"
                  v-model="seller.product_charge.name"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >제품 및 배송 관리 담당자 전화번호</label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="012-3456-7890"
                  v-model="seller.product_charge.contact"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >제품 및 배송 관리 담당자 Email</label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="commerce@sgma.io"
                  v-model="seller.product_charge.email"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group float-right">
                <button
                  v-if="id !== 'new'"
                  class="btn btn-sm btn-primary admin-btn-sm"
                  :disabled="isDetail"
                  @click="updateCharge('SETTLEMENT', seller.settlement_charge)"
                >
                  담당자 수정
                </button>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">정산 관리 담당자명</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="김어신"
                  v-model="seller.settlement_charge.name"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >정산 관리 담당자 전화번호
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="012-3456-7890"
                  v-model="seller.settlement_charge.contact"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">정산 관리 담당자 Email </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="commerce@sgma.io"
                  v-model="seller.settlement_charge.email"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group float-right">
                <button
                  v-if="id !== 'new'"
                  class="btn btn-sm btn-primary admin-btn-sm"
                  :disabled="isDetail"
                  @click="updateBank(seller.bank_account)"
                >
                  은행정보 수정
                </button>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">정산계좌 주거래 은행</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="어신은행"
                  v-model="seller.bank_account.bank_nm"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">계좌번호</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="012-3456-7890"
                  v-model="seller.bank_account.account_num"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">예금주</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="김어신"
                  v-model="seller.bank_account.holder_nm"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer align-items-center">
          <div v-if="id === 'new'" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="update"
            >
              저장
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              취소
            </button>
          </div>
          <div v-else-if="id !== 'new' && isDetail" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="changeMode(false)"
            >
              수정
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-danger"
              @click="deleteSeller"
            >
              삭제
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              목록
            </button>
          </div>
          <div v-else class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="update"
            >
              저장
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-default"
              @click="changeMode(true)"
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const seller = ref({
      user_id: '',
      name: '',
      ceo_name: '',
      address: '',
      address_detail: '',
      conditions: '',
      business_category: '',
      contact: '',
      is_active: true,
      product_charge: {
        charge_type: 'PRODUCT',
        name: '',
        contact: '',
        email: '',
      },
      settlement_charge: {
        charge_type: 'SETTLEMENT',
        name: '',
        contact: '',
        email: '',
      },
      bank_account: {
        bank_nm: '',
        holder_nm: '',
        account_num: '',
      },
    });

    onMounted(async () => {
      if (id !== 'new') {
        const sellerData = await getSeller(id);
        seller.value = sellerData;
      }
      loadDaumAddress();
    });

    const getSeller = async id => {
      const response = await apiService.get(`/partners-mall/seller/${id}`);
      console.log(response.data.item);
      return response.data.item;
    };

    const update = async () => {
      if (!checkValidate()) {
        return false;
      }
      const sellerData = {
        ...seller.value,
      };
      console.log('sellerData', sellerData);
      if (id === 'new') {
        const response = await apiService.post(
          '/partners-mall/seller/',
          sellerData,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.successConfirm(() => {
            router.replace('/commerce/seller');
          });
        } else {
          swal.alert(response.data.message);
        }
      } else {
        const response = await apiService.put(
          `/partners-mall/seller/${id}`,
          sellerData,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.successConfirm(() => {
            isDetail.value = true;
          });
        } else {
          swal.alert(response.data.message);
        }
      }
    };
    const back = () => {
      router.back();
    };
    const changeMode = boolean => {
      isDetail.value = boolean;
    };

    const deleteSeller = async () => {
      if (confirm('셀러를 삭제 하시겠습니까?')) {
        const response = await apiService.delete(`/partners-mall/seller/${id}`);
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          alert(response.data.message);
          router.replace('/commerce/seller');
        } else {
          alert(response.data.message);
        }
      }
    };

    const updateCharge = async (type, charge) => {
      const typeName = type === 'PRODUCT' ? '제품 담당자' : '정산 담당자';
      swal.updateConfirm(async () => {
        const response = await apiService.put(
          `/partners-mall/seller-charge/${charge.data_cd}`,
          charge,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.successConfirm();
        } else {
          swal.alert(response.data.message);
        }
      }, `${typeName} 정보를 수정하시겠습니까?`);
      // if (confirm(`${typeName} 정보를 수정하시겠습니까?`)) {
      //   const response = await apiService.put(`/partners-mall/seller-charge/${charge.data_cd}`, charge);
      //   if (response.data.statusCode === 201 || response.data.statusCode === 200) {
      //     alert(response.data.message);
      //   } else {
      //     alert(response.data.message);
      //   }
      // }
    };
    const updateBank = async bank => {
      swal.updateConfirm(async () => {
        const response = await apiService.put(
          `/partners-mall/seller-bank/${bank.data_cd}`,
          bank,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.successConfirm();
        } else {
          swal.alert(response.data.message);
        }
      }, '계좌 정보를 수정하시겠습니까?');
    };
    const loadDaumAddress = () => {
      const scriptTag = document.createElement('script');
      scriptTag.src =
        'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      document.getElementsByTagName('body')[0].appendChild(scriptTag);
    };
    const openSearchAddress = () => {
      new daum.Postcode({
        oncomplete: data => {
          seller.value.address = data.address;
        },
      }).open();
    };
    const checkValidate = () => {
      if (seller.value.name.trim() === '') {
        swal.alert('셀러명을 입력하세요.');
        return false;
      }

      if (seller.value.ceo_name === '') {
        swal.alert('대표이름을 입력하세요.');
        return false;
      }

      if (seller.value.user_id === '') {
        swal.alert('사업자등록번호를 입력하세요.');
        return false;
      }

      if (seller.value.address === '') {
        swal.alert('주소를 입력하세요.');
        return false;
      }

      if (seller.value.conditions === '') {
        swal.alert('업태를 입력하세요.');
        return false;
      }

      if (seller.value.business_category === '') {
        swal.alert('업종을 입력하세요.');
        return false;
      }

      if (seller.value.contact === '') {
        swal.alert('대표 연락처를 입력하세요.');
        return false;
      }

      if (seller.value.product_charge.name === '') {
        swal.alert('제품 담당자명 입력하세요.');
        return false;
      }
      if (seller.value.product_charge.contact === '') {
        swal.alert('제품 담당자명 연락처를 입력하세요.');
        return false;
      }
      if (seller.value.product_charge.email === '') {
        swal.alert('제품 담당자 이메일을 입력하세요.');
        return false;
      }

      if (seller.value.settlement_charge.name === '') {
        swal.alert('정산 담당자명 입력하세요.');
        return false;
      }
      if (seller.value.settlement_charge.contact === '') {
        swal.alert('정산 담당자명 연락처를 입력하세요.');
        return false;
      }
      if (seller.value.settlement_charge.email === '') {
        swal.alert('정산 담당자 이메일을 입력하세요.');
        return false;
      }

      return true;
    };

    return {
      id,
      isDetail,
      seller,
      update,
      updateCharge,
      updateBank,
      back,
      changeMode,
      deleteSeller,
      openSearchAddress,
    };
  },
};
</script>

<style scoped></style>
