
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import Model from '../../models/jinCategories.model';

export default defineComponent({
  components: {
    SubmitButton,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    const isSubmitting = ref(false);
    const loading = ref(true);
    const thumbnailPhoneFile = ref<any>(null);
    const thumbnailTabletFile = ref<any>(null);

    const categoryId = router.currentRoute.value.params.id;
    const category = ref({
      name: '',
      sub_name: '',
      event_key: '',
      status: false,
      thumbnail_home: '',
      thumbnail_phone: '',
      thumbnail_tablet: '',
      deleted_at: '',
      order_number: 0,
    });
    const setPreviewImg = ref('');
    onMounted(async () => {
      const { item } = await Model.findById(categoryId);
      category.value = item;
      setPreviewImg.value = category.value.thumbnail_phone;
      loading.value = false;
    });

    async function submitForm() {
      isSubmitting.value = true;
      let thumbnail_phone = await thumbnailPhoneFile.value?.uploadImage();
      if (thumbnail_phone.length <= 0) {
        thumbnail_phone = category.value.thumbnail_phone;
      }

      let thumbnail_tablet = await thumbnailTabletFile.value?.uploadImage();
      if (thumbnail_tablet.length <= 0) {
        thumbnail_tablet = category.value.thumbnail_phone;
      }

      const order_number_payload = Number(category.value.order_number);

      const data = {
        name: category.value.name,
        sub_name: category.value.sub_name,
        event_key: category.value.event_key,
        status: category.value.status,
        thumbnail_home: thumbnail_phone,
        thumbnail_phone,
        thumbnail_tablet,
        order_number: order_number_payload,
      };

      const addResult = await Model.update(categoryId, data);
      if (addResult.status) {
        isSubmitting.value = false;
        router.replace('/jin-categories');
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }
    return {
      submitForm,
      isSubmitting,
      category,
      loading,
      thumbnailPhoneFile,
      thumbnailTabletFile,
      setPreviewImg,
    };
  },
});
