
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as pushModel } from '../../models/appPushes.model';
import dateTimeHelper from '../../helper/datetime.helper';
import swal from '../../helper/swal.helper';

export default defineComponent({
  setup() {
    const router = useRouter();
    let pushId = router.currentRoute.value.params.id;
    let loading = ref(false);
    let push = ref<any>({
      title: '',
      desc: '',
      landing_url: '',
      push_schedule: 0,
      push_now: 0,
      total_sent: 0,
      total_open: 0,
    });

    onMounted(async () => {
      push.value = await pushModel.findById(pushId);
      if (push.value.push_schedule) {
        push.value.push_schedule = dateTimeHelper.timestampMilisToDateTimeInput(
          push.value.push_schedule,
        );
      }
    });

    async function submitForm() {
      loading.value = true;
      if (push.value.push_schedule) {
        push.value.push_schedule = dateTimeHelper.toFirebaseTimestamp(
          push.value.push_schedule,
        );
      }

      let updateResult = await pushModel.update(pushId, push.value);
      if (updateResult.status) {
        router.push('/app-pushes');
      } else {
        swal.alert(updateResult.message);
      }
      loading.value = false;
    }

    return {
      loading,
      push,
      submitForm,
    };
  },
});
