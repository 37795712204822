
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    // <th>주문번호</th>
    // <th>발주번호</th>
    // <th>상품명</th>
    // <th>수량</th>
    // <th>정산금액</th>
    // <th>수령인명</th>
    // <th>배송예정일</th>
    const contractList = ref([
      {
        id: 1,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 2,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 3,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 4,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 5,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 6,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 7,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 8,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      }, {
        id: 9,
        order_number: '202230403223',
        contract_number: '22320023412',
        product_name: '생수1세트',
        product_count: 2,
        contract_price: '22,500',
        receiver_name: '박호머',
        delivery_date: '2023-03-05',
      },
    ]);
    const excelDownload = () => {
      alert('발주 양식 엑셀 다운로드');
    };
    const viewDetail = () => {
      router.push('/commerce/statement/detail');
    };
    return {
      contractList,
      excelDownload,
      viewDetail,
    };
  },
};
