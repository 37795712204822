<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2 v-if="id === 'new'">
        상품 등록
      </h2>
      <h2 v-else-if="id !== 'new' && isDetail">
        상품 상세
      </h2>
      <h2 v-else>
        상품 수정
      </h2>
    </div>
    <div class="col-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>상품 선택</label>
                <select
                  class="form-control"
                  v-model="promotion.product_data_cd"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option value="">상품선택 선택</option>
                  <option
                    v-for="product in productList"
                    :key="product.data_cd"
                    :value="product.data_cd"
                  >
                    {{ product.name }}{{ product.data_cd }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>프로모션 제목</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="제품명"
                  v-model="promotion.title"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>순서</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="promotion.idx"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>노출여부</label>
                <select
                  class="form-control"
                  v-model="promotion.is_active"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option :value="true">활성</option>
                  <option :value="false">비활성</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>웹용 프로모션 썸네일 이미지</label>
                <div class="d-flex flex-row">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="File name"
                    v-model="promotion.web_thumbnail_url"
                    disabled
                  />
                  <input
                    style="display: none;"
                    ref="webThumbnailFile"
                    type="file"
                    accept="image/*"
                    @change="uploadWebThumbnailFile($event)"
                  />
                  <button
                    class="btn btn-primary admin-btn"
                    :disabled="id !== 'new' && isDetail"
                    @click="openWebThumbnailImage"
                  >
                    파일찾기
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>프로모션 웹용 썸네일 미리보기</label>
              </div>
              <img
                ref="webThumbnailImg"
                class="web-thumbnail-img"
                v-show="showWebThumbnailImage"
                :src="promotion.web_thumbnail_url"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>프로모션 썸네일 이미지</label>
                <div class="d-flex flex-row">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="File name"
                    v-model="promotion.thumbnail_url"
                    disabled
                  />
                  <input
                    style="display: none;"
                    ref="thumbnailFile"
                    type="file"
                    accept="image/*"
                    @change="uploadThumbnailFile($event)"
                  />
                  <button
                    class="btn btn-primary admin-btn"
                    :disabled="id !== 'new' && isDetail"
                    @click="openThumbnailImage"
                  >
                    파일찾기
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>프로모션 썸네일 미리보기</label>
              </div>
              <img
                ref="thumbnailImg"
                class="thumbnail-img"
                v-show="showThumbnailImage"
                :src="promotion.thumbnail_url"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="card-footer align-items-center">
          <div v-if="id === 'new'" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="submit"
            >
              저장
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              취소
            </button>
          </div>
          <div v-else-if="id !== 'new' && isDetail" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="changeMode(false)"
            >
              수정
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-danger"
              @click="deletePromotion"
            >
              삭제
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              목록
            </button>
          </div>
          <div v-else class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="submit"
            >
              저장
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-default"
              @click="changeMode(true)"
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted, onUpdated } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';
import commerceUtil from '@/helper/commerceUtil';

export default {
  name: 'PartnersMallProductRegister',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const productList = ref(null);
    const thumbnailFile = ref(null);
    const thumbnailImg = ref(null);
    const uploadThumbnailImage = ref(false);
    const showThumbnailImage = ref(false);
    const thumbnailImageInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    const webThumbnailFile = ref(null);
    const webThumbnailImg = ref(null);
    const uploadWebThumbnailImage = ref(false);
    const showWebThumbnailImage = ref(false);
    const webThumbnailImageInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    const promotion = ref({
      product_data_cd: '',
      title: '',
      thumbnail_url: '',
      web_thumbnail_url: '',
      idx: 1,
      is_active: true,
    });

    onMounted(async () => {
      productList.value = await getProductList();
      if (id !== 'new') {
        const promotionData = await getPromotion(id);
        console.log(promotionData);
        showThumbnailImage.value = true;
        showWebThumbnailImage.value = true;
        promotion.value = promotionData;
      }
    });

    const getProductList = async () => {
      const response = await apiService.get('/partners-mall/product', {
        size: 500,
        page: 0,
      });
      return response.data.items;
    };
    const getPromotion = async id => {
      const response = await apiService.get(
        `/partners-mall/product-promotion/${id}`,
      );
      return response.data.item;
    };

    const submit = async () => {
      if (!checkValidate()) {
        return false;
      }
      if (id === 'new') {
        swal.createConfirm(create);
      } else {
        swal.updateConfirm(update);
      }
      // 순서값 Number로 업데이트
      promotion.value.idx = Number(promotion.value.idx);
    };
    const create = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (uploadWebThumbnailImage.value) {
        await uploadWebThumbnail();
      }
      // const response = await apiService.post('/partners/post-category/', category.value);
      console.log(promotion.value);
      const response = await apiService.post(
        '/partners-mall/product-promotion/',
        promotion.value,
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        alert(response.data.message);
        router.replace('/commerce/promotion');
      } else {
        alert(response.data.message);
      }
    };
    const update = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (uploadWebThumbnailImage.value) {
        await uploadWebThumbnail();
      }
      const response = await apiService.put(
        `/partners-mall/product-promotion/${id}`,
        promotion.value,
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        swal.successConfirm(async () => {
          router.push('/commerce/promotion');
        });
      } else {
        console.error(response);
      }
    };
    const checkValidate = () => {
      if (promotion.value.title.trim() === '') {
        swal.alert('프로모션 제목이 입력되지 않았습니다.');
        return false;
      }
      if (promotion.value.product_data_cd.trim() === '') {
        swal.alert('프로모션 상품이 선택되지 않았습니다.');
        return false;
      }
      if (promotion.value.web_thumbnail_url === '') {
        swal.alert('웹용 썸네일 이미지가 선택되지 않았습니다.');
        return false;
      }
      if (promotion.value.thumbnail_url === '') {
        swal.alert('썸네일 이미지가 선택되지 않았습니다.');
        return false;
      }

      return true;
    };
    const back = () => {
      router.back();
    };
    const changeMode = mode => {
      isDetail.value = mode;
    };
    const uploadThumbnailFile = event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        thumbnailImg.value.src = e?.target?.result;
        showThumbnailImage.value = true;
      };

      reader.readAsDataURL(file);
      uploadThumbnailImage.value = true;

      if (file) {
        promotion.value.thumbnail_url = file.name;
        thumbnailImageInfo.value.extension = file.name.split('.').reverse()[0];
        thumbnailImageInfo.value.imageName = file.name;
        thumbnailImageInfo.value.imageFile = file;
      }
    };
    const uploadWebThumbnailFile = event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        webThumbnailImg.value.src = e?.target?.result;
        showWebThumbnailImage.value = true;
      };

      reader.readAsDataURL(file);
      uploadWebThumbnailImage.value = true;

      if (file) {
        promotion.value.web_thumbnail_url = file.name;
        webThumbnailImageInfo.value.extension = file.name
          .split('.')
          .reverse()[0];
        webThumbnailImageInfo.value.imageName = file.name;
        webThumbnailImageInfo.value.imageFile = file;
      }
    };
    const openThumbnailImage = () => {
      thumbnailFile.value.click();
    };
    const openWebThumbnailImage = () => {
      webThumbnailFile.value.click();
    };

    const getPresignedUrl = async fileName => {
      const response = await apiService.get(
        `/external/aws/s3/presignedUrl?serviceDomainType=partnersMall&filePath=promotion&fileNm=${fileName}`,
      );
      return response.data.item.pre_signed_url;
    };
    const uploadImage = async (url, file) =>
      await apiService.uploadImage(url, file);
    const uploadThumbnail = async () => {
      const imageName = `${commerceUtil.getUuid()}.${
        thumbnailImageInfo.value.extension
      }`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(
        presignedUrl,
        thumbnailImageInfo.value.imageFile,
      );
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? 'https://cdn.uhshin.com/partnersMall/promotion/'
          : 'https://cdn.uhshin.com/development/partnersMall/promotion/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      promotion.value.thumbnail_url = `${imageServer}${imageName}`;
      console.log(promotion.value.thumbnail_url);
    };
    const uploadWebThumbnail = async () => {
      console.log('uploadThumbnail');
      const imageName = `${commerceUtil.getUuid()}.${
        webThumbnailImageInfo.value.extension
      }`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(
        presignedUrl,
        webThumbnailImageInfo.value.imageFile,
      );
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? 'https://cdn.uhshin.com/partnersMall/promotion/'
          : 'https://cdn.uhshin.com/development/partnersMall/promotion/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      promotion.value.web_thumbnail_url = `${imageServer}${imageName}`;
      console.log(promotion.value.thumbnail_url);
    };
    const deletePromotion = async () => {
      if (confirm('프로모션을 삭제 하시겠습니까?')) {
        const response = await apiService.delete(
          `/partners-mall/product-promotion/${id}`,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          alert(response.data.message);
          router.replace('/commerce/promotion');
        } else {
          alert(response.data.message);
        }
      }
    };
    return {
      id,
      isDetail,
      promotion,
      productList,
      thumbnailFile,
      thumbnailImg,
      showThumbnailImage,
      webThumbnailFile,
      webThumbnailImg,
      showWebThumbnailImage,
      submit,
      back,
      changeMode,
      uploadThumbnailFile,
      openThumbnailImage,
      uploadWebThumbnailFile,
      openWebThumbnailImage,
      deletePromotion,
    };
  },
};
</script>

<style scoped>
.thumbnail-img {
  width: 335px;
  height: 200px;
}
.web-thumbnail-img {
  width: 716px;
  height: 250px;
}
</style>
