import cookieHelper from '../../helper/cookie.helper'

const state = () => ({
	loadingLogin: false,
	loadingAuth: false,
	token: cookieHelper.getCookie('access_token'),
	permissions: cookieHelper.getCookie('permissions')
})

// getters
const getters = {

}

// actions
const actions = {
	setLoadingLogin (context: any, requestData: any) {
		context.commit('setLoadingLogin', requestData);
	},

	setLoadingAuth (context: any, requestData: any) {
		context.commit('setLoadingAuth', requestData);
	},

	setToken (context: any, requestData: any) {
		context.commit('setToken', requestData);
	},

	setPermissions (context: any, requestData: any) {
		context.commit('setPermissions', requestData);
	},
}

// mutations
const mutations = {
	setLoadingLogin (state: any, loadingLogin: any) {
		state.loadingLogin = loadingLogin
	},
	
	setLoadingAuth (state: any, loadingAuth: any) {
		state.loadingAuth = loadingAuth
	},

	setToken (state: any, token: any) {
		state.token = token
	},

	setPermissions (state: any, permissions: any) {
		state.permissions = permissions
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};