
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { key } from '../../store';

export default defineComponent({
  setup() {
    const store = useStore(key);
    const permissions = ref<string[]>([]);
    onMounted(async () => {
      if (store.state.adminAccounts.permissions) {
        permissions.value = store.state.adminAccounts.permissions;
      }
    });
    const activeClass = ref('c-active');
    const inactiveClass = ref('');

    return {
      activeClass,
      inactiveClass,
      permissions,
    };
  },
});
