
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import ElementStyleHelper, {
  UserJoinStateCssStyle,
  UserPaidCssStyle,
} from '@/helper/element-style.helper';
import numberHelper from '@/helper/number.helper';
import copy from 'fast-copy';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  BooleanTypes,
  IsUserSearchEventAt,
  UserJoinStateCode,
  UserPaidCode,
} from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import swal from '../../helper/swal.helper';
import CrudModel, {
  PartnersFishCode,
} from '../../models/partnersFishCode.model';
import { ApiService } from '../../services/backend-api/api.services';

export default defineComponent({
  name: 'PartnersJoinList',
  props: {},
  components: {
    PaginationV2,
  },
  setup: () => {
    type interactStatus = {
      added: boolean;
      edited: boolean;
      deleted: boolean;
      moved: boolean;
    };
    type use_yn_bool = {
      use_yn_bool: boolean;
    };
    const router = useRouter();
    const route = useRoute();
    const fishCodes = ref<
      Array<PartnersFishCode & interactStatus & use_yn_bool>
    >([]);
    const backupFishCodes = ref<
      Array<PartnersFishCode & interactStatus & use_yn_bool>
    >([]);
    const apiService = new ApiService();
    const partnersSummary = ref({});
    const loading = ref(false);
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const filter = ref({
      category: '',
      fish_cd: '',
      code_kor_name: '',
      use_yn: '',
    });
    type filterKeyType = 'category' | 'fish_cd' | 'code_kor_name' | 'use_yn';
    const perpage = 200;

    const orderOptions = ref([['create_at', 'desc']]);
    const filterConditions = ref<Array<any>>([]);

    onMounted(async () => {
      await searchHandler();
    });
    const updateData = (items: Array<any>) => {
      loading.value = false;
      fishCodes.value = items;
      fishCodes.value = fishCodes.value.map(item => ({
        ...item,
        use_yn_bool: item.use_yn === 'Y',
      }));
      backupFishCodes.value = copy(fishCodes.value);
    };
    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    async function searchHandler() {
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];

      setSearchFilterCondition('category');
      setSearchFilterCondition('fish_cd');
      setSearchFilterCondition('use_yn');
      setSearchFilterCondition('code_kor_name');
    }

    /**
     * 데이터 검색 조건 셋팅
     * @param filterKey
     * @param replaceFilterValue
     */
    function setSearchFilterCondition(
      filterKey: filterKeyType,
      replaceFilterValue?: string,
    ): void {
      if (filter.value[filterKey].trim() || replaceFilterValue) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? filter.value[filterKey],
        ]);
      } else if (
        // 페이지가 새로고침되었을 때 또는 뒤로가기로 왔을 때 url에 검색 관련 쿼리스트링이 있으면, 검색 조건에 추가 합니다.
        ((!filter.value[filterKey].trim() && route.query[filterKey]?.length) ||
          replaceFilterValue) &&
        userSearchEventFlag.value === IsUserSearchEventAt.N
      ) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? route.query[filterKey],
        ]);
        filter.value[filterKey] =
          replaceFilterValue ?? `${route.query[filterKey]}`;
      }
    }

    /**
     * Reset 버튼을 클릭했을 때 검색 조건을 초기화 합니다.
     */
    async function resetFilter() {
      filter.value.category = '';
      filter.value.fish_cd = '';
      filter.value.code_kor_name = '';
      filter.value.use_yn = '';

      userSearchEventFlag.value = IsUserSearchEventAt.Y;
      await searchHandler();
    }

    const deleteFishCode = (index: number) => {
      fishCodes.value[index].delete_yn = BooleanTypes.Y;
      setStatus(fishCodes.value[index], 'deleted');
    };

    // const moveFishCode = (index: number, offset: number) => {
    //   const fromIndex = index;
    //   const toIndex = fromIndex + offset;

    //   if (fromIndex <= 0 && offset < 0) { return undefined; }
    //   if (toIndex >= fishCodes.value.length && offset > 0) { return undefined; }

    //   const fromItem = fishCodes.value[fromIndex];
    //   const toItem = fishCodes.value[toIndex];

    //   if (fromItem.category !== toItem.category) {
    //     return undefined;
    //   }

    //   // order_idx 변경
    //   moveOrderIdx(fishCodes.value, fromIndex, toIndex);
    //   // moveOrderIdx(backupFishCodes.value, fromIndex, toIndex);
    //   // 배열 위치 변경
    //   moveArray(fishCodes.value, fromIndex, toIndex);
    //   moveArray(backupFishCodes.value, fromIndex, toIndex);

    //   // 어종 코드 데이터 상태 변경
    //   // setStatus(fishCodes.value[toIndex], 'moved'); // moved
    //   changeFishCodeEventHandler(toIndex);
    // };

    /**
     * 어종코드의 정렬 순서(order_idx)를 변경 합니다.
     */
    // const moveOrderIdx = (arr: Array<any>, fromIndex: number, toIndex: number) => {
    //   const fromFishCode = arr[fromIndex];
    //   const toFishCode = arr[toIndex];

    //   const tempOrderIdx = fromFishCode.order_idx;
    //   fromFishCode.order_idx = toFishCode.order_idx;
    //   toFishCode.order_idx = tempOrderIdx;
    // };

    /**
     * 어종코드 배열 순서를 변경 합니다.
     * @param arr
     * @param fromIndex
     * @param toIndex
     */
    // const moveArray = (arr: Array<any>, fromIndex: number, toIndex: number) => {
    //   const element = arr[fromIndex];
    //   arr.splice(fromIndex, 1);
    //   arr.splice(toIndex, 0, element);
    // };

    const setStatus = (
      interactStatus: interactStatus,
      status: 'added' | 'edited' | 'deleted' | 'moved',
    ) => {
      if (interactStatus?.added) {
        return undefined;
      }
      if (interactStatus?.deleted) {
        return undefined;
      }
      interactStatus[status] = true;
    };

    const getStatusColor = (fishCode: PartnersFishCode & interactStatus) => {
      if (fishCode.added) {
        return 'bg-success';
      }
      if (fishCode.deleted) {
        return 'bg-danger';
      }
      if (fishCode.edited || fishCode.moved) {
        return 'bg-warning';
      }
      return '';
    };

    const changeFishCodeEventHandler = (index: number) => {
      if (fishCodes.value[index].added) {
        return undefined;
      }

      const backupData = backupFishCodes.value[index];
      const changeData = fishCodes.value[index];

      if (backupData.order_idx === changeData.order_idx) {
        changeData.moved = false;
      } else {
        changeData.moved = true;
      }

      if (
        backupData.category === changeData.category &&
        backupData.code_kor_name === changeData.code_kor_name &&
        backupData.use_yn_bool === changeData.use_yn_bool &&
        +backupData.order_idx === +changeData.order_idx
      ) {
        changeData.edited = false;
        return undefined;
      }

      syncUseYn(index);
      setStatus(fishCodes.value[index], 'edited');
      fishCodes.value.sort((a, b) => a.order_idx - b.order_idx);
    };

    const syncUseYn = (index: number) => {
      fishCodes.value[index].use_yn = fishCodes.value[index].use_yn_bool
        ? BooleanTypes.Y
        : BooleanTypes.N;
    };

    const addFishCode = () => {
      router.push({
        path: '/partners-fish-code/add',
        query: route.query,
      });
    };

    const applyEventHandler = async () => {
      isLoading(true);
      const response = await CrudModel.bulkUpdate(fishCodes.value);
      console.log(response);
      if (!response.status) {
        swal.alert(response.message);
        isLoading(false);
        return undefined;
      }

      swal.successConfirm(async () => {
        await searchHandler();
      });
    };

    return {
      fishCodes,
      dateTimeHelper,
      numberHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      CrudModel,
      searchHandler,
      resetFilter,
      partnersSummary,
      IsUserSearchEventAt,
      userSearchEventFlag,
      UserJoinStateCode,
      UserPaidCode,
      UserPaidCssStyle,
      UserJoinStateCssStyle,
      ElementStyleHelper,
      BooleanTypes,
      deleteFishCode,
      // moveFishCode,
      getStatusColor,
      changeFishCodeEventHandler,
      addFishCode,
      applyEventHandler,
    };
  },
});
