
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as NotificationModel } from '../../models/notification.model';
import FileUpload from '../../components/ui/FileUpload.vue';
import Editor from '../../components/ui/Editor.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    FileUpload,
    Editor,
  },
  setup() {
    const router = useRouter();
    let notificationId = router.currentRoute.value.params.id;
    let loading = ref(false);
    let thumbnailFile = ref<any>(null);
    let customEditor = ref<any>(null);
    let previewImg = ref('');
    let notification = ref({
      title: '',
      description: '',
      thumbnail: '',
      show: 1,
      pin: 0,
      read: 0,
      landing_url: '',
      show_home: false,
      show_home_order: 0,
      show_more: false,
      show_more_order: 0,
      app: 0,
      no_margin_layout: false,
      os: 0,
    });

    onMounted(async () => {
      notification.value = await NotificationModel.findById(notificationId);
      previewImg.value = notification.value.thumbnail;
    });

    async function submitForm() {
      loading.value = true;
      // Upload file
      if (thumbnailFile.value!.hasFile) {
        notification.value.thumbnail = await thumbnailFile.value?.uploadImage();
      }
      notification.value.description = await customEditor.value?.getContents();
      const result = await NotificationModel.update(
        notificationId,
        notification.value,
      );
      loading.value = false;

      if (result.status) {
        router.push('/notifications');
      } else {
        swal.alert(result.message);
      }
    }

    return {
      loading,
      notification,
      thumbnailFile,
      previewImg,
      submitForm,
      customEditor,
    };
  },
});
