
import { ref, watch, defineComponent } from 'vue';
import firebaseConfig from '../../firebaseConfig';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
      default: '대표 이미지',
    },
    fileType: {
      type: String,
      required: false,
    },
    setPreviewImg: {
      type: String,
      required: false,
      default: '',
    },
    folderName: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup: props => {
    const acceptType = ref('image/*');
    const imageData = ref<any>();
    const previewImg = ref('');
    const hasFile = ref(false);

    watch(
      () => props.fileType,
      (newValue, oldValue) => {
        acceptType.value = props.fileType!;
      },
    );

    watch(
      () => props.setPreviewImg,
      (newValue, oldValue) => {
        previewImg.value = props.setPreviewImg!;
      },
    );

    function previewImage(event: any) {
      const reader = new FileReader();
      imageData.value = event.target.files[0];
      reader.onload = e => {
        if (e.target !== null && e.target.result !== null) {
          previewImg.value = e.target.result.toString();
        }
      };
      reader.readAsDataURL(event.target.files[0]);
      hasFile.value = true;
    }

    async function uploadImage() {
      if (imageData.value) {
        const fileName = imageData.value.name;
        const name = fileName
          .split('.')
          .slice(0, -1)
          .join('.');
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        // rename file
        let renamedFile = '';
        if (props.folderName) {
          renamedFile = `${props.folderName}/${name}_${Date.now()}.${ext}`;
        } else {
          renamedFile = `${name}_${Date.now()}.${ext}`;
        }

        return firebaseConfig
          .storage()
          .ref()
          .child(renamedFile)
          .put(imageData.value)
          .then(snap => snap.ref.getDownloadURL())
          .then(downloadUrl => downloadUrl)
          .catch(error => {
            console.log('An error occurred while uploading the file.');
          });
      }
      return '';
    }

    return {
      imageData,
      previewImg,
      acceptType,
      hasFile,
      previewImage,
      uploadImage,
    };
  },
});
