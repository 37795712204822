import {BaseModel} from "./base.model";

class PermissionsModel extends BaseModel {
	protected collectionName = 'permission'

	private static _instance: PermissionsModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default PermissionsModel.Instance