import {BaseModel} from "./base.model";
import firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;

export interface FishingTubes {
    name: string,
    desc: string,
    video_id: string,
    show: boolean,
    thumbnail: string,
    published_at: number,
    created_at: number,
    updated_at: number,
    order: number,
    view_count: number,
    show_view_count: boolean
}

class FishingTubesModel extends BaseModel {
    protected collectionName = 'fishing_tubes'

    private static _instance: FishingTubesModel

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default FishingTubesModel.Instance