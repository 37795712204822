
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { default as PostModel, ProPost } from '../../models/proPost.model';
import { default as PostCategoriesModel } from '../../models/proPostCategories.model';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import dateTimeHelper from '../../helper/datetime.helper';
import Editor from '../../components/ui/Editor.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    FileUpload,
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter();
    let thumbnailFile = ref<any>(null);
    let customEditor = ref<any>(null);
    let isSubmitting = ref(false);
    let categories = ref([]);
    let post = ref<ProPost>({
      title: '',
      category_id: '',
      published_at: 0,
      pin: false,
      show: true,
      landing_url: '',
      contents_url: '',
      desc: '',
      thumbnail: '',
      updated_at: 0,
      created_at: 0,
      view_count: 0,
      order: 0,
      show_view_count: false,
      no_margin_layout: false,
    });
    let publishTime = ref<string>('');

    onMounted(async () => {
      categories.value = await PostCategoriesModel.getAll();
    });

    async function submitForm() {
      isSubmitting.value = true;

      let data = {
        category_id: post.value.category_id,
        desc: await customEditor.value?.getContents(),
        pin: post.value.pin,
        landing_url: post.value.landing_url,
        contents_url: post.value.contents_url,
        published_at: publishTime.value
          ? dateTimeHelper.dateToTimestamp(publishTime.value)
          : 0,
        show: post.value.show,
        thumbnail: await thumbnailFile.value?.uploadImage(),
        title: post.value.title,
        view_count: post.value.view_count,
        order: post.value.order,
        show_view_count: post.value.show_view_count,
        no_margin_layout: post.value.no_margin_layout,
      };
      let addResult = await PostModel.create(data);
      if (addResult.status) {
        isSubmitting.value = false;
        router.push('/pro-post');
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      post,
      thumbnailFile,
      submitForm,
      categories,
      isSubmitting,
      publishTime,
      customEditor,
    };
  },
});
