<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>포스트 카테고리 이름</label>
            <input
              type="text"
              class="form-control"
              v-model="category.name"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>순서</label>
            <input
              type="number"
              class="form-control"
              v-model="category.idx"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>프로모션 썸네일 이미지</label>
            <div class="d-flex flex-row">
              <input
                type="text"
                class="form-control"
                placeholder="File name"
                v-model="category.thumbnail"
                disabled
              />
              <input
                style="display: none;"
                ref="thumbnailFile"
                type="file"
                accept="image/*"
                @change="uploadThumbnailFile($event)"
              />
              <button
                class="btn btn-primary admin-btn"
                :disabled="!id && isDetail"
                @click="openThumbnailImage"
              >
                파일찾기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>프로모션 썸네일 미리보기</label>
          </div>
          <img
            ref="thumbnailImg"
            class="thumbnail-img"
            v-show="showThumbnailImage"
            :src="category.thumbnail"
            alt=""
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mb-5">
            <label class="c-switch c-switch-pill c-switch-primary">
              <label>노출여부</label>
              <input
                type="checkbox"
                class="c-switch-input"
                v-model="category.is_active"
              />
              <span class="c-switch-slider"></span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <div v-if="!id" class="footer-btn-wrapper">
          <button type="submit" class="btn btn-lg btn-primary" @click="submit">
            저장
          </button>
          <button type="submit" class="btn btn-lg btn-default" @click="back">
            취소
          </button>
        </div>
        <div v-else-if="id !== 'new' && isDetail" class="footer-btn-wrapper">
          <button
            type="submit"
            class="btn btn-lg btn-primary"
            @click="changeMode(false)"
          >
            수정
          </button>
          <button
            type="submit"
            class="btn btn-lg btn-danger"
            @click="deleteCategory"
          >
            삭제
          </button>
          <button type="submit" class="btn btn-lg btn-default" @click="back">
            목록
          </button>
        </div>
        <div v-else class="footer-btn-wrapper">
          <button type="submit" class="btn btn-lg btn-primary" @click="submit">
            저장
          </button>
          <button
            type="submit"
            class="btn btn-lg btn-default"
            @click="changeMode(true)"
          >
            취소
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue';
import { ApiService } from '@/services/backend-api/api.services';
import { useRoute, useRouter } from 'vue-router';
import commerceUtil from '@/helper/commerceUtil';
import swal from '../../helper/swal.helper';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(false);
    const isSubmitting = ref(false);
    const thumbnailFile = ref(null);
    const thumbnailImg = ref(null);
    const uploadThumbnailImage = ref(false);
    const showThumbnailImage = ref(false);
    const thumbnailImageInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    const category = ref({
      name: '',
      idx: 1,
      thumbnail: '',
      is_active: true,
    });
    onMounted(async () => {
      if (id) {
        const categoryData = await getCategory(id);
        console.log('categoryData', categoryData);
        showThumbnailImage.value = true;
        category.value = categoryData;
      }
    });
    const getCategory = async () => {
      const response = await apiService.get(`/partners/post-category/${id}`);
      return response.data.item;
    };
    async function submitForm() {
      isSubmitting.value = true;
      // const order_number_payload = Number(orderNumber.value);

      // const addResult = await Model.create(category);
      // if (addResult.status) {
      //   router.replace('/jin-categories');
      //   isSubmitting.value = false;
      // } else {
      //   swal.alert(addResult.message);
      //   isSubmitting.value = false;
      // }
    }
    const getPresignedUrl = async fileName => {
      console.log('filename', fileName);
      const response = await apiService.get(
        `/external/aws/s3/presignedUrl?serviceDomainType=partners&filePath=post-category&fileNm=${fileName}`,
      );
      return response.data.item.pre_signed_url;
    };
    const uploadImage = async (url, file) =>
      await apiService.uploadImage(url, file);
    const uploadThumbnail = async () => {
      console.log('uploadThumbnail');
      const imageName = `${commerceUtil.getUuid()}.${
        thumbnailImageInfo.value.extension
      }`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(
        presignedUrl,
        thumbnailImageInfo.value.imageFile,
      );
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? 'https://cdn.uhshin.com/partners/post-category/'
          : 'https://cdn.uhshin.com/development/partners/post-category/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      category.value.thumbnail = `${imageServer}${imageName}`;
      console.log(category.value.thumbnail);
    };
    const uploadThumbnailFile = event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        thumbnailImg.value.src = e?.target?.result;
        showThumbnailImage.value = true;
      };

      reader.readAsDataURL(file);
      uploadThumbnailImage.value = true;

      if (file) {
        category.value.thumbnail = file.name;
        thumbnailImageInfo.value.extension = file.name.split('.').reverse()[0];
        thumbnailImageInfo.value.imageName = file.name;
        thumbnailImageInfo.value.imageFile = file;
      }
    };
    const openThumbnailImage = () => {
      thumbnailFile.value.click();
    };
    const back = () => {
      router.back();
    };
    const changeMode = mode => {
      isDetail.value = mode;
    };

    const submit = async () => {
      if (!checkValidate()) {
        return false;
      }
      if (!id) {
        swal.createConfirm(create);
      } else {
        swal.updateConfirm(update);
      }
    };
    const checkValidate = () => {
      if (category.value.name.trim() === '') {
        swal.alert('프로모션 제목이 입력되지 않았습니다.');
        return false;
      }
      if (category.value.idx.toString().trim() === '') {
        swal.alert('프로모션 상품이 선택되지 않았습니다.');
        return false;
      }

      return true;
    };
    const create = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      const response = await apiService.post(
        '/partners/post-category/',
        category.value,
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        swal.successConfirm(async () => {
          router.push('/partners-post-category');
        });
      } else {
        alert(response.data.message);
      }
    };
    const update = async () => {
      const response = await apiService.put(
        `/partners/post-category/${id}`,
        category.value,
      );
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        swal.successConfirm(async () => {
          router.push('/partners-post-category');
        });
      } else {
        alert(response.data.message);
      }
    };
    return {
      submitForm,
      id,
      isDetail,
      category,
      isSubmitting,
      thumbnailFile,
      thumbnailImg,
      showThumbnailImage,
      submit,
      uploadThumbnailFile,
      openThumbnailImage,
      back,
      changeMode,
    };
  },
});
</script>
