<template>
  <div class="loadingBox" v-show="isLoading">
    <div class="imageContainer">
      <img src="/img/sample.gif" style="width: 50px;vertical-align: middle;" />
    </div>
    <div class="backgroundContainer"></div>
  </div>
</template>

<script>
import { ref, toRefs, watch, defineComponent } from 'vue'
export default defineComponent({
  name: "Loading",
  props: {
    isLoading: Boolean,
  },
});
</script>
<style scoped>
.loadingBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
}
.loadingBox .imageContainer {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  z-index: 11;
  text-align: center;
  vertical-align: middle;
  line-height: 120px;
  left: 50%;
  top: 50%;
  margin-left: -60px;
  margin-top: -60px;
}
.loadingBox .backgroundContainer {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>