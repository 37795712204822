
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import dateTimeHelper from "../../helper/datetime.helper";
import {default as UserModel, User} from "../../models/user.model";
import SubmitButton from "../../components/ui/SubmitButton.vue";
import FileUpload from "../../components/ui/FileUpload.vue";
import swal from '../../helper/swal.helper'

export default defineComponent({
	components: {
		SubmitButton,
		FileUpload
	},
	setup() {
		const router = useRouter()
		let isSubmitting = ref(false)
		let thumbnailFile = ref<any>(null)
		let userInfo = ref<User>({
			avatar: '',
			date_last_login: 0,
			date_signup: 0,
			email_address: '',
			fishing_type_sea: [],
			fishing_type_water: [],
			my_favorite_spots: [],
			nick_name: '',
			preferred_type: [],
			uid: ''
		})
		let dateSignUp = ref<string>('')
		let dateLastLogin = ref<string>('')
		let errors = ref<Array<string>>([])

		async function onSubmitAddForm() {
			isSubmitting.value = true

			let avatar = await thumbnailFile.value?.uploadImage()
			if (avatar.length <= 0) {
				avatar = 'https://firebasestorage.googleapis.com/v0/b/sgma-uhshin.appspot.com/o/no_image.png?alt=media&token=b6d41b56-4dc7-468a-bc03-81a0811bfdb8'
			}
			let data = {
				email_address: userInfo.value.email_address,
				nick_name: userInfo.value.nick_name,
				date_signup: dateSignUp.value ? dateTimeHelper.dateToTimestamp(dateSignUp.value) : 0,
				date_last_login: dateLastLogin.value ? dateTimeHelper.dateToTimestamp(dateLastLogin.value) : 0,
				preferred_type: userInfo.value.preferred_type,
				avatar: avatar
			}

			errors.value = [];
			// check if email is existed
			let conditionsEmail = [
				['email_address', '==', data.email_address],
			];
			let itemEmail = await UserModel.getAll(conditionsEmail)
			if (typeof itemEmail !== 'undefined' && !itemEmail.empty && itemEmail.length > 0) {
				errors.value.push('Email already exists in our system');
				swal.alert('Email already exists in our system')
			}
		
			//check if username is existed
			let conditionsUsername = [
				['nick_name', '==', data.nick_name],
			];
			let itemUsername = await UserModel.getAll(conditionsUsername)
			if (typeof itemUsername !== 'undefined' && !itemUsername.empty && itemUsername.length > 0) {
				errors.value.push('Nickname already exists in our system');
				swal.alert('Nickname already exists in our system')
			}

			if (!errors.value.length) {
				let addResult = await UserModel.create(data)
				if (addResult.status) {
					isSubmitting.value = false
					router.push('/user-management')
				} else {
					errors.value.push(addResult.message)
					swal.alert(addResult.message)
					isSubmitting.value = false
				}
			}
		}

		return {
			onSubmitAddForm,
			thumbnailFile,
			isSubmitting,
			userInfo,
			dateSignUp,
			dateLastLogin,
			errors
		}
	}
});
