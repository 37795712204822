import axios from 'axios';
import pluralize from 'pluralize';
import cookieHelper from '../../helper/cookie.helper';
import stringHelper from '../../helper/string.helper';
import router from '../../router/router';

interface ApiResponse {
  status: boolean,
  message: string,
  data: any,
  headers?: any
}

export class ApiService {
  protected httpClient = axios.create({
    baseURL: `${process.env.VUE_APP_BACKEND_API_URI}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  public async authenticate(postData: any) {
    return this._makeRequest('/auth/login', 'post', postData, false);
  }

  public async verifyToken() {
    return this.httpClient.post('/auth/verify-token')
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async refreshToken() {
    const httpClient = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND_API_URI}`,
      headers: {
        Authorization: `Bearer ${cookieHelper.getCookie('refresh_token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return httpClient.post('/auth/refresh-token')
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        cookieHelper.createCookie('access_token', response.data.access_token, 86400);
        cookieHelper.createCookie('permissions', response.data.permissions, 86400);
        cookieHelper.createCookie('refresh_token', response.data.refresh_token, parseInt(response.data.refresh_token_life));
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async getAdminAccounts() {
    return this.httpClient.get('admin-accounts')
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async deleteAdminAccount(id: any) {
    return this.httpClient.delete(`admin-accounts/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async getAdminAccount(id: any) {
    return this.httpClient.get(`admin-accounts/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async updateAdminAccount(id: any, putData: any) {
    return this.httpClient.put(`admin-accounts/${id}`, putData)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async addAdminAccount(postData: any) {
    return this.httpClient.post('admin-accounts', postData)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param id
   */
  public async previewPost(id: any) {
    return this.httpClient.get(`posts/preview/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  public async previewKnot(id: any) {
    return this.httpClient.get(`fishing-knots/preview/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param id
   */
  public async previewNotification(id: any) {
    return this.httpClient.get(`notifications/preview/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param id
   */
  public async updatePostViewCount(id: any) {
    return this.httpClient.put(`posts/update-view-count/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param id
   */
  public async updateProPostViewCount(id: any) {
    return this.httpClient.put(`pro-posts/update-view-count/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param id
   */
  public async updateNoticeViewCount(id: any) {
    return this.httpClient.put(`notifications/update-view-count/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * Get users summary
   */
  public async userSummary() {
    return this._makeRequest('/users/summary', 'get', [], true);
  }

  /**
   *
   * Get users summary
   */
  public async userDashboardSummary(filter?: any) {
    return this._makeRequest('/summaries/user', 'get', filter, true);
  }

  /**
   *
   * Get users summary
   */
  public async getUserEventsLog(postData: any) {
    return this._makeRequest('/users/user-events', 'post', postData, true);
  }

  /**
   *
   * Get fishing grams summary
   */
  public async fishingGramSummary() {
    return this._makeRequest('/fishing-grams/summary', 'get', [], true);
  }

  /**
   *
   * Import partner data
   */
  public async importPartnerData(postData: any) {
    return this._makeRequest('/partners/import-data', 'post', postData, true);
  }

  /**
   *
   * Delete blind data
   */
  public async deleteBlindData(data: any) {
    return this._makeRequest(`/blinds/${data.type}/${data.target_id}/${data.parent_id}`, 'delete');
  }

  /**
   *
   * Export users data by csv file
   */
  public async exportUser() {
    this.httpClient.defaults.headers.common.Authorization = `Bearer ${cookieHelper.getCookie('access_token')}`;
    return this.httpClient.post('users/export-users-data')
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param id
   */
  public async previewProPost(id: any) {
    return this.httpClient.get(`pro-posts/preview/${id}`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   *
   * @param collectionName
   * @param conditions
   */
  public async getListDataByCollection(collectionName: string, conditions: any, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(collectionName, 'get', conditions);
  }

  /**
   * 엑셀 다운로드
   */
  public async downloadExcel(collectionName: string, conditions: any, keepCollectionName = false) {
    this.httpClient.defaults.responseType = 'blob';
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);

    const params = new URLSearchParams(conditions);

    // const response = await this.httpClient.get(`${collectionName}/excel/download`, { params });
    const response = await this._makeRequest(`${collectionName}/excel/download`, 'get', conditions);
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    // console.log(`download excel response: ${JSON.stringify(response)}`);
    const fileName = response?.headers['content-disposition']?.split('filename=')?.[1];
    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);

    fileLink.click();
  }

  /**
   * 파트너스 가입 승인 요청
   * @param partnersId
   * @returns
   */
  public async approvePartnersJoin(partnersId: number) {
    this.httpClient.defaults.headers.common.Authorization = `Bearer ${cookieHelper.getCookie('access_token')}`;
    return this.httpClient.patch(`partners/join/${partnersId}/approve`)
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   * 파트너스 가입 반려 요청
   * @param partnersId
   * @returns
   */
  public async rejectPartnersJoin(partnersId: number, reject_remark: string) {
    this.httpClient.defaults.headers.common.Authorization = `Bearer ${cookieHelper.getCookie('access_token')}`;
    return this.httpClient.patch(`partners/join/${partnersId}/reject`, { reject_remark })
      .then((response) => {
        if (typeof response.data.code !== 'undefined') {
          return { status: false, data: response.data.message };
        }
        return { status: true, data: response.data };
      })
      .catch((e) => ({ status: false, data: e }));
  }

  /**
   * 공통코드 타입 리스트 조회
   * @returns
   */
  public async getCommonCodeTypes(url: string) {
    this.httpClient.defaults.headers.common.Authorization = `Bearer ${cookieHelper.getCookie('access_token')}`;
    return this.httpClient.get(url);
  }

  /**
   * bulk 업데이트
   * @param collectionName
   * @param updateData
   * @param keepCollectionName
   * @returns
   */
  public async bulkUpdate(collectionName: string, updateData: any, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(collectionName, 'put', updateData);
  }

  public async findAllFishinggamBest(collectionName: string, keepCollectionName = false) {
    return this._makeRequest('/fishing-grams-best-candidates/best', 'get', [], true);
    // Build restful url by collection name
    // collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    // return this._makeRequest(collectionName, 'get', updateData);
  }

  /**
   * uid로 상세 정보 조회
   * @param collectionName
   * @param uid
   * @param keepCollectionName
   * @returns
   */
  public async findByUidAndCollection(collectionName: string, uid: string, keepCollectionName = false) {
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(`${collectionName}/uid/${uid}`, 'get');
  }

  public async findByBusinessTypeCodeAndId(collectionName: string, keepCollectionName = false) {
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(collectionName, 'get');
  }

  /**
   * @param collectionName
   * @param filter
   */
  public async findOneByCollection(collectionName: string, filter: Array<any>, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(collectionName, 'get');
  }

  /**
   *
   * @param collectionName
   * @param id
   */
  public async findByIdAndCollection(collectionName: string, id: string, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(`${collectionName}/${id}`, 'get');
  }

  /**
   * @param collectionName
   * @param data
   */
  public async createByCollection(collectionName: string, data: any, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(collectionName, 'post', data);
  }

  /**
   * @param collectionName
   * @param updateData
   * @param id
   */
  public async updateByCollection(collectionName: string, updateData: any, id: string, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(`${collectionName}/${id}`, 'put', updateData);
  }

  /**
   * @param collectionName
   * @param id
   */
  public async deleteByCollection(collectionName: string, id: string, keepCollectionName = false) {
    // Build restful url by collection name
    collectionName = this.convertCollectionName(collectionName, keepCollectionName);
    return this._makeRequest(`${collectionName}/${id}`, 'delete');
  }

  private convertCollectionName(collectionName: string, keepCollectionName = false) {
    // Build restful url by collection name

    return keepCollectionName ? collectionName : stringHelper.dasherize(pluralize(collectionName));
  }

  private async _makeRequest(requestUrl: string, method: string, requestData: Array<any> = [], auth = true): Promise<ApiResponse> {
    try {
      if (auth) {
        this.httpClient.defaults.headers.common.Authorization = `Bearer ${cookieHelper.getCookie('access_token')}`;
      }
      let result: { [key: string]: any } = {};
      switch (method) {
        case 'get': {
          const params = new URLSearchParams(requestData);
          result = await this.httpClient.get(requestUrl, { params });
          break;
        }
        case 'post':
          result = await this.httpClient.post(requestUrl, requestData);
          break;
        case 'put':
          result = await this.httpClient.put(requestUrl, requestData);
          break;
        case 'patch':
          result = await this.httpClient.patch(requestUrl, requestData);
          break;
        case 'delete':

          if (requestData) {
            console.log(requestData);
            result = await this.httpClient.delete(requestUrl, { data: requestData });
          } else {
            result = await this.httpClient.delete(requestUrl);
          }
          break;
      }
      return {
        status: true,
        message: '',
        headers: result.headers,
        data: result.data,
      };
    } catch (error) {
      if (error.response.status) {
        switch (error.response.status) {
          case 401:
            if (cookieHelper.getCookie('refresh_token')) {
              const refreshTokenResult = await this.refreshToken();
              if (refreshTokenResult.status) {
                // Make request again
                return await this._makeRequest(requestUrl, method, requestData);
              }
              cookieHelper.createCookie('refresh_token', '', -1);
              cookieHelper.createCookie('access_token', '', -1);
              cookieHelper.createCookie('permissions', '', -1);
              router.push({ name: 'Login' });
            }
            return {
              status: false,
              message: error.response.data.message ? error.response.data.message : error,
              data: {},
            };
          default:
            return {
              status: false,
              message: error.response.data.message ? error.response.data.message : error,
              data: {},
            };
        }
      } else {
        return {
          status: false,
          message: error,
          data: {},
        };
      }
    }
  }

  public async request(method: string, url: string, requestData: any = {}) {
    return this._makeRequest(url, method, requestData);
  }

  public async get(url: string, requestData: any = {}) {
    return this._makeRequest(url, 'get', requestData);
  }

  public async post(url: string, requestData: any = {}) {
    return this._makeRequest(url, 'post', requestData);
  }

  public async put(url: string, requestData: any = {}) {
    return this._makeRequest(url, 'put', requestData);
  }

  public async delete(url: string, requestData: any = {}) {
    return this._makeRequest(url, 'delete', requestData);
  }

  public async patch(url: string, requestData: any = {}) {
    return this._makeRequest(url, 'patch', requestData);
  }

  public async getPresignedUrl(dataCd: string, requestData: any = {}) {
    return this._makeRequest(
      `/partners-mall/product/pre-signed-url${dataCd}`,
      'get', requestData,
    );
  }

  public async uploadImage(presignedUrl: string, file: any) {
    const httpClient = axios.create({
      baseURL: '',
      headers: {
        'Content-Type': file.type,
      },
    });
    const response = await httpClient.put(presignedUrl, file);
    return response;
  }
}
