
import { defineComponent } from "vue";
import exAxios from "../../services/maps/exios.services";
import Loading from "../../components/ui/Loading.vue";
import WaterQuality from "../../components/ui/WaterQuality.vue";
import WaterLevel from "../../components/ui/WaterLevel.vue";
import mapManager from "../../services/maps/mapManager.services";
declare let naver: any;

export default defineComponent({
	name: "Map",
	components: { Loading, WaterQuality, WaterLevel },
	data() {
		return {
			regionGeoJson: [] as any,
			windowHeight: 0,
			isLoading: false,
			type: "WATER",
			map: {} as any,
			searchResults: [],
			waterQuality: null,
			waterLevel: null,
			totalPointsCount : 0,
			mapManagerController: {} as any,
			value: ''
		};
	},
	computed: {
		fullHeight(): string {
			return `height:${this.windowHeight}px`;
		},
		checkTotalPoints(): any {
			return (
				this.mapManagerController != null &&
				this.mapManagerController.getSpots() != null &&
				this.mapManagerController.getSpots().length > 0
			);
		},
	},
	mounted() {
		// window.checkAndAttachMapScript()
		this.checkWindowHeight();
		this.loadNaverMapFromCDN();
	},
	methods: {
		gotoPoint(gotoPoint: any) {
			this.mapManagerController.gotoPoint(gotoPoint);
		},
		loadNaverMapFromCDN() {
			var scriptTag = document.createElement("script");
			scriptTag.src =
				"https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=rsqbygeix2";
			scriptTag.onload = this.loadMap;
			document.getElementsByTagName("body")[0].appendChild(scriptTag);
		},
		checkWindowHeight() {
			this.windowHeight = document.body.offsetHeight;
			this.windowHeight = this.windowHeight - 50;
		},
		changeSearchKey(searchKey: any) {
			if (this.checkTotalPoints) {
				this.searchResults = this.mapManagerController
					.getSpots()
					.filter((spot: { name: string|any[]; }) => spot.name.indexOf(searchKey) != -1);
			}
		},
		loadMap() {
			this.isLoading = true;
			var mapOptions = {
				useStyleMap: true,
				center: new naver.maps.LatLng(33.38, 126.55),
				zoom: 11,
			};
			this.map = new naver.maps.Map("mapContainer", mapOptions);
			this.mapManagerController = mapManager(this.map, this);
			this.mapManagerController.mapClickEvent();
			this.loadAdministrativeArea();
		},
		loadAdministrativeArea() {
			exAxios.get(
				"/api/map/country",
				{},
				(res: { status: number; data: { result: any[]; }; }) => {
					if (
						res.status == 200 &&
						res.data &&
						res.data.result &&
						res.data.result.length > 0
					) {
						res.data.result.forEach((geojson) => {
							geojson["label"] = geojson.properties.area1;
							geojson["value"] = geojson.properties.area1;
							this.regionGeoJson.push(geojson);
						});
						this.gotoMapLocation(this.regionGeoJson[0]);
					}
					this.isLoading = false;
				},
				(error: any) => {
					console.log(error);
					this.isLoading = false;
				}
			);
		},
		gotoMapLocation(selectGeo: any) {
			var selectP = new naver.maps.LatLngBounds(
				new naver.maps.LatLng(selectGeo.bbox[1], selectGeo.bbox[0]),
				new naver.maps.LatLng(selectGeo.bbox[3], selectGeo.bbox[2])
			);
			this.map.fitBounds(selectP);
			this.loadWaterPlaces({ geo_data: selectGeo.geometry });
		},
		loadWaterPlaces(data: { geo_data: any; }) {
			exAxios.post(
				"/api/water/list",
				data,
				(res: { status: number; data: { result: any[]; }; }) => {
					if (
						res.status == 200 &&
						res.data &&
						res.data.result &&
						res.data.result.length > 0
					) {
						this.removeMarkerAll();
						res.data.result.map((water) => (water["type"] = "water"));
						this.mapManagerController.makeMarkerFromSpots(res.data.result);
						this.totalPointsCount = res.data.result.length;
					}
				},
				(error: any) => {
					console.log(error);
				}
			);
		},
		removeMarkerAll() {
			this.mapManagerController.removeMarkerAll();
		},
		loadingAction(ck: boolean) {
			this.isLoading = ck;
		},
		loadSpots(e: { target: { value: any; }; }) {
			this.removeMarkerAll();
			var selectGeo = this.regionGeoJson.filter((geo: any) => {
				return geo.properties.area1 == e.target.value;
			});
			this.gotoMapLocation(selectGeo[0]);
		},
		clearWaterInfos() {
			this.waterLevel = null;
			this.waterQuality = null;
		},
		updateWaterQuality(data: null) {
			this.waterQuality = data;
		},
		updateWaterLevel(data: null) {
			this.waterLevel = data;
		},
	},
});
