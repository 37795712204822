import {BaseModel} from "./base.model";

export interface AdminAccount {
    id: string,
    account_type: string,
    email: string,
    name: string,
    password: string,
    created_at: number,
}

class AdminAccountModel extends BaseModel {
    protected collectionName = 'admin_accounts'

    private static _instance: AdminAccountModel

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default AdminAccountModel.Instance