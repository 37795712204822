
import { ref, defineComponent } from 'vue'

export default defineComponent({
	name: 'Splash',
	props: {},
	setup: () => {
		const count = ref(0)
		return { count }
	}
})
