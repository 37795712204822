import {BaseModel} from "./base.model";

export interface ProPost {
    category_id: string,
    title: string,
    desc: string,
    contents_url: string,
    landing_url: string,
    pin: boolean,
    show: boolean,
    thumbnail: string,
    published_at: number,
    created_at: number,
    updated_at: number,
    order: number,
    view_count: number,
    show_view_count: boolean,
    no_margin_layout: boolean,
}

class ProPostModel extends BaseModel {
    protected collectionName = 'pro_posts'

    private static _instance: ProPostModel

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default ProPostModel.Instance