import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-body preview-panel" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 1,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["card no-margin no-border", {loading: _ctx.loading}]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", {
          class: ["col-md-3 post-content", { 'post-content-padding': !_ctx.data.no_margin_layout }]
        }, [
          (!_ctx.errors.length)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                innerHTML: _ctx.data.description
              }, null, 8, ["innerHTML"]))
            : _createCommentVNode("", true),
          (_ctx.errors.length)
            ? (_openBlock(), _createBlock("div", _hoisted_3, " Notification is not found. "))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ], 2))
}