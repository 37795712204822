
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const apiService = new ApiService();
    const categoryBody = ref<any>(null);
    const categoryList = ref([]);
    onMounted(async () => {
      categoryList.value = await getCategoryList();
    });
    const getCategoryList = async () => {
      const response = await apiService.get('/partners-mall/product-category', {
        page: 0,
        size: 100,
      });
      if (response.data.statusCode === 200) {
        return response.data.items;
      }
      alert(response.data.message);
      return [];
    };
    const viewDetail = (id: string) => {
      console.log(id);
      router.push(`/commerce/product-category/register/${id}`);
    };
    const goResgiter = () => {
      console.log('goResgister');
      router.push(
        `/commerce/product-category/register/new?idx=${categoryList.value.length}`,
      );
    };
    const setEditMode = (isEditor: boolean) => {
      console.log('set', isEditor);
    };
    return {
      categoryList,
      categoryBody,
      viewDetail,
      goResgiter,
      setEditMode,
    };
  },
};
