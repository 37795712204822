
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const placingOrderInfo = ref({});
    onMounted(async () => {
      placingOrderInfo.value = await getPlacingOrder();
      console.log(placingOrderInfo.value);
    });
    const getPlacingOrder = async () => {
      const response = await apiService.get(
        '/partners-mall/placing-order/after/dash-board',
      );
      return response.data.items;
    };
    const viewDetail = (id: string) => {
      console.log(id);
      // router.push(`/commerce/seller/detail/${id}`);
    };
    const goResgiter = () => {
      console.log('goResgister');
      router.push('/commerce/seller/register');
    };
    return {
      placingOrderInfo,
      viewDetail,
      goResgiter,
    };
  },
};
