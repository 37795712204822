
import { defineComponent, ref, onMounted } from 'vue';
import {
  Role,
  default as AdminRolesModel,
} from '../../models/adminRoles.model';
import { default as PermissionsModel } from '../../models/permissions.model';
import { useRouter } from 'vue-router';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'AdminRolesList',
  setup: () => {
    const router = useRouter();
    let loading = ref(false);
    let roles = ref<any[]>([]);
    let permissions = ref<any[]>([]);

    onMounted(async () => {
      loading.value = true;
      roles.value = await AdminRolesModel.getAll();
      let permissionInfo = await PermissionsModel.getAll([], [], 1, 1000);
      for (let key in roles.value) {
        let role = roles.value[key];
        permissions.value[role.id] = [];
        for (let k in permissionInfo) {
          let permission: any = permissionInfo[k];
          if (role.permissions.includes(permission.id)) {
            permissions.value[role.id].push(permission.name);
          }
        }
      }
      loading.value = false;
    });

    async function deleteRecord(id: any) {
      swal.simpleDeleteConfirm(async function() {
        loading.value = true;
        await AdminRolesModel.delete(id);
        roles.value = await AdminRolesModel.getAll();
        let permissionInfo = await PermissionsModel.getAll([], [], 1, 1000);
        for (let key in roles.value) {
          let role = roles.value[key];
          permissions.value[role.id] = [];
          for (let k in permissionInfo) {
            let permission: any = permissionInfo[k];
            if (role.permissions.includes(permission.id)) {
              permissions.value[role.id].push(permission.name);
            }
          }
        }
        loading.value = false;
      });
    }

    async function editRecord(id: any) {
      router.push('/admin-roles/edit/' + id);
    }

    return {
      roles,
      deleteRecord,
      editRecord,
      loading,
      permissions,
    };
  },
});
