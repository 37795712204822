import { createApp } from 'vue';
import { store, key } from './store';
import App from './App.vue';
import router from './router/router';
import './assets/stylesheet/reset.css';
import './assets/stylesheet/style.css';
import './assets/stylesheet/coreui.min.css';
import './assets/stylesheet/custom.css';
import './assets/stylesheet/adminlte.min.css';
import './assets/stylesheet/all.min.css';
import './assets/javascript/coreui.bundle.min.js';

import DashboardLayout from './components/layout/DashboardLayout.vue';
import DefaultLayout from './components/layout/DefaultLayout.vue';
import PrtnersLayout from './components/layout/PartnersLayout.vue';
import CommerceLayout from './components/layout/CommerceLayout.vue';
import UsinLayout from './components/layout/UsinLayout.vue';
import CommonLayout from './components/layout/CommonLayout.vue';
import EmptyLayout from './components/layout/EmptyLayout.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp(App);

app.component('default-layout', DashboardLayout);
app.component('new-layout', DefaultLayout);
app.component('partners-layout', PrtnersLayout);
app.component('commerce-layout', CommerceLayout);
app.component('usin-layout', UsinLayout);
app.component('common-layout', EmptyLayout);
app.component('empty-layout', EmptyLayout);

app.use(store, key);
app.use(CKEditor);
app.use(router);
app.mount('#app');
