
import { defineComponent, ref, onMounted } from 'vue';
import { ApiService } from '../services/backend-api/api.services';
import dateTimeHelper from '../helper/datetime.helper';
import numberHelper from '../helper/number.helper';
import UsersChart from '../components/ui/charts/UsersChart.vue';
import swal from '../helper/swal.helper';

export default defineComponent({
  name: 'PartnerList',
  props: {},
  components: {
    UsersChart,
  },
  setup: () => {
    const loading = ref(true);
    const isReady = ref(false);
    const data = ref<any>({});
    const labels = ref<string[]>([]);
    const activeLabels = ref<string[]>([]);
    const totalData = ref<number[]>([]);
    const newData = ref<number[]>([]);
    const activeData = ref<number[]>([]);
    const filterConditions = ref<Array<any>>([]);
    const filter = ref({
      toDate: '',
      fromDate: '',
    });
    const apiService = new ApiService();
    onMounted(async () => {
      const previewData = await apiService.userDashboardSummary();
      await updateUserChart(previewData);
    });

    async function searchHandler() {
      filterConditions.value = [];
      if (filter.value.fromDate !== null && filter.value.fromDate.length > 0) {
        filterConditions.value.push([
          'fromDate',
          dateTimeHelper.dateToTimestamp(filter.value.fromDate),
        ]);
      }
      if (filter.value.toDate !== null && filter.value.toDate.length > 0) {
        filterConditions.value.push([
          'toDate',
          dateTimeHelper.dateToTimestamp(filter.value.toDate),
        ]);
      }
      if (filter.value.fromDate > filter.value.toDate) {
        swal.alert('From Date must be smaller than or equal to To Date');
        return false;
      }
      loading.value = true;
      isReady.value = false;
      const previewData = await apiService.userDashboardSummary(
        filterConditions.value,
      );
      await updateUserChart(previewData);
    }

    async function updateUserChart(previewData: any) {
      previewData.data.reverse();
      labels.value = [];
      activeLabels.value = [];
      totalData.value = [];
      newData.value = [];
      activeData.value = [];
      if (previewData) {
        previewData.data.forEach((item: any, key: number) => {
          if (item.for_date) {
            labels.value.push(
              dateTimeHelper.timestampToDateTime(item.for_date, 'YYYY-MM-DD'),
            );
            totalData.value.push(item.total);
            newData.value.push(item.new_user);
            activeData.value.push(item.active);
            if (item.for_date_active) {
              activeLabels.value.push(
                dateTimeHelper.timestampToDateTime(
                  item.for_date_active,
                  'YYYY-MM-DD',
                ),
              );
            } else {
              activeLabels.value.push('');
            }
          }
        });
      }

      if (previewData.status) {
        data.value = previewData.data;
        isReady.value = true;
      } else {
        swal.alert(previewData.data.message);
      }
      loading.value = false;
    }

    return {
      data,
      loading,
      dateTimeHelper,
      numberHelper,
      labels,
      activeLabels,
      totalData,
      newData,
      activeData,
      isReady,
      filter,
      searchHandler,
    };
  },
});
