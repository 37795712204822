
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import CrudModel from '../../models/partnersNotice.model';

export default defineComponent({
  components: {
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isSubmitting = ref(false);
    const errors = ref<string[]>([]);
    const customEditor = ref<any>(null);
    const notice = ref({
      title: '',
      detail: '',
    });

    async function submitForm() {
      isSubmitting.value = true;
      errors.value = [];

      // content encoding base 64
      const content = await customEditor.value.getContents();
      notice.value.detail = btoa(unescape(encodeURIComponent(content)));

      const addResult = await CrudModel.create(notice.value);
      if (addResult.status) {
        isSubmitting.value = false;
        await router.push({
          path: '/partners-notice',
          query: route.query,
        });
      } else {
        errors.value.push(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      errors,
      notice,
      submitForm,
      isSubmitting,
      Editor,
      customEditor,
    };
  },
});
