
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import ElementStyleHelper, {
  UserJoinStateCssStyle,
  UserPaidCssStyle,
} from '@/helper/element-style.helper';
import numberHelper from '@/helper/number.helper';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import swal from '@/helper/swal.helper';
import {
  BooleanTypes,
  constants,
  IsUserSearchEventAt,
  UserJoinStateCode,
  UserPaidCode,
  BookingRequestState,
} from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import CrudModel from '../../models/partners-member.model';
import { ApiService } from '../../services/backend-api/api.services';

export default defineComponent({
  name: 'PartnersList',
  props: {},
  components: {
    PaginationV2,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const partners = ref<Array<any>>([]);
    const apiService = new ApiService();
    const partnersSummary = ref({});
    const loading = ref(false);
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const filter = ref({
      business_type_cd: '',
      name: '',
      contact: '',
      cell_num: '',
      boat_yn: '',
      spot_yn: '',
      shop_yn: '',
      sign_out_yn: '',
      paid_status: '',
      paid_cd: '',
      partners_name: '',
      booking_request_state: '',
    });
    type filterKeyType =
      | 'business_type_cd'
      | 'name'
      | 'contact'
      | 'cell_num'
      | 'boat_yn'
      | 'spot_yn'
      | 'shop_yn'
      | 'sign_out_yn'
      | 'paid_cd'
      | 'partners_name'
      | 'paid_status'
      | 'booking_request_state';
    const perpage = constants().DEFAULT_PERPAGE;

    const orderOptions = ref([['create_at', 'desc']]);
    const filterConditions = ref<Array<any>>([]);

    onMounted(async () => {
      await searchHandler();
    });

    const updateData = (items: Array<any>) => {
      loading.value = false;
      partners.value = items;
    };
    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    const editRecord = (id: string) => {
      router.push({
        path: `/partners-member/edit/${id}`,
        query: route.query,
      });
    };

    async function searchHandler() {
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];

      // '구독상태' 값이 회원탈퇴이면, 회원탈퇴 조건을 추가합니다.
      if (filter.value.paid_status === BooleanTypes.Y) {
        filter.value.paid_cd = '';
        filter.value.sign_out_yn = BooleanTypes.Y;
      } else {
        filter.value.sign_out_yn = '';
        filter.value.paid_cd = filter.value.paid_status;
      }

      setSearchFilterCondition('business_type_cd');
      setSearchFilterCondition('name');
      setSearchFilterCondition('cell_num');
      setSearchFilterCondition('contact');

      setSearchFilterCondition('sign_out_yn');
      setSearchFilterCondition('paid_cd');
      setSearchFilterCondition('partners_name');
      setSearchFilterCondition('paid_status');
      setSearchFilterCondition('booking_request_state');
    }

    /**
     * 데이터 검색 조건 셋팅
     * @param filterKey
     * @param replaceFilterValue
     */
    function setSearchFilterCondition(
      filterKey: filterKeyType,
      replaceFilterValue?: string,
    ): void {
      if (filter.value[filterKey].trim() || replaceFilterValue) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? filter.value[filterKey],
        ]);
      } else if (
        // 페이지가 새로고침되었을 때 또는 뒤로가기로 왔을 때 url에 검색 관련 쿼리스트링이 있으면, 검색 조건에 추가 합니다.
        ((!filter.value[filterKey].trim() && route.query[filterKey]?.length) ||
          replaceFilterValue) &&
        userSearchEventFlag.value === IsUserSearchEventAt.N
      ) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? route.query[filterKey],
        ]);
        filter.value[filterKey] =
          replaceFilterValue ?? `${route.query[filterKey]}`;
      }
    }

    /**
     * Reset 버튼을 클릭했을 때 검색 조건을 초기화 합니다.
     */
    async function resetFilter() {
      filter.value.business_type_cd = '';
      filter.value.name = '';
      filter.value.contact = '';
      filter.value.cell_num = '';
      filter.value.boat_yn = '';
      filter.value.spot_yn = '';
      filter.value.shop_yn = '';
      filter.value.sign_out_yn = '';
      filter.value.paid_cd = '';
      filter.value.partners_name = '';
      filter.value.paid_status = '';
      filter.value.booking_request_state = '';

      userSearchEventFlag.value = IsUserSearchEventAt.Y;
      await searchHandler();
    }

    const linkToProduct = (partnersId: number) => {
      router.push({
        path: '/partners-product',
        query: {
          partnersId,
          page: 1,
        },
      });
    };

    const changeRecommend = async (id: string, name: string, event: any) => {
      if (!name) {
        swal.alert('사업장 소개가 등록되지 않은 선사는 추천할 수 없습니다.');
        return false;
      }
      const response = await apiService.put(
        `/partners/member/${id}/recommend`,
        {},
      );
      if (response.data.isDeleted) {
        swal.success(`'${name}' 추천이 취소되었습니다.`);
      } else {
        swal.success(`'${name}' 추천이 등록되었습니다..`);
      }
    };

    return {
      partners,
      editRecord,
      dateTimeHelper,
      numberHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      CrudModel,
      searchHandler,
      resetFilter,
      partnersSummary,
      IsUserSearchEventAt,
      userSearchEventFlag,
      BooleanTypes,
      UserJoinStateCode,
      UserPaidCode,
      UserPaidCssStyle,
      UserJoinStateCssStyle,
      ElementStyleHelper,
      linkToProduct,
      changeRecommend,
      BookingRequestState,
    };
  },
});
