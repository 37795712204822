
import { defineComponent } from 'vue';
import Sidebar from './AppSidebar.vue';
import Header from './AppHeader.vue';
import Footer from './AppFooter.vue';
import Splash from '../Splash.vue';
import Loading from '../ui/AdminLoading.vue';

export default defineComponent({
  components: {
    Header,
    Sidebar,
    Footer,
    Splash,
    Loading,
  },
  computed: {
    loaded() {
      return false;
    },
  },
});
