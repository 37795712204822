
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { ContentViewTypes } from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import swal from '../../helper/swal.helper';
import PostModel, { Post } from '../../models/post.model';
import PostCategoriesModel from '../../models/postCategories.model';

export default defineComponent({
  components: {
    FileUpload,
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter();
    const thumbnailFile = ref<any>(null);
    const homeThumbnailFile = ref<any>(null);
    const customEditor = ref<any>(null);
    const isSubmitting = ref(false);
    const categories = ref([]);
    const contentViewType = ref(false);
    const post = ref<Post>({
      title: '',
      id: '',
      category_id: '',
      pin: false,
      show: true,
      landing_url: '',
      contents_url: '',
      desc: '',
      thumbnail: '',
      home_thumbnail: '',
      published_at: 0,
      updated_at: 0,
      created_at: 0,
      view_count: 0,
      order: 0,
      show_view_count: false,
      show_home: false,
      show_home_order: 0,
      show_more: false,
      show_more_order: 0,
      event_period_from: 0,
      event_period_to: 0,
      no_margin_layout: false,
      view_type: ContentViewTypes.WEB_VIEW,
    });
    const publishTime = ref<string>('');
    const eventPeriodFrom = ref<string>('');
    const eventPeriodTo = ref<string>('');

    onMounted(async () => {
      categories.value = await PostCategoriesModel.getAll();
    });

    async function submitForm() {
      isSubmitting.value = true;

      const data = {
        category_id: post.value.category_id,
        created_at: Date.now(),
        updated_at: Date.now(),
        desc: await customEditor.value?.getContents(),
        pin: post.value.pin,
        landing_url: post.value.landing_url,
        contents_url: post.value.contents_url,
        published_at: Date.now(),
        show: post.value.show,
        thumbnail: await thumbnailFile.value?.uploadImage(),
        home_thumbnail: await homeThumbnailFile.value?.uploadImage(),
        title: post.value.title,
        view_count: post.value.view_count,
        order: post.value.order,
        show_view_count: post.value.show_view_count,
        show_home: post.value.show_home,
        show_home_order: post.value.show_home_order,
        show_more: post.value.show_more,
        show_more_order: post.value.show_more_order,
        event_period_from: eventPeriodFrom.value
          ? dateTimeHelper.dateToTimestamp(eventPeriodFrom.value)
          : 0,
        event_period_to: eventPeriodTo.value
          ? dateTimeHelper.dateToTimestamp(eventPeriodTo.value)
          : 0,
        no_margin_layout: post.value.no_margin_layout,
        view_type: contentViewType.value
          ? ContentViewTypes.WEB_BROWSER_LINK
          : ContentViewTypes.WEB_VIEW,
      };
      const addResult = await PostModel.create(data);
      if (addResult.status) {
        isSubmitting.value = false;
        router.push('/post');
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      post,
      thumbnailFile,
      homeThumbnailFile,
      submitForm,
      categories,
      isSubmitting,
      publishTime,
      eventPeriodFrom,
      eventPeriodTo,
      customEditor,
      contentViewType,
    };
  },
});
