<template>
	<div>
		<canvas :id="chartId"></canvas>
	</div>
</template>

<script>
import { defineComponent, onMounted, toRefs, watch, ref } from 'vue'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default defineComponent({
	name: 'UsersChart',
	props: {
		chartId: {
			type: String,
			required: false,
			default: function () {
				return "sample-chart"
			}
		},
		labels: {
			type: Array,
			required: false,
			default: function () {
				return []
			}
		},
		data: {
			type: Array,
			required: false,
			default: function () {
				return []
			}
		},
		datasetsLabel: {
			type: String,
			required: false,
			default: function () {
				return "Data"
			}
		},
		datasetsColor: {
			type: String,
			required: false,
			default: function () {
				return "#47b784"
			}
		},
		datasetsBackgroundColor: {
			type: String,
			required: false,
			default: function () {
				return "rgba(71, 183,132,.5)"
			}
		},
		isReady: {
			type: Boolean,
			required: false,
			default: function () {
				return false
			}
		},
	},
	setup: (props) => {
		const { labels, data, datasetsLabel, chartId, datasetsColor, datasetsBackgroundColor } = toRefs(props)
		let chart = ref();
		watch([data], (newValue, oldValue) => {
			loadData();
		}, {
			deep: true
		})

		async function loadData() {
			const ctx = document.getElementById(chartId.value);
			if (chart.value) {
				chart.value.destroy();
			}
			
			chart.value = new Chart(ctx, {
				type: "line",
				data: {
					labels: labels.value,
					datasets: [
						{
							label: datasetsLabel.value,
							data: data.value,
							backgroundColor: datasetsBackgroundColor.value,
							borderColor: datasetsColor.value,
						}
					]
				},
				options: {
					responsive: true,
					lineTension: 0,
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
								}
							}
						]
					}
				}
			});
		}

		onMounted(async () => {
			loadData();
		})
	}
})
</script>