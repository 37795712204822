
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import dateTimeHelper from '../../helper/datetime.helper';
import { default as UserModel, User } from '../../models/user.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    let isSubmitting = ref(false);

    let userId = router.currentRoute.value.params.id;
    let uid = router.currentRoute.value.params.uid;
    let user = ref<User>({
      avatar: '',
      date_last_login: 0,
      date_signup: 0,
      email_address: '',
      fishing_type_sea: [],
      fishing_type_water: [],
      my_favorite_spots: [],
      nick_name: '',
      preferred_type: [],
      uid: '',
    });
    let errors = ref<Array<string>>([]);
    let setPreviewImg = ref('');
    onMounted(async () => {
      if (!userId) {
        const savedUser = await UserModel.findByUid(uid);
        console.log(savedUser);
        if (savedUser) {
          userId = savedUser.id;
        }
      }
      user.value = await UserModel.findById(userId);
      if (user.value.date_signup) {
        user.value.date_signup = dateTimeHelper.timestampMilisToDateTimeInput(
          user.value.date_signup,
          'YYYY-MM-DD',
        );
      } else {
        user.value.date_signup = null;
      }
      if (user.value.date_last_login) {
        user.value.date_last_login = dateTimeHelper.timestampMilisToDateTimeInput(
          user.value.date_last_login,
          'YYYY-MM-DD',
        );
      } else {
        user.value.date_signup = null;
      }

      setPreviewImg.value = user.value.avatar;
    });

    let thumbnailFile = ref<any>(null);
    async function onSubmitEditForm() {
      isSubmitting.value = true;

      let avatar = await thumbnailFile.value?.uploadImage();
      if (avatar.length <= 0) {
        avatar = user.value.avatar;
      }
      let dateSignUp = dateTimeHelper.toFirebaseTimestamp(
        user.value.date_signup,
      );
      let dateLastLogin = dateTimeHelper.toFirebaseTimestamp(
        user.value.date_last_login,
      );
      let data = {
        email_address: user.value.email_address,
        nick_name: user.value.nick_name,
        date_signup: dateSignUp.toMillis(),
        date_last_login: dateLastLogin.toMillis(),
        preferred_type: user.value.preferred_type,
        avatar: avatar,
      };

      errors.value = [];
      // check if email is existed
      let conditionsEmail = [
        ['email_address', '==', data.email_address],
        ['id', '!=', userId],
      ];

      let itemEmail = await UserModel.getAll(conditionsEmail);
      if (
        typeof itemEmail !== 'undefined' &&
        !itemEmail.empty &&
        itemEmail.length > 0
      ) {
        errors.value.push('Email already exists in our system');
        swal.alert('Email already exists in our system');
      }

      //check if username is existed
      let conditionsUsername = [
        ['nick_name', '===', data.nick_name],
        ['id', '!==', userId],
      ];
      let itemUsername = await UserModel.getAll(conditionsUsername);
      if (
        typeof itemUsername !== 'undefined' &&
        !itemUsername.empty &&
        itemUsername.length > 0
      ) {
        errors.value.push('Nickname already exists in our system');
        swal.alert('Nickname already exists in our system');
      }

      if (!errors.value.length) {
        let updateResult = await UserModel.update(userId, data);
        if (updateResult.status) {
          router.push('/user-management');
        } else {
          errors.value.push(updateResult.message);
          swal.alert(updateResult.message);
        }
      }

      isSubmitting.value = false;
    }

    return {
      user,
      errors,
      onSubmitEditForm,
      thumbnailFile,
      setPreviewImg,
      isSubmitting,
    };
  },
});
