
import { defineComponent } from 'vue'
export default defineComponent({
	name: "WaterQuality",
	props: {
		waterQuality: {
			type: Object,
		},
	},
	methods: {
		popupAction() {
			// this.waterQuality = null;
		},
	},
});
