
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const orderList = ref([
      {
        id: 1,
        method: '카드',
        total_price: '223200',
        order_name: '호머호',
        order_cell: '010-7132-7008',
        recipient_name: '박호머',
        recipient_cell: '010-7132-7008',
        order_cnt: 7,
      }, {
        id: 2,
        method: '무통장입금',
        total_price: '223200',
        order_name: '호머호',
        order_cell: '010-7132-7008',
        recipient_name: '박호머',
        recipient_cell: '010-7132-7008',
        order_cnt: 7,
      },
      {
        id: 3,
        method: '카드',
        total_price: '223200',
        order_name: '호머호',
        order_cell: '010-7132-7008',
        recipient_name: '박호머',
        recipient_cell: '010-7132-7008',
        order_cnt: 7,
      },
      {
        id: 4,
        method: '카드',
        total_price: '223200',
        order_name: '호머호',
        order_cell: '010-7132-7008',
        recipient_name: '박호머',
        recipient_cell: '010-7132-7008',
        order_cnt: 7,
      },
      {
        id: 5,
        method: '카드',
        total_price: '223200',
        order_name: '호머호',
        order_cell: '010-7132-7008',
        recipient_name: '박호머',
        recipient_cell: '010-7132-7008',
        order_cnt: 3,
      },
    ]);
    const viewDetail = (id: string) => {
      console.log(id);
      // router.push(`/commerce/seller/detail/${id}`);
    };
    const goResgiter = () => {
      console.log('goResgister');
      router.push('/commerce/seller/register');
    };
    return {
      orderList,
      viewDetail,
      goResgiter,
    };
  },
};
