
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { default as model } from '../../models/fishingKnotCategories.model';
import dateTimeHelper from '../../helper/datetime.helper';
import { constants } from '../../config/constant.config';
import numberHelper from '../../helper/number.helper';

export default defineComponent({
  name: 'FishingKnotCategoryList',
  props: {},
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const categories = ref<Array<any>>([]);
    const loading = ref(false);
    const uid = route.query.uid;
    const filter = ref({
      partner_name: '',
      category: '',
      product_type: '',
      name: '',
      status: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;
    const currentPage = ref<number>(1);

    const orderOptions = ref([['created_at', 'desc']]);
    const filterConditions = ref<Array<any>>([]);
    if (uid) {
      filterConditions.value.push(['uid', uid]);
    }
    const updateData = async () => {
      loading.value = true;
      const result = await model.getAll(
        filterConditions.value,
        orderOptions.value,
        currentPage.value,
        perpage,
      );
      console.log(result);
      categories.value = result;
      loading.value = false;
    };

    const editRecord = (id: string) => {
      router.push('/partner-products/edit/' + id);
    };

    const clearProductType = () => {
      filter.value.product_type = '';
    };

    async function searchHandler() {
      filterConditions.value = [];

      if (filter.value.status.trim()) {
        filterConditions.value.push(['status', filter.value.status]);
      }
      updateData();
    }

    const nextPage = () => {
      currentPage.value += 1;
      updateData();
    };

    const prevPage = () => {
      currentPage.value -= 1;
      updateData();
    };

    const resetFilter = () => {
      filterConditions.value = [];
      currentPage.value = 1;
      updateData();
    };

    onMounted(() => {
      updateData();
    });

    return {
      categories,
      dateTimeHelper,
      loading,
      filter,
      numberHelper,
      currentPage,
      editRecord,
      searchHandler,
      clearProductType,
      nextPage,
      prevPage,
      resetFilter,
    };
  },
});
