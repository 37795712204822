
import { defineComponent, ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import dateTimeHelper from "../../helper/datetime.helper"
import { ApiService } from '../../services/backend-api/api.services'
import {default as PostModel} from "../../models/proPost.model"

export default defineComponent({
	components: {
	},
	setup() {
		const router = useRouter()
		let loading = ref(false)
		let errors = ref<string[]>([])
		let post = ref<any>({
			title: '',
			category_id: '',
			published_at: 0,
			pin: false,
			show: false,
			landing_url: '',
			contents_url: '',
			desc: '',
			thumbnail: '',
			created_at: 0,
			updated_at: 0,
			view_count: 0,
		})
		let postId = router.currentRoute.value.params.id
		onMounted(async () => {
			const apiService = new ApiService
			let previewData = await apiService.previewProPost(postId)
			if (previewData.status) {
				post.value = previewData.data;

				//update view_count field
				await apiService.updateProPostViewCount(postId)
			} else {
				errors.value.push(previewData.data.message)
			}
			loading.value = false
		})

		return {
			errors,
			post,
			loading,
			dateTimeHelper,
		}
	}
});
