
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { default as FishingKnotModel } from '../../models/fishingKnot.model';
import { default as FishingKnotCategoryModel } from '../../models/fishingKnotCategories.model';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    FileUpload,
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    let thumbnailFile = ref<any>(null);
    let isSubmitting = ref(false);
    let categories = ref([]);
    let knot = ref<any>({
      category_info: '',
      knot_name: '',
      is_show: true,
      thumbnail: '',
      youtube_url: '',
    });

    onMounted(async () => {
      categories.value = await FishingKnotCategoryModel.getAll();
    });

    async function submitForm() {
      isSubmitting.value = true;
      const categoryInfo = knot.value.category_info.split('|');
      const data = {
        category_id: categoryInfo[0],
        category_name: categoryInfo[1],
        knot_name: knot.value.knot_name,
        youtube_url: knot.value.youtube_url,
        thumbnail: await thumbnailFile.value?.uploadImage(),
        is_show: knot.value.is_show,
      };
      console.log(data);
      let addResult = await FishingKnotModel.create(data);
      if (addResult.status) {
        isSubmitting.value = false;
        router.replace('/fishing-knots');
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      knot,
      thumbnailFile,
      submitForm,
      categories,
      isSubmitting,
    };
  },
});
