abstract class PrevNextButton {
  protected disabled = false;

  protected readonly page: number;

  protected readonly totalPageCount: number;

  protected readonly clickEventHandler: any;

  constructor(param: {page: number; totalPageCount: number; clickEventHandler: any}) {
    this.page = param.page;
    this.totalPageCount = param.totalPageCount;
    this.clickEventHandler = param.clickEventHandler;
  }

  enable(): void {
    this.disabled = false;
  }

  disable(): void {
    this.disabled = true;
  }

  abstract isDisabled(): boolean;

  click(): void {
    if (this.isDisabled()) return;
    this.clickEventHandler();
  }
}

/**
 * 페이지네이션 이전 버튼
 */
export class PrevButton extends PrevNextButton {
  // constructor(param: {page: number; totalPageCount: number; clickEventHandler: any}) {
  //   super(param);
  // }

  isDisabled() {
    if (this.page > 1) this.enable();
    else this.disable();

    return this.disabled;
  }
}

/**
 * 페이지네이션 다음 버튼
 */
export class NextButton extends PrevNextButton {
  // constructor(param: {page: number; totalPageCount: number; clickEventHandler: any}) {
  //   super(param);
  // }

  isDisabled() {
    if (this.page < this.totalPageCount) this.enable();
    else this.disable();

    return this.disabled;
  }
}

/**
 * 페이징 블록
 * ex) pageCount가 5일 경우,
 *     block 1: 1~5 page,
 *     blcok 2: 6~10 page,
 *     block 3: 11~15 page...
 */
export class PageBlock {
  private readonly page: number;

  private readonly pageCount: number;

  private readonly block: number;

  private readonly totalPageCount: number;

  constructor(param: {page: number; totalPageCount: number; pageCount: number}) {
    this.page = param.page;
    this.pageCount = param.pageCount;
    this.block = Math.floor((this.page - 1) / this.pageCount);
    this.totalPageCount = param.totalPageCount;
  }

  getStartBlock(): number {
    return (this.block * this.pageCount) + 1;
  }

  getLastBlock(): number {
    const lastBlock = this.getStartBlock() + (this.pageCount - 1);
    return lastBlock;
  }
}

/**
 * 페이지 이동을 위한 페이지 아이템 입니다.
 */
export class PageItem {
  page: number;

  totalPageCount: number;

  private readonly clickEventHandler: any;

  constructor(param: {page: number, totalPageCount: number, clickEventHandler: any}) {
    this.page = param.page;
    this.totalPageCount = param.totalPageCount;
    this.clickEventHandler = param.clickEventHandler;
  }

  /**
   * 현재 페이지에 해당하는 페이지 아이템 버튼을 active 합니다.
   * @param page
   */
  isPageItemActive(currentPage: number): boolean {
    return this.page === currentPage;
  }

  isDisabled(): boolean {
    if (this.page > this.totalPageCount) return true;
    return false;
  }

  /**
   * 페이지 아이템을 클릭했을 때 페이지 이동을 합니다.
   * @param page
   */
  click() {
    if (this.isDisabled()) return;
    this.clickEventHandler();
  }
}
