
import { defineComponent, ref, onMounted } from 'vue';
import { ApiService } from '../../services/backend-api/api.services';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'PartnerList',
  props: {},
  components: {},
  setup: () => ({}),
});
