
import { defineComponent, ref, onMounted } from 'vue';
import { default as PostModel, Post } from '../../models/post.model';
import {
  default as PostCategoriesModel,
  PostCategory,
} from '../../models/postCategories.model';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import { useRouter } from 'vue-router';
import Pagination from '../../components/ui/Pagination.vue';
import { constants } from '../../config/constant.config';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'PostList',
  props: {},
  components: {
    Pagination,
  },
  setup: () => {
    const router = useRouter();
    let posts = ref<Post[]>([]);
    let categories = ref<any>([]);
    let listCategories = ref<PostCategory[]>([]);
    let loading = ref(false);
    let filter = ref({
      title: '',
      pin: null,
      show: null,
      show_more: null,
      show_home: null,
      category: null,
      created: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;

    onMounted(async () => {
      listCategories.value = await PostCategoriesModel.getAll();
      for (let key in listCategories.value) {
        let value = listCategories.value[key];
        categories.value[value.id] = value.name;
      }
    });

    let orderOptions = ref([['created_at', 'desc']]);
    let filterConditions = ref<Array<any>>([]);
    function updateData(items: Post[]) {
      loading.value = false;
      posts.value = items;
    }
    function isLoading(value: boolean) {
      loading.value = value;
    }

    async function deletePost(id: any) {
      swal.simpleDeleteConfirm(async function() {
        await PostModel.delete(id);
        posts.value = await PostModel.getAll();
      });
    }

    async function editPost(id: any) {
      router.push('/post/edit/' + id);
    }

    async function searchHandler() {
      filterConditions.value = [];
      if (filter.value.title && filter.value.title.length > 0) {
        filterConditions.value.push(['title', filter.value.title]);
      }

      if (filter.value.pin !== null) {
        filterConditions.value.push(['pin', filter.value.pin]);
      }

      if (filter.value.show !== null) {
        filterConditions.value.push(['show', filter.value.show]);
      }

      if (filter.value.show_more !== null) {
        filterConditions.value.push(['show_more', filter.value.show_more]);
      }

      if (filter.value.show_home !== null) {
        filterConditions.value.push(['show_home', filter.value.show_home]);
      }

      if (filter.value.category !== null) {
        filterConditions.value.push(['category_id', filter.value.category]);
      }

      if (filter.value.created !== null && filter.value.created.length > 0) {
        filterConditions.value.push([
          'created_at',
          dateTimeHelper.dateToTimestamp(filter.value.created),
        ]);
      }
    }

    return {
      posts,
      deletePost,
      editPost,
      dateTimeHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      PostModel,
      searchHandler,
      categories,
      listCategories,
      numberHelper,
    };
  },
});
