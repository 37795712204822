
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import Model, { Jin } from '../../models/jin.model';
import JinCategoryModel from '../../models/jinCategories.model';

export default defineComponent({
  components: {
    SubmitButton,
    Editor,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isSubmitting = ref(false);
    const errors = ref<string[]>([]);
    const loading = ref(true);
    const thumbnailFile = ref<any>(null);

    const JinCategories = ref<any>([]);
    const jin = ref<
      Pick<
        Jin,
        | 'title'
        | 'category_id'
        | 'summary'
        | 'content'
        | 'thumbnail'
        | 'show'
        | 'show_view_count'
        | 'no_margin_layout'
        | 'brand'
      >
    >({
      category_id: '',
      title: '',
      summary: '',
      content: '',
      thumbnail: '',
      show: true,
      show_view_count: false,
      no_margin_layout: false,
      brand: '',
    });
    const customEditor = ref<any>(null);
    onMounted(async () => {
      // jin categoreis
      const { items: resAskCategories } = await JinCategoryModel.getAll(
        [],
        [],
        1,
        100,
      );
      JinCategories.value = resAskCategories;
      loading.value = false;
    });

    const submitForm = async () => {
      swal.createConfirm(save);
    };

    const save = async () => {
      isSubmitting.value = true;
      errors.value = [];

      jin.value.content = await customEditor.value?.getContents();
      jin.value.thumbnail = await thumbnailFile.value?.uploadImage();

      const savedResult = await Model.create(jin.value);
      if (savedResult.status) {
        isSubmitting.value = false;
        await router.push({
          path: '/jin',
          query: route.query,
        });
      } else {
        swal.alert(savedResult.message);
        isSubmitting.value = false;
      }
    };

    return {
      submitForm,
      isSubmitting,
      jin,
      JinCategories,
      loading,
      thumbnailFile,
      customEditor,
    };
  },
});
