<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2>
        셀러 목록
      </h2>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
              <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
              <div class="input-group-append">
                <button type="submit" class="btn btn-default">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>ID</th>
              <th>상호</th>
              <th>제품 담당자명</th>
              <th>제품 담당자 전화번호</th>
              <th>제품 담당자 이메일</th>
              <th>정산관리 담당자명</th>
              <th>정산관리 담당자 전화번호</th>
              <th>정산관리 담당자 이메일</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="seller in sellerList" :key="seller.id">
              <td>{{ seller.id }}</td>
              <td>{{ seller.name }}</td>
              <td>{{ seller.manager1 }}</td>
              <td>{{ seller.manager1_cell }}</td>
              <td>{{ seller.manager1_email }}</td>
              <td>{{ seller.manager2 }}</td>
              <td>{{ seller.manager2_cell }}</td>
              <td>{{ seller.manager2_email }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const sellerList = ref([
      {
        id: 1,
        name: '롯데제과',
        manager1: '박롯데',
        manager1_cell: '010-7132-7008',
        manager1_email: 'kwstyle@gmail.com',
        manager2: '박롯데',
        manager2_cell: '010-7132-7008',
        manager2_email: 'kwstyle@gmail.com',
      }, {
        id: 2,
        name: '롯데칠성',
        manager1: '김롯데',
        manager1_cell: '010-7132-7008',
        manager1_email: 'kwstyle@gmail.com',
        manager2: '김롯데',
        manager2_cell: '010-7132-7008',
        manager2_email: 'kwstyle@gmail.com',
      },
    ]);

    return {
      sellerList,
    };
  },
};
</script>

<style scoped>

</style>
