
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import { default as Model } from '../../models/ask.model';
import { default as AskCategoryModel } from '../../models/askCategories.model';

export default defineComponent({
  components: {
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isSubmitting = ref(false);
    const errors = ref<string[]>([]);
    const loading = ref(true);
    const thumbnailFile = ref<any>(null);

    const askId = router.currentRoute.value.params.id;
    const askCategories = ref<any>([]);
    const ask = ref({
      title: '', // 질문 제목
      desc: '', // 질문 내용
      user: {
        nick_name: '',
      }, // 질문자
      answer: '', // 답변 내용
      category_id: '', // 답변자 id
      answer_state: false, // 답변 여부
      contents_url: '', // contents url
      show: false,
      deleted_at: null, // 삭제일시
    });
    const customEditor = ref<any>(null);
    const setPreviewImg = ref('');
    onMounted(async () => {
      // ask categoreis
      const { items: resAskCategories } = await AskCategoryModel.getAll(
        [],
        [],
        1,
        100,
      );
      askCategories.value = resAskCategories;

      // ask
      const { item } = await Model.findById(askId);
      Object.assign(ask.value, item);

      loading.value = false;
    });

    const submitForm = async () => {
      swal.updateConfirm(update);
    };

    const update = async () => {
      isSubmitting.value = true;
      errors.value = [];

      ask.value.answer = await customEditor.value?.getContents();
      const updatedResult = await Model.update(askId, ask.value);
      if (updatedResult.status) {
        isSubmitting.value = false;
        await router.push({
          path: '/ask',
          query: route.query,
        });
      } else {
        swal.alert(updatedResult.message);
        isSubmitting.value = false;
      }
    };

    const validateAnswer = async () => {
      if (ask.value.answer_state) {
        const answer = await customEditor.value?.getContents();
        console.log(answer);
        if (answer.length === 0) {
          swal.alert('답변 내용을 입력해주세요.');
          ask.value.answer_state = false;
        }
      }
    };

    return {
      submitForm,
      isSubmitting,
      ask,
      askCategories,
      loading,
      thumbnailFile,
      setPreviewImg,
      customEditor,
      validateAnswer,
    };
  },
});
