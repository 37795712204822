
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import Model, { Jin } from '../../models/jin.model';
import JinCategoryModel from '../../models/jinCategories.model';

export default defineComponent({
  components: {
    SubmitButton,
    Editor,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isSubmitting = ref(false);
    const errors = ref<string[]>([]);
    const loading = ref(true);
    const thumbnailFile = ref<any>(null);

    const jinId = router.currentRoute.value.params.id;
    const jinCategories = ref<any>([]);
    const jin = ref<
      Pick<
        Jin,
        | 'title'
        | 'category_id'
        | 'summary'
        | 'content'
        | 'thumbnail'
        | 'show'
        | 'show_view_count'
        | 'contents_url'
        | 'landing_url'
        | 'no_margin_layout'
        | 'brand'
      >
    >({
      category_id: '',
      title: '',
      summary: '',
      content: '',
      thumbnail: '',
      contents_url: '',
      landing_url: '',
      show: true,
      show_view_count: false,
      no_margin_layout: false,
      brand: '',
    });
    const customEditor = ref<any>(null);
    const editorContent = ref<string>('');
    const setPreviewImg = ref('');
    onMounted(async () => {
      // jin categoreis
      const { items: resJinCategories } = await JinCategoryModel.getAll(
        [],
        [],
        1,
        20,
      );
      jinCategories.value = resJinCategories;

      // jin
      const { item } = await Model.findById(jinId);
      Object.assign(jin.value, item);
      // show_view_count 필드는 나중에 추가 되었습니다. undefined 인 데이터는 true로 셋팅 합니다.
      jin.value.show_view_count = item.show_view_count ?? true;

      editorContent.value = jin.value.content;

      loading.value = false;
    });

    const submitForm = async () => {
      swal.updateConfirm(update);
    };

    const update = async () => {
      isSubmitting.value = true;
      errors.value = [];

      jin.value.content = await customEditor.value?.getContents();

      let thumbnail = await thumbnailFile.value?.uploadImage();
      if (thumbnail.length <= 0) {
        thumbnail = jin.value.thumbnail;
      }
      jin.value.thumbnail = thumbnail;

      const updatedResult = await Model.update(jinId, jin.value);
      console.log(JSON.stringify(updatedResult));
      if (updatedResult.status) {
        isSubmitting.value = false;
        await router.push({
          path: '/jin',
          query: route.query,
        });
      } else {
        swal.alert(updatedResult.message);
        isSubmitting.value = false;
      }
    };

    return {
      submitForm,
      isSubmitting,
      jin,
      jinCategories,
      loading,
      thumbnailFile,
      setPreviewImg,
      customEditor,
      editorContent,
    };
  },
});
