import {BaseModel} from "./base.model";

export interface Push {
  title: string;
	data_id: string;
  body: string;
  imageUrl: string;
	keywords: string;
	dry_run: boolean;
	landingUrl: string;
}

export interface AppPushServiceSubscriptionParams {
  page: number;
  size: number;
  progress_status: string;
  progress_status_nm: string;
}

class AppPushesModel extends BaseModel {
	protected collectionName = 'app_pushes'
	protected timestamp = true

	private static _instance: AppPushesModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default AppPushesModel.Instance