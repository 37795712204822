import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    class: ["btn px-4", [_ctx.style]],
    type: "button",
    disabled: _ctx.isDoing
  }, [
    (_ctx.isDoing)
      ? (_openBlock(), _createBlock("span", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.isDoing)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.loadingText), 1))
      : _createCommentVNode("", true),
    (!_ctx.isDoing)
      ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ], 10, ["disabled"]))
}