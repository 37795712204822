import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';
import adminAccounts from './modules/adminAccounts';
import components from './modules/components';
import loading from './modules/loading';

export interface State {
    adminAccounts: {
        loadingLogin: false,
        loadingAuth: false,
        token: '',
        permissions: ''
    },
    components: {
        submitLoading: false,
        disablePrev: false,
        disableNext: false,
        currentPage: 1
    },
    loading: {
        loadingText: '로딩중입니다.',
        isLoading: false,
    }
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    adminAccounts,
    components,
    loading,
  },
});
