import { BaseModel } from './base.model';

class UserReportModel extends BaseModel {
    protected collectionName = 'user_report'

    private static _instance: UserReportModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

export default UserReportModel.Instance