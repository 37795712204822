
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import commerceUtil from '@/helper/commerceUtil';
import BlindModel from '@/models/blind.model';
import swal from '@/helper/swal.helper';
// import Pagination from '@/components/ui/NewPagination.vue';

export default {
  name: 'PartnersFishingResultList',
  components: {
    // Pagination,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const productList = ref(null);
    const { page = 0 } = route.query;
    const pageSize = ref(10);
    const total = ref(0);
    const totalPage = ref(0);
    const currentPage = ref(page);
    onMounted(async () => {
      productList.value = await getProductList();
    });
    // watch(route.params, async (newPage) => {
    //   console.log('watch', newPage);
    //   productList.value = await getProductList();
    // });
    const getProductList = async () => {
      const response = await apiService.get('/partners-mall/product', {
        page: currentPage.value,
        size: pageSize.value,
      });
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };
    const viewDetail = (id: string) => {
      router.push(`/commerce/product/register/${id}`);
    };
    const goResgiter = () => {
      router.push('/commerce/product/register/new');
    };
    const loadPage = (page: number) => {
      router.push(`/commerce/product?page=${page}`);
    };
    const getCurruncy = (price: number) => commerceUtil.getCurrency(price);
    const checkRecommend = async (
      dataCd: string,
      name: string,
      e: { target: { checked: boolean } },
    ) => {
      const isRecommend = e.target.checked;
      const response = await apiService.put(
        `/partners-mall/product/${dataCd}/recommend`,
        {
          is_recommend: isRecommend,
        },
      );
      if (isRecommend) {
        swal.success(`'${name}' 추천이 등록되었습니다.`);
      } else {
        swal.success(`'${name}' 추천이 취소되었습니다.`);
      }
    };
    const checkPopular = async (
      dataCd: string,
      name: string,
      e: { target: { checked: boolean } },
    ) => {
      const isPopular = e.target.checked;
      const response = await apiService.put(
        `/partners-mall/product/${dataCd}/popular`,
        {
          is_popular: isPopular,
        },
      );
      if (isPopular) {
        swal.success(`'${name}' 인기상품이 등록되었습니다.`);
      } else {
        swal.success(`'${name}' 인기상품이 취소되었습니다.`);
      }
    };
    return {
      currentPage,
      pageSize,
      totalPage,
      productList,
      viewDetail,
      goResgiter,
      getCurruncy,
      loadPage,
      checkRecommend,
      checkPopular,
    };
  },
};
