
import { defineComponent, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { BooleanTypes, FishCodeCategoryTypes } from '../../config/constant.config';
import swal from '../../helper/swal.helper';
import Model from '../../models/partnersFishCode.model';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
		const route = useRoute();
    const use_yn_bool = ref(true);
    const isSubmitting = ref(false);
    const category = ref<FishCodeCategoryTypes>(FishCodeCategoryTypes.SEA);
    const code_kor_name = ref('');
    const order_idx = ref<number>(0);

    async function submitForm() {
      isSubmitting.value = true;

      const addResult = await Model.bulkUpdate([{
        category: category.value,
        code_kor_name: code_kor_name.value,
        use_yn: use_yn_bool.value ? BooleanTypes.Y : BooleanTypes.N,
        delete_yn: BooleanTypes.N,
        order_idx: +order_idx.value,
      }]);
      if (addResult.status) {
        router.push({
		    path: '/partners-fish-code',
		    query: route.query,
		    });
        isSubmitting.value = false;
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      category,
      code_kor_name,
      use_yn_bool,
      isSubmitting,
      order_idx,
    };
  },
});
