import { Post, AppPushServicePostParams } from '@/models/post.model';
import { ApiService } from '../../services/backend-api/api.services';
import swal from '../../helper/swal.helper';

export class PostRepository {
  private apiService: ApiService = new ApiService();

  async fetchPostList(params: AppPushServicePostParams): Promise<{items: Post[]}> {
    try {
      const response = await this.apiService.get('/posts', params);
      return { items: response.data };
    } catch (error) {
      swal.alert('post fetching Error');
      throw error;
    }
  }
}