export enum Permission {
  LIST_ADMIN_ACCOUNT = 'admin-accounts.list',
  DETAIL_ADMIN_ACCOUNT = 'admin-accounts.detail',
  CREATE_ADMIN_ACCOUNT = 'admin-accounts.create',
  UPDATE_ADMIN_ACCOUNT = 'admin-accounts.update',
  DELETE_ADMIN_ACCOUNT = 'admin-accounts.delete',

  LIST_ADMIN_ROLE = 'admin-roles.list',
  DETAIL_ADMIN_ROLE = 'admin-roles.detail',
  CREATE_ADMIN_ROLE = 'admin-roles.create',
  UPDATE_ADMIN_ROLE = 'admin-roles.update',
  DELETE_ADMIN_ROLE = 'admin-roles.delete',

  LIST_APP_PUSH = 'app-pushes.list',
  DETAIL_APP_PUSH = 'app-pushes.detail',
  CREATE_APP_PUSH = 'app-pushes.create',
  UPDATE_APP_PUSH = 'app-pushes.update',
  DELETE_APP_PUSH = 'app-pushes.delete',

  LIST_NOTIFICATION = 'notifications.list',
  DETAIL_NOTIFICATION = 'notifications.detail',
  CREATE_NOTIFICATION = 'notifications.create',
  UPDATE_NOTIFICATION = 'notifications.update',
  DELETE_NOTIFICATION = 'notifications.delete',

  LIST_PARTNERS = 'partners.list',
  DETAIL_PARTNERS = 'partners.detail',
  CREATE_PARTNERS = 'partners.create',
  UPDATE_PARTNERS = 'partners.update',
  DELETE_PARTNERS = 'partners.delete',

  LIST_PARTNER_CATEGORY = 'partner-categories.list',
  DETAIL_PARTNER_CATEGORY = 'partner-categories.detail',
  CREATE_PARTNER_CATEGORY = 'partner-categories.create',
  UPDATE_PARTNER_CATEGORY = 'partner-categories.update',
  DELETE_PARTNER_CATEGORY = 'partner-categories.delete',

  LIST_PERMISSION = 'permissions.list',
  DETAIL_PERMISSION = 'permissions.detail',
  CREATE_PERMISSION = 'permissions.create',
  UPDATE_PERMISSION = 'permissions.update',
  DELETE_PERMISSION = 'permissions.delete',

  LIST_POST = 'posts.list',
  DETAIL_POST = 'posts.detail',
  CREATE_POST = 'posts.create',
  UPDATE_POST = 'posts.update',
  DELETE_POST = 'posts.delete',

  LIST_POST_CATEGORY = 'post-categories.list',
  DETAIL_POST_CATEGORY = 'post-categories.detail',
  CREATE_POST_CATEGORY = 'post-categories.create',
  UPDATE_POST_CATEGORY = 'post-categories.update',
  DELETE_POST_CATEGORY = 'post-categories.delete',

  LIST_PRO_POST = 'pro-posts.list',
  DETAIL_PRO_POST = 'pro-posts.detail',
  CREATE_PRO_POST = 'pro-posts.create',
  UPDATE_PRO_POST = 'pro-posts.update',
  DELETE_PRO_POST = 'pro-posts.delete',

  LIST_PRO_POST_CATEGORY = 'pro-post-categories.list',
  DETAIL_PRO_POST_CATEGORY = 'pro-post-categories.detail',
  CREATE_PRO_POST_CATEGORY = 'pro-post-categories.create',
  UPDATE_PRO_POST_CATEGORY = 'pro-post-categories.update',
  DELETE_PRO_POST_CATEGORY = 'pro-post-categories.delete',

  LIST_USERS = 'users.list',
  DETAIL_USERS = 'users.detail',
  CREATE_USERS = 'users.create',
  UPDATE_USERS = 'users.update',
  DELETE_USERS = 'users.delete',

  LIST_FISHING_TUBE = 'fishing-tubes.list',
  DETAIL_FISHING_TUBE = 'fishing-tubes.detail',
  CREATE_FISHING_TUBE = 'fishing-tubes.create',
  UPDATE_FISHING_TUBE = 'fishing-tubes.update',
  DELETE_FISHING_TUBE = 'fishing-tubes.delete',

  LIST_FISHING_GRAM = 'fishing-grams.list',
  DELETE_FISHING_GRAM = 'fishing-grams.delete',
  UPDATE_FISHING_GRAM = 'fishing-grams.update',
  DETAIL_FISHING_GRAM = 'fishing-grams.detail',

  LIST_FISHING_GRAM_BEST_CANDIDATES = 'fishing-grams-best-candidates.list',

  LIST_RESERVATION = 'reservations.list',
  DETAIL_RESERVATION = 'reservations.detail',
  CREATE_RESERVATION = 'reservations.create',
  UPDATE_RESERVATION = 'reservations.update',
  DELETE_RESERVATION = 'reservations.delete',

  LIST_PARTNERS_JOIN = 'partners-join.list',
  DETAIL_PARTNERS_JOIN = 'partners-join.detail',
  CREATE_PARTNERS_JOIN = 'partners-join.create',
  UPDATE_PARTNERS_JOIN = 'partners-join.update',
  DELETE_PARTNERS_JOIN = 'partners-join.delete',

  LIST_PARTNERS_PRODUCT = 'partners-product.list',
  DETAIL_PARTNERS_PRODUCT = 'partners-product.detail',
  CREATE_PARTNERS_PRODUCT = 'partners-product.create',
  UPDATE_PARTNERS_PRODUCT = 'partners-product.update',
  DELETE_PARTNERS_PRODUCT = 'partners-product.delete',

  LIST_PARTNERS_NOTICE = 'partners-notice.list',
  DETAIL_PARTNERS_NOTICE = 'partners-notice.detail',
  CREATE_PARTNERS_NOTICE = 'partners-notice.create',
  UPDATE_PARTNERS_NOTICE = 'partners-notice.update',
  DELETE_PARTNERS_NOTICE = 'partners-notice.delete',

  LIST_PARTNERS_FISH_CODE = 'partners-fish-code.list',
  CREATE_PARTNERS_FISH_CODE = 'partners-fish-code.create',

  LIST_PARTNERS_COMMON_CODE = 'partners-common-code.list',
  CREATE_PARTNERS_COMMON_CODE = 'partners-common-code.create',

  LIST_PARTNER_FISH_CONDITION = 'partner-fish-conditions.list',
  DETAIL_PARTNER_FISH_CONDITION = 'partner-fish-conditions.detail',
  CREATE_PARTNER_FISH_CONDITION = 'partner-fish-conditions.create',
  UPDATE_PARTNER_FISH_CONDITION = 'partner-fish-conditions.update',
  DELETE_PARTNER_FISH_CONDITION = 'partner-fish-conditions.delete',

  LIST_USER_REPORT = 'user-report.list',
  DETAIL_USER_REPORT = 'user-report.detail',
  CREATE_USER_REPORT = 'user-report.create',
  UPDATE_USER_REPORT = 'user-report.update',
  DELETE_USER_REPORT = 'user-report.delete',

  LIST_FISHING_KNOT_CATEGORY = 'fishing-knot-category.list',
  DETAIL_FISHING_KNOT_CATEGORY = 'fishing-knot-category.detail',
  CREATE_FISHING_KNOT_CATEGORY = 'fishing-knot-category.create',
  UPDATE_FISHING_KNOT_CATEGORY = 'fishing-knot-category.update',
  DELETE_FISHING_KNOT_CATEGORY = 'fishing-knot-category.delete',

  LIST_FISHING_KNOT = 'fishing-knot.list',
  DETAIL_FISHING_KNOT = 'fishing-knot.detail',
  CREATE_FISHING_KNOT = 'fishing-knot.create',
  UPDATE_FISHING_KNOT = 'fishing-knot.update',
  DELETE_FISHING_KNOT = 'fishing-knot.delete',

  SIREN_LIST = 'siren.list',

  LIST_ASK = 'ask.list',
  DETAIL_ASK = 'ask.detail',
  UPDATE_ASK = 'ask.update',

  LIST_ASK_CATEGORY = 'ask-category.list',
  DETAIL_ASK_CATEGORY = 'ask-category.detail',
  CREATE_ASK_CATEGORY = 'ask-category.create',
  UPDATE_ASK_CATEGORY = 'ask-category.update',
  DELETE_ASK_CATEGORY = 'ask-category.delete',

  LIST_JIN_CATEGORY = 'jin-category.list',
  DETAIL_JIN_CATEGORY = 'jin-category.detail',
  CREATE_JIN_CATEGORY = 'jin-category.create',
  UPDATE_JIN_CATEGORY = 'jin-category.update',
  DELETE_JIN_CATEGORY = 'jin-category.delete',

  LIST_JIN = 'jin.list',
  DETAIL_JIN = 'jin.detail',
  CREATE_JIN = 'jin.create',
  UPDATE_JIN = 'jin.update',
  DELETE_JIN = 'jin.delete',

  LIST_REWARD_COMMON_CODE = 'reward-common-code.list',
  CREATE_REWARD_COMMON_CODE = 'reward-common-code.create',
}
