
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { ContentViewTypes } from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import swal from '../../helper/swal.helper';
import PostModel, { Post } from '../../models/post.model';
import PostCategoriesModel from '../../models/postCategories.model';

export default defineComponent({
  components: {
    FileUpload,
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter();
    const thumbnailFile = ref<any>(null);
    const homeThumbnailFile = ref<any>(null);
    const customEditor = ref<any>(null);
    const loading = ref(false);
    const isSubmitting = ref(false);
    const categories = ref([]);
    const contentViewType = ref(false);
    const post = ref<Post>({
      title: '',
      id: '',
      category_id: '',
      published_at: 0,
      pin: false,
      show: false,
      landing_url: '',
      contents_url: '',
      desc: '',
      thumbnail: '',
      home_thumbnail: '',
      created_at: 0,
      updated_at: 0,
      order: 0,
      view_count: 0,
      show_view_count: false,
      show_home: false,
      show_home_order: 0,
      show_more: false,
      show_more_order: 0,
      event_period_from: 0,
      event_period_to: 0,
      no_margin_layout: false,
      view_type: ContentViewTypes.WEB_VIEW,
    });
    const publishTime = ref<string>('');
    const eventPeriodFrom = ref<any>(0);
    const eventPeriodTo = ref<any>(0);
    const postId = router.currentRoute.value.params.id;
    const setPreviewImg = ref('');
    const setHomePreviewImg = ref('');
    onMounted(async () => {
      loading.value = true;
      post.value = await PostModel.findById(postId);
      publishTime.value = dateTimeHelper.timestampMilisToDateTimeInput(
        post.value.published_at,
      );
      if (post.value.event_period_from) {
        eventPeriodFrom.value = dateTimeHelper.timestampMilisToDateTimeInput(
          post.value.event_period_from,
          'YYYY-MM-DD',
        );
      }
      if (post.value.event_period_to) {
        eventPeriodTo.value = dateTimeHelper.timestampMilisToDateTimeInput(
          post.value.event_period_to,
          'YYYY-MM-DD',
        );
      }
      if (post.value.view_type === ContentViewTypes.WEB_VIEW) {
        contentViewType.value = false;
      } else {
        contentViewType.value = true;
      }
      categories.value = await PostCategoriesModel.getAll();
      setPreviewImg.value = post.value.thumbnail;
      setHomePreviewImg.value = post.value.home_thumbnail;
      loading.value = false;
    });

    async function submitForm() {
      isSubmitting.value = true;

      let thumbnail = await thumbnailFile.value?.uploadImage();
      if (thumbnail.length <= 0) {
        thumbnail = post.value.thumbnail;
      }
      let homeThumbnail = await homeThumbnailFile.value?.uploadImage();
      if (homeThumbnail.length <= 0) {
        homeThumbnail = post.value.home_thumbnail;
      }

      const data = {
        category_id: post.value.category_id,
        created_at: post.value.created_at,
        desc: await customEditor.value?.getContents(),
        pin: post.value.pin,
        landing_url: post.value.landing_url,
        contents_url: post.value.contents_url,
        published_at: post.value.published_at,
        updated_at: Date.now(),
        show: post.value.show,
        thumbnail,
        home_thumbnail: homeThumbnail,
        title: post.value.title,
        order: post.value.order,
        view_count: post.value.view_count,
        show_view_count: post.value.show_view_count,
        show_home: post.value.show_home,
        show_home_order: post.value.show_home_order,
        show_more: post.value.show_more,
        show_more_order: post.value.show_more_order,
        event_period_from: eventPeriodFrom.value
          ? dateTimeHelper.dateToTimestamp(eventPeriodFrom.value)
          : 0,
        event_period_to: eventPeriodTo.value
          ? dateTimeHelper.dateToTimestamp(eventPeriodTo.value)
          : 0,
        no_margin_layout: post.value.no_margin_layout,
        view_type: contentViewType.value
          ? ContentViewTypes.WEB_BROWSER_LINK
          : ContentViewTypes.WEB_VIEW,
      };
      const updateResult = await PostModel.update(postId, data);
      if (updateResult.status) {
        isSubmitting.value = false;
        router.push('/post');
      } else {
        isSubmitting.value = false;
        swal.alert(updateResult.message);
      }
    }

    return {
      post,
      thumbnailFile,
      homeThumbnailFile,
      submitForm,
      categories,
      loading,
      isSubmitting,
      setPreviewImg,
      setHomePreviewImg,
      publishTime,
      eventPeriodFrom,
      eventPeriodTo,
      customEditor,
      contentViewType,
    };
  },
});
