export default {
  getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
      return v.toString(16);
    });
  },
  getCurrency(price: number) {
    if (!price) {
      return 0;
    }
    const formatter = new Intl.NumberFormat('ko', {
      // style: 'currency',
      // currency: 'krw',
      // notation: 'compact',
    });
    return formatter.format(price);
  },
};
