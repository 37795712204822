
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import { constants, IsUserSearchEventAt } from '@/config/constant.config';
import CrudModel from '../../models/askCategories.model';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'AskCategories',
  props: {},
  components: {
    PaginationV2,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const categories = ref<any[]>([]);
    const perpage = constants().DEFAULT_PERPAGE;
    const loading = ref(false);
    const orderOptions = ref([['order_number', 'asc']]);
    const filterConditions = ref<Array<any>>([]);
    function updateData(items: any[]) {
      loading.value = false;
      categories.value = items;
    }
    function isLoading(status: boolean) {
      loading.value = status;
    }
    async function editRecord(id: any) {
      router.push(`/ask-categories/edit/${id}`);
    }
    async function deleteRecord(id: any) {
      swal.simpleDeleteConfirm(async () => {
        loading.value = true;
        await CrudModel.delete(id);
        categories.value = await CrudModel.getAll();
        loading.value = false;
        await searchHandler();
      });
    }
    async function searchHandler() {
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];
    }

    onMounted(async () => {
      await searchHandler();
    });

    return {
      categories,
      loading,
      orderOptions,
      perpage,
      filterConditions,
      updateData,
      isLoading,
      CrudModel,
      deleteRecord,
      editRecord,
    };
  },
});
