<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2>
        롯데칠성 월별 정산 내역
      </h2>
    </div>
    <div class="col-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <section class="invoice">
                <h3>내역상세</h3>
                <p>기간 2023. 2. 1 ~ 2023. 2. 28</p>
                <p>발주건수 총 24건</p>
                <p>정산금액 : 42,323,000 원</p>
                <h3>발주내역</h3>
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th>발주일자</th>
                    <th>발주건수</th>
                    <th>발주금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>2023. 2. 1</td>
                    <td>32건</td>
                    <td>232,000</td>
                  </tr>
                  <tr>
                    <td>2023. 2. 2</td>
                    <td>32건</td>
                    <td>232,000</td>
                  </tr>
                  <tr>
                    <td>2023. 2. 3</td>
                    <td>32건</td>
                    <td>232,000</td>
                  </tr>
                  <tr>
                    <td>2023. 2. 5</td>
                    <td>32건</td>
                    <td>232,000</td>
                  </tr>
                  <tr>
                    <td>2023. 2. 7</td>
                    <td>32건</td>
                    <td>232,000</td>
                  </tr>
                  <tr>
                    <td>2023. 2. 8</td>
                    <td>32건</td>
                    <td>232,000</td>
                  </tr>
                  </tbody>
                </table>
              </section>
            </div><!-- /.col -->
          </div>
        </div>
        <div class="card-footer align-items-center">
          <div class="footer-btn-wrapper">
            <button type="submit" class="btn btn-lg btn-primary" @click="back">인쇄</button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">목록</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'CommerceOrderList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const
      orderProductList = ref([
        {
          id: 1,
          image: 'https://cdn.011st.com/11dims/resize/600x600/quality/75/11src/product/1694288721/B.jpg?949000000',
          seller: '롯데칠성',
          name: '생수 세트',
          count: 1,
          price: '11,500',
        }, {
          id: 2,
          image: 'https://cdn-mart.baemin.com/sellergoods/main/e369179c-4ba3-4b80-8854-02163df1e16f.jpg',
          seller: '롯데칠성',
          name: '핫식스 세트',
          count: 1,
          price: '15,500',
        }, {
          id: 3,
          image: 'http://www.econovill.com/news/photo/201407/217339_872_1427.jpg',
          seller: '롯데제과',
          name: '어신 주번부리 세트',
          count: 2,
          price: '25,500',
        },
      ]);
    const back = () => {
      router.back();
    };

    return {
      orderProductList,
      back,
    };
  },
};
</script>

<style scoped>
.invoice > header {
  color: #000000;
}
.order-item-box {
  display: flex;
  flex-direction: row;
  border: solid 1px grey;
  border-radius: 6px;
  margin: 10px;
  padding: 10px;
  align-items: center;
}
</style>
