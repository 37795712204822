import cookieHelper from "../helper/cookie.helper";

export default function guest({from, to,  next, router }: any) {
	const accessToken = cookieHelper.getCookie('access_token')
	if (accessToken) {
		const requestRouteName = to.name
		if (requestRouteName === 'Login') {
			return router.push({ name: 'Index' });
		} else {
			return next();
		}
	} else {
		return next()
	}
}