import {BaseModel} from "./base.model";

export interface Blind {
	target_id: string;
	parent_id: string;
	type: string;
	reason: string;
	blind_date: string;
}

class BlindModel extends BaseModel {
	protected collectionName = 'blind'

	private static _instance: BlindModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default BlindModel.Instance