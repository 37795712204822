import { BaseModel } from './base.model';

class RadioactivityModel extends BaseModel {
	protected collectionName = 'radioactivities';

	private static _instance: RadioactivityModel;

	public static get Instance() {
		return this._instance || (this._instance = new this());
	}
}

export default RadioactivityModel.Instance