import { BooleanTypes } from '../config/constant.config';
import { ApiService } from '../services/backend-api/api.services';
import { BaseV2Model } from './base-v2.model';

export interface PartnersCommonCode {
  code: string;
  code_type: string;
  code_kor_name: string;
  order_idx: number;
  use_yn: BooleanTypes;
  delete_yn: BooleanTypes;
  remark?: string;
}

class PartnersCommonCodeModel extends BaseV2Model {
  protected collectionName = 'partners/common-code';

  protected keepCollectionName = true;

  private static _instance: PartnersCommonCodeModel

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public async getCommonCodeTypes() {
    const apiService = new ApiService();
    return await apiService.getCommonCodeTypes(`${this.collectionName}/type`);
  }

  public async bulkUpdate(datas: PartnersCommonCode[]) {
    const apiServcie = new ApiService();
    return await apiServcie.bulkUpdate(
      this.collectionName,
      {
        code_item_array: datas,
      }, true,
    );
  }
}

export default PartnersCommonCodeModel.Instance;
