import axios from 'axios';
import authentication from './auth.services';

axios.interceptors.request.use((req) => {
  const timestamp = new Date().getTime();
  const token = authentication.generatePayload(req, timestamp);
  req.headers.Authorization = token;
  req.headers['api-version'] = '1.0';
  req.headers.timestamp = timestamp;

  return req;
});

const exAxios = function () {
  //
};

exAxios.get = (url: string, param: any, res: any, error: any) => {
  axios
    .get(url, param)
    .then(res)
    .catch(error);
};

exAxios.put = (url: string, param: any, res: any, error: any) => {
  axios
    .put(url, param)
    .then(res)
    .catch(error);
};

exAxios.post = (url: string, param: any, res: any, error: any) => {
  axios
    .post(url, param)
    .then(res)
    .catch(error);
};
exAxios.delete = (url: string, param: any, res: any, error: any) => {
  axios
    .delete(url, param)
    .then(res)
    .catch(error);
};

export default exAxios;
