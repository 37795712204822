
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as Model } from '../../models/partnerCategories.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    let status = ref(true);
    let isSubmitting = ref(false);
    let categoryName = ref('');

    async function submitForm() {
      isSubmitting.value = true;
      let category = {
        name: categoryName.value,
        status: status.value,
      };

      let addResult = await Model.create(category);
      if (addResult.status) {
        router.push('/partner-categories');
        isSubmitting.value = false;
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      categoryName,
      status,
      isSubmitting,
    };
  },
});
