
import {
  BooleanTypes,
  BusinessTypes,
  UserJoinStateCode,
  UserPaidCode,
} from '@/config/constant.config';
import ElementStyleHelper, {
  UserJoinStateCssStyle,
  UserPaidCssStyle,
} from '@/helper/element-style.helper';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import { default as CrudModel } from '../../models/reservation.model';
import { ApiService } from '@/services/backend-api/api.services';
import FileUpload from '../../components/ui/FileUpload.vue';
import OtherButton from '../../components/ui/OtherButton.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import CrudModel from '../../models/partners-member.model';
import { PartnerInfo, initialPartnerInfo } from '@/models/partner_info.model';

export default defineComponent({
  components: {
    FileUpload,
    SubmitButton,
    OtherButton,
  },
  setup() {
    const apiService = new ApiService();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const bizCerityFile = ref<any>(null);
    const isSubmitting = ref(false);
    const isApproving = ref(false);
    const isRejecting = ref(false);
    const errors = ref<string[]>([]);
    const partner = ref<PartnerInfo>(initialPartnerInfo);
    const homePage = ref('');
    const reason = ref<string>('');
    const instagram = ref('');
    const band = ref('');
    const naverCafe = ref('');
    const setPreviewImg = ref('');
    const registrationUrl = ref<any>(null);
    const { id } = route.params;
    const uploadRegistrationUrl = ref(false);
    const showRegistrationUrl = ref(false);
    const registrationUrlInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    onMounted(async () => {
      loading.value = true;
      const response = await CrudModel.findById(id);
      // categories.value = await CategoriesModel.getAll();
      partner.value = response.item;
      console.log(partner.value);
      setPreviewImg.value = partner.value.registration_url;
      // SNS 변수 셋팅
      homePage.value =
        partner.value.profile.snsList.find(
          sns => sns.sns_type_cd === 'HOMEPAGE',
        )?.sns_url ?? '';
      instagram.value =
        partner.value.profile.snsList.find(
          sns => sns.sns_type_cd === 'INSTAGRAM',
        )?.sns_url ?? '';
      naverCafe.value =
        partner.value.profile.snsList.find(sns => sns.sns_type_cd === 'NAVER')
          ?.sns_url ?? '';
      band.value =
        partner.value.profile.snsList.find(sns => sns.sns_type_cd === 'BAND')
          ?.sns_url ?? '';

      loading.value = false;
    });

    const submitForm = async () => {
      // if(parseInt(partner.value.status) === 1) {
      //   if(!partner.value.booking_tel) {
      //     swal.alert('예약 연락처는 필수로 입력 바랍니다.');
      //     return;
      //   }
      //   swal.partnerConfirm(update);
      // } else {
      //   swal.updateConfirm(update);
      // }

      swal.updateConfirm(update);
    };

    const back = () => {
      const currentPath = router.currentRoute.value.path;

      const match = currentPath.match(/^\/[^/]+/);

      if (match) {
        const newPath = match[0];
        router.push(newPath);
      } else {
        router.back(); // 해당 세그먼트를 찾을 수 없을 경우 뒤로가기
      }
    };

    const deleteAccount = async () => {
      const reasonValue = reason.value;
      if (reasonValue === '') {
        swal.alert('탈퇴 사유가 입력되지 않았습니다.');
        return false;
      }
      const response = await apiService.post(
        `/v2/partners/member/sign-out/${id}`,
        {
          reason: reasonValue,
        },
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        router.push('/partners-member');
      } else {
        console.error(response);
      }
    };

    const restoreAccount = async () => {
      const response = await apiService.delete(
        `/v2/partners/member/sign-out/${id}`,
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        router.push('/partners-member');
      } else {
        console.error(response);
      }
    };

    const update = async () => {
      let url = await registrationUrl.value?.uploadImage();
      if (url.length <= 0) {
        url = partner.value.registration_url;
      }
      const response = await apiService.patch(`/partners/member/${id}`, {
        remark: partner.value.remark,
        user_id: partner.value.user_id,
        email: partner.value.email,
        name: partner.value.name,
        ceo_name: partner.value.ceo_name,
        contact: partner.value.contact,
        conditions: partner.value.conditions,
        business_category: partner.value.business_category,
        cell_num: partner.value.cell_num,
        registration_url: url,
      });
      console.log(response);
      swal.successConfirm(async () => {
        const response = await CrudModel.findById(id);
        partner.value = response.item;
      }, '수정이 완료되었습니다.');
    };
    const approve = async (dataId: string) => {
      swal.updateConfirm(async () => {
        const response = await apiService.patch(
          `/v2/partners/member/booking-role-review/${dataId}`,
        );
        swal.successConfirm(async () => {
          const response = await CrudModel.findById(id);
          partner.value = response.item;
        }, '승인이 완료되었습니다.');
      }, '해당 파트너스 부킹시스템을 승인하시겠습니까?');
    };
    return {
      errors,
      partner,
      homePage,
      instagram,
      naverCafe,
      band,
      bizCerityFile,
      submitForm,
      isSubmitting,
      reason,
      isApproving,
      isRejecting,
      loading,
      // setPreviewBiz,
      ElementStyleHelper,
      BooleanTypes,
      BusinessTypes,
      UserJoinStateCode,
      UserPaidCode,
      UserJoinStateCssStyle,
      UserPaidCssStyle,
      back,
      deleteAccount,
      restoreAccount,
      approve,
      setPreviewImg,
      registrationUrl,
    };
  },
});
