
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as Model } from '../../models/notification.model';
import FileUpload from '../../components/ui/FileUpload.vue';
import Editor from '../../components/ui/Editor.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    FileUpload,
    Editor,
  },
  setup() {
    const router = useRouter();
    let loading = ref(false);
    let thumbnailFile = ref<any>(null);
    let customEditor = ref<any>(null);
    let notification = ref({
      title: '',
      description: '',
      thumbnail: '',
      show: false,
      pin: true,
      read: 0,
      contents_url: '',
      landing_url: '',
      view_count: 0,
      show_home: false,
      show_home_order: 0,
      show_more: false,
      show_more_order: 0,
      app: 0,
      no_margin_layout: false,
      os: 0,
    });

    async function submitForm() {
      loading.value = true;
      // Upload file
      notification.value.thumbnail = await thumbnailFile.value?.uploadImage();
      notification.value.description = await customEditor.value?.getContents();
      let addResult = await Model.create(notification.value);
      if (addResult.status) {
        loading.value = false;
        router.push('/notifications');
      } else {
        swal.alert(addResult.message);
        loading.value = false;
      }
    }

    return {
      loading,
      notification,
      thumbnailFile,
      submitForm,
      customEditor,
    };
  },
});
