
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import ElementStyleHelper, {
  UserJoinStateCssStyle,
  UserPaidCssStyle,
} from '@/helper/element-style.helper';
import numberHelper from '@/helper/number.helper';
import copy from 'fast-copy';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  BooleanTypes,
  IsUserSearchEventAt,
  UserJoinStateCode,
  UserPaidCode,
} from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import swal from '../../helper/swal.helper';
import CrudModel, {
  PartnersCommonCode,
} from '../../models/partnersCommonCode.model';
import { ApiService } from '../../services/backend-api/api.services';

export default defineComponent({
  name: 'RewardCommonCodeList',
  props: {},
  components: {
    PaginationV2,
  },
  setup: () => {
    type interactStatus = {
      added: boolean;
      edited: boolean;
      deleted: boolean;
      moved: boolean;
    };
    type use_yn_bool = {
      use_yn_bool: boolean;
    };
    const router = useRouter();
    const route = useRoute();
    const commonCodes = ref<
      Array<PartnersCommonCode & interactStatus & use_yn_bool>
    >([]);
    const backupFishCodes = ref<
      Array<PartnersCommonCode & interactStatus & use_yn_bool>
    >([]);
    const apiService = new ApiService();
    const partnersSummary = ref({});
    const loading = ref(false);
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const filter = ref({
      code_type: '',
      code: '',
      code_kor_name: '',
      use_yn: '',
    });
    type filterKeyType = 'code_type' | 'code' | 'code_kor_name' | 'use_yn';
    const savedCodeTypes = ref<string[]>(['']);
    const perpage = 200;

    const orderOptions = ref([['create_at', 'desc']]);
    const filterConditions = ref<Array<any>>([]);

    onMounted(async () => {
      await searchHandler();
    });
    const updateData = (items: Array<any>) => {
      loading.value = false;
      commonCodes.value = items;
      commonCodes.value = commonCodes.value.map(item => ({
        ...item,
        use_yn_bool: item.use_yn === 'Y',
      }));
      backupFishCodes.value = copy(commonCodes.value);
    };
    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    async function searchHandler() {
      await refreshSavedCodeTypes();
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];

      setSearchFilterCondition('code_type');
      setSearchFilterCondition('code');
      setSearchFilterCondition('use_yn');
      setSearchFilterCondition('code_kor_name');
    }

    /**
     * 데이터 검색 조건 셋팅
     * @param filterKey
     * @param replaceFilterValue
     */
    function setSearchFilterCondition(
      filterKey: filterKeyType,
      replaceFilterValue?: string,
    ): void {
      if (filter.value[filterKey].trim() || replaceFilterValue) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? filter.value[filterKey],
        ]);
      } else if (
        // 페이지가 새로고침되었을 때 또는 뒤로가기로 왔을 때 url에 검색 관련 쿼리스트링이 있으면, 검색 조건에 추가 합니다.
        ((!filter.value[filterKey].trim() && route.query[filterKey]?.length) ||
          replaceFilterValue) &&
        userSearchEventFlag.value === IsUserSearchEventAt.N
      ) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? route.query[filterKey],
        ]);
        filter.value[filterKey] =
          replaceFilterValue ?? `${route.query[filterKey]}`;
      }
    }

    /**
     * Reset 버튼을 클릭했을 때 검색 조건을 초기화 합니다.
     */
    async function resetFilter() {
      filter.value.code_type = '';
      filter.value.code = '';
      filter.value.code_kor_name = '';
      filter.value.use_yn = '';

      userSearchEventFlag.value = IsUserSearchEventAt.Y;
      await searchHandler();
    }

    const deleteFishCode = (index: number) => {
      commonCodes.value[index].delete_yn = BooleanTypes.Y;
      setStatus(commonCodes.value[index], 'deleted');
    };

    const setStatus = (
      interactStatus: interactStatus,
      status: 'added' | 'edited' | 'deleted' | 'moved',
    ) => {
      if (interactStatus?.added) {
        return undefined;
      }
      if (interactStatus?.deleted) {
        return undefined;
      }
      interactStatus[status] = true;
    };

    const getStatusColor = (
      commonCode: PartnersCommonCode & interactStatus,
    ) => {
      if (commonCode.added) {
        return 'bg-success';
      }
      if (commonCode.deleted) {
        return 'bg-danger';
      }
      if (commonCode.edited || commonCode.moved) {
        return 'bg-warning';
      }
      return '';
    };

    const changeFishCodeEventHandler = (index: number) => {
      if (commonCodes.value[index].added) {
        return undefined;
      }

      const backupData = backupFishCodes.value[index];
      const changeData = commonCodes.value[index];

      if (backupData.order_idx === changeData.order_idx) {
        changeData.moved = false;
      } else {
        changeData.moved = true;
      }

      if (
        backupData.code_type === changeData.code_type &&
        backupData.code_kor_name === changeData.code_kor_name &&
        backupData.use_yn_bool === changeData.use_yn_bool &&
        +backupData.order_idx === +changeData.order_idx
      ) {
        changeData.edited = false;
        return undefined;
      }

      syncUseYn(index);
      setStatus(commonCodes.value[index], 'edited');
      commonCodes.value.sort((a, b) => a.order_idx - b.order_idx);
      commonCodes.value.sort((a, b) => (a.code_type < b.code_type ? -1 : 1));
    };

    const syncUseYn = (index: number) => {
      commonCodes.value[index].use_yn = commonCodes.value[index].use_yn_bool
        ? BooleanTypes.Y
        : BooleanTypes.N;
    };

    const addRecord = () => {
      router.push({
        path: '/partners-common-code/add',
        query: route.query,
      });
    };

    const applyEventHandler = async () => {
      isLoading(true);
      const response = await CrudModel.bulkUpdate(commonCodes.value);
      console.log(response);
      if (!response.status) {
        swal.alert(response.message);
        isLoading(false);
        return undefined;
      }

      swal.successConfirm(async () => {
        await searchHandler();
      });
    };

    const refreshSavedCodeTypes = async () => {
      const response = await CrudModel.getCommonCodeTypes();
      if (response.status === 200) {
        savedCodeTypes.value = response.data.item.code_types;
      }
    };

    return {
      commonCodes,
      dateTimeHelper,
      numberHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      CrudModel,
      searchHandler,
      resetFilter,
      partnersSummary,
      IsUserSearchEventAt,
      userSearchEventFlag,
      UserJoinStateCode,
      UserPaidCode,
      UserPaidCssStyle,
      UserJoinStateCssStyle,
      ElementStyleHelper,
      BooleanTypes,
      deleteFishCode,
      // moveFishCode,
      getStatusColor,
      changeFishCodeEventHandler,
      addRecord,
      applyEventHandler,
      savedCodeTypes,
    };
  },
});
