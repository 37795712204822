<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2 v-if="id === 'new'">
        카테고리 등록
      </h2>
      <h2 v-else-if="id !== 'new' && isDetail">
        카테고리 상세
      </h2>
      <h2 v-else>
        카테고리 수정
      </h2>
    </div>
    <div class="col-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleInputEmail1">카테고리 코드</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="미발행"
                  disabled="disabled"
                  v-model="category.data_cd"
                />
              </div>
              <div class="form-group">
                <label>카테고리명</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="카테고리명"
                  v-model="category.name"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label>순서</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  v-model="category.idx"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
              <div class="form-group">
                <label>노출여부</label>
                <select
                  class="form-control"
                  v-model="category.is_active"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option :value="true">활성</option>
                  <option :value="false">비활성</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer align-items-center">
          <div v-if="id === 'new'" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="update"
            >
              저장
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              취소
            </button>
          </div>
          <div v-else-if="id !== 'new' && isDetail" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="changeMode(false)"
            >
              수정
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-danger"
              @click="deleteCategory"
            >
              삭제
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              목록
            </button>
          </div>
          <div v-else class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="update"
            >
              저장
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-default"
              @click="changeMode(true)"
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';

export default {
  name: 'PartnersMallCategoryRegister',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const category = ref({
      name: '',
      is_active: true,
      idx: 0,
    });
    onMounted(async () => {
      if (id === 'new') {
        const { idx } = route.query;
        category.value = {
          name: '',
          idx: Number(idx),
          is_active: true,
        };
      } else {
        const categoryData = await getCategory(id);
        category.value = categoryData;
      }
    });

    const getCategory = async id => {
      const response = await apiService.get(
        `/partners-mall/product-category/${id}`,
      );
      console.log(response.data.item);
      return response.data.item;
    };

    // eslint-disable-next-line consistent-return
    const update = async () => {
      if (!checkValidate()) {
        return false;
      }
      let response = null;
      if (id === 'new') {
        response = await apiService.post(
          '/partners-mall/product-category/',
          category.value,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.successConfirm(() => {
            router.replace('/commerce/product-category');
          });
        } else {
          swal.alert(response.data.message);
        }
      } else {
        response = await apiService.put(
          `/partners-mall/product-category/${id}`,
          category.value,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.successConfirm(() => {
            isDetail.value = true;
          });
        } else {
          swal.alert(response.data.message);
        }
      }
    };

    const checkValidate = () => {
      if (category.value.name.trim() === '') {
        swal.alert('카테고리명을 입력하세요.');
        return false;
      }

      if (category.value.idx === '') {
        swal.alert('순서를 입력하세요.');
        return false;
      }

      return true;
    };

    const deleteCategory = async () => {
      if (confirm('카테고리를 삭제 하시겠습니까?')) {
        const response = await apiService.delete(
          `/partners-mall/product-category/${id}`,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.alert(response.data.message);
          router.replace('/commerce/product-category');
        } else {
          swal.alert(response.data.message);
        }
      }
    };

    const back = () => {
      router.back();
    };

    const changeMode = boolean => {
      isDetail.value = boolean;
    };

    return {
      id,
      isDetail,
      category,
      update,
      back,
      changeMode,
      deleteCategory,
    };
  },
};
</script>

<style scoped></style>
