
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as Model } from '../../models/askCategories.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    const status = ref(true);
    const isSubmitting = ref(false);
    const categoryName = ref('');
    const subName = ref('');
    const orderNumber = ref();
    const thumbnailFile = ref<any>(null);
    const eventKey = ref('');

    async function submitForm() {
      isSubmitting.value = true;
      const category = {
        name: categoryName.value,
        sub_name: subName.value,
        event_key: eventKey.value,
        status: status.value,
        order_number: orderNumber.value,
        thumbnail: await thumbnailFile.value?.uploadImage(),
      };

      const addResult = await Model.create(category);
      if (addResult.status) {
        router.replace('/ask-categories');
        isSubmitting.value = false;
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      categoryName,
      subName,
      status,
      isSubmitting,
      thumbnailFile,
      orderNumber,
      eventKey,
    };
  },
});
