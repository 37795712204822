import { BooleanTypes } from '../config/constant.config';
import { ApiService } from '../services/backend-api/api.services';
import { BaseV2Model } from './base-v2.model';

export interface RewardCommonCode {
  _id?: string;
  type: string;
  code: string;
  name: string;
  useYn: BooleanTypes;
  delete_yn: BooleanTypes;
  remark?: string;
}

class RewardCommonCodeModel extends BaseV2Model {
  protected collectionName = 'reward-code';

  protected keepCollectionName = true;

  private static _instance: RewardCommonCodeModel

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public async getCommonCodeTypes(): Promise<any> {
    const apiService = new ApiService();
    return await apiService.getCommonCodeTypes(`${this.collectionName}/type`);
  }

  public async bulkUpdate(datas: RewardCommonCode[]) {
    const apiService = new ApiService();
    return await apiService.bulkUpdate(
      this.collectionName,
      {
        code_item_array: datas,
      }, true,
    );
  }
}

export default RewardCommonCodeModel.Instance;
