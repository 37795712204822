
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Editor from '../../components/ui/Editor.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { BooleanTypes } from '../../config/constant.config';
import swal from '../../helper/swal.helper';
import CrudModel from '../../models/partnersNotice.model';

export default defineComponent({
  components: {
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter(); const
      route = useRoute();
    const loading = ref<boolean>(false);
    const isSubmitting = ref<boolean>(false);
    const errors = ref<string[]>([]);
    const customEditor = ref<any>(null);
    const notice = ref({
      title: '',
      detail: '',
      show_yn: '',
      show_yn_slider: true,
    });
    const editorContent = ref<string>('');

    const { id } = route.params;
    onMounted(async () => {
      loading.value = true;
      const response = await CrudModel.findById(id);
      notice.value = response.item;

      //
      notice.value.show_yn_slider = notice.value.show_yn === BooleanTypes.Y;
      editorContent.value = notice.value.detail;
      loading.value = false;
    });

    const submitForm = async () => {
      swal.updateConfirm(update);
    };
    const update = async () => {
      isSubmitting.value = true;
      errors.value = [];

      const content = await customEditor.value.getContents();
      notice.value.detail = btoa(unescape(encodeURIComponent(content)));

      notice.value.show_yn = notice.value.show_yn_slider === true ? BooleanTypes.Y : BooleanTypes.N;

      const updateResult = await CrudModel.update(id, notice.value);
      if (updateResult.status) {
        isSubmitting.value = false;
        swal.successConfirm(async () => {
          await router.push({
            path: '/partners-notice',
            query: route.query,
          });
        });
      } else {
        isSubmitting.value = false;
        errors.value.push(updateResult.message);
      }
    };

    return {
      errors,
      notice,
      submitForm,
      isSubmitting,
      loading,
      customEditor,
      BooleanTypes,
      editorContent,
    };
  },
});
