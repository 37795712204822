import {BaseModel} from './base.model';

class ShellfishToxinModel extends BaseModel {
	protected collectionName = 'shellfish_toxins'

	private static _instance: ShellfishToxinModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default ShellfishToxinModel.Instance