
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { default as Model } from '../../models/partnerCategories.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    let status = ref(true);
    let isSubmitting = ref(false);
    let loading = ref(true);
    let categoryName = ref('');

    let categoryId = router.currentRoute.value.params.id;
    let category = ref({
      name: '',
      status: false,
    });

    onMounted(async () => {
      category.value = await Model.findById(categoryId);
      loading.value = false;
    });

    async function submitForm() {
      isSubmitting.value = true;
      let data = {
        name: category.value.name,
        status: category.value.status,
      };

      let addResult = await Model.update(categoryId, data);
      if (addResult.status) {
        isSubmitting.value = false;
        router.push('/partner-categories');
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      categoryName,
      status,
      isSubmitting,
      category,
      loading,
    };
  },
});
