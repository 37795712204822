
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  default as CrudModel,
  PostCategory,
} from '../../models/postCategories.model';
import Pagination from '../../components/ui/Pagination.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'PostCategories',
  props: {},
  components: {
    Pagination,
  },
  setup: () => {
    const router = useRouter();
    let categories = ref<PostCategory[]>([]);
    let loading = ref(false);
    let orderOptions = ref([['order_number', 'asc']]);
    let filterConditions = ref<Array<any>>([]);
    function updateData(items: PostCategory[]) {
      loading.value = false;
      categories.value = items;
    }
    function isLoading(status: boolean) {
      loading.value = status;
    }
    async function editRecord(id: any) {
      router.push('/post-categories/edit/' + id);
    }
    async function deleteRecord(id: any) {
      swal.simpleDeleteConfirm(async function() {
        loading.value = true;
        await CrudModel.delete(id);
        categories.value = await CrudModel.getAll();
        loading.value = false;
      });
    }
    return {
      categories,
      loading,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      CrudModel,
      deleteRecord,
      editRecord,
    };
  },
});
