
import { defineComponent, ref } from 'vue';
import { default as CrudModel } from '../../models/appPushes.model';
import dateTimeHelper from '../../helper/datetime.helper';
import { useRouter } from 'vue-router';
import Pagination from '../../components/ui/Pagination.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'AppPush',
  components: { Pagination },
  setup: () => {
    const router = useRouter();
    let loading = ref(false);
    let pushes = ref<Array<any>>([]);
    let orderOptions = ref([['created_at', 'desc']]);
    let filterConditions = ref<Array<any>>([]);
    let filter = ref({
      title: '',
    });

    function filterHandler() {
      filterConditions.value = [];
      if (filter.value.title && filter.value.title.length > 0) {
        filterConditions.value.push(['title', filter.value.title]);
      }
    }

    function isLoading(status: any) {
      if (status) {
        loading.value = true;
      } else {
        loading.value = false;
      }
    }

    function updateData(items: Array<any>) {
      loading.value = false;
      pushes.value = items;
    }

    async function deleteRecord(id: any) {
      swal.simpleDeleteConfirm(async function() {
        await CrudModel.delete(id);
        pushes.value = await CrudModel.getAll();
      });
    }

    async function editRecord(id: any) {
      router.push('/app-pushes/edit/' + id);
    }

    return {
      loading,
      pushes,
      filter,
      orderOptions,
      filterConditions,
      CrudModel,
      dateTimeHelper,
      deleteRecord,
      editRecord,
      isLoading,
      updateData,
      filterHandler,
    };
  },
});
