
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { default as AdminRolesModel } from '../../models/adminRoles.model';
import {
  AdminAccount,
  default as Model,
} from '../../models/adminAccount.model';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    let adminAccount = ref<AdminAccount>({
      id: '',
      email: '',
      password: '',
      name: '',
      account_type: '',
      created_at: 0,
    });
    let roles = ref();
    let isSubmitting = ref(false);

    onMounted(async () => {
      roles.value = await AdminRolesModel.getAll();
      adminAccount.value.account_type = '';
    });

    async function onSubmitAddForm() {
      isSubmitting.value = true;
      let postData = {
        email: adminAccount.value.email,
        password: adminAccount.value.password,
        name: adminAccount.value.name,
        account_type: adminAccount.value.account_type,
      };

      if (postData.account_type.length <= 0) {
        swal.alert('Please select role before saving admin account');
      }

      let response = await Model.create(postData);
      if (response.status) {
        isSubmitting.value = false;
        router.push('/admin-account');
      } else {
        swal.alert(response.message);
        isSubmitting.value = false;
      }
    }

    return {
      onSubmitAddForm,
      adminAccount,
      isSubmitting,
      roles,
    };
  },
});
