
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import { CButton, CPopover } from '@coreui/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { constants, IsUserSearchEventAt } from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import swal from '../../helper/swal.helper';
import FishingGramsBestCandidatesModel, {
  FishingGramsBestCandidates,
} from '../../models/fishingGramsBestCandidates.model';
import { ApiService } from '../../services/backend-api/api.services';

export default defineComponent({
  name: 'FishingGramsList',
  props: {},
  components: {
    PaginationV2,
    CPopover,
    CButton,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const apiService = new ApiService();
    const fishingGrams = ref<FishingGramsBestCandidates[]>([]);
    const fishingGramsBest = ref<FishingGramsBestCandidates[]>([]);
    const loading = ref(false);
    const orderType = ref('desc');
    const orderBy = ref('created_at');
    const filter = ref({
      show_home: null,
      has_report_comments: null,
      uid: '',
      nick_name: '',
      tags: '',
      fishing_place_type: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;
    const errorSummary = ref('');

    const orderOptions = ref([[orderBy.value, orderType.value]]);
    const filterConditions = ref<Array<any>>([]);
    const updateData = (items: FishingGramsBestCandidates[]) => {
      loading.value = false;
      fishingGrams.value = items;
      fishingGrams.value.forEach(item => {
        if (item.show_home === undefined) item.ban = false;
        else item.ban = !item.show_home;
      });
    };

    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    onMounted(async () => {
      await findAllBest();
      await searchHandler();
    });

    const findAllBest = async () => {
      const response = await FishingGramsBestCandidatesModel.findAllBest();
      if (response.status) {
        fishingGramsBest.value = response.data.items;
      }
    };

    const deleteAction = (id: any) => {
      swal.simpleDeleteConfirm(async () => {
        const currentDatetime = dateTimeHelper.timestampToDateTime(
          Date.now(),
          'YYYY-MM-DDTHH:mm:ss.SSS',
        );
        await FishingGramsBestCandidatesModel.update(id, {
          deleted_at: currentDatetime,
        });
        fishingGrams.value = await FishingGramsBestCandidatesModel.getAll(
          [],
          orderOptions.value,
          1,
          25,
        );
      });
    };

    const editAction = async (id: any) => {
      router.push(`/fishing-grams/edit/${id}`);
    };

    const searchHandler = async () => {
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];
    };

    const changeShowHandler = async (id: string, e: any) => {
      await FishingGramsBestCandidatesModel.update(id, {
        show_home: !e.target.checked,
      });
      findAllBest();
      searchHandler();
    };

    const changeFixHomeHandler = async (
      id: string,
      e: { target: { checked: boolean } },
    ) => {
      isLoading(true);
      await FishingGramsBestCandidatesModel.update(id, {
        fix_home: e.target.checked,
      });
      findAllBest();
      searchHandler();
    };

    const sortHandler = async (field: string) => {
      switch (orderType.value) {
        case 'asc':
          orderType.value = 'desc';
          break;
        case 'desc':
          orderType.value = 'asc';
          break;
        default:
      }
      orderOptions.value = [[field, orderType.value]];
      orderBy.value = field;
    };

    const viewDetail = async (id: any) => {
      await router.push(`/fishing-grams/detail/${id}`);
    };

    const isBestData = (id: string) =>
      fishingGramsBest.value.find(fishingGram => fishingGram.id === id);

    return {
      fishingGrams,
      deleteAction,
      editAction,
      dateTimeHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      FishingGramsBestCandidatesModel,
      searchHandler,
      numberHelper,
      changeShowHandler,
      sortHandler,
      orderType,
      viewDetail,
      errorSummary,
      orderBy,
      changeFixHomeHandler,
      userSearchEventFlag,
      IsUserSearchEventAt,
      isBestData,
    };
  },
});
