import { BaseV2Model } from '@/models/base-v2.model';

export interface Jin {
	indexCounter?: number;

	id: string;
  category_id: string; // 진 카테고리 id
  title: string;
  summary: string; // 제목 밑 요약 내용
  content: string;
  thumbnail: string;
  contents_url: string; // 웹 뷰 링크
  landing_url: string; // 웹에서 앱으로 연결하기 위한 링크
  read_cnt: number; // 조회 수
  no_margin_layout: boolean; // 웹 뷰 마진 적용 여부
  brand: string; // 브랜드 or 작성자

  show: boolean;
  show_view_count: boolean;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  remark: string;
}

class JinModel extends BaseV2Model {
	protected collectionName = 'jin'

	protected keepCollectionName = true;

	private static _instance: JinModel

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}
}

export default JinModel.Instance;
