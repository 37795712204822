
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { default as model } from '../../models/userReport.model';
import dateTimeHelper from '../../helper/datetime.helper';
import { constants } from '../../config/constant.config';

export default defineComponent({
  name: 'PartnerNotices',
  props: {},
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const userReports = ref<Array<any>>([]);
    const loading = ref(false);
    const uid = route.query.uid;
    const filter = ref({
      report_state: '',
      target_user_id: '',
      reporter_id: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;
    const currentPage = ref<number>(1);

    const orderOptions = ref([['created_at', 'desc']]);

    const filterConditions = ref<Array<any>>([]);

    if (uid) {
      filterConditions.value.push(['uid', uid]);
    }

    const updateData = async () => {
      const result = await model.getAll(
        filterConditions.value,
        orderOptions.value,
        currentPage.value,
        perpage,
      );
      loading.value = false;
      console.log(result);
      userReports.value = result;
    };
    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    const editRecord = (id: string) => {
      router.push('/user-report/detail/' + id);
    };

    const nextPage = () => {
      currentPage.value += 1;
      updateData();
    };
    const prevPage = () => {
      currentPage.value -= 1;
      updateData();
    };
    const searchHandler = async () => {
      filterConditions.value = [];

      if (filter.value.report_state.trim()) {
        filterConditions.value.push([
          'report_state',
          filter.value.report_state,
        ]);
      }

      if (filter.value.target_user_id.trim()) {
        filterConditions.value.push([
          'target_user_id',
          filter.value.target_user_id,
        ]);
      }

      if (filter.value.reporter_id.trim()) {
        filterConditions.value.push(['reporter_id', filter.value.reporter_id]);
      }
      updateData();
    };

    const resetFilter = () => {
      filterConditions.value = [];
      currentPage.value = 1;
      updateData();
    };

    onMounted(() => {
      updateData();
    });
    return {
      userReports,
      editRecord,
      dateTimeHelper,
      loading,
      filter,
      updateData,
      isLoading,
      searchHandler,
      currentPage,
      nextPage,
      prevPage,
      resetFilter,
    };
  },
});
