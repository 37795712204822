import { BaseV2Model } from './base-v2.model';

class PartnersJoinModel extends BaseV2Model {
    protected collectionName = 'partners/join'

    protected keepCollectionName = true

    private static _instance: PartnersJoinModel

    public static get Instance() {
      return this._instance || (this._instance = new this());
    }
}

export default PartnersJoinModel.Instance;
