
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as Model } from '../../models/askCategories.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import FileUpload from '../../components/ui/FileUpload.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    const isSubmitting = ref(false);
    const loading = ref(true);
    const thumbnailFile = ref<any>(null);

    const categoryId = router.currentRoute.value.params.id;
    const category = ref({
      name: '',
      sub_name: '',
      event_key: '',
      status: false,
      thumbnail: '',
      order_number: 0,
    });
    const setPreviewImg = ref('');
    onMounted(async () => {
      const { item } = await Model.findById(categoryId);
      category.value = item;
      setPreviewImg.value = category.value.thumbnail;
      loading.value = false;
    });

    async function submitForm() {
      isSubmitting.value = true;
      let thumbnail = await thumbnailFile.value?.uploadImage();
      if (thumbnail.length <= 0) {
        thumbnail = category.value.thumbnail;
      }

      const data = {
        name: category.value.name,
        sub_name: category.value.sub_name,
        event_key: category.value.event_key,
        status: category.value.status,
        thumbnail,
        order_number: category.value.order_number,
      };

      const addResult = await Model.update(categoryId, data);
      if (addResult.status) {
        isSubmitting.value = false;
        router.replace('/ask-categories');
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }
    return {
      submitForm,
      isSubmitting,
      category,
      loading,
      thumbnailFile,
      setPreviewImg,
    };
  },
});
