
import { ref, defineComponent } from 'vue'

export default defineComponent({
	name: 'AppFooter',
	props: {},
	setup: () => {
		let currentYear = ref(2020)
		currentYear.value = new Date().getFullYear();
		return { currentYear }
	}
})
