
import { constants, IsUserSearchEventAt } from '@/config/constant.config';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'PartnersPostCategories',
  props: {},
  components: {},
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const categories = ref<any[]>([]);
    const loading = ref(false);
    const orderOptions = ref([[]]);
    const filterConditions = ref<Array<any>>([]);
    function updateData(items: any[]) {
      loading.value = false;
      console.log(items);
      categories.value = items;
    }
    function isLoading(status: boolean) {
      loading.value = status;
    }

    const addRecord = () => {
      router.push({
        path: '/partners-post-category/add',
        query: route.query,
      });
    };

    async function editRecord(id: any) {
      router.push(`/partners-post-category/edit/${id}`);
    }

    onMounted(async () => {
      categories.value = await getCategoryList();
    });

    const totalPage = ref(0);
    const currentPage = ref(0);
    const pageSize = ref(30);
    const total = ref(0);
    const getCategoryList = async () => {
      const response = await apiService.get('/partners/post-category', {
        page: currentPage.value,
        size: pageSize.value,
      });
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };

    return {
      categories,
      loading,
      orderOptions,
      filterConditions,
      addRecord,
      updateData,
      isLoading,
      // CrudModel,
      // deleteRecord,
      editRecord,
    };
  },
});
