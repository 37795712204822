<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-6">
			<div class="clearfix">
				<h1 class="float-left display-3 mr-4">403</h1>
				<h4 class="pt-3">Access denied.</h4>
				<p class="text-muted">You do not have permission to access this page. Please contact the administrator.</p>
			</div>
		</div>
	</div>
</div>
</template>