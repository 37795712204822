import firebase from 'firebase';
import functions = firebase.functions;

declare let naver: any;

const MARKER_ENABLE = '사용가능';
const MARKER_DISABLE = '사용불가';
const MARKER_UNKNOWN = '확인안됨';
const mapHtmlTemplate: any = {
  setHotspotIcon() {
    return {
      content:
				'<img src="/img/marker_hotspot.png" alt="" '
				+ 'style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; '
				+ '-webkit-user-select: none; position: absolute; width: 25px; height: 35px; left: 0px; top: 0px;">',
      size: new naver.maps.Size(25, 35),
      anchor: new naver.maps.Point(11, 35),
    };
  },

  setRedIcon() {
    return {
      content:
				'<img src="/img/marker_red.png" alt="" '
				+ 'style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; '
				+ '-webkit-user-select: none; position: absolute; width: 20px; height: 28px; left: 0px; top: 0px;">',
      size: new naver.maps.Size(20, 28),
      anchor: new naver.maps.Point(10, 28),
    };
  },

  createMarkerWaterDataHtml(data: any) {
    const lat = data.position._lat;
    const lon = data.position._lng;
    return `<div class="edit-container" >
					<h4>저수지 포인트 추가</h4>
				<p>
					<input type="text" id="waterName"  placeholder="이름 입력"/>
					<input type="text" id="waterFacCode"  placeholder="저수지 코드 입력"/>
					<input type="text" id="waterMaxHeight"  placeholder="만수위"/>
					<input type="text" id="waterMinHeight"  placeholder="저수위"/>
					<input type="text" id="waterOverHeight"  placeholder="홍수위"/>
				</p>
				<div style="font-size:12px;margin-bottom:10px;">
				<div style="border-top:1px dashed silver;margin-top:10px;padding-top:10px;"></div>
					위도 : ${lat}<br/>
					경도 : ${lon}<br/>
				</div>
				<button class="created-pin-btn" onclick="mapManagerController.createLocationWaterMarker(${lat}, ${lon})">핀 생성</button>
				`;
  },

  createMarkerDataHtml(data: any, checkLocationData: any, createLocationMarker: any) {
    console.log('data', data);
    console.log(checkLocationData);
    const lat = data.position._lat;
    const lon = data.position._lng;
    const el = document.createElement('div');
    el.className = 'edit-container';
    el.innerHTML = `
		<p>
					<input type="text" id="name" name="name" size="10"  placeholder="이름 입력"/>
				</p>
				<div style="font-size:12px;margin-bottom:10px;">
				<div style="border-top:1px dashed silver;margin-top:10px;padding-top:10px;"></div>
					위도 : ${lat}<br/>
					경도 : ${lon}<br/>
				</div>
				<div >해양 데이터 : <span id="tideCheck">${
  data.tideCheck === true
    ? MARKER_ENABLE
    : data.tideCheck === false
      ? MARKER_DISABLE
      : MARKER_UNKNOWN
}</span>
					<button class="btn1">체크</button>
					<button class="btn2 created-pin-btn" style="display:none" >핀 생성</button>
		`;
    el.querySelector('.btn1')?.addEventListener('click', () => {
      checkLocationData(null, lat, lon);
    });

    el.querySelector('.btn2')?.addEventListener('click', () => {
      createLocationMarker(lat, lon);
    });

    return el;
  },

  makeWaterLocationDataHtml(waterInfo: any) {
    const lat =			waterInfo.location
			&& waterInfo.location.coordinates
			&& waterInfo.location.coordinates.length === 2
			  ? waterInfo.location.coordinates[1]
			  : waterInfo.y;
    const lon =			waterInfo.location
			&& waterInfo.location.coordinates
			&& waterInfo.location.coordinates.length === 2
			  ? waterInfo.location.coordinates[0]
			  : waterInfo.x;
    return `<div class="edit-container">
				<p style="padding:0px;margin:0px;margin-bottom:5px;"><input type="text" id="name" value="${
  waterInfo.name
}" placeholder="이름"/></p>
				<p style="padding:0px;margin:0px;margin-bottom:5px;">
				<div style="font-size:12px;margin-bottom:10px;">
					<input type="text" id="address" value="${
  waterInfo.address ? waterInfo.address : ''
}" placeholder="주소 입력"/> <br/>
					<div style="border-top:1px dashed silver;margin-top:10px;padding-top:10px;"></div>
					위도 : ${lat}<br/>
					경도 : ${lon}<br/>
					활성상태 : <input type="checkbox" id="activation" style="width:inherit;vertical-align:middle"  ${
  waterInfo.activation ? 'checked' : ''
}/>
				</div>
				</p>
				<button onclick="mapManagerController.updateLocationMarker('${
  waterInfo.id
}',true)">업데이트</button>
				<button onclick="mapManagerController.resetLocationDataPosition('${
  waterInfo.id
}')">핀 원위치</button>
				<button onclick="mapManagerController.removeLocationData('${
  waterInfo.id
}')">핀 삭제</button>
				<div style="border-top:1px dashed silver;padding-top:10px;margin-top:10px"></div>
				<button onclick="mapManagerController.loadLocationWaterState(${
  waterInfo.id
})">수위데이터조회</button>
				<button onclick="mapManagerController.loadLocationWaterQuality(${
  waterInfo.id
})">수질데이터조회</button>
				</div>
				`;
  },

  makeLocationDataHtml(data: any, api: any) {
    const lat =			data.location && data.location.coordinates && data.location.coordinates.length == 2
			  ? data.location.coordinates[1]
			  : data.y;
    const lon =			data.location && data.location.coordinates && data.location.coordinates.length == 2
			  ? data.location.coordinates[0]
			  : data.x;

    const el = document.createElement('div');
    el.className = 'edit-container';
    el.innerHTML = `
		<input type="text" id="name" value="${data.name}" placeholder="이름"/>
					<div id="${data.id}" style="overflow:hidden;margin-top:5px">
						<div style="font-size:12px;margin-bottom:10px;">
							<input type="text" id="address" value="${
  data.address ? data.address : ''
}" placeholder="주소 입력"/> <br/>
							<div style="border-top:1px dashed silver;margin-top:10px;padding-top:10px;"></div>
							위도 : ${lat}<br/>
							경도 : ${lon}<br/>
						</div>
					</div>
					<div >해양 데이터 : <span id="tideCheck">${
  data.tideCheck === true
    ? MARKER_ENABLE
    : data.tideCheck === false
      ? MARKER_DISABLE
      : MARKER_UNKNOWN
}</span>
					<button class="btn1" >체크</button>
					</div>
					<div style="border-top:1px dashed silver;padding-top:10px;margin-top:10px"></div>
					<button class="btn2">핫스팟</button>
					<button class="btn3">핀 원위치</button>
					<button class="btn4">핀 삭제</button>
					<button class="created-pin-btn btn5" style="display:none">정보 저장</button><br/>`;

    el.querySelector('.btn1')?.addEventListener('click', () => {
      api.checkLocationData(data.id);
    });
    el.querySelector('.btn2')?.addEventListener('click', () => {
      api.updateHotspotMarker(data.id);
    });
    el.querySelector('.btn3')?.addEventListener('click', () => {
      api.resetLocationDataPosition(data.id);
    });
    el.querySelector('.btn4')?.addEventListener('click', () => {
      api.removeLocationData(data.id);
    });
    el.querySelector('.btn5')?.addEventListener('click', () => {
      api.updateLocationMarker(data.id);
    });
    return el;
  },
};

export default mapHtmlTemplate;
