const state = () => ({
  loadingText: '로딩중입니다.',
  isLoading: false,
});

// getters
const getters = {

};

// actions
const actions = {
  showLoading(context: any, requestData: any) {
    context.commit('setSubmitLoading', requestData);
  },
  hideLoading(context: any, requestData: any) {
    context.commit('setSubmitLoading', requestData);
  },
};

// mutations
const mutations = {
  showLoading(state: any, loadingText = '로딩중입니다.') {
    state.loadingText = loadingText;
    state.isLoading = true;
  },
  hideLoading(state: any) {
    state.loadingText = '';
    state.isLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
