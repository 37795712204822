import { BooleanTypes, FishCodeCategoryTypes } from '../config/constant.config';
import { ApiService } from '../services/backend-api/api.services';
import { BaseV2Model } from './base-v2.model';

export interface PartnersFishCode {
  fish_cd?: string;
  category: FishCodeCategoryTypes;
  code_kor_name: string;
  order_idx: number;
  use_yn: BooleanTypes;
  delete_yn: BooleanTypes;
}

class PartnersFishCodeModel extends BaseV2Model {
  protected collectionName = 'partners/fish-code';

  protected keepCollectionName = true;

  private static _instance: PartnersFishCodeModel

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public async bulkUpdate(datas: PartnersFishCode[]) {
    const apiServcie = new ApiService();
    return await apiServcie.bulkUpdate(
      this.collectionName,
      {
        fish_code_item_array: datas,
      }, true,
    );
  }
}

export default PartnersFishCodeModel.Instance;
