import { BaseV2Model } from '@/models/base-v2.model';

export interface AskCategory {
	id: string,
	name: string,
	sub_name: string,
	status: boolean,
	order_number: number,
	thumbnail: string,
	deleted_at: string,
}

class AskCategoriesModel extends BaseV2Model {
	protected collectionName = 'ask-category'

	private static _instance: AskCategoriesModel

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}
}

export default AskCategoriesModel.Instance;
