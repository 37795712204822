
import { defineComponent, ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import { ApiService } from '../../services/backend-api/api.services'

export default defineComponent({
  components: {
  },
  setup() {
    const router = useRouter()
    const loading = ref(false);
    const errors = ref<string[]>([]);
    const knot = ref<any>({
      youtube_url: '',
    });
    const knotId = router.currentRoute.value.params.id;
    const knotContent: any = ref(null);
    const ytplayer: any = ref(null);

    onMounted(async () => {
      // const el: any = document.querySelector('.post-content');
      knotContent.value.style.height = `${window.innerHeight}px`;
      const apiService = new ApiService;
      let previewData = await apiService.previewKnot(knotId);
      if (previewData.status) {
        knot.value = previewData.data;
      } else {
        errors.value.push(previewData.data.message);
      }
      loading.value = false;
    })

    return {
      errors,
      knot,
      loading,
      knotContent,
      ytplayer
    }
  }
});
