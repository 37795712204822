import firebaseConfig from "../../firebaseConfig";

export class UploadAdapter {
	loader: any;
	folderName: any;
	constructor(loader: any, folderName: any) {
		this.loader = loader;
		this.folderName = folderName;
	}
	// Starts the upload process.
	upload() {
		return this.loader.file.then(
			(file: any) =>
				new Promise((resolve, reject) => {
					const storage = firebaseConfig.storage().ref();
					const fileName = file.name
					const name = fileName.split('.').slice(0, -1).join('.')
					const ext = fileName.substr(fileName.lastIndexOf('.') + 1)
					//rename file
					let renamedFile = ''
					if (this.folderName) {
						renamedFile = this.folderName + '/' + name + '_' + Date.now() + '.' + ext
					} else {
						renamedFile = name + '_' + Date.now() + '.' + ext
					}

					const uploadTask = storage
						.child(renamedFile)
						.put(file);
					uploadTask.on(
						'state_changed', // or 'state_changed'
						function(snapshot) {
							// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
							const progress =
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							console.log("Upload is " + progress + "% done");
							switch (snapshot.state) {
								case 'paused': // or 'paused'
									console.log("Upload is paused");
									break;
								case 'running': // or 'running'
									console.log("Upload is running");
									break;
							}
						},
						function(error) {
							// A full list of error codes is available at
							// https://firebase.google.com/docs/storage/web/handle-errors
							// eslint-disable-next-line default-case
							switch (error.code) {
								case "storage/unauthorized":
									reject(" User doesn't have permission to access the object");
									break;

								case "storage/canceled":
									reject("User canceled the upload");
									break;

								case "storage/unknown":
									reject(
										"Unknown error occurred, inspect error.serverResponse"
									);
									break;
							}
						},
						function() {
							// Upload completed successfully, now we can get the download URL
							uploadTask.snapshot.ref
								.getDownloadURL()
								.then(function(downloadURL) {
									// console.log("File available at", downloadURL);
									resolve({
										default: downloadURL
									});
								});
						}
					);
				})
		);
	}
}