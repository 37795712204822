
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';

export default {
  name: 'CommerceSellerList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const sellerList = ref(null);
    onMounted(async () => {
      sellerList.value = await getSellerList();
      console.log(sellerList.value);
    });
    const getSellerList = async () => {
      const response = await apiService.get('/partners-mall/seller', {
        page: 0,
        size: 100,
      });
      return response.data.items;
    };
    const viewDetail = (id: string) => {
      router.push(`/commerce/seller/register/${id}`);
    };
    const goResgiter = () => {
      router.push('/commerce/seller/register/new');
    };
    return {
      sellerList,
      viewDetail,
      goResgiter,
    };
  },
};
