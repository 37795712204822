import exAxios from './exios.services';
import mapHtmlTemplate from '../../helper/mapHtmlTemplate.helper';

declare let naver: any;

const MARKER_ENABLE = '사용가능';
const MARKER_DISABLE = '사용불가';
const MARKER_UNKNOWN = '확인안됨';

const mapManager = function (map: any, mapVue: any) {
  // mapVue = mapVue
  // map = map
  let tempMarker: any;
  let spots: any;
  let infoWindow: any;
  let markerList: any[];
  markerList = [];

  const findMarkerById = (id: any) => markerList.filter((marker: { data: { id: any; }; }) => marker.data.id === id);

  const mapClickEvent = () => {
    new naver.maps.Event.addListener(map, 'click', (e: { coord: any; }) => {
      if (tempMarker) {
        tempMarker.setMap(null);
      }
      tempMarker = new naver.maps.Marker({
        position: e.coord,
        map,
        animation: 2,
        draggable: true,
      });
      if (infoWindow && infoWindow.getMap()) infoWindow.close();
      // markerList.push(marker);
      tempMarker.addListener('click', () => {
        const locationData = mapHtmlTemplate.createMarkerDataHtml(tempMarker, checkLocationData, createLocationMarker);
        infoWindow = new naver.maps.InfoWindow({
          content: locationData,
        });
        infoWindow.open(map, tempMarker);
      });
      if (mapVue.type === 'WATER') {
        tempMarker.addListener('click', () => {
          const locationData = mapHtmlTemplate.createMarkerWaterDataHtml(tempMarker);
          infoWindow = new naver.maps.InfoWindow({
            content: locationData,
          });
          infoWindow.open(map, tempMarker);
        });
      }
    });
  };

  const gotoPoint = (gotoPoint: { location: { coordinates: any[]; }; id: any; }) => {
    const goto = new naver.maps.LatLng(
      gotoPoint.location.coordinates[1],
      gotoPoint.location.coordinates[0],
    );
    const marker = markerList.find((marker: { data: { id: any; }; }) => marker.data.id === gotoPoint.id);
    loadLocationData(marker);
    map.setCenter(goto);
    map.setZoom(14, true);
  };

  const removeMarkerAll = () => {
    markerList.forEach((marker: { setMap: (arg0: null) => any; }) => marker.setMap(null));
    markerList = [];
  };

  const makeMarker = (spot: { location: { coordinates: string | any[]; }; y: StringConstructor; x: StringConstructor; name: any; address: any; tideCheck: boolean; hotspot: null; activation: boolean | null | undefined; }) => {
    const position = {
      lat: String,
      lng: String,
    };
    // item.location.coordinates : 데이터 베이스 좌표, 좌표 조정시 실제 좌표값이 들어가야 할 위치.
    // item.y : 네이버 지도에서 가져온 정보
    position.lat =			spot.location && spot.location.coordinates && spot.location.coordinates.length === 2
			  ? spot.location.coordinates[1]
			  : spot.y;
    position.lng =			spot.location && spot.location.coordinates && spot.location.coordinates.length === 2
			  ? spot.location.coordinates[0]
			  : spot.x;
    const marker = new naver.maps.Marker({
      position,
      title: `[${spot.name}] ${spot.address}`,
      draggable: true,
      map,
    });
    if (spot.tideCheck === true) {
      if (spot.hotspot !== null && spot.hotspot) {
        marker.setIcon(mapHtmlTemplate.setHotspotIcon());
      } else {
        marker.setIcon(mapHtmlTemplate.setRedIcon());
      }
    } else if (spot.activation !== undefined && spot.activation !== null && spot.activation === true) {
      marker.setIcon(mapHtmlTemplate.setRedIcon());
    } else {
      marker.setIcon();
    }
    marker.data = spot;
    markerList.push(marker);
    markerEventLoad(marker);
  };

  const makeMarkerFromSpots = (spotsData: any) => {
    spots = spotsData;
    spots.forEach((spot: any) => {
      makeMarker(spot);
    });
  };

  const markerEventLoad = (marker: { addListener: (arg0: string, arg1: () => void) => void; data: { type: string; tideCheck: null; }; getPosition: () => any; setIcon: () => void; }) => {
    marker.addListener('click', () => {
      loadLocationData(marker);
    }); //
    new naver.maps.Event.addListener(marker, 'dragend', () => {
      if (marker.data.type === 'water') {
        infoWindow.setPosition(marker.getPosition());
        infoWindow.setContent(mapHtmlTemplate.makeWaterLocationDataHtml(marker.data));
      } else {
        marker.data.tideCheck = null;
        marker.setIcon();
        if (infoWindow && infoWindow.getMap()) {
          infoWindow.setPosition(marker.getPosition());
          infoWindow.setContent(mapHtmlTemplate.makeLocationDataHtml(marker.data, {
            checkLocationData,
            updateHotspotMarker,
            resetLocationDataPosition,
            removeLocationData,
            updateLocationMarker,
          }));
        }
      }
    });
  };

  const loadLocationData = (marker: { data: { type: string; }; }) => {
    const locationData = marker.data.type === 'water'
			  ? mapHtmlTemplate.makeWaterLocationDataHtml(marker.data)
			  : mapHtmlTemplate.makeLocationDataHtml(marker.data, {
			    checkLocationData,
			    updateHotspotMarker,
			    resetLocationDataPosition,
			    removeLocationData,
			    updateLocationMarker,
			  });
    infoWindow = new naver.maps.InfoWindow({
      content: locationData,
    });

    if (infoWindow.getMap()) {
      infoWindow.close();
    } else {
      infoWindow.open(map, marker);
    }
  };

  const updateHotspotMarker = (id: string) => {
    const marker = findMarkerById(id)[0];
    mapVue.loadingAction(true);
    exAxios.put(
      `/api/ocean/location/hotspot/${id}`,
      null,
      (res: { status: number; data: { result: { hotspot: any; }; }; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data) {
          if (res.data.result.hotspot) {
            marker.setIcon(mapHtmlTemplate.setHotspotIcon());
          }
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  };
  const resetLocationDataPosition = (id: any) => {
    const marker = findMarkerById(id)[0];
    const position = {
      lat: marker.data.location.coordinates[1],
      lng: marker.data.location.coordinates[0],
    };
    infoWindow.setPosition(position);
    marker.setPosition(position);
  };
  const removeMarker = (marker: { setPosition: (arg0: { lat: null; lng: null; }) => void; }) => {
    const position = { lat: null, lng: null };
    marker.setPosition(position);
    infoWindow.setPosition(position);
  };
  const removeLocationData = (id: string) => {
    if (confirm('마커를 삭제하시겠습니까?')) {
      const marker = findMarkerById(id)[0];
      mapVue.loadingAction(true);
      let type = 'ocean';
      if (marker.data.type === 'water') type = marker.data.type;
      exAxios.delete(
        `/api/${type}/location/${id}`,
        null,
        (res: { status: number; data: any; }) => {
          mapVue.loadingAction(false);
          if (res.status === 200 && res.data) {
            removeMarker(marker);
            alert('삭제 되었습니다.');
          }
        },
        (error: any) => {
          console.log(error);
        },
      );
    }
  };
  const updateLocationMarker = (id: string) => {
    const marker = findMarkerById(id)[0];
    const pointName = (<HTMLInputElement>document.getElementById('name')).value;
    const address = (<HTMLInputElement>document.getElementById('address')).value;
    let activation = '';
    if (marker.data.type === 'water') activation = (<HTMLInputElement>document.getElementById('activation')).value;
    if (pointName.trim() === '') {
      alert('포인트 이름을 입력 해주세요.');
      return;
    }
    if (address.trim() === '') {
      alert('포인트 주소를 입력 해주세요.');
      return;
    }
    let type = 'ocean';
    if (marker.data.type === 'water') type = marker.data.type;
    mapVue.loadingAction(true);
    exAxios.put(
      `/api/${type}/location/${id}`,
      {
        lat: marker.position.y,
        lon: marker.position.x,
        tideCheck: true,
        name: pointName,
        address,
        activation,
      },
      (res: { status: number; data: any; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data) {
          alert('업데이트 완료 하였습니다.');
          infoWindow.close();
          marker.data.tideCheck = true;
          marker.data.name = pointName;
          marker.data.address = address;
          marker.data.activation = activation;
          if (activation) {
            marker.setIcon(mapHtmlTemplate.setRedIcon());
          } else {
            marker.setIcon();
          }
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  };
  const createLocationWaterMarker = (lat: any, lon: any) => {
    const waterName = (<HTMLInputElement>document.getElementById('waterName')).value;
    const waterFacCode = (<HTMLInputElement>document.getElementById('waterFacCode')).value;
    const waterMaxHeight = (<HTMLInputElement>document.getElementById('waterMaxHeight')).value;
    const waterMinHeight = (<HTMLInputElement>document.getElementById('waterMinHeight')).value;
    const waterOverHeight = (<HTMLInputElement>document.getElementById('waterOverHeight')).value;

    mapVue.loadingAction(true);
    exAxios.post(
      '/api/water/location/',
      {
        name: waterName,
        lat,
        lon,
        id: waterFacCode,
        max_w: waterMaxHeight,
        min_w: waterMinHeight,
        over_w: waterOverHeight,
      },
      (res: { status: number; data: { result: any; }; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data) {
          alert('생성 되었습니다.');
          removeMarker(tempMarker);
          if (res.data && res.data.result) {
            makeMarker(res.data.result);
          }
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  };

  const createLocationMarker = (lat: number, lon: number) => {
    const pointName = (<HTMLInputElement>document.getElementById('name')).value;
    if (pointName.trim() === '') {
      alert('포인트 이름을 입력 해주세요.');
      return;
    }
    if (isNaN(lat) || isNaN(lon)) {
      alert('정확하지 않은 좌표값입니다.');
      return;
    }
    mapVue.loadingAction(true);
    exAxios.post(
      '/api/ocean/location/',
      {
        name: pointName,
        lat,
        lon,
        tideCheck: 'true',
      },
      (res: { status: number; data: { result: { tideCheck: any; }; }; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data) {
          alert('생성 되었습니다.');
          removeMarker(tempMarker);
          if (res.data && res.data.result) {
            // makeMarker(
            // 	res.data.result,
            // 	!!res.data.result.tideCheck,
            // 	false
            // )
          }
        }
      },
      (error: any) => {
        mapVue.loadingAction(false);
        console.log(error);
      },
    );
  };

  const checkLocationData = (id: null, lat: any, lon: any) => {
    let _lat; let
      _lon;
    let marker: { position: { y: any; x: any; }; setIcon: (arg0: undefined) => void; };
    if (id == null) {
      marker = tempMarker;
      _lat = lat;
      _lon = lon;
    } else {
      marker = findMarkerById(id)[0];
      _lat = marker.position.y;
      _lon = marker.position.x;
    }
    mapVue.loadingAction(true);
    exAxios.get(
      '/api/ocean/tideBedPre',
      {
        params: {
          lat: _lat,
          lon: _lon,
        },
      },
      (res: { status: number; data: { result: string | any[]; }; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data && res.data.result) {
          if (res.data.result.length > 0) {
            alert('데이터가 있습니다.');
            (<HTMLElement>document.getElementById('tideCheck')).innerText = '사용가능';
            const createdPinBtn = document.getElementsByClassName('created-pin-btn') as HTMLCollectionOf<HTMLElement>;
            createdPinBtn[0].style.display = 'inline-block';

            marker.setIcon(mapHtmlTemplate.setRedIcon());
          } else {
            alert('데이터가 없습니다.');
            (<HTMLElement>document.getElementById('tideCheck')).innerText = '사용불가';
            // marker.setIcon()
          }
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  };
  const loadLocationWaterQuality = (id: string) => {
    mapVue.clearWaterInfos();
    mapVue.loadingAction(true);
    exAxios.get(
      `/api/water/quality/${id}`,
      {},
      (res: { status: number; data: { result: any; }; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data && res.data.result) {
          mapVue.updateWaterQuality(res.data.result);
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  };
  const loadLocationWaterState = (id: string) => {
    mapVue.clearWaterInfos();
    mapVue.loadingAction(true);
    exAxios.get(
      `/api/water/info/${id}`,
      {},
      (res: { status: number; data: { result: any; }; }) => {
        mapVue.loadingAction(false);
        if (res.status === 200 && res.data && res.data.result) {
          mapVue.updateWaterLevel(res.data.result);
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  };

  const getSpots = () => spots;

  return {
    gotoPoint,
    getSpots,
    mapClickEvent,
    makeMarkerFromSpots,
    markerEventLoad,
    loadLocationData,
    removeMarkerAll,
    findMarkerById,
    checkLocationData,
    updateHotspotMarker,
    resetLocationDataPosition,
    removeLocationData,
    updateLocationMarker,
    createLocationMarker,
    makeMarker,
    loadLocationWaterQuality,
    loadLocationWaterState,
    createLocationWaterMarker,
  };
};

export default mapManager;
