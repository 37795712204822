
import { defineComponent, ref, onMounted } from 'vue';
import { ApiService } from '@/services/backend-api/api.services';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'PartnerList',
  props: {},
  components: {},
  setup() {
    const apiService = new ApiService();
    onMounted(async () => {
      const data = await apiService.get('/partners/join', {
        orderBy: 'created_at',
        orderType: 'desc',
        page: 1,
        pageSize: 5,
      });
      console.log(data);
    });
  },
});
