import cookieHelper from '../helper/cookie.helper';
import { ApiService } from '../services/backend-api/api.services';

export default async function auth({
  from, to, next, router,
}: any) {
  // Make sure user logged-in
  const accessToken = cookieHelper.getCookie('access_token');
  if (accessToken) {
    const requestRouteName = to.name;
    if (cookieHelper.getCookie('permissions') === 'undefined' && cookieHelper.getCookie('refresh_token')) {
      const apiService = new ApiService();
      const refreshTokenResult = await apiService.refreshToken();
      if (!refreshTokenResult.status) {
        router.push({ name: 'Login' });
      }
    }
    const permissions = cookieHelper.getCookie('permissions');
    let arrayPermissions: Array<string> = [];
    if (permissions) {
      arrayPermissions = permissions.split(',');
    }
    if (arrayPermissions.indexOf(requestRouteName) >= 0) {
      next();
    } else {
      // 어신인 경우 어신 토크 url로 이동합니다.
      if (cookieHelper.getCookie('usin_name')) {
        router.push('/ask');
      } else {
        // Permission denied
        router.push({ name: 'AccessDenied' });
      }
    }
  } else {
    router.push({ name: 'Login' });
  }
}
