<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2 v-if="id === 'new'">
        상품 등록
      </h2>
      <h2 v-else-if="id !== 'new' && isDetail">
        상품 상세
      </h2>
      <h2 v-else>
        상품 수정
      </h2>
    </div>
    <div class="col-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>상품 코드</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="미발행"
                  v-model="product.data_cd"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>상품 카테고리</label>
                <select
                  class="form-control"
                  v-model="product.category_data_cd"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option value="">카테고리 선택</option>
                  <option
                    v-for="category in categoryList"
                    :key="category.data_cd"
                    :value="category.data_cd"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>판매처</label>
                <select
                  class="form-control"
                  v-model="product.seller_data_cd"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option value="">판매처 선택</option>
                  <option
                    v-for="seller in sellerList"
                    :key="seller.data_cd"
                    :value="seller.data_cd"
                  >
                    {{ seller.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>제품명</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="제품명"
                  v-model="product.name"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>종류/규격</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="종류/규격"
                  v-model="product.kind_desc"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>설명(옵션)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="설명(옵션)"
                  v-model="product.desc"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>판매상태</label>
                <select
                  class="form-control"
                  v-model="product.sale_state"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option value="SALE_ON">판매중</option>
                  <option value="SALE_OFF">판매중단</option>
                  <option value="SOLD_OUT">재고없음</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>정상가</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="10000"
                  v-model="product.price_normal"
                  step="100"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>판매가</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="10000"
                  v-model="product.price_sale"
                  step="100"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>공급가</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="10000"
                  v-model="product.price_usin"
                  step="10"
                  :disabled="id !== 'new' && isDetail"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>추천상품</label>
                <select
                  class="form-control"
                  v-model="product.is_recommend"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option :value="true">활성</option>
                  <option :value="false">비활성</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>인기상품</label>
                <select
                  class="form-control"
                  v-model="product.is_popular"
                  :disabled="id !== 'new' && isDetail"
                >
                  <option :value="true">활성</option>
                  <option :value="false">비활성</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>상품 썸네일 이미지</label>
                <div class="d-flex flex-row">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="File name"
                    v-model="product.thumbnail_url"
                    disabled
                  />
                  <input
                    style="display: none;"
                    ref="thumbnailFile"
                    type="file"
                    accept="image/*"
                    @change="uploadThumbnailFile($event)"
                  />
                  <button
                    class="btn btn-primary admin-btn"
                    :disabled="id !== 'new' && isDetail"
                    @click="openThumbnailImage"
                  >
                    파일찾기
                  </button>
                </div>
              </div>
              <img
                ref="thumbnailImg"
                class="thumbnail-img"
                v-show="showThumbnailImage"
                :src="product.thumbnail_url"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>상품 상세 이미지</label>
                <div class="d-flex flex-row">
                  <input
                    style="display: none;"
                    ref="detailFile"
                    type="file"
                    multiple
                    accept="image/*"
                    @change="uploadDetailFile($event)"
                  />
                  <button
                    class="btn btn-primary admin-btn"
                    :disabled="id !== 'new' && isDetail"
                    @click="openDetailImage"
                  >
                    이미지 선택
                  </button>
                </div>
              </div>
              <div class="detail-img-wrap d-flex flex-column">
                <img
                  class="detail-img"
                  v-for="(image, index) in product.product_images"
                  :key="`image${index}`"
                  :src="image.image_url"
                  alt=""
                />
                <img
                  class="add-detail-img"
                  v-for="(image, index) in detailImageList"
                  :key="`image${index}`"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer align-items-center">
          <div v-if="id === 'new'" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="submit"
            >
              저장
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              취소
            </button>
          </div>
          <div v-else-if="id !== 'new' && isDetail" class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="changeMode(false)"
            >
              수정
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-danger"
              @click="deleteProduct"
            >
              삭제
            </button>
            <button type="submit" class="btn btn-lg btn-default" @click="back">
              목록
            </button>
          </div>
          <div v-else class="footer-btn-wrapper">
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              @click="submit"
            >
              저장
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-default"
              @click="changeMode(true)"
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted, onUpdated } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';
import commerceUtil from '@/helper/commerceUtil';

export default {
  name: 'PartnersMallProductRegister',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const categoryList = ref(null);
    const sellerList = ref(null);
    const thumbnailFile = ref(null);
    const thumbnailImg = ref(null);
    const uploadThumbnailImage = ref(false);
    const showThumbnailImage = ref(false);
    const thumbnailImageInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    const detailFile = ref(null);
    const uploadDetailImage = ref(false);
    const detailImg = ref(null);
    const detailImageList = ref([]);
    const product = ref({
      data_cd: '',
      category_data_cd: '',
      seller_data_cd: '',
      name: '',
      kind_desc: '단일 대량 상품',
      desc: '',
      sale_state: 'SALE_ON',
      price_normal: 0,
      price_sale: 0,
      price_usin: 0,
      thumbnail_url: '',
      product_images: [],
      is_recommend: false,
      is_popular: false,
    });
    onMounted(async () => {
      console.log('process.env.NODE_ENV', process.env.NODE_ENV);
      console.log('process.env.mode', process.env.mode);
      console.log('process.env.MODE', process.env.MODE);
      console.log('process.env.VUE_APP_MODE', process.env.VUE_APP_MODE);
      categoryList.value = await getCategoryList();
      sellerList.value = await getSellerList();
      if (id !== 'new') {
        const productData = await getProduct(id);
        showThumbnailImage.value = true;
        product.value = productData;
      }
    });

    const getCategoryList = async () => {
      const response = await apiService.get('/partners-mall/product-category', {
        size: 100,
        page: 0,
      });
      return response.data.items;
    };
    const getSellerList = async () => {
      const response = await apiService.get('/partners-mall/seller', {
        size: 100,
        page: 0,
      });
      return response.data.items;
    };
    const getProduct = async id => {
      const response = await apiService.get(`/partners-mall/product/${id}`);
      return response.data.item;
    };

    const submit = async () => {
      if (!checkValidate()) {
        return false;
      }
      if (id === 'new') {
        swal.createConfirm(create);
      } else {
        swal.updateConfirm(update);
      }
    };
    const create = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (uploadDetailImage.value) {
        await uploadDetail();
      }
      const response = await apiService.post(
        '/partners-mall/product/',
        product.value,
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        alert(response.data.message);
        router.replace('/commerce/product');
      } else {
        alert(response.data.message);
      }
    };
    const update = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (uploadDetailImage.value) {
        await uploadDetail();
      }
      const response = await apiService.put(
        `/partners-mall/product/${id}`,
        product.value,
      );
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        swal.successConfirm(async () => {
          router.push('/commerce/product');
        });
      } else {
        alert(response.data.message);
      }
    };
    const checkValidate = () => {
      if (product.value.name.trim() === '') {
        swal.alert('상품명을 입력되지 않았습니다.');
        return false;
      }
      if (product.value.category_data_cd === '') {
        swal.alert('상품카테고리가 선택되지 않았습니다.');
        return false;
      }
      if (product.value.seller_data_cd === '') {
        swal.alert('판매처가 선택되지 않았습니다.');
        return false;
      }
      if (product.value.kind_desc === '') {
        swal.alert('종류/규격 입력되지 않았습니다.');
        return false;
      }
      if (product.value.desc === '') {
        swal.alert('설명(옵션)이 입력되지 않았습니다.');
        return false;
      }
      if (product.value.price_normal === 0) {
        swal.alert('정상가가 입력되지 않았습니다.');
        return false;
      }
      if (product.value.price_sale === 0) {
        swal.alert('판매가가 입력되지 않았습니다.');
        return false;
      }
      if (product.value.price_usin === 0) {
        swal.alert('어신 공급가가 입력되지 않았습니다.');
        return false;
      }
      // if (product.value.thumbnail_url === '') {
      //   swal.alert('썸네일 이미지가 선택되지 않았습니다.');
      //   return false;
      // }
      // if (product.value.product_images.length === 0) {
      //   swal.alert('상품상세 이미지가 선택되지 않았습니다.');
      //   return false;
      // }

      return true;
    };
    const back = () => {
      router.back();
    };
    const changeMode = mode => {
      isDetail.value = mode;
    };
    const uploadThumbnailFile = event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        thumbnailImg.value.src = e?.target?.result;
        showThumbnailImage.value = true;
      };

      reader.readAsDataURL(file);
      uploadThumbnailImage.value = true;

      if (file) {
        thumbnailImageInfo.value.extension = file.name.split('.').reverse()[0];
        thumbnailImageInfo.value.imageName = file.name;
        thumbnailImageInfo.value.imageFile = file;
      }
    };
    const openThumbnailImage = () => {
      thumbnailFile.value.click();
    };
    const openDetailImage = () => {
      detailFile.value.click();
    };
    const uploadDetailFile = event => {
      const { files } = event.target;
      const OriginDetailImgs = document.querySelectorAll('.detail-img');

      if (files.length > 0) {
        uploadDetailImage.value = true;
        // 기존 이미지 삭제
        OriginDetailImgs.forEach(img => img.remove());
        // input 이미지 초기화
        detailImageList.value = [];
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          detailImageList.value.push({
            extension: file.name.split('.').reverse()[0],
            imageName: file.name,
            imageFile: file,
          });
        }
      }
    };
    onUpdated(() => {
      console.log(product.value.product_images);
      setDetailImage();
    });
    const setDetailImage = () => {
      detailImageList.value.forEach((img, index) => {
        const reader = new FileReader();
        const detailImgs = document.querySelectorAll('.add-detail-img');
        reader.onload = e => {
          detailImgs[index].src = e.target.result;
        };
        reader.readAsDataURL(img.imageFile);
      });
    };
    const getPresignedUrl = async fileName => {
      const response = await apiService.get(
        `/partners-mall/product/pre-signed-url/${product.value.seller_data_cd}?service_type=product&file_name=${fileName}`,
      );
      return response.data.item.pre_signed_url;
    };
    const uploadImage = async (url, file) =>
      await apiService.uploadImage(url, file);
    const uploadThumbnail = async () => {
      console.log('uploadThumbnail');
      const imageName = `${commerceUtil.getUuid()}.${
        thumbnailImageInfo.value.extension
      }`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(
        presignedUrl,
        thumbnailImageInfo.value.imageFile,
      );
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? `https://cdn.uhshin.com/partnersMall/product/${product.value.seller_data_cd}/`
          : `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      product.value.thumbnail_url = `${imageServer}${imageName}`;
      console.log(product.value.thumbnail_url);
    };
    const uploadDetail = async () => {
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? `https://cdn.uhshin.com/partnersMall/product/${product.value.seller_data_cd}/`
          : `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      // 기존 이미지 리스트 초기화
      product.value.product_images = [];
      for (const image of detailImageList.value) {
        const imageName = `${commerceUtil.getUuid()}.${image.extension}`;
        const presignedUrl = await getPresignedUrl(imageName);
        await uploadImage(presignedUrl, image.imageFile);
        product.value.product_images.push({
          image_url: `${imageServer}${imageName}`,
        });
      }
    };
    const deleteProduct = async () => {
      if (confirm('상품을 삭제 하시겠습니까?')) {
        const response = await apiService.delete(
          `/partners-mall/product/${id}`,
        );
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          alert(response.data.message);
          router.replace('/commerce/product');
        } else {
          alert(response.data.message);
        }
      }
    };
    return {
      id,
      isDetail,
      product,
      categoryList,
      sellerList,
      thumbnailFile,
      thumbnailImg,
      showThumbnailImage,
      detailFile,
      detailImg,
      detailImageList,
      submit,
      back,
      changeMode,
      uploadThumbnailFile,
      openThumbnailImage,
      uploadDetailFile,
      openDetailImage,
      deleteProduct,
    };
  },
};
</script>

<style scoped>
.thumbnail-img {
  width: 300px;
  height: 300px;
}
.detail-img {
  width: 200px;
  height: 200px;
}
.add-detail-img {
  width: 200px;
  height: 200px;
}
</style>
