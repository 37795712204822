
import { UserJoinStateCode, UserPaidCode } from '@/config/constant.config';
import ElementStyleHelper, { UserJoinStateCssStyle, UserPaidCssStyle } from '@/helper/element-style.helper';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import { default as CrudModel } from '../../models/reservation.model';
import { ApiService } from '@/services/backend-api/api.services';
import OtherButton from '../../components/ui/OtherButton.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import CrudModel from '../../models/partnersJoin.model';

export default defineComponent({
  components: {
    SubmitButton,
    OtherButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const apiService = new ApiService();
    const bizCerityFile = ref<any>(null);
    const isSubmitting = ref(false);
    const isApproving = ref(false);
    const isRejecting = ref(false);
    const errors = ref<string[]>([]);
    const partner = ref({
      name: '',
      ceo_name: '',
      user_id: '',
      category: '',
      address: '',
      address_detail: '',
      email: '',
      contact: '',
      cell_num: '',
      conversion_contact: '',
      registration_url: '',
      bank_nm: '',
      holder_nm: '0',
      account_num: '',
      business_type_cd: '',
      business_type_nm: '',
      conditions: '',
      business_category: '',
      remark: '',
      join_stat_cd: '',
      join_stat_nm: '',
      reject_remark: '',
      paid_cd: '',
      paid_nm: '',
      recommender: {
        recommender_type: '',
        data_id: '',
      },
    });
    const recommenderCrewList = ref([]);
    const recommenderPartnersList = ref([]);
    // const setPreviewBiz = ref('');

    const { id } = route.params;
    onMounted(async () => {
      loading.value = true;
      const response = await CrudModel.findById(id);
      // categories.value = await CategoriesModel.getAll();
      const partnersRecommenderResponse = await apiService.get('/partners/recommender?recommenderType=PARTNERS');
      recommenderPartnersList.value = partnersRecommenderResponse.data.items;
      const crewRecommenderResponse = await apiService.get('/partners/recommender?recommenderType=SALES_CREW');
      recommenderCrewList.value = crewRecommenderResponse.data.items;
      partner.value = response.item;
      if (!partner.value.recommender) {
        partner.value.recommender = {
          recommender_type: '',
          data_id: '',
        };
      }
      loading.value = false;
    });

    const submitForm = async () => {
      // if(parseInt(partner.value.status) === 1) {
      //   if(!partner.value.booking_tel) {
      //     swal.alert('예약 연락처는 필수로 입력 바랍니다.');
      //     return;
      //   }
      //   swal.partnerConfirm(update);
      // } else {
      //   swal.updateConfirm(update);
      // }

      swal.updateConfirm(update);
    };
    const update = async () => {
      isSubmitting.value = true;
      errors.value = [];
      // const biz_cerity = await bizCerityFile.value?.uploadImage();
      // if (biz_cerity.length <= 0) {
      // biz_cerity = partner.value.biz_cerity;
      // } else {
      // partner.value.biz_cerity = biz_cerity;
      // }

      const updateResult = await CrudModel.update(id, partner.value);
      if (updateResult.status) {
        isSubmitting.value = false;
        swal.successConfirm(async () => {
          await router.push({
            path: '/partners-join',
            query: route.query,
          });
        });
      } else {
        isSubmitting.value = false;
        errors.value.push(updateResult.message);
      }
    };

    // 가입신청 승인 처리 요청
    const approveConfirm = async () => {
      swal.partnersApproveConfirm(async () => {
        isApproving.value = true;
        const approved = await apiService.approvePartnersJoin(Number(id));

        isApproving.value = false;

        await swal.partnersSuccessConfirm(async () => {
          if (approved.status) {
            await router.push({
              path: '/partners-join',
              query: route.query,
            });
          } else {
            isApproving.value = false;
            swal.alert(approved.data);
          }
        }, '승인 되었습니다.');
      });
    };

    // 가입신청 반려 처리 요청
    const rejectConfirm = async () => {
      swal.partnersRejectConfirm(async (reject_remark: string) => {
        isRejecting.value = true;

        const apiService = new ApiService();
        const rejected = await apiService.rejectPartnersJoin(Number(id), reject_remark);

        isRejecting.value = false;

        await swal.partnersSuccessConfirm(async () => {
          if (rejected.status) {
            await router.push({
              path: '/partners-join',
              query: route.query,
            });
          } else {
            isRejecting.value = false;
            swal.alert(rejected.data);
          }
        }, '반려 되었습니다.');
      });
    };
    const updateRecommener = () => {
      const data_id = partner.value.recommender.data_id === '' ? null : partner.value.recommender.data_id;
      const recommender_type = partner.value.recommender.recommender_type === '' ? null : partner.value.recommender.recommender_type;
      if (!data_id || (recommender_type && !data_id)) {
        swal.alert('추천인을 선택해 주세요.');
        return false;
      }
      swal.updateConfirm(async () => {
        const response = await apiService.put(`/partners/join/${id}/recommender`, {
          data_id,
          recommender_type,
        });
        if (response.status) {
          swal.successConfirm(() => {});
        }
      }, '추천인 정보를 업데이트 하시겠습니까?');
    };
    const setRecommender = () => {
      partner.value.recommender.data_id = '';
    };
    return {
      errors,
      partner,
      bizCerityFile,
      submitForm,
      isSubmitting,
      isApproving,
      isRejecting,
      loading,
      approveConfirm,
      rejectConfirm,
      // setPreviewBiz,
      ElementStyleHelper,
      UserJoinStateCode,
      UserPaidCode,
      UserJoinStateCssStyle,
      UserPaidCssStyle,
      updateRecommener,
      recommenderPartnersList,
      recommenderCrewList,
      setRecommender,
    };
  },
});
