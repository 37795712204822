
import { defineComponent, ref, onMounted} from 'vue'
import { useRouter } from 'vue-router'
import dateTimeHelper from '../../helper/datetime.helper'
import { ApiService } from '../../services/backend-api/api.services'

export default defineComponent({
	setup() {
		const router = useRouter()
		let email = router.currentRoute.value.params.email
		let errors = ref<Array<string>>([])
		let eventsLog = ref({})
		let loading = ref(true)
		onMounted(async () => {
			const apiService = new ApiService
			let requestData = await apiService.getUserEventsLog({email})
			if (requestData.status) {
				eventsLog.value = requestData.data;
			} else {
				errors.value.push(requestData.data.message)
			}
			loading.value = false
		})

		return {
			errors,
			email,
			eventsLog,
			dateTimeHelper,
			loading
		}
	}
})
