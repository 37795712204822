export function constants() {
  return {
    DEFAULT_PAGE: 1,
    DEFAULT_PERPAGE: 25,
    API_BASE_URL: 'http://localhost:3001/',
  };
}

export enum BooleanTypes {
  Y = 'Y', N = 'N'
}

export enum IsUserSearchEventAt {
  Y, N
}

export enum UserJoinStateCode {
  JOIN='JOIN',
  APPROVE='APPROVE',
  REJECT='REJECT'
}

export enum UserPaidCode {
  PAID='PAID',
  FREE='FREE',
}

export enum BookingRequestState {
  NO_REQUEST='NOT_REQUEST',
  REQUEST ='REQUEST',
  CONFIRM = 'CONFIRM'
}

export enum Show {
  YES = 'true',
  NO = 'false'
}

export enum BusinessTypes {
  BOAT = 'BOAT',
  SPOT = 'SPOT',
  SHOP = 'SHOP',
}

export enum PartnersProductCategoryTypes {
  BOATPRODUCT = 'BOATPRODUCT', // 선박낚시
  ETCFISHSPOT = 'ETCFISHSPOT', // 잡어터
  ROCKPRODUCT = 'ROCKPRODUCT', // 갯바위
  TASTESOPT = 'TASTESOPT', // 손맛터
}

export enum PartnersProductPaidTypes {
  PAID = 'PAID', // 유료
  FREE = 'FREE', // 무료
}

/**
 * 사용자에 의한 신고사유 유형
 */
export enum UserReportReasonTypes {
  // 부적절한 콘텐츠 게시
  INAPPROPRIATE = 'inappropriate',
  // 타인사칭
  IMPOSTOR = 'impostor',
  // 스펨계정
  SPAM = 'spam',
  // 기타
  ETC = 'etc',
}

/**
 * 어종 코드 카테고리
 */
export enum FishCodeCategoryTypes {
  SEA = 'SEA', // 바다
  FRESH = 'FRESH', // 민물
}

/**
 * 컨텐츠 조회 유형
 */
export enum ContentViewTypes {
  // 웹 뷰
  WEB_VIEW = 'WEB_VIEW',

  // 웹 브라우저 링크
  WEB_BROWSER_LINK = 'WEB_BROWSER_LINK',
}
