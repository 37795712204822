import firebase from 'firebase';
import { BaseModel } from './base.model';
import Timestamp = firebase.firestore.Timestamp;

export interface FishingGrams {
	images: Array<string>;
	show_home: boolean;
	fishing_place_type: string;
	created_at: number;
	updated_at: number;
	ban: boolean;
	fix_home: boolean;
}

class FishingGramsModel extends BaseModel {
	protected collectionName = 'fishing_grams'

	private static _instance: FishingGramsModel

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}
}

export default FishingGramsModel.Instance;
