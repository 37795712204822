
import { defineComponent } from 'vue';
import Sidebar from './CommerceSidebar.vue';
import Header from './DefaultHeader.vue';
import Footer from './DefaultFooter.vue';
import Splash from '../Splash.vue';

export default defineComponent({
  components: {
    Header,
    Sidebar,
    Footer,
    Splash,
  },
  computed: {
    loaded() {
      return false;
    },
  },
});
