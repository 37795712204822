
import { defineComponent, ref } from 'vue';
import { default as CrudModel } from '../../models/notification.model';
import dateTimeHelper from '../../helper/datetime.helper';
import { useRouter } from 'vue-router';
import Pagination from '../../components/ui/Pagination.vue';
import numberHelper from '../../helper/number.helper';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'NotificationManager',
  components: { Pagination },
  setup: () => {
    const router = useRouter();
    let loading = ref(false);
    let notifications = ref<Array<any>>([]);
    let orderOptions = ref([['created_at', 'desc']]);
    let filterConditions = ref<Array<any>>([]);
    let filter = ref({
      pin: null,
      show: null,
      show_home: null,
      show_more: null,
      app: null,
    });

    function filterHandler() {
      filterConditions.value = [];
      if (filter.value.pin !== null) {
        filterConditions.value.push(['pin', filter.value.pin]);
      }

      if (filter.value.show !== null) {
        filterConditions.value.push(['show', filter.value.show]);
      }

      if (filter.value.show_home !== null) {
        filterConditions.value.push(['show_home', filter.value.show_home]);
      }

      if (filter.value.show_more !== null) {
        filterConditions.value.push(['show_more', filter.value.show_more]);
      }

      if (filter.value.app !== null) {
        filterConditions.value.push(['app', filter.value.app]);
      }
    }

    function isLoading(status: boolean) {
      if (status) {
        loading.value = true;
      } else {
        loading.value = false;
      }
    }

    function updateData(items: Array<any>) {
      loading.value = false;
      notifications.value = items;
    }

    async function deleteRecord(id: any) {
      swal.simpleDeleteConfirm(async function() {
        await CrudModel.delete(id);
        notifications.value = await CrudModel.getAll();
      });
    }

    async function editRecord(id: any) {
      router.push('/notifications/edit/' + id);
    }

    return {
      loading,
      notifications,
      filter,
      orderOptions,
      filterConditions,
      CrudModel,
      dateTimeHelper,
      deleteRecord,
      editRecord,
      isLoading,
      updateData,
      filterHandler,
      numberHelper,
    };
  },
});
