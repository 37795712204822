
import { defineComponent, ref, onMounted } from 'vue';
import { ApiService } from '@/services/backend-api/api.services';
import { useRouter } from 'vue-router';
import {
  Role,
  default as AdminRolesModel,
} from '../../models/adminRoles.model';
import { default as PermissionsModel } from '../../models/permissions.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
  },
  computed: {
    checked() {
      return true;
    },
  },
  setup() {
    const apiService = new ApiService();
    const router = useRouter();
    let loading = ref(false);
    let errors = ref<string[]>([]);
    let role = ref<Role>({
      id: '',
      role_code: '',
      role_name: '',
      status: true,
      permissions: [],
    });
    let isSubmitting = ref(false);
    let allRoutes = ref(router.getRoutes());
    let myRoute = router.getRoutes();
    let role_code = ref('');
    let role_name = ref('');
    let permissions = ref<string[]>([]);
    let checkedPermissions = ref<string[]>([]);
    let status = ref(1);
    const responsePermissionsId = ref<string[]>([]);

    let groupRoutes = myRoute.reduce(function(r: any, a: any) {
      r[a.meta.group] = r[a.meta.group] || [];
      r[a.meta.group].push(a);
      return r;
    }, Object.create(null));

    let id = router.currentRoute.value.params.id;
    onMounted(async () => {
      loading.value = true;
      role.value = await AdminRolesModel.findById(id);
      let permissionInfo = await PermissionsModel.getAll([], [], 1, 1000);
      if (role.value.permissions.length > 0) {
        for (let k in permissionInfo) {
          let v: any = permissionInfo[k];
          if (role.value.permissions.includes(v.id)) {
            permissions.value.push(
              v.client_route + '@' + v.server_route + '@' + v.name,
            );
          }
        }
      }
      loading.value = false;
    });

    async function onSubmitform() {
      isSubmitting.value = true;
      errors.value = [];

      let validCode = /^[a-zA-Z]+$/.test(role.value.role_code);
      if (!validCode) {
        errors.value.push('Role code must be a string of alphabet characters');
        swal.alert('Role code must be a string of alphabet characters');
        isSubmitting.value = false;
        return;
      }

      (document.querySelectorAll('.form-check-input') as NodeListOf<
        HTMLInputElement
      >).forEach(checkbox => {
        if (checkbox.checked) {
          checkedPermissions.value.push(checkbox.value);
        }
      });

      const transformedPermissions = checkedPermissions.value.map(
        permission => {
          const [clientRoute, serverRoute, name] = permission.split('@');
          return {
            name: name,
            client_route: clientRoute,
            server_route: serverRoute,
            server_route_method: '',
          };
        },
      );

      if (!errors.value.length) {
        try {
          const response = await apiService.put('/v2/permissions/bulk', {
            permissions: transformedPermissions,
          });

          if (
            response.data.statusCode === 200 ||
            response.data.statusCode === 201
          ) {
            responsePermissionsId.value = response.data.items.map(
              (item: { id: string }) => item.id,
            );
            const updateResult = await AdminRolesModel.update(id, {
              permission: responsePermissionsId.value,
            });
            if (updateResult.status) {
              router.push('/admin-roles');
            } else {
              swal.alert(updateResult.message);
            }
          } else {
            throw new Error(response.data.message || 'Update failed');
          }
        } catch (error) {
          errors.value.push(error.message);
          swal.alert(error.message);
        } finally {
          isSubmitting.value = false;
        }
      } else {
        isSubmitting.value = false;
      }
    }

    return {
      onSubmitform,
      isSubmitting,
      allRoutes,
      role_code,
      role_name,
      permissions,
      status,
      role,
      loading,
      groupRoutes,
    };
  },
});
