
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'DefaultFooter',
  props: {},
  setup: () => {
    const currentYear = ref(2020);
    currentYear.value = new Date().getFullYear();
    return { currentYear };
  },
});
