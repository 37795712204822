
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import { AppPushService } from '@/services/view/appPush.services';
import { Push } from '../../models/appPushes.model';
import FileUpload from '../../components/ui/FileUpload.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    FileUpload,
  },

  setup() {
    const router = useRouter();
    const loading = ref(false);
    const subscriptionList = ref<{ [key: string]: string }>({});
    const appPushService = new AppPushService();
    const apiService = new ApiService();
    const push = ref<Push>({
      title: '',
      data_id: '',
      body: '',
      imageUrl: '',
      keywords: '',
      dry_run: false,
      landingUrl: '',
    });

    interface CustomFile extends File {
      uploadImage: () => Promise<string>;
    }

    const setPreviewImg = ref('');
    const thumbnailFile = ref<CustomFile | null>(null);
    const selectedCategory = ref('');
    const selectedLandingUrl = ref('');
    const landingUrlOption = ref({});
    const landingUrlList = ref(['홈 슬라이드', '청약몰 상세', '포인트 충전소']);

    onMounted(async () => {
      console.log(push.value);
      try {
        loading.value = true;
        subscriptionList.value = await appPushService.getSubscriptionList();
      } catch (error) {
        swal.alert('Error fetching subscription list');
      } finally {
        loading.value = false;
      }
    });

    const handleSelectionChange = async () => {
      loading.value = true;
      if (!landingUrlList.value.includes(selectedCategory.value)) {
        console.error('Invalid category selected:', selectedCategory);
        return;
      }

      landingUrlOption.value = {};
      if (selectedCategory.value === landingUrlList.value[0]) {
        landingUrlOption.value = await appPushService.getPostUrlList();
      } else if (selectedCategory.value === landingUrlList.value[1]) {
        landingUrlOption.value = await appPushService.getSubscriptionList();
      } else if (selectedCategory.value === landingUrlList.value[2]) {
        selectedLandingUrl.value = 'usin://usin/conet-point-cash';
      }
      loading.value = false;
    };

    const submitForm = async () => {
      loading.value = true;

      if (thumbnailFile.value) {
        push.value.imageUrl = await thumbnailFile.value?.uploadImage();
      }

      if (selectedLandingUrl.value) {
        push.value.landingUrl = selectedLandingUrl.value;
      }

      Object.keys(push.value).forEach(key => {
        if (push.value[key as keyof Push] === '') {
          delete (push.value as Partial<Push>)[key as keyof Push];
        }
      });

      const response = await apiService.post(
        '/v3/usin-subscription-event/instant',
        push.value,
      );
      if (response.status === true) {
        // window.location.reload();
        await router.push({
          path: '/app-pushes/add',
          query: { refresh: Date.now().toString() },
        });
        // 쿼리 추가로 네비게이션, 컴포넌트 마운트 시켜 데이터 새로 불러옴
      } else {
        swal.alert(response.message);
      }
      loading.value = false;
    };

    return {
      loading,
      push,
      submitForm,
      setPreviewImg,
      thumbnailFile,
      landingUrlList,
      selectedCategory,
      selectedLandingUrl,
      landingUrlOption,
      handleSelectionChange,
    };
  },
});
