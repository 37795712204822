import { RouteRecordRaw } from 'vue-router';
import guest from '@/middleware/guest';
import Commerce from '../views/commerce/Index.vue';
import CommerceSeller from '../views/commerce/seller/Index.vue';
import CommerceSellerRegister from '../views/commerce/seller/Register.vue';
import CommerceSellerDetail from '../views/commerce/seller/Detail.vue';
import CommerceCategory from '../views/commerce/product-category/Index.vue';
import CommerceCategoryRegister from '../views/commerce/product-category/Register.vue';
import CommerceProduct from '../views/commerce/product/Index.vue';
import CommerceProductRegister from '../views/commerce/product/Register.vue';
import CommerceOrder from '../views/commerce/order/Index.vue';
import CommerceOrderDetail from '../views/commerce/order/Detail.vue';
import CommerceContract from '../views/commerce/contract/Index.vue';
import CommerceContractList from '../views/commerce/contract/List.vue';
import CommercePlacingOrder from '../views/commerce/placing-order-after/Index.vue';
import CommercePlacingOrderList from '../views/commerce/placing-order-after/List.vue';
import CommercePlacingOrderDetailList from '../views/commerce/placing-order-after/DetailList.vue';
import CommerceShipping from '../views/commerce/shipping/Index.vue';
import CommerceStatement from '../views/commerce/statement/Index.vue';
import CommerceStatementList from '../views/commerce/statement/List.vue';
import CommerceStatementDetail from '../views/commerce/statement/Detail.vue';
import CommerceProductPromotion from '../views/commerce/product-promotion/Index.vue';
import CommerceProductPromotionRegister from '../views/commerce/product-promotion/Register.vue';

import auth from '../middleware/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/commerce',
    name: 'Commerce',
    component: Commerce,
    meta: {
      text: '어신 커머스 메인', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스',
    },
  },
  {
    path: '/commerce/seller',
    name: 'CommerceSellerIndex',
    component: CommerceSeller,
    meta: {
      text: '어신 커머스 셀러 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 셀러관리', guest: true,
    },
  },
  {
    path: '/commerce/seller/register/:id',
    name: 'CommerceSellerRegister',
    component: CommerceSellerRegister,
    meta: {
      text: '어신 커머스 셀러 등록/수정', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 셀러관리', guest: true,
    },
  },
  {
    path: '/commerce/seller/detail',
    name: 'CommerceSellerDetail',
    component: CommerceSellerDetail,
    meta: {
      text: '어신 커머스 셀러 상세', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 셀러관리', guest: true,
    },
  },
  {
    path: '/commerce/product-category',
    name: 'CommerceCategoryIndex',
    component: CommerceCategory,
    meta: {
      text: '어신 커머스 상품 카테고리 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 카테고리 관리', guest: true,
    },
  },
  {
    path: '/commerce/product-category/register/:id',
    name: 'CommerceCategoryRegister',
    component: CommerceCategoryRegister,
    meta: {
      text: '어신 커머스 카테고리 등록/수정', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 카테고리 관리', guest: true,
    },
  },
  {
    path: '/commerce/product',
    name: 'CommerceProductList',
    component: CommerceProduct,
    meta: {
      text: '어신 커머스 상품 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 상품 관리', guest: true,
    },
  },
  {
    path: '/commerce/product/register/:id',
    name: 'CommerceProductRegister',
    component: CommerceProductRegister,
    meta: {
      text: '어신 커머스 상품 등록/수정', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 상품 관리', guest: true,
    },
  },
  {
    path: '/commerce/order',
    name: 'CommerceOrderIndex',
    component: CommerceOrder,
    meta: {
      text: '어신 커머스 주문 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 주문 관리', guest: true,
    },
  },
  {
    path: '/commerce/order/detail/:id',
    name: 'CommerceOrderDetail',
    component: CommerceOrderDetail,
    meta: {
      text: '어신 커머스 주문 상세', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 주문 관리', guest: true,
    },
  },
  {
    path: '/commerce/contract',
    name: 'CommerceContractIndex',
    component: CommerceContract,
    meta: {
      text: '어신 커머스 미발주 현황', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 발주 관리', guest: true,
    },
  },
  {
    path: '/commerce/contract/list/:id',
    name: 'CommerceContractList',
    component: CommerceContractList,
    meta: {
      text: '어신 커머스 미발주 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 발주 관리', guest: true,
    },
  },
  {
    path: '/commerce/placing-order-after',
    name: 'CommercePlacingOrderAfterCIndex',
    component: CommercePlacingOrder,
    meta: {
      text: '어신 커머스 발주 현황', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 발주 관리', guest: true,
    },
  },
  {
    path: '/commerce/placing-order-after/list/:id',
    name: 'CommercePlacingOrderAfterList',
    component: CommercePlacingOrderList,
    meta: {
      text: '어신 커머스 발주 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 발주 관리', guest: true,
    },
  },
  {
    path: '/commerce/placing-order-after/detail-list/:sellerCode/:id',
    name: 'CommercePlacingOrderAfterDetailList',
    component: CommercePlacingOrderDetailList,
    meta: {
      text: '어신 커머스 발주 디테일', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 발주 관리', guest: true,
    },
  },
  {
    path: '/commerce/shipping',
    name: 'CommerceShippingIndex',
    component: CommerceShipping,
    meta: {
      text: '어신 커머스 배송 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 배송 관리', guest: true,
    },
  },
  {
    path: '/commerce/statement',
    name: 'CommerceStatementIndex',
    component: CommerceStatement,
    meta: {
      text: '어신 커머스 미정산 내역', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 정산 관리', guest: true,
    },
  },
  {
    path: '/commerce/statement/list',
    name: 'CommerceStatementList',
    component: CommerceStatementList,
    meta: {
      text: '어신 커머스 미정산 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 정산 관리', guest: true,
    },
  },
  {
    path: '/commerce/statement/Detail',
    name: 'CommerceStatementDetail',
    component: CommerceStatementDetail,
    meta: {
      text: '어신 커머스 미정산 상세', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 정산 관리', guest: true,
    },
  },
  {
    path: '/commerce/promotion',
    name: 'CommerceProductPromotion',
    component: CommerceProductPromotion,
    meta: {
      text: '어신 커머스 상품 프로모션 목록', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 프로모션 관리', guest: true,
    },
  },
  {
    path: '/commerce/promotion/register/:id',
    name: 'CommerceProductPromotionRegister',
    component: CommerceProductPromotionRegister,
    meta: {
      text: '어신 커머스 프로모션 상품 등록/수정', layout: 'default', middleware: guest, serverRoute: '', group: '어신 커머스 프로모션 관리', guest: true,
    },
  },
];

export default routes;
