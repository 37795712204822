import {BaseModel} from "./base.model";
export interface Role {
	id: string;
	role_code: string;
	role_name: string;
	status: boolean;
	permissions: Array<string>;
}

class AdminRolesModel extends BaseModel {
	protected collectionName = 'admin_roles'

	private static _instance: AdminRolesModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default AdminRolesModel.Instance