
import { defineComponent} from 'vue'
export default defineComponent({
	name: "waterLevel",
	props: {
		waterLevel: {
			type: Object,
		},
	},
	methods: {
		popupAction() {
			// this.waterLevel = null;
		},
	},
});
