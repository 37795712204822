
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { CImage } from '@coreui/vue';
import FishingGramsModel from '../../models/fishingGrams.model';
import dateTimeHelper from '../../helper/datetime.helper';
import BlindModel from '../../models/blind.model';
import { ApiService } from '../../services/backend-api/api.services';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    CImage,
  },
  setup() {
    const router = useRouter();
    const apiService = new ApiService();
    const thumbnailFile = ref<any>(null);
    const customEditor = ref<any>(null);
    const loading = ref(false);
    const isSubmitting = ref(false);
    const postId = router.currentRoute.value.params.id;
    const fishingGram = ref<any>({
      uid: '',
      images: [],
      show_home: true,
      status: true,
      content: '',
      tags: [],
      fishing_date: 0,
      how_to_long_fishing_time: 0,
      fish_name: [],
      fish_size: 0,
      fishing_type: 0,
      env_weather_info: [],
      bait: [],
      location: [],
      likes: [],
      comments: [],
      sharing_number: 0,
      fishing_place_type: '',
      preferred_type: [],
      created_at: 0,
      updated_at: 0,
      deleted_at: 0,
    });

    onMounted(async () => {
      loading.value = true;
      fishingGram.value = await FishingGramsModel.findById(postId);
      console.log(fishingGram.value);
      loading.value = false;
    });

    async function submitForm() {
      isSubmitting.value = true; // form submit 작업 시작

      // 피싱그램 데이터 수정
      const updateResult = await FishingGramsModel.update(
        postId,
        fishingGram.value,
      );

      if (updateResult.status) {
        router.push('/fishing-grams');
      } else {
        swal.alert(updateResult.message);
      }
      isSubmitting.value = false; // form submit 작업 종료
    }

    async function blindHandler(
      id: string,
      e: { target: { checked: boolean } },
    ) {
      const data = {
        target_id: id,
        parent_id: postId,
        type: 'fishinggram.comment',
        reason: 'Remove comment from admin',
        blind_date: Date.now(),
      };

      if (e.target.checked) {
        const addResult = await BlindModel.create(data);
        if (!addResult.status) {
          e.target.checked = !e.target.checked;
          swal.alert(addResult.message);
        }
      } else {
        const changeResult = await apiService.deleteBlindData(data);
        if (!changeResult.status) {
          e.target.checked = !e.target.checked;
          swal.alert(changeResult.message);
        }
      }
    }

    return {
      thumbnailFile,
      submitForm,
      loading,
      isSubmitting,
      customEditor,
      fishingGram,
      postId,
      blindHandler,
    };
  },
});
