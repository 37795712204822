<template>
  <div class="card">
    <div class="card-body">
      <section class="admin-main-content">
        <div class="admin-content-header">
          <h2>
            청약 제품 상세보기
          </h2>
        </div>
        <div class="col-12">
          <div class="card card-default">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>청약 이벤트명</label>
                    <input type="text" class="form-control" placeholder="청약 이벤트명" v-model="subscription.title">
                  </div>
                  <div class="form-group">
                    <label>이벤트 상세</label>
                    <textarea class="form-control" placeholder="이벤트 상세 내용" v-model="subscription.desc"></textarea>
                  </div>
                  <div class="form-group">
                    <label>청약 추첨 알고리즘</label>
                    <select class="form-control" v-model="subscription.subscribe_algorithm">
                      <option value="RANDOM">랜덤</option>
                      <option value="FIRST_COME">선착순</option>
                    </select>
                  </div>
                  <label>노출여부</label>
                  <div class="form-group">
                    <label class="c-switch c-switch-pill c-switch-primary">
                      <input type="checkbox" class="c-switch-input" v-model="subscription.is_public">
                      <span class="c-switch-slider"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label>청약가능 등급</label>
                    <select class="form-control" v-model="subscription.purchasable_tier.tier_id">
                      <option value="">청약 가능등급을 선택하세요.</option>
                      <option v-for="tier in tierList" :key="tier.id" :value="tier.id">{{ tier.codeName }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>추첨수</label>
                    <input type="number" class="form-control" placeholder="" v-model="subscription.subscribe_count">
                  </div>
                  <div class="form-group">
                    <label>일반가격</label>
                    <input type="number" class="form-control" placeholder="100000" v-model="subscription.normal_price">
                  </div>
                  <div class="form-group">
                    <label>이벤트가격</label>
                    <input type="number" class="form-control" placeholder="60000" v-model="subscription.event_price">
                  </div>
                  <div class="form-group">
                    <label>이벤트게시 일시</label>
                    <input type="datetime-local" class="form-control" placeholder="" v-model="subscription.publish_date">
                  </div>
                  <div class="form-group">
                    <label>청약이벤트 기간</label>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="datetime-local" class="form-control" placeholder="" v-model="subscription.event_start_at">
                      </div>
                      <div class="col-md-6">
                        <input type="datetime-local" class="form-control" placeholder="" v-model="subscription.event_end_at">
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>청약 추첨 후 결제 마감 시간 간격</label>
                    <select class="form-control" v-model="subscription.payment_closing_term">
                      <option value="03:00:00">3시간</option>
                      <option value="06:00:00">6시간</option>
                      <option value="12:00:00">12시간</option>
                      <option value="18:00:00">18시간</option>
                      <option value="24:00:00">24시간</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>비고</label>
                    <input type="text" class="form-control" placeholder="" v-model="subscription.remark">
                  </div>
                  <label>옵션여부</label>
                  <div class="form-group">
                    <label class="c-switch c-switch-pill c-switch-primary">
                      <input type="checkbox" class="c-switch-input" v-model="subscription.is_with_option" :disabled="subscription.progress_status !== 'READY'">
                      <span class="c-switch-slider"></span>
                    </label>
                  </div>
                  <div v-if="subscription.is_with_option" class="form-group">
                    <div class="table-responsive">
                      <table class="table table-responsive-sm table-bordered table-striped table-sm">
                        <thead>
                        <tr class="text-center">
                          <th>옵션명</th>
                          <th>옵션정보값 예) 화이트,핑크,블랙(','로 구분)</th>
                          <th>삭제</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="optionResult.option_meta.option_1">
                          <td>
                            <input type="text" class="form-control" placeholder="옵션명" v-model="optionResult.option_meta.option_1.key" :disabled="subscription.progress_status !== 'READY'">
                          </td>
                          <td>
                            <input type="text" class="form-control" placeholder="옵션정보값 예) 화이트, 핑크 블랙" v-model="optionResult.option_meta.option_1.value" :disabled="subscription.progress_status !== 'READY'">
                          </td>
                          <td></td>
                        </tr>
                        <tr v-if="optionResult.option_meta.option_2">
                          <td>
                            <input type="text" class="form-control" placeholder="옵션명" v-model="optionResult.option_meta.option_2.key" :disabled="subscription.progress_status !== 'READY'">
                          </td>
                          <td>
                            <input type="text" class="form-control" placeholder="옵션정보값 예) 화이트, 핑크 블랙" v-model="optionResult.option_meta.option_2.value" :disabled="subscription.progress_status !== 'READY'">
                          </td>
                          <td>
                            <button class="btn btn-danger" @click="removeOption('option_2')" :disabled="subscription.progress_status !== 'READY'">X</button>
                          </td>
                        </tr>
                        <tr v-if="optionResult.option_meta.option_3">
                          <td>
                            <input type="text" class="form-control" placeholder="옵션명" v-model="optionResult.option_meta.option_3.key" :disabled="subscription.progress_status !== 'READY'">
                          </td>
                          <td>
                            <input type="text" class="form-control" placeholder="옵션정보값 예) 화이트, 핑크 블랙" v-model="optionResult.option_meta.option_3.value" :disabled="subscription.progress_status !== 'READY'">
                          </td>
                          <td>
                            <button class="btn btn-danger" @click="removeOption('option_3')" :disabled="subscription.progress_status !== 'READY'">X</button>
                          </td>
                        </tr>
                        <tr v-if="!optionResult.option_meta.option_3">
                          <td class="text-center" colspan="3">
                            <button class="btn btn-primary" @click="addOption" :disabled="subscription.progress_status !== 'READY'">옵션 추가</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <button class="btn btn-primary" @click="setOption" :disabled="subscription.progress_status !== 'READY'">옵션 목록으로 적용</button>
                    </div>
                  </div>
                  <div v-if="subscription.is_with_option && optionResult.option_result.length > 0" class="form-group">
                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                      <thead>
                      <tr class="text-center">
                        <th colspan="3">옵션명</th>
                        <th rowspan="2" width="100">사용여부</th>
                      </tr>
                      <tr class="text-center">
                        <th>{{ optionResult.option_meta.option_1.key }}</th>
                        <th>{{ optionResult.option_meta.option_2?.key }}</th>
                        <th>{{ optionResult.option_meta.option_3?.key }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(option, index) in optionResult.option_result" :key="`option${index}`">
                        <td>{{ option.option_1 }}</td>
                        <td>{{ option.option_2 }}</td>
                        <td>{{ option.option_3 }}</td>
                        <td>
                          <label class="c-switch c-switch-pill c-switch-primary">
                            <input type="checkbox" class="c-switch-input" v-model="option.is_public" @change="toggleOption(option)">
                            <span class="c-switch-slider"></span>
                          </label>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>이벤트 제품명</label>
                    <input type="text" class="form-control" placeholder="이벤트 제품명" v-model="subscription.product_name">
                  </div>
                  <div class="form-group">
                    <label>제품 브랜드</label>
                    <input type="text" class="form-control" placeholder="제품 브랜드" v-model="subscription.brand">
                  </div>
                  <div class="form-group">
                    <label>반품/교환 주소</label>
                    <input type="text" class="form-control" placeholder="주소(클릭해서 검색)" v-model="subscription.brand_address" readonly @click="openSearchAddress">
                    <input type="text" class="form-control" placeholder="상세주소" v-model="subscription.brand_address_detail">
                  </div>
                  <div class="form-group">
                    <label>판매제품 문의처</label>
                    <input type="text" class="form-control" placeholder="0648685427" v-model="subscription.brand_contact">
                  </div>
                  <div class="form-group">
                    <label>썸네일 이미지</label>
                    <div class="d-flex flex-row">
                      <input type="text" class="form-control" placeholder="File name" disabled v-model="subscription.thumbnail_url">
                      <input style="display: none;" ref="thumbnailFile" type="file" accept="image/*" @change="uploadThumbnailFile($event)"/>
                      <button class="btn btn-primary admin-btn" @click="openThumbnailImage">파일찾기</button>
                    </div>
                  </div>
                  <img ref="thumbnailImg" class="thumbnail-img" v-show="showThumbnailImage" :src="subscription.thumbnail_url" alt="" />
                  <div class="form-group">
                    <label>상품 상세 이미지</label>
                    <div class="d-flex flex-row">
                      <input style="display: none;" ref="detailFile" type="file" multiple accept="image/*" @change="uploadDetailFile($event)"/>
                      <button class="btn btn-primary admin-btn" @click="openDetailImage">이미지 선택</button>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <img class="detail-img" v-for="(image, index) in subscription.images" :key="`image${index}`" :src="image.image_url" alt="" />
                    <img class="add-detail-img" v-for="(image, index) in detailImageList" :key="`image${index}`" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer align-items-center">
              <div v-if="id === 'new'" class="footer-btn-wrapper">
                <button type="submit" class="btn btn-lg btn-primary" @click="submit">저장</button>
                <button type="submit" class="btn btn-lg btn-default" @click="back">취소</button>
              </div>
              <div v-else class="footer-btn-wrapper">
                <button type="submit" class="btn btn-lg btn-primary" @click="submit">수정</button>
                <button type="submit" class="btn btn-lg btn-default" @click="back">취소</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, ref, onMounted, onUpdated,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '@/store';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';
import commerceUtil from '@/helper/commerceUtil';

export default {
  name: 'PartnersMallsubscriptionRegister',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const store = useStore(key);
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const categoryList = ref(null);
    const thumbnailFile = ref(null);
    const thumbnailImg = ref(null);
    const uploadThumbnailImage = ref(false);
    const showThumbnailImage = ref(false);
    const thumbnailImageInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    const detailFile = ref(null);
    const uploadDetailImage = ref(false);
    const detailImg = ref(null);
    const detailImageList = ref([]);
    const tierList = ref([]);
    const subscription = ref({
      data_id: '',
      title: '',
      desc: '',
      product_name: '',
      is_public: true,
      publish_date: '',
      event_start_at: '',
      event_end_at: '',
      payment_closing_term: '12:00:00',
      normal_price: 0,
      event_price: 0,
      subscribe_algorithm: 'RANDOM',
      subscribe_count: 5,
      thumbnail_url: '',
      remark: '비고내용',
      progress_status: 'READY',
      brand: '',
      brand_address: '',
      brand_address_detail: '',
      brand_contact: '',
      is_subscribe_close: false,
      purchasable_tier: {
        tier_id: '6373271ab89d39de72d31f48',
      },
      images: [],
      is_with_option: false,
      option_meta: null,
    });
    // 테스트용
    // const subscription = ref({
    //   data_id: '',
    //   title: '청약 무옵션 테스트',
    //   desc: '청약 옵션 테스트',
    //   product_name: '청약 제품',
    //   is_public: true,
    //   publish_date: '',
    //   event_start_at: '',
    //   event_end_at: '',
    //   payment_closing_term: '12:00:00',
    //   normal_price: 10000,
    //   event_price: 1000,
    //   subscribe_algorithm: 'RANDOM',
    //   subscribe_count: 3,
    //   thumbnail_url: '',
    //   remark: '비고내용',
    //   progress_status: 'READY',
    //   brand: '애쓰지마',
    //   spec: '적당한 크기',
    //   is_subscribe_close: false,
    //   purchasable_tier: {
    //     tier_id: '6373271ab89d39de72d31f48',
    //   },
    //   images: [],
    //   is_with_option: false

    //   option_meta: null,
    //   options: [],
    // });
    const optionResult = ref({
      option_meta: {
        option_1: {
          key: '',
          value: '',
        },
        option_2: null,
        option_3: null,
      },
      option_result: [],
    });
    onMounted(async () => {
      const tierData = await getTier();
      tierList.value = tierData.reverse();
      if (id !== 'new') {
        store.commit('loading/showLoading', '정보를 불러오는 중입니다.');
        const subscriptionData = await getSubscription(id);
        console.log(subscriptionData);
        store.commit('loading/hideLoading');
        showThumbnailImage.value = true;
        subscription.value = subscriptionData;
        initOption();
      }
      loadDaumAddress();
    });
    const getSubscription = async (id) => {
      const response = await apiService.get(`/v3/usin-subscription-event/${id}`);
      return response.data.item;
    };
    const getTier = async () => {
      const response = await apiService.get('/reward-tier?useYn=Y');
      return response.data.items;
    };
    const submit = async () => {
      if (!checkValidate()) {
        return false;
      }
      if (id === 'new') {
        swal.createConfirm(create);
      } else {
        swal.updateConfirm(update);
      }
      return true;
    };
    const create = async () => {
      store.commit('loading/showLoading', '저장중입니다.');
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (uploadDetailImage.value) {
        await uploadDetail();
      }
      if (subscription.value.is_with_option === true) {
        subscription.value.option_meta = optionResult.value.option_meta;
        subscription.value.options = optionResult.value.option_result;
      } else {
        subscription.value.option_meta = null;
        subscription.value.options = [];
      }
      const response = await apiService.post('/v3/usin-subscription-event/', subscription.value);
      store.commit('loading/hideLoading');
      if (response.data.statusCode === 201 || response.data.statusCode === 200) {
        swal.successConfirm(() => {
          router.replace('/usin/subscription');
        });
      } else {
        console.error(response);
      }
    };
    const update = async () => {
      store.commit('loading/showLoading', '저장중입니다.');
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      if (uploadDetailImage.value) {
        await uploadDetail();
      }
      if (subscription.value.progress_status === 'READY') {
        if (subscription.value.is_with_option === true) {
          subscription.value.option_meta = optionResult.value.option_meta;
          subscription.value.options = optionResult.value.option_result;
        } else {
          subscription.value.option_meta = null;
          subscription.value.options = [];
        }
      }
      const response = await apiService.put(`/v3/usin-subscription-event/${id}`, subscription.value);
      store.commit('loading/hideLoading');
      if (response.data.statusCode === 201 || response.data.statusCode === 200) {
        swal.successConfirm(() => {
          router.push('/usin/subscription');
        });
      } else {
        console.error(response);
      }
    };
    const checkValidate = () => {
      if (subscription.value.title.trim() === '') {
        swal.alert('이벤트 제목이 입력되지 않았습니다.');
        return false;
      }
      if (subscription.value.product_name === '') {
        swal.alert('상품명이 입력되지 않았습니다.');
        return false;
      }
      // if (subscription.value.normal_price === 0) {
      //   swal.alert('일반 판매가가 입력되지 않았습니다.');
      //   return false;
      // }
      // if (subscription.value.event_price === 0) {
      //   swal.alert('어신 이벤트 판매가가 입력되지 않았습니다.');
      //   return false;
      // }
      if (subscription.value.event_start_at.trim() === '') {
        swal.alert('이벤트 시작일시가 입력되지 않았습니다.');
        return false;
      }
      if (subscription.value.event_end_at.trim() === '') {
        swal.alert('이벤트 종료일시가 입력되지 않았습니다.');
        return false;
      }
      if (subscription.value.payment_closing_term.trim() === '') {
        swal.alert('추첨 후 결제 마감시간이 입력되지 않았습니다.');
        return false;
      }
      if (subscription.value.is_with_option && optionResult.value.option_result.length === 0) {
        swal.alert('옵션목록이 등록되지 않았습니다.');
        return false;
      }
      if (subscription.value.brand_address.trim() === '') {
        swal.alert('반품/교환 주소가 입력되지 않았습니다.');
        return false;
      }
      if (subscription.value.brand_contact.trim() === '') {
        swal.alert('판매제품 문의처가 입력되지 않았습니다.');
        return false;
      }
      // if (subscription.value.thumbnail_url === '') {
      //   swal.alert('썸네일 이미지가 선택되지 않았습니다.');
      //   return false;
      // }
      // if (subscription.value.subscription_images.length === 0) {
      //   swal.alert('상품상세 이미지가 선택되지 않았습니다.');
      //   return false;
      // }
      subscription.value.subscribe_count = Number(subscription.value.subscribe_count);
      subscription.value.normal_price = Number(subscription.value.normal_price);
      subscription.value.event_price = Number(subscription.value.event_price);
      return true;
    };
    const back = () => {
      router.back();
    };
    const uploadThumbnailFile = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        thumbnailImg.value.src = e?.target?.result;
        showThumbnailImage.value = true;
      };

      reader.readAsDataURL(file);
      uploadThumbnailImage.value = true;

      if (file) {
        thumbnailImageInfo.value.extension = file.name.split('.').reverse()[0];
        thumbnailImageInfo.value.imageName = file.name;
        thumbnailImageInfo.value.imageFile = file;
      }
    };
    const openThumbnailImage = () => {
      thumbnailFile.value.click();
    };
    const openDetailImage = () => {
      detailFile.value.click();
    };
    const uploadDetailFile = (event) => {
      const { files } = event.target;
      subscription.value.images = [];
      uploadDetailImage.value = true;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          detailImageList.value.push({
            extension: file.name.split('.').reverse()[0],
            imageName: file.name,
            imageFile: file,
          });
        }
      }
    };
    onUpdated(() => {
      setDetailImage();
    });
    const setDetailImage = () => {
      detailImageList.value.forEach((img, index) => {
        const reader = new FileReader();
        const detailImgs = document.querySelectorAll('.add-detail-img');
        reader.onload = (e) => {
          detailImgs[index].src = e.target.result;
        };
        reader.readAsDataURL(img.imageFile);
      });
    };
    const getPresignedUrl = async (fileName) => {
      const response = await apiService.get(
        `/external/aws/s3/presignedUrl?serviceDomainType=usinSubscription&filePath=subscription&fileNm=${fileName}`,
      );
      return response.data.item.pre_signed_url;
    };
    const uploadImage = async (url, file) => await apiService.uploadImage(url, file);
    const uploadThumbnail = async () => {
      const imageName = `${commerceUtil.getUuid()}.${thumbnailImageInfo.value.extension}`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(presignedUrl, thumbnailImageInfo.value.imageFile);
      const imageServer = process.env.VUE_APP_MODE === 'production'
        ? 'https://cdn.uhshin.com/usinSubscription/subscription/'
        : 'https://cdn.uhshin.com/development/usinSubscription/subscription/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/subscription/${subscription.value.seller_data_cd}/`;
      subscription.value.thumbnail_url = `${imageServer}${imageName}`;
    };
    const uploadDetail = async () => {
      const imageServer = process.env.VUE_APP_MODE === 'production'
        ? 'https://cdn.uhshin.com/usinSubscription/subscription/'
        : 'https://cdn.uhshin.com/development/usinSubscription/subscription/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/subscription/${subscription.value.seller_data_cd}/`;
      for (const image of detailImageList.value) {
        const imageName = `${commerceUtil.getUuid()}.${image.extension}`;
        const presignedUrl = await getPresignedUrl(imageName);
        await uploadImage(presignedUrl, image.imageFile);
        subscription.value.images.push({
          image_url: `${imageServer}${imageName}`,
        });
      }
    };
    const addOption = () => {
      if (!optionResult.value.option_meta.option_2) {
        optionResult.value.option_meta.option_2 = {
          key: '',
          value: '',
        };
      } else if (!optionResult.value.option_meta.option_3) {
        optionResult.value.option_meta.option_3 = {
          key: '',
          value: '',
        };
      }
    };
    const removeOption = (option_name) => {
      if (option_name === 'option_3') {
        optionResult.value.option_meta.option_3 = null;
      } else if (option_name === 'option_2') {
        if (optionResult.value.option_meta.option_3 === null) {
          optionResult.value.option_meta.option_2 = null;
        } else {
          optionResult.value.option_meta.option_2 = optionResult.value.option_meta.option_3;
          optionResult.value.option_meta.option_3 = null;
        }
      }
    };
    const initOption = () => {
      if (subscription.value.option_meta) {
        optionResult.value.option_meta = JSON.parse(JSON.stringify(subscription.value.option_meta));
      } else {
        optionResult.value.option_meta = {
          option_1: { key: '', value: '' },
          option_2: null,
          option_3: null,
        };
      }
      if (subscription.value.options) {
        optionResult.value.option_result = JSON.parse(JSON.stringify(subscription.value.options));
      } else {
        optionResult.value.option_result = [];
      }
    };
    const setOption = () => {
      if (optionResult.value.option_meta.option_1 && (optionResult.value.option_meta.option_1.key === '' || optionResult.value.option_meta.option_1.value === '')) {
        swal.alert('옵션명이나 옵션정보값이 입력되지 않았습니다.');
        return false;
      }
      if (optionResult.value.option_meta.option_2 && (optionResult.value.option_meta.option_2.key === '' || optionResult.value.option_meta.option_2.value === '')) {
        swal.alert('옵션명이나 옵션정보값이 입력되지 않았습니다.');
        return false;
      }
      if (optionResult.value.option_meta.option_3 && (optionResult.value.option_meta.option_3.key === '' || optionResult.value.option_meta.option_3.value === '')) {
        swal.alert('옵션명이나 옵션정보값이 입력되지 않았습니다.');
        return false;
      }
      // optionResult.value.option_meta = {
      //   option_1: { key: optionResult.value.option_meta.option_1.key },
      //   option_2: { key: optionResult.value.option_meta.option_2?.key },
      //   option_3: { key: optionResult.value.option_meta.option_3?.key },
      // };
      optionResult.value.option_result = [];
      const option1List = optionResult.value.option_meta.option_1.value.split(',') ?? [];
      const option2List = optionResult.value.option_meta.option_2?.value.split(',') ?? [];
      const option3List = optionResult.value.option_meta.option_3?.value.split(',') ?? [];
      option1List.forEach((option1) => {
        if (option2List.length) {
          option2List.forEach((option2) => {
            if (option3List.length) {
              option3List.forEach((option3) => {
                optionResult.value.option_result.push(getOptionItem(option1, option2, option3));
              });
            } else {
              optionResult.value.option_result.push(getOptionItem(option1, option2, null));
            }
          });
        } else {
          optionResult.value.option_result.push(getOptionItem(option1, null, null));
        }
      });
    };
    const getOptionItem = (option1, option2, option3) => ({
      option_1: option1,
      option_2: option2,
      option_3: option3,
      is_public: true,
    });
    const toggleOption = async (option) => {
      if (subscription.value.progress_status !== 'READY') {
        const dataId = option.data_id;
        const isPublic = option.is_public;
        const response = await apiService.patch(`/v1/usin-subscription-event/option/${dataId}/is-public`, {
          event_data_id: id,
          is_public: isPublic,
        });
      }
    };
    const loadDaumAddress = () => {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      document.getElementsByTagName('body')[0].appendChild(scriptTag);
    };
    const openSearchAddress = () => {
      new daum.Postcode({
        oncomplete: (data) => {
          subscription.value.brand_address = data.address;
        },
      }).open();
    };
    return {
      id,
      isDetail,
      subscription,
      thumbnailFile,
      thumbnailImg,
      showThumbnailImage,
      detailFile,
      detailImg,
      detailImageList,
      submit,
      back,
      uploadThumbnailFile,
      openThumbnailImage,
      uploadDetailFile,
      openDetailImage,
      tierList,
      addOption,
      removeOption,
      setOption,
      optionResult,
      toggleOption,
      openSearchAddress,
    };
  },
};
</script>

<style scoped>
.thumbnail-img {
  width: 300px;
  height: 300px;
}
.detail-img {
  width: 200px;
}
.add-detail-img {
  width: 200px;
}
</style>
