/**
 * Export data to csv file
 * @author Luong
 * @date Thu 24 Jun, 2021
 */
export default {
	/**
	 * Convert data to csv string
	 * @param objArray 
	 * @returns 
	 */
	convertToCSV(objArray: string): string {
		const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
	
		for (let i = 0; i < array.length; i++) {
			let line = '';
			for (const index in array[i]) {
				if (line !== '') line += ','
	
				line += array[i][index];
			}
	
			str += line + '\r\n';
		}
	
		return str;
	},

	/**
	 * Export to csv file
	 * @param headers 
	 * @param items 
	 * @param fileTitle 
	 */
	exportCSVFile(headers: any, items: any[], fileTitle: string): any {
		if (headers) {
			items.unshift(headers);
		}
	
		// Convert Object to JSON
		const jsonObject = JSON.stringify(items);
	
		const csv = this.convertToCSV(jsonObject);
	
		const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
	
		const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' }); // "\uFEFF" is for Korean text
		if (navigator.msSaveBlob) { // IE 10+
			navigator.msSaveBlob(blob, exportedFilenmae);
		} else {
			const link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				const url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", exportedFilenmae);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}
}