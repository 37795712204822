import CryptoJS from 'crypto-js'

const authentication = function() {
	//
}

authentication.generatePayload = (req: any, dateTime: any) => {
	const method = req.method.toUpperCase()
	const contentType = req.contentType || ''
	const resource = req.url
	const stringToSign = `${method}\n${contentType}\n${resource}\n${dateTime}`
	const secretKeyBytes = CryptoJS.enc.Base64.parse(process.env.VUE_APP_KEY);
	const payloadBytes = CryptoJS.enc.Utf8.parse(stringToSign);
	const base64Mac = generateHMAC(secretKeyBytes, payloadBytes)

	return 'UAW ' + base64Mac
}

function generateHMAC(key: any, payload: any) {
	// 암호화 객체 생성, sha256 알고리즘 선택
	const hmac = CryptoJS.HmacSHA256(payload, key)
	const signatureBase64String = CryptoJS.enc.Base64.stringify(hmac);
	return signatureBase64String
}

export default authentication
