<template>
  <section class="admin-main-content">
    <div class="admin-content-header">
      <h2>
        {{ sellerName }} 발주 내역
      </h2>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
              <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
              <div class="input-group-append">
                <button type="submit" class="btn btn-default">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>셀러명</th>
              <th>발주일</th>
              <th>발주 수량</th>
<!--              <th>물류사</th>-->
<!--              <th>물류사코드</th>-->
<!--              <th>송장번호</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(placingOrder, index) in placingOrderList" :key="`${placingOrder.order_data_id}${index}`" @click="viewDetail(placingOrder.transaction_id)">
              <td>{{ placingOrder.seller_name }}</td>
              <td>{{ placingOrder.placing_order_req_date }}</td>
              <td>{{ getCurruncy(placingOrder.count) }}</td>
            </tr>
            </tbody>
          </table>
          <nav class="pagination-wrapper">
            <ul class="pagination justify-content-center">
              <li class="page-item" >
                <a class="page-link" href="#" tabindex="-1">
                  <span aria-hidden="true">이전</span>
                </a>
              </li>
              <li class="page-item" v-for="index in totalPage" :key="index" @click="loadPage(index - 1)">
                <a class="page-link" href="#">{{ index }}</a>
                <!--                <router-view class="page-link" :to="/commerce/product" >{{ index }}</router-view>-->
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  <span aria-hidden="true">다음</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </section>
</template>

<script>
import {
  defineComponent, ref, onMounted, computed,
} from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import commerceUtil from '@/helper/commerceUtil';
import dateTimeHelper from '@/helper/datetime.helper';

export default {
  name: 'PartnersFishingResultList',
  components: {
  },
  setup: () => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.5/xlsx.full.min.js',
    );
    document.head.appendChild(recaptchaScript);
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const sellerName = ref('');
    const sellerCode = ref('');
    const placingOrderList = ref([
      { seller_name: '' },
    ]);
    const { page = 0 } = route.query;
    const pageSize = 20;
    const total = ref(0);
    const totalPage = ref(0);
    const currentPage = ref(page);
    onMounted(async () => {
      placingOrderList.value = await getPlacingOrder();

      console.log(placingOrderList);
      if (placingOrderList.value.length > 0) {
        sellerName.value = placingOrderList.value[0].seller_name;
        sellerCode.value = placingOrderList.value[0].seller_data_cd;
      }
    });
    const getPlacingOrder = async () => {
      const response = await apiService.get(`/partners-mall/placing-order/after/list/${id}?$page=${currentPage.value}&size=${pageSize}`);
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize);
      return response.data.items;
    };

    const getCurruncy = (price) => commerceUtil.getCurrency(price);
    const viewDetail = (transactionId) => {
      router.push(`/commerce/placing-order-after/detail-list/${sellerCode.value}/${transactionId}`);
    };
    const loadPage = (page) => {
      router.push(`/commerce/placing-order-after/list/${id}?page=${page}`);
    };
    return {
      sellerName,
      currentPage,
      pageSize,
      totalPage,
      placingOrderList,
      loadPage,
      getCurruncy,
      viewDetail,
    };
  },
};
</script>

<style scoped>

</style>
