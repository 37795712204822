
import jwt_decode from 'jwt-decode';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Splash from '../components/Splash.vue';
import SubmitButton from '../components/ui/SubmitButton.vue';
import cookieHelper from '../helper/cookie.helper';
import { default as AskCategoryModel } from '../models/askCategories.model';
import { ApiService } from '../services/backend-api/api.services';
import { key } from '../store';

export default defineComponent({
  components: {
    Splash,
    SubmitButton,
  },
  computed: {
    loaded() {
      const store = useStore(key);
      return store.state.adminAccounts.loadingLogin;
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore(key);
    const isSubmitting = ref(false);
    const errorMessage = ref('');
    const email = ref('');
    const password = ref('');
    const apiService = new ApiService();

    async function login() {
      errorMessage.value = '';
      isSubmitting.value = true;
      const loginResult = await apiService.authenticate({ email: email.value, password: password.value });
      if (loginResult.status) {
        cookieHelper.createCookie('refresh_token', loginResult.data.refresh_token, parseInt(loginResult.data.refresh_token_life));
        cookieHelper.createCookie('access_token', loginResult.data.access_token, 86400);
        cookieHelper.createCookie('permissions', loginResult.data.permissions, 86400);
        store.dispatch('adminAccounts/setToken', loginResult.data.access_token);
        store.dispatch('adminAccounts/setPermissions', loginResult.data.permissions);
        isSubmitting.value = false;

        const { items } = await AskCategoryModel.getAll([], [], 1, 100);
        const decodeAccessToken: any = jwt_decode(loginResult.data.access_token);
        const isUsin = items.some((askCategory: any) => decodeAccessToken?.name === askCategory.name);
        if (isUsin) {
          cookieHelper.createCookie('usin_name', decodeAccessToken.name, 86400);
          router.push('/ask');
        } else {
          cookieHelper.createCookie('usin_name', '', -1);
          router.push('/');
        }
      } else {
        errorMessage.value = 'Username or password is not correct';
        isSubmitting.value = false;
      }
    }

    return {
      login,
      isSubmitting,
      errorMessage,
      email,
      password,
    };
  },
});
