
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { CPopover, CButton } from '@coreui/vue';
import FishingGramsModel, {
  FishingGrams,
} from '../../models/fishingGrams.model';
import BlindModel from '../../models/blind.model';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import Pagination from '../../components/ui/Pagination.vue';
import { constants } from '../../config/constant.config';
import swal from '../../helper/swal.helper';
import { ApiService } from '../../services/backend-api/api.services';

export default defineComponent({
  name: 'FishingGramsList',
  props: {},
  components: {
    Pagination,
    CPopover,
    CButton,
  },
  setup: () => {
    const router = useRouter();
    const apiService = new ApiService();
    const fishingGrams = ref<FishingGrams[]>([]);
    const loading = ref(false);
    const orderType = ref('desc');
    const orderBy = ref('created_at');
    const filter = ref({
      show_home: null,
      has_report_comments: null,
      uid: '',
      nick_name: '',
      tags: '',
      fishing_place_type: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;
    const fishingGramsSummary = ref({});
    const errorSummary = ref('');

    const orderOptions = ref([[orderBy.value, orderType.value]]);
    const filterConditions = ref<Array<any>>([]);
    const updateData = (items: FishingGrams[]) => {
      loading.value = false;
      fishingGrams.value = items;
    };

    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    // onMounted(async () => {
    //   const requestSummary = await apiService.fishingGramSummary();
    //   if (requestSummary.status) {
    //     fishingGramsSummary.value = requestSummary.data;
    //   } else {
    //     errorSummary.value = requestSummary.data.message;
    //   }
    // });

    const deleteAction = (id: any) => {
      swal.simpleDeleteConfirm(async () => {
        const currentDatetime = dateTimeHelper.timestampToDateTime(
          Date.now(),
          'YYYY-MM-DDTHH:mm:ss.SSS',
        );
        await FishingGramsModel.update(id, { deleted_at: currentDatetime });
        fishingGrams.value = await FishingGramsModel.getAll(
          [],
          orderOptions.value,
          1,
          25,
        );
      });
    };

    const editAction = async (id: any) => {
      router.push(`/fishing-grams/edit/${id}`);
    };

    const searchHandler = async () => {
      filterConditions.value = [];
      if (filter.value.show_home !== null) {
        filterConditions.value.push(['show_home', filter.value.show_home]);
      }
      if (filter.value.has_report_comments !== null) {
        filterConditions.value.push([
          'has_report_comments',
          filter.value.has_report_comments,
        ]);
      }
      if (filter.value.uid && filter.value.uid.length > 0) {
        filterConditions.value.push(['uid', filter.value.uid]);
      }
      if (filter.value.nick_name && filter.value.nick_name.length > 0) {
        filterConditions.value.push(['nick_name', filter.value.nick_name]);
      }
      if (filter.value.tags && filter.value.tags.length > 0) {
        filterConditions.value.push(['tags', filter.value.tags]);
      }
      if (
        filter.value.fishing_place_type &&
        filter.value.fishing_place_type.length > 0
      ) {
        filterConditions.value.push([
          'fishing_place_type',
          filter.value.fishing_place_type,
        ]);
      }
    };

    const changeShowHandler = async (id: string, e: any) => {
      await FishingGramsModel.update(id, { show_home: e.target.checked });
    };

    const blindHandler = async (
      id: string,
      e: { target: { checked: boolean } },
    ) => {
      const data = {
        target_id: id,
        parent_id: '',
        type: 'fishinggram',
        reason: 'Remove from admin',
        blind_date: Date.now(),
      };

      if (e.target.checked) {
        const addResult = await BlindModel.create(data);
        if (!addResult.status) {
          e.target.checked = !e.target.checked;
          swal.alert(addResult.message);
        }
      } else {
        const changeResult = await apiService.deleteBlindData(data);
        if (!changeResult.status) {
          e.target.checked = !e.target.checked;
          swal.alert(changeResult.message);
        }
      }
    };

    const sortHandler = async (field: string) => {
      switch (orderType.value) {
        case 'asc':
          orderType.value = 'desc';
          break;
        case 'desc':
          orderType.value = 'asc';
          break;
        default:
      }
      orderOptions.value = [[field, orderType.value]];
      orderBy.value = field;
    };

    const viewDetail = async (id: any) => {
      await router.push(`/fishing-grams/detail/${id}`);
    };

    return {
      fishingGrams,
      deleteAction,
      editAction,
      dateTimeHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      FishingGramsModel,
      searchHandler,
      numberHelper,
      changeShowHandler,
      sortHandler,
      orderType,
      viewDetail,
      fishingGramsSummary,
      errorSummary,
      orderBy,
      blindHandler,
    };
  },
});
