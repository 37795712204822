import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "c-wrapper c-fixed-components" }
const _hoisted_2 = { class: "c-body" }
const _hoisted_3 = { class: "c-main" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "fade-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")
  const _component_Header = _resolveComponent("Header")
  const _component_Footer = _resolveComponent("Footer")
  const _component_Splash = _resolveComponent("Splash")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Sidebar),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createVNode("div", _hoisted_2, [
        _createVNode("main", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ]),
        _createVNode(_component_Footer)
      ])
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_Splash, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}