<template>
  <section class="admin-main-content">
    <div class="admin-content-header">
      <h2>
        {{ sellerName }} 발주 내역
      </h2>
      <div>
      <xlsx-workbook class="download-btn">
        <xlsx-sheet
            v-for="sheet in sheets"
            :collection="sheet.data"
            :key="sheet.name"
            :sheet-name="sheet.name"
        />
        <button class="btn btn-primary" @click="download">다운로드</button>
        <xlsx-download :filename="sheetName">
          <button ref="downloadBtn" class="btn btn-primary" style="display: none;">발주서 다운로드</button>
        </xlsx-download>
      </xlsx-workbook>
      <input ref="fileBtn" style="display: none;" type="file" @change="fileUploaded" />
      <button class="btn btn-primary mr-3" @click="upload">업로드</button>
      <button class="btn btn-primary" @click="updateDelivery">저장</button>
      <xlsx-read :file="file" >
<!--        <xlsx-table />-->
        <xlsx-json @parsed="onParsed">
<!--          <template #default="{collection}">-->
<!--            <div>-->
<!--              {{ collection }}-->
<!--            </div>-->
<!--          </template>-->
        </xlsx-json>
      </xlsx-read>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
              <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
              <div class="input-group-append">
                <button type="submit" class="btn btn-default">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>인덱스</th>
              <th>상품명</th>
              <th>종류/규격</th>
              <th>수량</th>
              <th>정산금액</th>
              <th>수령인명</th>
              <th>택배물류사</th>
              <th>송장번호</th>
<!--              <th>물류사</th>-->
<!--              <th>물류사코드</th>-->
<!--              <th>송장번호</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(placingOrder, index) in placingOrderList" :key="`${placingOrder.order_data_id}${index}`" @click="viewDetail(placingOrder.id)">
              <td>{{ index + 1 }}</td>
              <td>{{ placingOrder.product_name }}</td>
              <td>{{ placingOrder.product_kind_desc }}</td>
              <td>1</td>
              <td>{{ getCurruncy(placingOrder.price_usin) }}</td>
              <td>{{ placingOrder.delivery_name }}</td>
              <td :class="{'text-danger' : updateMode}">{{ placingOrder.delivery_company_cd }}</td>
              <td :class="{'text-danger' : updateMode}">{{ placingOrder.invoice_number }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </section>
</template>

<script>
import {
  defineComponent, ref, onMounted, computed,
} from 'vue';

import { useRoute, useRouter } from 'vue-router';
import {
  XlsxRead, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload,
} from 'vue3-xlsx';
import { ApiService } from '@/services/backend-api/api.services';
import commerceUtil from '@/helper/commerceUtil';
import dateTimeHelper from '@/helper/datetime.helper';
import swal from '@/helper/swal.helper';

export default {
  name: 'PartnersFishingResultList',
  components: {
    XlsxRead,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  setup: () => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.5/xlsx.full.min.js',
    );
    document.head.appendChild(recaptchaScript);
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id, sellerCode } = route.params;
    const orderDeliveries = [];
    const sellerName = ref('');
    const updateMode = ref(false);
    const placingOrderList = ref([
      { seller_name: '' },
    ]);
    const placingOrderAfter = ref([]);
    const fileBtn = ref(null);
    const file = ref(null);
    // 엑셀 다운로드
    const sheet = ref([]);
    const sheets = ref([]);
    const sheetName = ref('test.xlsx');
    const downloadBtn = ref(null);
    // const sellingDate = ref('');
    onMounted(async () => {
      placingOrderList.value = await getPlacingOrder();
      if (placingOrderList.value.length > 0) {
        sellerName.value = placingOrderList.value[0].seller_name;
        console.log(sellerName.value);
        if (!placingOrderList.value[0].delivery_company_cd) {
          updateMode.value = true;
        }
        // sellingDate.value = placingOrderList.value[0].placing_order_res_at.split(' ')[0];
      }
      setSheet(placingOrderList.value);
      // sheets.value.push({
      //   name: 'asef',
      //   data: placingOrderList.value,
      // });
      console.log('list');

      console.log(placingOrderList.value);
      console.log(dateTimeHelper.timestampToDateTime(new Date()));
    });
    const getPlacingOrder = async () => {
      const response = await apiService.get(`/partners-mall/placing-order/after/list/${sellerCode}/${id}`);
      console.log(response);
      return response.data.items;
    };
    const data = [];
    // const upload = async () => {
    //   let text = await navigator.clipboard.readText();
    //   console.log(text);
    //   text = text.split('\"').join('');
    //   console.log(text);
    //   const data = text.split('\n');
    //
    //   console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     const lawdata = data[i].split('\t');
    //     contractList.value[i].mul_code = lawdata[0];
    //     contractList.value[i].mul = lawdata[1];
    //     contractList.value[i].data = lawdata[2];
    //   }
    // };
    const getCurruncy = (price) => commerceUtil.getCurrency(price);
    const fileUploaded = (event) => {
      file.value = event.target.files ? event.target.files[0] : null;
    };
    const upload = () => {
      fileBtn.value.click();
    };
    const loading = ref(false);
    const onParsed = (data) => {
      setDeliveryData(data);
    };
    const setDeliveryData = (data) => {
      console.log(data);
      const placingOrderDeleiveryList = [];
      placingOrderList.value.forEach((order) => {
        console.log('order', order);
        const test = data.find((deliveryData) => order.data_cd === deliveryData['발주번호']);
        console.log('test', test);
        console.log(test['택배물류사']);
        console.log(test['송장번호']);
        order.delivery_company_cd = test['택배물류사'];
        order.invoice_number = `${test['송장번호']}`;
        placingOrderDeleiveryList.push(order);
        orderDeliveries.push({
          delivery_company_cd: order.delivery_company_cd,
          invoice_number: order.invoice_number,
          data_cd: order.data_cd,
        });
      });
      placingOrderList.value = placingOrderDeleiveryList;
      console.log(placingOrderList.value);
    };
    const updateDelivery = async () => {
      const response = await apiService.post(`/partners-mall/order-delivery/list/${sellerCode}`, {
        order_deliveries: orderDeliveries,
      });
      swal.successConfirm(async () => {
        placingOrderList.value = await getPlacingOrder();
      }, '배송정보 업데이트가 완료되었습니다.');
      console.log(response);
    };
    const setSheet = (placingOrderAfterList) => {
      console.log('setSheet', placingOrderAfterList);
      placingOrderAfterList.forEach((order) => {
        const placingOrder = {
          주문번호: order.order_data_id,
          발주번호: order.data_cd,
          상품명: order.product_name,
          규격: order.product_kind_desc,
          상품코드: order.manufacturing_cd,
          주문수량: 1,
          정산금액: order.price_usin,
          수령인명: order.delivery_name,
          수령인연락처: order.delivery_contact,
          우편번호: order.delivery_post_code,
          주소: order.delivery_address,
          배송요청일: order.delivery_req_at,
          택배물류사: order.delivery_company_cd ?? '',
          송장번호: order.invoice_number ?? '',
        };
        sheet.value.push(placingOrder);
      });
      sheets.value.push({
        name: '시트1',
        data: sheet.value,
      });
      const date = dateTimeHelper.timestampToDateTime(new Date());
      sheetName.value = `${sellerName.value}-${date.split(' ')[0]}.xlsx`;
      console.log(sheetName.value);
    };
    const download = async () => {
      swal.dowloadXlsx(downloadXlsx, '발주목록을 다운로드합니다.');
    };
    const downloadXlsx = () => {
      downloadBtn.value.click();
    };
    return {
      sellerName,
      // sellingDate,
      placingOrderList,
      getCurruncy,
      fileUploaded,
      upload,
      file,
      onParsed,
      updateDelivery,
      fileBtn,
      updateMode,
      sheets,
      sheetName,
      download,
      downloadBtn,
    };
  },
};
</script>

<style scoped>
.download-btn {
  display: inline-block;
  margin-right: 1rem;
}
</style>
