import firebase from 'firebase';
import { ApiService } from '../services/backend-api/api.services';
import { BaseV2Model } from './base-v2.model';
import Timestamp = firebase.firestore.Timestamp;

export interface FishingGramsBestCandidates {
	id: string;
	images: Array<string>;
	show_home: boolean;
	fishing_place_type: string;
	created_at: number;
	updated_at: number;
	ban: boolean;
	fix_home: boolean;
}

class FishingGramsBestCandidatesModel extends BaseV2Model {
	protected collectionName = 'fishing-grams-best-candidates'

	protected keepCollectionName = true;

	private static _instance: FishingGramsBestCandidatesModel;

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}

	public async findAllBest() {
	  const apiService = new ApiService();
	  return await apiService.findAllFishinggamBest(this.collectionName, true);
	}
}

export default FishingGramsBestCandidatesModel.Instance;
