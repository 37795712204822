import { AppPushRepository } from '@/repository/appPush/usin-subscription-event.repository';
import { PostRepository } from '@/repository/post/post.repository';
import swal from '../../helper/swal.helper';

export class AppPushService {
  private appPushRepository: AppPushRepository = new AppPushRepository();

  private postRepository: PostRepository = new PostRepository();

  async getSubscriptionList(): Promise<{ [key: string]: string }> {
    const params = {
      page: 0,
      size: 30,
      progress_status: 'PROGRESS',
      progress_status_nm: '진행중',
    };

    const response = await this.appPushRepository.fetchSubscriptionList(params);

    const landingUrlOption: { [key: string]: string } = {};

    const { items }: { items: { items: { title: string; data_id: string }[] } } = response as unknown as { items: { items: { title: string; data_id: string }[] } };

    const itemsArray = items.items;

    if (!response || !response.items || !Array.isArray(itemsArray)) {
      swal.alert('Invalid response structure');
      return {};
    }

    itemsArray.forEach(({ title, data_id }) => {
      landingUrlOption[title] = `usin://event-mall?dataId=${data_id}`;
    });

    return landingUrlOption;
  }

  async getPostUrlList(): Promise<{ [key: string]: string }> {
    const params = {
      page: 1,
      pageSize: 25,
      show_home: true,
    };

    const response = await this.postRepository.fetchPostList(params);

    const landingUrlOption: { [key: string]: string } = {};

    const { items }: { items: { title: string; id: string }[] } = response as unknown as { items: { title: string; id: string }[] };

    if (!response || !Array.isArray(items)) {
      console.error(items);
      swal.alert('Invalid response structure');
      return {};
    }

    items.forEach(({ title, id }) => {
      landingUrlOption[title] = `usin://featured-post?pid=${id}`;
    });

    return landingUrlOption;
  }
}
