
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { default as AdminRolesModel } from '../../models/adminRoles.model';
import { default as PermissionsModel } from '../../models/permissions.model';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    let errors = ref<string[]>([]);
    let isSubmitting = ref(false);
    let allRoutes = ref(router.getRoutes());
    let myRoute = router.getRoutes();
    let role_code = ref('');
    let role_name = ref('');
    let permissions = ref([]);
    let status = ref(1);

    let groupRoutes = myRoute.reduce(function(r: any, a: any) {
      r[a.meta.group] = r[a.meta.group] || [];
      r[a.meta.group].push(a);
      return r;
    }, Object.create(null));

    async function onSubmitform() {
      isSubmitting.value = true;
      errors.value = [];

      // Role code validation
      let validCode = /^[a-zA-Z]+$/.test(role_code.value);
      if (!validCode) {
        errors.value.push('Role code must be a string of alphabet characters');
        swal.alert('Role code must be a string of alphabet characters');
      }

      let savePermissions = [];
      if (permissions.value.length > 0) {
        for (let key in permissions.value) {
          let value: string = permissions.value[key];
          let arrSplit = value.split('@');
          let thisPermission = {
            client_route: arrSplit[0],
            server_route: arrSplit[1],
            name: arrSplit[2],
            server_route_method: '',
          };

          let checkExist = await PermissionsModel.getAll([
            ['client_route', thisPermission.client_route],
          ]);
          let thisPermissionId = '';
          if (!checkExist[0]) {
            // if does not exist --> create a new permission
            let addResult = await PermissionsModel.create(thisPermission);
            if (addResult.data.id) {
              thisPermissionId = addResult.data.id;
            }
          } else {
            thisPermissionId = checkExist[0].id;
          }

          if (thisPermissionId.length > 0) {
            savePermissions.push(thisPermissionId);
          }
        }
      }

      let data = {
        role_code: role_code.value,
        role_name: role_name.value,
        permission: savePermissions,
        status: status.value,
      };
      if (!errors.value.length) {
        let newRole = await AdminRolesModel.create(data);
        if (newRole.status) {
          router.push('/admin-roles');
        } else {
          errors.value.push(newRole.message);
          swal.alert(newRole.message);
        }
        isSubmitting.value = false;
      } else {
        isSubmitting.value = false;
      }
    }

    return {
      onSubmitform,
      isSubmitting,
      allRoutes,
      role_code,
      role_name,
      permissions,
      status,
      groupRoutes,
    };
  },
});
