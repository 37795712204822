<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card" :class="{ loading: loading }">
        <div class="card-body">
          <button
            class="btn btn-primary align-bottom mb-3"
            type="button"
            @click="addRecord()"
          >
            청약등록
          </button>

          <div class="table-responsive">
            <table
              class="table table-responsive-sm table-bordered table-striped table-sm"
            >
              <thead>
                <tr>
                  <th>대표 이미지</th>
                  <th>브랜드</th>
                  <th>구매가능티어</th>
                  <th>제목</th>
                  <th>노출여부</th>
                  <th>진행상태</th>
                  <th>추첨방식</th>
                  <th>추첨진행여부</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="subscription in subscriptionList"
                  :key="subscription.data_id"
                >
                  <td><img :src="subscription.thumbnail_url" height="50" /></td>
                  <td>{{ subscription.brand }}</td>
                  <td>
                    {{ subscription.purchasable_tier?.code_name }}
                  </td>
                  <td>{{ subscription.title }}</td>
                  <td>
                    <span
                      v-if="subscription.is_public"
                      class="badge bg-success text-white"
                      >활성</span
                    >
                    <span v-else class="badge bg-danger text-white"
                      >비활성</span
                    >
                  </td>
                  <td>{{ subscription.progress_status_nm }}</td>
                  <td>{{ subscription.subscribe_algorithm_nm }}</td>
                  <td>{{ subscription.prize_status_nm }}</td>
                  <td>
                    <!--										<button class="btn btn-btn-warning" :disabled="loading" type="button" @click="deleteRecord(category.data_id)">-->
                    <!--											<span :class="{'text-danger': !loading}"><svg class="c-icon c-icon-lg"><use xlink:href="/assets/icons/sprites/free.svg#cil-trash"></use></svg></span>-->
                    <!--										</button>-->
                    <button
                      class="btn btn-primary mr-1"
                      :disabled="loading"
                      type="button"
                      @click="viewDetail(subscription.data_id)"
                    >
                      상세
                    </button>
                    <button
                      class="btn btn-warning"
                      :disabled="loading"
                      type="button"
                      @click="editRecord(subscription.data_id)"
                    >
                      수정
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '../../../helper/swal.helper';

export default defineComponent({
  name: 'PartnerssubscriptionList',
  props: {},
  components: {},
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const subscriptionList = ref([]);
    const loading = ref(false);
    const orderOptions = ref([[]]);
    const totalPage = ref(0);
    const currentPage = ref(0);
    const pageSize = ref(200);
    const total = ref(0);

    function updateData(items) {
      loading.value = false;
      console.log(items);
      subscriptionList.value = items;
    }
    function isLoading(status) {
      loading.value = status;
    }

    const addRecord = () => {
      router.push({
        path: '/usin/subscription/add/new',
        query: route.query,
      });
    };

    async function editRecord(id) {
      router.push(`/usin/subscription/edit/${id}`);
    }

    onMounted(async () => {
      subscriptionList.value = await getSubscriptionList();
    });

    const getSubscriptionList = async () => {
      const response = await apiService.get('/v1/usin-subscription-event', {
        page: currentPage.value,
        size: pageSize.value,
        // is_public: true, // true, false,
        // subscribe_algorithm: 'RANDOM', // RANDOM, FIRST_COME
        // progress_status: 'READY', 'PROGRESS', 'END'
      });
      console.log(response);
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };
    const viewDetail = dataId => {
      router.push(`/usin/subscription/detail/${dataId}`);
    };

    return {
      subscriptionList,
      loading,
      orderOptions,
      addRecord,
      updateData,
      isLoading,
      editRecord,
      viewDetail,
    };
  },
});
</script>
<style scoped>
.red_fish {
  background-color: #ff003c;
}
.orange_fish {
  background-color: #ff8200;
}
.yellow_fish {
  background-color: #ffbe00;
}
.green_fish {
  background-color: #32b400;
}
.blue_fish {
  background-color: #0096ff;
}
.navy_fish {
  background-color: #0050d2;
}
.purple_fish {
  background-color: #aa23c8;
}
</style>
