
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  default as FishingTubesModel,
  FishingTubes,
} from '../../models/fishingTubes.model';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import dateTimeHelper from '../../helper/datetime.helper';
import Editor from '../../components/ui/Editor.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    FileUpload,
    SubmitButton,
    Editor,
  },
  setup() {
    const router = useRouter();
    let thumbnailFile = ref<any>(null);
    let customEditor = ref<any>(null);
    let loading = ref(false);
    let isSubmitting = ref(false);
    let fishingTube = ref<FishingTubes>({
      name: '',
      show: true,
      video_id: '',
      desc: '',
      thumbnail: '',
      published_at: 0,
      updated_at: 0,
      created_at: 0,
      view_count: 0,
      order: 0,
      show_view_count: false,
    });
    let publishTime = ref<string>('');
    let id = router.currentRoute.value.params.id;
    let setPreviewImg = ref('');
    onMounted(async () => {
      loading.value = true;
      fishingTube.value = await FishingTubesModel.findById(id);
      publishTime.value = dateTimeHelper.timestampMilisToDateTimeInput(
        fishingTube.value.published_at,
      );
      setPreviewImg.value = fishingTube.value.thumbnail;
      loading.value = false;
    });

    async function submitForm() {
      isSubmitting.value = true;

      let thumbnail = await thumbnailFile.value?.uploadImage();
      if (thumbnail.length <= 0) {
        thumbnail = fishingTube.value.thumbnail;
      }

      let data = {
        created_at: new Date(),
        desc: await customEditor.value?.getContents(),
        video_id: fishingTube.value.video_id,
        published_at: publishTime.value
          ? dateTimeHelper.dateToTimestamp(publishTime.value)
          : 0,
        show: fishingTube.value.show,
        thumbnail: thumbnail,
        name: fishingTube.value.name,
        order: fishingTube.value.order,
        view_count: fishingTube.value.view_count,
        show_view_count: fishingTube.value.show_view_count,
      };
      let updateResult = await FishingTubesModel.update(id, data);
      if (updateResult.status) {
        isSubmitting.value = false;
        router.push('/fishing-tubes');
      } else {
        isSubmitting.value = false;
        swal.alert(updateResult.message);
      }
    }

    return {
      fishingTube,
      thumbnailFile,
      submitForm,
      loading,
      isSubmitting,
      setPreviewImg,
      publishTime,
      customEditor,
    };
  },
});
