import {BaseModel} from "./base.model";
import firebase from "firebase";

export interface FishingKnot {
	category_id: string,
	title: string,
	desc: string,
	contents_url: string,
	show: boolean,
	thumbnail: string,
	published_at: number,
	created_at: number,
	updated_at: number,
	order: number,
}

class FishingKnotModel extends BaseModel {
	protected collectionName = 'fishing_knots'

	private static _instance: FishingKnotModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default FishingKnotModel.Instance