
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OtherButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    loadingText: {
      type: String,
      required: true,
    },
    isDoing: {
      type: Boolean,
      required: false,
      default: false,
    },
    style: {
      type: String,
      required: false,
      default: 'btn-secondary',
    },
  },
  computed: {
    // submitLoading() {
    //   const store = useStore(key);
    //   return store.state.components.submitLoading;
    // },
  },
});
