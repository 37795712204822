export default {
  createCookie(name: string, value: string, seconds: number) {
    const expires = `; max-age=${seconds}`;

    document.cookie = `${name}=${value}${expires}; path=/`;
  },
  getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';').shift();
  },
};
