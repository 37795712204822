<template>
  <section class="loading-wrapper" :class="{ 'loading-show': isLoading }">
    <img src="/assets/images/loading.gif" alt="" width="200" height="200" />
    <p class="loading-text">{{ loadingText }}</p>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';

export default {
  name: 'AdminLoading',
  setup() {
    const store = useStore(key);

    const isLoading = computed(
      () => store.state.loading.isLoading,
    );
    const loadingText = computed(
      () => store.state.loading.loadingText,
    );
    // document.body.classList.add('hidden');
    return {
      isLoading,
      loadingText,
    };
  },

  // computed: {
  //   isLoading() {
  //     console.log('computed');
  //     const store = useStore(key);
  //     return store.state.isLoading;
  //   },
  //   loadingText() {
  //     const store = useStore(key);
  //     return store.state.loadingText;
  //   },
  // },
};
</script>

<style scoped>
.loading-wrapper {
  background-color: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  display: none;
  flex-direction: column;
  padding: 15px;
  overflow: hidden;
}
.loading-text {
  font-size: 16px;
  color: #fff;
}
.loading-show {
  display: flex;
}
</style>
