import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AccessDenied from '../components/pages/403.vue';
import PageNotFound from '../components/pages/404.vue';
import AddAdminAccount from '../views/admin-account/Add.vue';
import EditAdminAccount from '../views/admin-account/Edit.vue';
import AdminAccount from '../views/admin-account/Index.vue';
import AddRole from '../views/admin-roles/Add.vue';
import EditRole from '../views/admin-roles/Edit.vue';
import AdminRoles from '../views/admin-roles/Index.vue';
import AddAppPush from '../views/app-push/Add.vue';
import EditAppPush from '../views/app-push/Edit.vue';
import AppPush from '../views/app-push/Index.vue';
import IndexComponent from '../views/Index.vue';
import Login from '../views/Login.vue';
import SeaMap from '../views/maps/SeaMap.vue';
import WaterMap from '../views/maps/WaterMap.vue';
import AddNotification from '../views/notification/Add.vue';
import EditNotification from '../views/notification/Edit.vue';
import Notification from '../views/notification/Index.vue';
import PreviewNotification from '../views/notification/Preview.vue';
import PartnersMember from '../views/partners-member/Index.vue';
import AddPostCategories from '../views/post-categories/Add.vue';
import EditPostCategories from '../views/post-categories/Edit.vue';
import PostCategories from '../views/post-categories/Index.vue';
import AddPost from '../views/post/Add.vue';
import EditPost from '../views/post/Edit.vue';
import Post from '../views/post/Index.vue';
import PreviewPost from '../views/post/Preview.vue';
import AddProPostCategories from '../views/pro-post-categories/Add.vue';
import EditProPostCategories from '../views/pro-post-categories/Edit.vue';
import ProPostCategories from '../views/pro-post-categories/Index.vue';
import AddProPost from '../views/pro-post/Add.vue';
import EditProPost from '../views/pro-post/Edit.vue';
import ProPost from '../views/pro-post/Index.vue';
import PreviewProPost from '../views/pro-post/Preview.vue';
import AddUser from '../views/user-management/Add.vue';
import EditUser from '../views/user-management/Edit.vue';
import UserManagement from '../views/user-management/Index.vue';
import UserEventsLog from '../views/user-management/UserEventsLog.vue';
// import AddPartners from '../views/partners/Add.vue';
import EditPartnersMember from '../views/partners-member/Edit.vue';
// import ImportPartners from '../views/partners/Import.vue';
import auth from '../middleware/auth';
import guest from '../middleware/guest';
import { Permission } from '../services/backend-api/permission.enum';
import AddAskCategories from '../views/ask-categories/Add.vue';
import EditAskCategories from '../views/ask-categories/Edit.vue';
import AskCategories from '../views/ask-categories/Index.vue';
import EditAsk from '../views/ask/Edit.vue';
import Ask from '../views/ask/Index.vue';
import FishingGramsBestCandidates from '../views/fishing-grams-best-condidates/Index.vue';
import FishingGramsDetail from '../views/fishing-grams/Detail.vue';
import FishingGrams from '../views/fishing-grams/Index.vue';
import AddFishingKnotCategory from '../views/fishing-knot-categories/Add.vue';
import EditFishingKnotCategory from '../views/fishing-knot-categories/Edit.vue';
import FishingKnotCategory from '../views/fishing-knot-categories/Index.vue';
import AddFishingKnot from '../views/fishing-knots/Add.vue';
import FishingKnot from '../views/fishing-knots/Index.vue';
import FishingKnotPriview from '../views/fishing-knots/Preview.vue';
import AddFishingTubes from '../views/fishing-tubes/Add.vue';
import EditFishingTubes from '../views/fishing-tubes/Edit.vue';
import FishingTubes from '../views/fishing-tubes/Index.vue';
import AddJinCategories from '../views/jin-categories/Add.vue';
import EditJinCategories from '../views/jin-categories/Edit.vue';
import JinCategories from '../views/jin-categories/Index.vue';
import AddJin from '../views/jin/Add.vue';
import EditJin from '../views/jin/Edit.vue';
import Jin from '../views/jin/Index.vue';
import AddPartnerCategories from '../views/partner-categories/Add.vue';
import EditPartnerCategories from '../views/partner-categories/Edit.vue';
import PartnerCategories from '../views/partner-categories/Index.vue';
import AddPartnerFishConditions from '../views/partner-fish-conditions/Add.vue';
import EditPartnerFishConditions from '../views/partner-fish-conditions/Edit.vue';
import PartnerFishConditions from '../views/partner-fish-conditions/Index.vue';
import AddPartnersCommonCode from '../views/partners-common-code/Add.vue';
import PartnersCommonCode from '../views/partners-common-code/Index.vue';
import AddPartnersFishCode from '../views/partners-fish-code/Add.vue';
import PartnersFishCode from '../views/partners-fish-code/Index.vue';
import EditPartnersJoin from '../views/partners-join/Edit.vue';
import PartnersJoin from '../views/partners-join/Index.vue';
import AddPartnersNotice from '../views/partners-notice/Add.vue';
import EditPartnersNotice from '../views/partners-notice/Edit.vue';
import PartnersNotice from '../views/partners-notice/Index.vue';
import EditPartnerProduct from '../views/partners-product/Edit.vue';
import PartnersProduct from '../views/partners-product/Index.vue';
import PartnersFishingResult from '../views/partners/fishing-result/Index.vue';
import PartnersFishingResultDetail from '../views/partners/fishing-result/Detail.vue';
import PartnersPostCategory from '../views/partners-post-category/Index.vue';
import AddPartnersPostCategory from '../views/partners-post-category/Add.vue';
import PartnersPost from '../views/partners-post/Index.vue';
import AddPartnersPost from '../views/partners-post/Add.vue';
import RewardCommonCode from '../views/reward-common-code/Index.vue';
import AddRewardCommonCode from '../views/reward-common-code/Add.vue';
import Siren from '../views/siren/Index.vue';
import UserReportDetail from '../views/user-report/Detail.vue';
import UserReports from '../views/user-report/Index.vue';

import CommerceSeller from '../views/commerce/seller/Index.vue';
import CommerceSellerRegister from '../views/commerce/seller/Register.vue';
import CommerceSellerDetail from '../views/commerce/seller/Detail.vue';
import CommerceCategory from '../views/commerce/product-category/Index.vue';
import CommerceCategoryRegister from '../views/commerce/product-category/Register.vue';
import CommerceProduct from '../views/commerce/product/Index.vue';
import CommerceProductRegister from '../views/commerce/product/Register.vue';
import CommerceOrder from '../views/commerce/order/Index.vue';
import CommerceOrderDetail from '../views/commerce/order/Detail.vue';
import CommerceContract from '../views/commerce/contract/Index.vue';
import CommerceContractList from '../views/commerce/contract/List.vue';
import CommercePlacingOrder from '../views/commerce/placing-order-after/Index.vue';
import CommercePlacingOrderList from '../views/commerce/placing-order-after/List.vue';
import CommercePlacingOrderDetailList from '../views/commerce/placing-order-after/DetailList.vue';
import CommerceShipping from '../views/commerce/shipping/Index.vue';
import CommerceStatement from '../views/commerce/statement/Index.vue';
import CommerceStatementList from '../views/commerce/statement/List.vue';
import CommerceStatementDetail from '../views/commerce/statement/Detail.vue';
import CommerceProductPromotion from '../views/commerce/product-promotion/Index.vue';
import CommerceProductPromotionRegister from '../views/commerce/product-promotion/Register.vue';

import Test from './default';
import Partners from './partners';
import Commerce from './commerce';
import Usin from './usin';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: IndexComponent,
    meta: {
      text: 'Dashboard', layout: 'default', middleware: auth, serverRoute: '', group: '대시보드',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'empty', guest: true, middleware: guest },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notification,
    meta: {
      text: '공지 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_NOTIFICATION},${Permission.DELETE_NOTIFICATION}`, group: '공지사항',
    },
  },
  {
    path: '/notifications/add',
    name: 'AddNotification',
    component: AddNotification,
    meta: {
      text: '공지 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_NOTIFICATION, group: '공지사항',
    },
  },
  {
    path: '/notifications/edit/:id',
    name: 'EditNotification',
    component: EditNotification,
    meta: {
      text: '공지 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_NOTIFICATION},${Permission.UPDATE_NOTIFICATION}`, group: '공지사항',
    },
  },
  {
    path: '/notifications/preview/:id',
    name: 'PreviewNotification',
    component: PreviewNotification,
    meta: { layout: 'empty', guest: true, middleware: guest },
  },
  {
    path: '/post',
    name: 'Post',
    component: Post,
    meta: {
      text: '포스트 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_POST},${Permission.DELETE_POST}`, group: '포스트',
    },
  },
  {
    path: '/post/add',
    name: 'AddPost',
    component: AddPost,
    meta: {
      text: '포스트 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_POST, group: '포스트',
    },
  },
  {
    path: '/post/edit/:id',
    name: 'EditPost',
    component: EditPost,
    meta: {
      text: '포스트 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_POST},${Permission.DETAIL_POST}`, group: '포스트',
    },
  },
  {
    path: '/post/preview/:id',
    name: 'PreviewPost',
    component: PreviewPost,
    meta: { layout: 'empty', guest: true, middleware: guest },
  },
  {
    path: '/pro-post',
    name: 'ProPost',
    component: ProPost,
    meta: {
      text: '이전 포스트 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PRO_POST},${Permission.DELETE_PRO_POST}`, group: 'Pro posts',
    },
  },
  {
    path: '/pro-post/add',
    name: 'AddProPost',
    component: AddProPost,
    meta: {
      text: '이전 포스트 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PRO_POST, group: 'Pro posts',
    },
  },
  {
    path: '/pro-post/edit/:id',
    name: 'EditProPost',
    component: EditProPost,
    meta: {
      text: '이전 포스트 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_PRO_POST},${Permission.UPDATE_PRO_POST}`, group: 'Pro posts',
    },
  },
  {
    path: '/pro-post/preview/:id',
    name: 'PreviewProPost',
    component: PreviewProPost,
    meta: { layout: 'empty', guest: true, middleware: guest },
  },
  {
    path: '/post-categories',
    name: 'PostCategories',
    component: PostCategories,
    meta: {
      text: '포스트 카테고리', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_POST_CATEGORY},${Permission.DELETE_POST_CATEGORY}`, group: '포스트 카테고리',
    },
  },
  {
    path: '/post-categories/add',
    name: 'AddPostCategories',
    component: AddPostCategories,
    meta: {
      text: '포스트 카테고리 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_POST_CATEGORY, group: '포스트 카테고리',
    },
  },
  {
    path: '/post-categories/edit/:id',
    name: 'EditPostCategories',
    component: EditPostCategories,
    meta: {
      text: '포스트 카테고리 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_POST_CATEGORY},${Permission.UPDATE_POST_CATEGORY}`, group: '포스트 카테고리',
    },
  },
  {
    path: '/pro-post-categories',
    name: 'ProPostCategories',
    component: ProPostCategories,
    meta: {
      text: '이전 포스트 카테고리 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PRO_POST_CATEGORY},${Permission.DELETE_PRO_POST_CATEGORY}`, group: 'Pro post categories',
    },
  },
  {
    path: '/pro-post-categories/add',
    name: 'AddProPostCategories',
    component: AddProPostCategories,
    meta: {
      text: '이전 포스트 카테고리 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PRO_POST_CATEGORY, group: 'Pro post categories',
    },
  },
  {
    path: '/pro-post-categories/edit/:id',
    name: 'EditProPostCategories',
    component: EditProPostCategories,
    meta: {
      text: '이전 포스트 카테고리 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_PRO_POST_CATEGORY},${Permission.UPDATE_PRO_POST_CATEGORY}`, group: 'Pro post categories',
    },
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: UserManagement,
    meta: {
      text: '사용자 관리', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_USERS},${Permission.DELETE_USERS}`, group: '사용자 관리',
    },
  },
  {
    path: '/user-management/add',
    name: 'AddUser',
    component: AddUser,
    meta: {
      text: '사용자 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_USERS, group: '사용자 관리',
    },
  },
  {
    path: '/user-management/edit/:id',
    name: 'EditUser',
    component: EditUser,
    meta: {
      text: '사용자 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_USERS},${Permission.UPDATE_USERS}`, group: '사용자 관리',
    },
  },
  {
    path: '/user-management/edit/uid/:uid',
    name: 'EditUserByUid',
    component: EditUser,
    meta: {
      text: 'UID 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_USERS},${Permission.UPDATE_USERS}`, group: '사용자 관리',
    },
  },
  {
    path: '/user-management/events-log/:email',
    name: 'UserEventsLog',
    component: UserEventsLog,
    meta: {
      text: '사용자 이벤트 로그', layout: 'default', middleware: auth, serverRoute: Permission.DETAIL_USERS, group: '사용자 관리',
    },
  },
  {
    path: '/admin-account',
    name: 'AdminAccount',
    component: AdminAccount,
    meta: {
      text: '관리자 계정 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_ADMIN_ACCOUNT},${Permission.DELETE_ADMIN_ACCOUNT}`, group: '관리자 계정',
    },
  },
  {
    path: '/admin-account/add',
    name: 'AddAdminAccount',
    component: AddAdminAccount,
    meta: {
      text: '관리자 계정 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_ADMIN_ACCOUNT, group: '관리자 계정',
    },
  },
  {
    path: '/admin-account/edit/:id',
    name: 'EditAdminAccount',
    component: EditAdminAccount,
    meta: {
      text: '관리자 계정 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_ADMIN_ACCOUNT},${Permission.DETAIL_ADMIN_ACCOUNT}`, group: '관리자 계정',
    },
  },
  {
    path: '/admin-roles',
    name: 'AdminRoles',
    component: AdminRoles,
    meta: {
      text: '관리자 권한 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_ADMIN_ROLE},${Permission.DELETE_ADMIN_ROLE},${Permission.LIST_PERMISSION},${Permission.DETAIL_PERMISSION},${Permission.CREATE_PERMISSION},${Permission.UPDATE_PERMISSION},${Permission.DELETE_PERMISSION}`, group: '관리자 역할',
    },
  },
  {
    path: '/admin-roles/add',
    name: 'AddRole',
    component: AddRole,
    meta: {
      text: '관리자 권한 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_ADMIN_ROLE, group: '관리자 역할',
    },
  },
  {
    path: '/admin-roles/edit/:id',
    name: 'EditRole',
    component: EditRole,
    meta: {
      text: '관리자 권한 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_ADMIN_ROLE},${Permission.DETAIL_ADMIN_ROLE}`, group: '관리자 역할',
    },
  },
  {
    path: '/maps/sea-map',
    name: 'SeaMap',
    component: SeaMap,
    meta: {
      text: '바다 포인트', layout: 'default', middleware: auth, serverRoute: '', group: '어신 포인트',
    },
  },
  {
    path: '/maps/water-map',
    name: 'WaterMap',
    component: WaterMap,
    meta: {
      text: '민물 포인트', layout: 'default', middleware: auth, serverRoute: '', group: '어신 포인트',
    },
  },
  {
    path: '/app-pushes',
    name: 'AppPush',
    component: AppPush,
    meta: {
      text: '앱 알림 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_APP_PUSH},${Permission.DELETE_APP_PUSH}`, group: '앱 푸시',
    },
  },
  {
    path: '/app-pushes/add',
    name: 'AddAppPush',
    component: AddAppPush,
    meta: {
      text: '앱 알림 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_APP_PUSH, group: '앱 푸시',
    },
  },
  {
    path: '/app-pushes/edit/:id',
    name: 'EditAppPush',
    component: EditAppPush,
    meta: {
      text: '앱 알림 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_APP_PUSH},${Permission.UPDATE_APP_PUSH}`, group: '앱 푸시',
    },
  },
  // {
  //   path: '/partner',
  //   name: 'Partner',
  //   component: Partner,
  //   meta: {
  //     text: '목록 partners', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNER},${Permission.DELETE_PARTNER}`, group: 'Partner',
  //   },
  // },
  // {
  //   path: '/partner/add',
  //   name: 'AddPartner',
  //   component: AddPartner,
  //   meta: {
  //     text: '추가 partner', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PARTNER, group: 'Partner',
  //   },
  // },
  // {
  //   path: '/partner/edit/:id',
  //   name: 'EditPartner',
  //   component: EditPartner,
  //   meta: {
  //     text: '수정 partner', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_PARTNER},${Permission.UPDATE_PARTNER}`, group: 'Partner',
  //   },
  // },
  // {
  //   path: '/partner/import',
  //   name: 'ImportPartner',
  //   component: ImportPartner,
  //   meta: {
  //     text: 'Import partner', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PARTNER, group: 'Partner',
  //   },
  // },
  {
    path: '/partner-categories',
    name: 'PartnerCategories',
    component: PartnerCategories,
    meta: {
      text: '파트너스 카테고리 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNER_CATEGORY},${Permission.DELETE_PARTNER_CATEGORY}`, group: '파트너스 카테고리',
    },
  },
  {
    path: '/partner-categories/add',
    name: 'AddPartnerCategories',
    component: AddPartnerCategories,
    meta: {
      text: '파트너스 카테고리 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PARTNER_CATEGORY, group: '파트너스 카테고리',
    },
  },
  {
    path: '/partner-categories/edit/:id',
    name: 'EditPartnerCategories',
    component: EditPartnerCategories,
    meta: {
      text: '파트너스 카테고리 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_PARTNER_CATEGORY},${Permission.UPDATE_PARTNER_CATEGORY}`, group: '파트너스 카테고리',
    },
  },
  {
    path: '/fishing-tubes',
    name: 'FishingTubes',
    component: FishingTubes,
    meta: {
      text: '피싱 유튜브 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_FISHING_TUBE},${Permission.DELETE_FISHING_TUBE}`, group: 'Fishing Tube',
    },
  },
  {
    path: '/fishing-tubes/add',
    name: 'AddFishingTubes',
    component: AddFishingTubes,
    meta: {
      text: '피싱 유튜브 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_FISHING_TUBE, group: 'Fishing Tube',
    },
  },
  {
    path: '/fishing-tubes/edit/:id',
    name: 'EditFishingTubes',
    component: EditFishingTubes,
    meta: {
      text: '피싱 유튜브 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_FISHING_TUBE},${Permission.DETAIL_FISHING_TUBE}`, group: 'Fishing Tube',
    },
  },
  {
    path: '/fishing-grams',
    name: 'FishingGrams',
    component: FishingGrams,
    meta: {
      text: '피싱그램 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_FISHING_GRAM},${Permission.DELETE_FISHING_GRAM},${Permission.UPDATE_FISHING_GRAM}`, group: '피싱그램',
    },
  },
  {
    path: '/fishing-grams/detail/:id',
    name: 'FishingGramsDetail',
    component: FishingGramsDetail,
    meta: {
      text: '피싱그램 상세', layout: 'default', middleware: auth, serverRoute: Permission.DETAIL_FISHING_GRAM, group: '피싱그램',
    },
  },
  {
    path: '/fishing-grams-best-candidates',
    name: 'FishingGramsBestCandidates',
    component: FishingGramsBestCandidates,
    meta: {
      text: '피싱그램 베스트 목록', layout: 'default', middleware: auth, serverRoute: Permission.LIST_FISHING_GRAM_BEST_CANDIDATES, group: '피싱그램 베스트',
    },
  },
  {
    path: '/user-report',
    name: 'User Reports',
    component: UserReports,
    meta: {
      text: '사용자 신고 목록', layout: 'default', middleware: auth, serverRoute: Permission.LIST_USER_REPORT, group: '사용자 신고 목록',
    },
  },
  {
    path: '/user-report/detail/:id',
    name: 'User report Detail',
    component: UserReportDetail,
    meta: {
      text: '사용자 신고 상세', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_USER_REPORT},${Permission.DETAIL_USER_REPORT}`, group: '사용자 신고 목록',
    },
  },
  {
    path: '/partners-member',
    name: 'Partners',
    component: PartnersMember,
    meta: {
      text: '파트너스 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNERS},${Permission.DETAIL_PARTNERS}`, group: '파트너스',
    },
  },
  {
    path: '/partners-member/edit/:id',
    name: 'EditPartners',
    component: EditPartnersMember,
    meta: {
      text: '파트너스 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_PARTNERS},${Permission.DELETE_PARTNERS}`, group: '파트너스',
    },
  },
  {
    path: '/partners-join',
    name: 'PartnersJoin',
    component: PartnersJoin,
    meta: {
      text: '파트너스 가입 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNERS_JOIN},${Permission.DETAIL_PARTNERS_JOIN}`, group: '파트너스 가입',
    },
  },
  {
    path: '/partners-join/edit/:id',
    name: 'EditPartnersJoin',
    component: EditPartnersJoin,
    meta: {
      text: '파트너스 가입 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_PARTNERS_JOIN},${Permission.DELETE_PARTNERS_JOIN}`, group: '파트너스 가입',
    },
  },
  {
    path: '/partners-product',
    name: 'PartnersProduct',
    component: PartnersProduct,
    meta: {
      text: '파트너스 상품 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 상품',
    },
  },
  // {
  //   path: '/partner-products/add',
  //   name: 'AddPartnerProducts',
  //   component: AddPartnerProducts,
  //   meta: {
  //     text: '추가 PartnerProducts', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PARTNER_PRODUCT, group: '파트너스 상품',
  //   },
  // },
  {
    path: '/partners/fishing-result',
    name: 'PartnersFishingResult',
    component: PartnersFishingResult,
    meta: {
      text: '파트너스 조황일지', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 조황',
    },
  },
  {
    path: '/partners/fishing-result/detail/:id',
    name: 'PartnersFishingResultDetail',
    component: PartnersFishingResultDetail,
    meta: {
      text: '파트너스 조황일지 상세', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 조황',
    },
  },
  {
    path: '/partners-post-category',
    name: 'PartnersPostCategory',
    component: PartnersPostCategory,
    meta: {
      text: '파트너스 포스트 카테고리', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 포스트 카테고리',
    },
  },
  {
    path: '/partners-post-category/add',
    name: 'AddPartnersPostCategory',
    component: AddPartnersPostCategory,
    meta: {
      text: '파트너스 포스트 카테고리 등록', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 포스트 카테고리',
    },
  },
  {
    path: '/partners-post-category/edit/:id',
    name: 'EditPartnersPostCategory',
    component: AddPartnersPostCategory,
    meta: {
      text: '파트너스 포스트 카테고리 상세/수정', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 포스트 카테고리',
    },
  },
  {
    path: '/partners-post',
    name: 'PartnersPost',
    component: PartnersPost,
    meta: {
      text: '파트너스 포스트 목록', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 포스트',
    },
  },
  {
    path: '/partners-post/add',
    name: 'AddPartnersPost',
    component: AddPartnersPost,
    meta: {
      text: '파트너스 포스트 등록', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 포스트',
    },
  },
  {
    path: '/partners-post/edit/:id',
    name: 'EditPartnersPost',
    component: AddPartnersPost,
    meta: {
      text: '파트너스 포스트 상세/수정', layout: 'default', middleware: guest, serverRoute: `${Permission.LIST_PARTNERS_PRODUCT}`, group: '파트너스 포스트',
    },
  },
  {
    path: '/partners-product/edit/:business_type_cd/:id',
    name: 'EditPartnersProduct',
    component: EditPartnerProduct,
    meta: {
      text: '파트너스 상품 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_PARTNERS_PRODUCT},${Permission.DETAIL_PARTNERS_PRODUCT}`, group: '파트너스 상품',
    },
  },
  {
    path: '/partners-notice',
    name: 'PartnersNotice',
    component: PartnersNotice,
    meta: {
      text: '파트너스 공지 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNERS_NOTICE},${Permission.DELETE_PARTNERS_NOTICE}`, group: '파트너스 공지',
    },
  },
  {
    path: '/partners-notice/add',
    name: 'AddPartnersNotice',
    component: AddPartnersNotice,
    meta: {
      text: '파트너스 공지 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PARTNERS_NOTICE, group: '파트너스 공지',
    },
  },
  {
    path: '/partners-notice/edit/:id',
    name: 'EditPartnersNotice',
    component: EditPartnersNotice,
    meta: {
      text: '파트너스 공지 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_PARTNERS_NOTICE},${Permission.DETAIL_PARTNERS_NOTICE}`, group: '파트너스 공지',
    },
  },
  {
    path: '/partners-fish-code',
    name: 'PartnersFishCode',
    component: PartnersFishCode,
    meta: {
      text: '어종 코드 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNERS_FISH_CODE}`, group: '어종 코드',
    },
  },
  {
    path: '/partners-fish-code/add',
    name: 'AddPartnersFishCode',
    component: AddPartnersFishCode,
    meta: {
      text: '어종 코드 추가', layout: 'default', middleware: auth, serverRoute: `${Permission.CREATE_PARTNERS_FISH_CODE}`, group: '어종 코드',
    },
  },
  {
    path: '/partners-common-code',
    name: 'PartnersCommonCode',
    component: PartnersCommonCode,
    meta: {
      text: '공통 코드', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNERS_COMMON_CODE}`, group: '공통 코드',
    },
  },
  {
    path: '/partners-common-code/add',
    name: 'AddPartnersCommonCode',
    component: AddPartnersCommonCode,
    meta: {
      text: '공통 코드 추가', layout: 'default', middleware: auth, serverRoute: `${Permission.CREATE_PARTNERS_COMMON_CODE}`, group: '공통 코드',
    },
  },
  // {
  //   path: '/partner-fish-conditions',
  //   name: 'PartnerFishConditions',
  //   component: PartnerFishConditions,
  //   meta: {
  //     text: '파트너스 fish conditions 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_PARTNER_FISH_CONDITION},${Permission.DELETE_PARTNER_FISH_CONDITION}`, group: 'PartnerFishConditions',
  //   },
  // },
  // {
  //   path: '/partner-fish-conditions/add',
  //   name: 'AddPartnerFishConditions',
  //   component: AddPartnerFishConditions,
  //   meta: {
  //     text: '파트너스 fish conditions 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_PARTNER_FISH_CONDITION, group: 'PartnerFishConditions',
  //   },
  // },
  // {
  //   path: '/partner-fish-conditions/edit/:id',
  //   name: 'EditPartnerFishConditions',
  //   component: EditPartnerFishConditions,
  //   meta: {
  //     text: '파트너스 fish conditions 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_PARTNER_FISH_CONDITION},${Permission.DETAIL_PARTNER_FISH_CONDITION}`, group: 'PartnerFishConditions',
  //   },
  // },
  {
    path: '/siren',
    name: 'Sirens',
    component: Siren,
    meta: {
      text: '사이렌 목록', layout: 'default', middleware: auth, serverRoute: Permission.SIREN_LIST, group: '어신 사이렌',
    },
  },
  {
    path: '/fishing-knot-categories',
    name: 'Fishing Knot Category',
    component: FishingKnotCategory,
    meta: {
      text: '매듭 카테고리 목록', layout: 'default', middleware: auth, serverRoute: Permission.LIST_FISHING_KNOT_CATEGORY, group: '피싱 매듭 카테고리',
    },
  },
  {
    path: '/fishing-knot-categories/add',
    name: 'AddFishingKnotCategory',
    component: AddFishingKnotCategory,
    meta: {
      text: '매듭 카테고리 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_FISHING_KNOT_CATEGORY, group: '피싱 매듭 카테고리',
    },
  },
  {
    path: '/fishing-knot-categories/edit/:id',
    name: 'EditPartnEditishingKnotCategoryerNotices',
    component: EditFishingKnotCategory,
    meta: {
      text: '매듭 카테고리 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.UPDATE_FISHING_KNOT_CATEGORY},${Permission.DETAIL_FISHING_KNOT_CATEGORY}`, group: '피싱 매듭 카테고리',
    },
  },
  {
    path: '/fishing-knots',
    name: 'Fishing Knots',
    component: FishingKnot,
    meta: {
      text: '매듭 목록', layout: 'default', middleware: auth, serverRoute: Permission.LIST_FISHING_KNOT, group: '피싱 매듭',
    },
  },
  {
    path: '/fishing-knots/add',
    name: 'AddFishingKnot',
    component: AddFishingKnot,
    meta: {
      text: '매듭 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_FISHING_KNOT, group: '피싱 매듭',
    },
  },
  {
    path: '/fishing-knots/preview/:id',
    name: 'PreviewFishingKnots',
    component: FishingKnotPriview,
    meta: { layout: 'empty', guest: true, middleware: guest },
  },
  {
    path: '/ask',
    name: 'Ask',
    component: Ask,
    meta: {
      text: '어신 토크 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_ASK}`, group: 'Ask',
    },
  },
  {
    path: '/ask/edit/:id',
    name: 'EditAsk',
    component: EditAsk,
    meta: {
      text: '어신 토크 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_ASK},${Permission.UPDATE_ASK}`, group: 'Ask',
    },
  },
  {
    path: '/ask-categories',
    name: 'AskCategories',
    component: AskCategories,
    meta: {
      text: '어신 토크 카테고리 목록', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_ASK_CATEGORY},${Permission.DELETE_ASK_CATEGORY}`, group: 'Ask categories',
    },
  },
  {
    path: '/ask-categories/add',
    name: 'AddAskCategories',
    component: AddAskCategories,
    meta: {
      text: '어신 토크 카테고리 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_ASK_CATEGORY, group: 'Ask categories',
    },
  },
  {
    path: '/ask-categories/edit/:id',
    name: 'EditAskCategories',
    component: EditAskCategories,
    meta: {
      text: '어신 토크 카테고리 수정', layout: 'default', middleware: auth, serverRoute: `${Permission.DETAIL_ASK_CATEGORY},${Permission.UPDATE_ASK_CATEGORY}`, group: 'Ask categories',
    },
  },

  {
    path: '/jin-categories',
    name: 'JinCategories',
    component: JinCategories,
    meta: {
      text: '어신 매거진 카테고리 목록', layout: 'default', middleware: auth, serverRoute: Permission.LIST_JIN_CATEGORY, group: '매거진 카테고리',
    },
  },

  {
    path: '/jin-categories/add',
    name: 'AddJinCategories',
    component: AddJinCategories,
    meta: {
      text: '어신 매거진 카테고리 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_JIN_CATEGORY, group: '매거진 카테고리',
    },
  },

  {
    path: '/jin-categories/edit/:id',
    name: 'EditJinCategories',
    component: EditJinCategories,
    meta: {
      text: '어신 매거진 카테고리 수정', layout: 'default', middleware: auth, serverRoute: Permission.UPDATE_JIN_CATEGORY, group: '매거진 카테고리',
    },
  },

  {
    path: '/jin',
    name: 'Jin',
    component: Jin,
    meta: {
      text: '어신 매거진 목록', layout: 'default', middleware: auth, serverRoute: Permission.LIST_JIN, group: '어신 매거진',
    },
  },

  {
    path: '/jin/add',
    name: 'AddJin',
    component: AddJin,
    meta: {
      text: '어신 매거진 추가', layout: 'default', middleware: auth, serverRoute: Permission.CREATE_JIN, group: '어신 매거진',
    },
  },

  {
    path: '/jin/edit/:id',
    name: 'EditJin',
    component: EditJin,
    meta: {
      text: '어신 매거진 수정', layout: 'default', middleware: auth, serverRoute: Permission.UPDATE_JIN, group: '어신 매거진',
    },
  },

  {
    path: '/reward-common-code',
    name: 'RewardCommonCode',
    component: RewardCommonCode,
    meta: {
      text: '리워드 공통 코드', layout: 'default', middleware: auth, serverRoute: `${Permission.LIST_REWARD_COMMON_CODE}`, group: '리워드 공통 코드',
    },
  },
  {
    path: '/reward-common-code/add',
    name: 'AddRewardCommonCode',
    component: AddRewardCommonCode,
    meta: {
      text: '리워드 공통 코드 추가', layout: 'default', middleware: auth, serverRoute: `${Permission.CREATE_REWARD_COMMON_CODE}`, group: '리워드 공통 코드',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { layout: 'empty', guest: true },
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied,
    meta: { layout: 'empty', guest: true },
  },
  ...Test,
  ...Partners,
  ...Commerce,
  ...Usin,
];
const router = createRouter({ history: createWebHistory(), routes });

function nextFactory(context: any, middleware: any, index: any) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters: any) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.text ? `Uhshin Admin | ${to.meta.text}` : 'Uhshin Admin';

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    middleware[0]({ ...context, next: nextMiddleware });
  } else {
    next();
  }
});

export default router;
