
import { defineComponent, watch, ref } from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import { UploadAdapter } from "../../services/editor/uploadAdaptor";
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';

export default defineComponent({
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
					EssentialsPlugin,
					BoldPlugin,
					ItalicPlugin,
					LinkPlugin,
					ParagraphPlugin,
					Alignment,
					Font,
					Heading,
					ImagePlugin,
					ImageCaption,
					ImageStyle,
					ImageToolbar,
					ImageUpload,
					ImageInsert,
					LinkImage,
					ImageResize,
					ListStyle,
					Indent,
					IndentBlock,
					MediaEmbed,
					BlockQuote,
					Table,
					TableToolbar
				],
				toolbar: {
					items: [
						'heading',
						'bold',
						'italic',
						'link',
						'alignment',
						'|',
						'fontFamily',
						'fontColor',
						'fontBackgroundColor',
						'fontSize',
						'|',
						'undo',
						'redo',
						'insertImage',
						'bulletedList',
						'numberedList',
						'outdent',
						'indent',
						'mediaEmbed',
						'blockQuote',
						'insertTable'
					],
					shouldNotGroupWhenFull: true
				},
				image: {
					toolbar: [
						'imageStyle:alignLeft',
						'imageStyle:full',
						'imageStyle:alignRight',
						'|',
						'imageTextAlternative',
						'|',
						'linkImage',
						'|',
						'resizeImage'
					],
					styles: [
						'full',
						'alignLeft',
						'alignRight'
					],
					resizeOptions: [
						{
							name: 'resizeImage:original',
							value: null,
							label: 'Original'
						},
						{
							name: 'resizeImage:25',
							value: '25',
							label: '25%'
						},
						{
							name: 'resizeImage:50',
							value: '50',
							label: '50%'
						},
						{
							name: 'resizeImage:75',
							value: '75',
							label: '75%'
						},
						{
							name: 'resizeImage:100',
							value: '100',
							label: '100%'
						}
					],
				},
				fontSize: {
					options: [
						// Numerical values.
						9, 10, 11, 12, 'default', 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 50
					],
					supportAllValues: true
				},
				mediaEmbed: {
					elementName: 'o-embed',
					previewsInData: true,
				},
				table: {
					contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
				},
				extraPlugins: [this.uploader],
			}
		};
	},
	methods: {
		uploader(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => UploadAdapter } } })
		{
			editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
				return new UploadAdapter( loader, 'post_contents' );
			};
		},
	},
	props: {
		setContents: {
			type: String,
			required: false,
			default: ''
		}
	},
	setup: (props) => {
		let contents = ref('')
		watch(() => props.setContents, () => {
			contents.value = props.setContents!
		})

		async function getContents() {
			return contents.value
		}

		return {
			contents,
			getContents
		}
	}
})
