
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { key } from '../../store';
import cookieHelper from '../../helper/cookie.helper';

export default defineComponent({
  name: 'AppHeader',
  props: {},
  setup: () => {
    const router = useRouter();
    const store = useStore(key);

    function logout() {
      cookieHelper.createCookie('refresh_token', '', -1);
      cookieHelper.createCookie('access_token', '', -1);
      cookieHelper.createCookie('permissions', '', -1);
      store.dispatch('adminAccounts/setToken', '');
      router.push('/login');
    }

    return {
      logout,
    };
  },
});
