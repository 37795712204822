
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { default as UserModel } from '../../models/user.model';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import { constants } from '../../config/constant.config';
import Pagination from '../../components/ui/Pagination.vue';
import { ApiService } from '../../services/backend-api/api.services';
import csvHelper from '../../helper/csv.helper';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'UsertList',
  components: { Pagination },
  methods: {},
  setup: () => {
    const router = useRouter();
    const perPage = constants().DEFAULT_PERPAGE;
    const loading = ref(false);
    const userSummary = ref({});
    const errorSummary = ref('');

    const orderType = ref('desc');
    const filter = ref({
      nick_name: '',
      email_address: '',
      is_terminated: null,
    });
    const users = ref<Array<any>>([]);

    // onMounted(async () => {
    // 	const apiService = new ApiService
    // 	let requestSummary = await apiService.userSummary()
    // 	if (requestSummary.status) {
    // 		userSummary.value = requestSummary.data;
    // 	} else {
    // 		errorSummary.value = requestSummary.data.message
    // 	}
    // })

    const orderOptions = ref([['date_last_login', orderType.value]]);
    const filterConditions = ref<Array<any>>([]);
    function updateData(items: Array<any>) {
      if (items) {
        items.forEach((value: any, key: any) => {
          items[key].favorite_spots_sgma = 0;
          items[key].favorite_spots_user = 0;
          if (value.my_favorite_spots?.length) {
            value.my_favorite_spots.forEach((data: any) => {
              if (data.owner === 'SGMA') {
                items[key].favorite_spots_sgma++;
              } else {
                items[key].favorite_spots_user++;
              }
            });
          }
        });
      }

      loading.value = false;
      users.value = items;
    }
    function isLoading(value: boolean) {
      loading.value = value;
    }

    async function deleteUser(id: any) {
      swal.simpleDeleteConfirm(async () => {
        loading.value = true;
        await UserModel.delete(id);
        users.value = await UserModel.getAll();
        loading.value = false;
      });
    }

    async function editUser(id: any) {
      router.push(`/user-management/edit/${id}`);
    }

    async function searchHandler() {
      filterConditions.value = [];
      if (filter.value.nick_name && filter.value.nick_name.length > 0) {
        filterConditions.value.push(['nick_name', filter.value.nick_name]);
      }
      if (filter.value.email_address && filter.value.email_address.length > 0) {
        filterConditions.value.push([
          'email_address',
          filter.value.email_address,
        ]);
      }
      if (filter.value.is_terminated !== null) {
        filterConditions.value.push([
          'is_terminated',
          filter.value.is_terminated,
        ]);
      }
    }

    async function sortHandler() {
      switch (orderType.value) {
        case 'asc':
          orderType.value = 'desc';
          break;
        case 'desc':
          orderType.value = 'asc';
          break;
        default:
      }
      orderOptions.value = [['date_last_login', orderType.value]];
    }

    async function exportCsv() {
      loading.value = true;
      const apiService = new ApiService();
      const requestSummary = await apiService.exportUser();
      if (requestSummary.status) {
        const itemsFormatted: any[] = [];

        // format the data
        requestSummary.data.items.forEach(
          (item: {
            username: string;
            email: any;
            sgma_point: any;
            user_point: any;
            last_login: any;
          }) => {
            let username = '';
            if (item.username && item.username.length) {
              // remove commas and " to avoid errors,
              username = item.username.replace(/,/g, '');
              username = username.replace(/"/g, '');
            }
            itemsFormatted.push({
              username,
              email: item.email,
              sgma_point: item.sgma_point,
              user_point: item.user_point,
              last_login: dateTimeHelper.timestampToDateTime(item.last_login),
            });
          },
        );

        const fileTitle = 'users_data'; // or 'my-unique-title'
        csvHelper.exportCSVFile(
          requestSummary.data.headers,
          itemsFormatted,
          fileTitle,
        );
        loading.value = false;
      } else {
        swal.alert(
          `An error occurred when trying to export users data: ${requestSummary.data.message}`,
        );
        loading.value = false;
      }
    }

    async function showEvents(email: string) {
      router.push(`/user-management/events-log/${email}`);
    }

    return {
      users,
      dateTimeHelper,
      numberHelper,
      deleteUser,
      editUser,
      searchHandler,
      perPage,
      loading,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      UserModel,
      filter,
      orderType,
      sortHandler,
      userSummary,
      errorSummary,
      exportCsv,
      showEvents,
    };
  },
});
