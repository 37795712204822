import {BaseModel} from "./base.model";

export interface FishingKnotCategory {
	id: string,
	name: string,
	status: boolean,
	order_number: number,
	icon: string
}

class FishingKnotCategoriesModel extends BaseModel {
	protected collectionName = 'fishing_knot_categories'

	private static _instance: FishingKnotCategoriesModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default FishingKnotCategoriesModel.Instance