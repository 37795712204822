import {
  BookingRoleRequest,
  initialBookingRoleRequest,
} from './booking_role_request.model';
import { PartnerProfile, initialPartnerProfile } from './partner_porfile.model';

export interface PartnerInfo {
  name: string;
  ceo_name: string;
  user_id: string;
  category: string;
  address: string;
  address_detail: string;
  email: string;
  contact: string;
  cell_num: string;
  conversion_contact: string;
  registration_url: string;
  bank_nm: string;
  holder_nm: string;
  account_num: string;
  business_type_cd: string;
  business_type_nm: string;
  conditions: string;
  business_category: string;
  remark: string;
  join_stat_cd: string;
  join_stat_nm: string;
  reject_remark: string;
  paid_cd: string;
  paid_nm: string;
  sign_out_yn: string;
  sign_out_reason: string;
  booking_role_request: BookingRoleRequest;
  profile: PartnerProfile;
}
export const initialPartnerInfo: PartnerInfo = {
  name: '',
  ceo_name: '',
  user_id: '',
  category: '',
  address: '',
  address_detail: '',
  email: '',
  contact: '',
  cell_num: '',
  conversion_contact: '',
  registration_url: '',
  bank_nm: '',
  holder_nm: '',
  account_num: '',
  business_type_cd: '',
  business_type_nm: '',
  conditions: '',
  business_category: '',
  remark: '',
  join_stat_cd: '',
  join_stat_nm: '',
  reject_remark: '',
  paid_cd: '',
  paid_nm: '',
  sign_out_yn: '',
  sign_out_reason: '',
  booking_role_request: initialBookingRoleRequest,
  profile: initialPartnerProfile,
};
