
import { defineComponent, ref, onMounted } from 'vue';
import { default as radioModel } from '../../models/radioactivity.model';
import { default as shellfishModel } from '../../models/shellfishToxin.model';
// import RadioactivityIndex from './RadioactivityIndex.vue';
// import ShellfishToxinIndex from './ShellfishToxinIndex.vue';
import dateTimeHelper from '../../helper/datetime.helper';
import { constants } from '../../config/constant.config';
export default defineComponent({
  name: 'UhshinSiren',
  props: {},
  components: {
    // RadioactivityIndex,
    // ShellfishToxinIndex
  },
  setup: () => {
    const shellfishBtn = ref<any>(null);
    const radiationBtn = ref<any>(null);
    const shellfishPage = ref<any>(null);
    const radiationPage = ref<any>(null);
    const templateBody = ref<any>(null);
    const radioactivityData = ref<any>({
      start_analysis_date: '',
      end_analysis_date: '',
      analysis_target: '국내산',
      detection_state: '불검출',
    });
    const radiationList = ref<any>([
      {
        isChecked: false,
        fish_name: '',
        cesium: 0,
        iodine: 0,
      },
    ]);
    const shellfishToxinData = ref<any>({
      start_sampling_date: '',
      end_sampling_date: '',
      hasBan: '없음',
      ban_areas: [],
    });
    const banAreaList = ref<any>([]);
    const currentRadioPage = ref<number>(1);
    const shellfishCurrentPage = ref<number>(1);

    const radioactivities = ref<Array<any>>([]);
    const shellfishToxins = ref<Array<any>>([]);
    const loading = ref(false);
    const filter = ref({
      title: '',
      pin: null,
      show: null,
      category: null,
      created: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;
    let editMode = false;
    let editId = '';

    onMounted(async () => {
      console.log('mounted');
      updateRadioData();
      updateShellfishData();
    });

    const orderOptions = ref([['created_at', 'desc']]);
    let filterConditions = ref<Array<any>>([]);

    function isLoading(value: boolean) {
      loading.value = value;
    }

    async function searchHandler() {
      filterConditions.value = [];

      if (filter.value.show !== null) {
        filterConditions.value.push(['show', filter.value.show]);
      }
    }

    const switchTab = (e: any, tab: string) => {
      if (e.currentTarget.classList.contains('active')) {
        return;
      }
      if (tab === '방사능') {
        radiationBtn.value.classList.add('active');
        shellfishBtn.value.classList.remove('active');
        radiationPage.value.classList.remove('hide');
        shellfishPage.value.classList.add('hide');
      } else {
        shellfishBtn.value.classList.add('active');
        radiationBtn.value.classList.remove('active');
        shellfishPage.value.classList.remove('hide');
        radiationPage.value.classList.add('hide');
      }
    };

    const openPopupRadiation = () => {
      templateBody.value.classList.add('modal-open');
      templateBody.value
        .querySelector('#radiationModal')
        .classList.add('showModal');
    };

    const closePopupRadiation = (e: any, isClose: boolean) => {
      if (e?.target.classList.contains('modal') || isClose) {
        resetRadioData();
        templateBody.value.classList.remove('modal-open');
        templateBody.value
          .querySelector('#radiationModal')
          .classList.remove('showModal');
      }
    };

    const openPopupShellfish = () => {
      templateBody.value.classList.add('modal-open');
      templateBody.value
        .querySelector('#shellfishModal')
        .classList.add('showModal');
    };

    const closePopupShellfish = (e: any, isClose: boolean) => {
      if (e?.target.classList.contains('modal') || isClose) {
        resetShellfishData();
        templateBody.value.classList.remove('modal-open');
        templateBody.value
          .querySelector('#shellfishModal')
          .classList.remove('showModal');
      }
    };

    const addRadiation = () => {
      console.log('add');
      radiationList.value.push({
        isChecked: false,
        fish_name: '',
        cesium: 0,
        iodine: 0,
      });
    };
    const removeRadiation = () => {
      const newRadiationList: any[] = [];
      radiationList.value.forEach((radiation: any) => {
        if (!radiation.isChecked) {
          newRadiationList.push(radiation);
        }
      });
      radiationList.value = newRadiationList;
    };

    const addBanArea = () => {
      banAreaList.value.push({
        isChecked: false,
        area: '',
      });
    };
    const removeBanArea = () => {
      const newBanAreaList: any[] = [];
      banAreaList.value.forEach((banArea: any) => {
        if (!banArea.isChecked) {
          newBanAreaList.push(banArea);
        }
      });
      banAreaList.value = newBanAreaList;
    };

    const nextRadioPage = () => {
      currentRadioPage.value += 1;
      updateRadioData();
    };
    const prevRadioPage = () => {
      currentRadioPage.value -= 1;
      updateRadioData();
    };

    const updateRadioData = async () => {
      loading.value = true;

      const result = await radioModel.getAll(
        filterConditions.value,
        orderOptions.value,
        currentRadioPage.value,
        perpage,
      );
      console.log(result);
      radioactivities.value = result;
      loading.value = false;
    };

    const submitRadioData = async () => {
      console.log('submit Radio Data');
      // radioactivities.value.analysis_results = radiationList.value;
      const analysis_results: any[] = [];
      radiationList.value.forEach((item: any) => {
        analysis_results.push({
          fish_name: item.fish_name,
          cesium: Number(item.cesium),
          iodine: Number(item.iodine),
        });
      });
      const data = {
        ...radioactivityData.value,
        analysis_results,
      };

      if (!validateRadiation(data)) {
        return;
      }

      if (editMode) {
        const updateResult = await radioModel.update(editId, data);
        editMode = false;
      } else {
        const createResult = await radioModel.create(data);
      }
      resetRadioData();
      closePopupRadiation(null, true);
      updateRadioData();
    };

    const validateRadiation = (data: any) => {
      if (data.start_analysis_date === '' || data.end_analysis_date === '') {
        alert('분석일시를 입력해주세요.');
        return false;
      }
      for (let i = 0; i < data.analysis_results.length; i++) {
        const item = data.analysis_results[i];
        if (item.fish_name === '') {
          alert('어종을 입력해주세요');
          return false;
        }
      }
      return true;
    };
    const resetRadioData = () => {
      editMode = false;
      editId = '';
      radioactivityData.value = {
        start_analysis_date: '',
        end_analysis_date: '',
        analysis_target: '국내산',
        detection_state: '불검출',
      };

      radiationList.value = [
        {
          isChecked: false,
          fish_name: '',
          cesium: 0,
          iodine: 0,
        },
      ];
    };

    const editRadio = (item: any) => {
      editId = item.id;
      radioactivityData.value = item;
      radiationList.value = item.analysis_results;
      editMode = true;
      openPopupRadiation();
    };

    const nextShellfishPage = () => {
      currentRadioPage.value += 1;
      updateShellfishData();
    };
    const prevShellfishPage = () => {
      currentRadioPage.value -= 1;
      updateShellfishData();
    };

    const updateShellfishData = async () => {
      loading.value = true;
      const result = await shellfishModel.getAll(
        filterConditions.value,
        orderOptions.value,
        currentRadioPage.value,
        perpage,
      );
      console.log(result);
      shellfishToxins.value = result;
      loading.value = false;
    };

    const resetShellfishData = () => {
      editMode = false;
      editId = '';
      shellfishToxinData.value = {
        start_sampling_date: '',
        end_sampling_date: '',
        hasBan: '없음',
        ban_areas: [],
      };

      banAreaList.value = [
        {
          isChecked: false,
          area: '',
        },
      ];
    };
    const editShellfishToxins = (item: any) => {
      editId = item.id;
      editMode = true;
      shellfishToxinData.value = item;
      if (item?.ban_areas?.length > 0) {
        shellfishToxinData.value.hasBan = '있음';
      } else {
        shellfishToxinData.value.hasBan = '없음';
      }
      const banAreas: any[] = [];
      console.log(item?.ban_areas);
      item?.ban_areas.forEach((item: any) => {
        banAreas.push({
          isChecked: false,
          area: item,
        });
      });
      banAreaList.value = banAreas;
      openPopupShellfish();
    };
    const submitShellfishData = async () => {
      console.log('submit Shellfish Data');
      // radioactivities.value.analysis_results = radiationList.value;
      console.log(shellfishToxinData.value);

      const data = {
        ...shellfishToxinData.value,
      };

      if (!validateShellfish(data)) {
        return;
      }
      if (shellfishToxinData.value.hasBan === '있음') {
        const ban_areas: any[] = [];
        if (banAreaList.value.length > 0) {
          banAreaList.value.forEach((item: any) => {
            ban_areas.push(item.area);
          });
        }
        data.ban_areas = ban_areas;
      } else {
        data.ban_areas = [];
      }

      if (editMode) {
        const updateResult = await shellfishModel.update(editId, data);
        editMode = false;
      } else {
        const createResult = await shellfishModel.create(data);
      }
      resetShellfishData();
      closePopupShellfish(null, true);
      updateShellfishData();
    };

    const validateShellfish = (data: any) => {
      console.log(data);
      if (data.start_sampling_date === '' || data.end_sampling_date === '') {
        alert('시료 채취기간을 입력해주세요.');
        return false;
      }

      for (let i = 0; i < data.ban_areas.length; i++) {
        const item = data.ban_areas[i];
        if (item.area === '') {
          alert('금지해역을 입력해주세요');
          return false;
        }
      }
      console.log(0);
      return true;
    };

    return {
      radiationBtn,
      shellfishBtn,
      radiationPage,
      shellfishPage,
      templateBody,
      radioactivityData,
      radiationList,
      banAreaList,
      removeRadiation,
      openPopupRadiation,
      closePopupRadiation,
      openPopupShellfish,
      closePopupShellfish,
      addRadiation,
      radioactivities,
      shellfishToxins,
      dateTimeHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      isLoading,
      perpage,
      searchHandler,
      switchTab,
      currentRadioPage,
      shellfishCurrentPage,
      nextRadioPage,
      prevRadioPage,
      submitRadioData,
      editRadio,
      addBanArea,
      removeBanArea,
      shellfishToxinData,
      editShellfishToxins,
      submitShellfishData,
      nextShellfishPage,
      prevShellfishPage,
    };
  },
});
