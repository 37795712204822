// @ts-nocheck
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';
import { ref } from 'vue';
import { ApiService } from '@/services/backend-api/api.services';
const apiService = new ApiService();

dayjs.locale('ko');
dayjs.extend(utc);

interface Holiday {
  year: number;
  month: number;
  day: number;
  name: string;
  is_holiday: boolean;
  date: string;
}

const holidayList = ref<Holiday[]>([]);

// const getDate = (date = )
export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  getMonthData(year: number, month: number): [] {
    const startDate = dayjs(new Date(year, month, 1).getTime());
    const monthData = [];
    let startDay = 1;
    let date = startDate;
    // 이전달 날짜 가져오기
    let week = [];
    // eslint-disable-next-line for-direction
    // if (startDate.$W !== 0) {
    for (let date = -(startDate.$W - 1); date <= 0; date++) {
      week.push(dayjs(new Date(year, month, date).getTime()));
    }
    // }
    let nextDate;
    while (startDate.$M === date.$M) {
      nextDate = dayjs(new Date(year, month, startDay++).getTime());
      nextDate.holiday = this.checkHoliday(nextDate);
      if (nextDate.$W === 0 && week.length > 0) {
        monthData.push(week);
        week = [];
        week.push(nextDate);
      } else {
        week.push(nextDate);
      }
      date = nextDate;
    }
    // 다음달 날짜 채우기
    if (nextDate.$W !== 0) {
      for (let date = nextDate.$W; date < 6; date++) {
        week.push(dayjs(new Date(year, month, startDay++).getTime()));
      }
      monthData.push(week);
    }
    return monthData;
  },
  checkHoliday(date) {
    let isHoliday = false;
    let desc = '';
    holidayList.value.forEach((holiday) => {
      if (holiday.year === date.$y && (holiday.month - 1) === date.$M && holiday.day === date.$D) {
        isHoliday = true;
        desc = holiday.name;
      }
    });
    console.log(holidayList.value);
    return {
      isHoliday,
      desc,
    };
  },
  getToday() {
    const today = dayjs();
    return today;
  },
  getDate(year: number, month: number, day: number) {
    const date = dayjs(new Date(year, month, day));
    return date;
  },
  getMonthLastday(year: number, month: number) {
    const lastday = dayjs(new Date(year, month, 0));
    return lastday.format('YYYY-MM-DD');
  },
  async getHoliday() {
    try {
      const response = await apiService.get('v1/holiday');
      holidayList.value = response.data.items;
    } catch (error) {
      console.error('Error fetching holidays', error);
    }
  },
};
