
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { UserReportReasonTypes } from '../../config/constant.config';
import swal from '../../helper/swal.helper';
import FishinggramModel from '../../models/fishingGrams.model';
import CrudModel from '../../models/userReport.model';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter(); const
      route = useRoute();
    const loading = ref<boolean>(false);
    const isSubmitting = ref<boolean>(false);
    const errors = ref<string[]>([]);
    const report = ref<any>({
      parent_data_id: '',
      reason_type: '',
      remark: '',
      report_state: '',
      report_state_at: '',
      type: '',
      target_id: '',
      target_user_id: '',
      create_at: 0,
    });
    const reportReasonTypeName = ref<string>('');
    const targetIdLink = ref<string>('');

    const { id } = route.params;
    onMounted(async () => {
      loading.value = true;
      const response = await CrudModel.findById(id);
      console.log(response);
      report.value = response;
      setTargetIdLink();
      setReportReasonTypeName();
      loading.value = false;
    });

    const submitForm = async () => {
      console.log(report.value);
      swal.updateConfirm(update);
    };
    const update = async () => {
      isSubmitting.value = true;
      errors.value = [];
      console.log(report.value);
      const updateResult = await CrudModel.update(id, report.value);
      if (updateResult.status) {
        isSubmitting.value = false;
        router.replace('/user-report');
      } else {
        isSubmitting.value = false;
        errors.value.push(updateResult.message);
      }
    };

    const moveList = () => {
      router.push('/user-report');
    };

    const setTargetIdLink = async () => {
      if (report.value.type === 'user') {
        targetIdLink.value = `/user-management/edit/uid/${report.value.target_id}`;
      } else if (report.value.type === 'fishinggram') {
        targetIdLink.value = `/fishing-grams/detail/${report.value.target_id}`;
      } else if (report.value.type === 'fishinggram.comment') {
        const conditions = [];
        conditions.push(['comment_id', report.value.target_id]);
        const findFishinggramByCommentId = await FishinggramModel.getAll(conditions);
        targetIdLink.value = `/fishing-grams/detail/${findFishinggramByCommentId[0].id}`;
      }
    };

    const setReportReasonTypeName = () => {
      if (report.value.reason_type === UserReportReasonTypes.SPAM) {
        reportReasonTypeName.value = '스팸';
      } else if (report.value.reason_type === UserReportReasonTypes.IMPOSTOR) {
        reportReasonTypeName.value = '타인 사칭';
      } else if (report.value.reason_type === UserReportReasonTypes.INAPPROPRIATE) {
        reportReasonTypeName.value = '부적절한 콘텐츠';
      } else if (report.value.reason_type === UserReportReasonTypes.ETC) {
        reportReasonTypeName.value = '기타';
      }
    };

    return {
      errors,
      report,
      submitForm,
      isSubmitting,
      loading,
      moveList,
      targetIdLink,
      reportReasonTypeName,
    };
  },
});
