import { ServiceCategory } from './service_category.model';
import { SnsInfo } from './sns_info.model';

export interface PartnerProfile {
  address: string;
  address_detail: string;
  name: string;
  lat: number;
  lon: number;
  intro: string;
  images: [];
  snsList: Array<SnsInfo>;
  serviceCategories: Array<ServiceCategory>;
}

export const initialPartnerProfile: PartnerProfile = {
  address: '',
  address_detail: '',
  name: '',
  lat: 0,
  lon: 0,
  intro: '',
  images: [],
  snsList: [],
  serviceCategories: [],
};
