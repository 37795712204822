import { CreatedAt, initialCreatedAt } from './created_at.model';

export interface BookingRoleRequest {
  account_num: string;
  account_registered_num: string;
  account_type: string;
  b_stt: string;
  bank_cd: string;
  bank_cd_ko: string;
  confirm_by: null;
  create_at: CreatedAt;
  create_by: string;
  data_id: string;
  holder_nm: string;
  member_data_id: number;
  partners_profile_id: number;
  representative_url: string;
  state: string;
  state_ko: string;
  tax_type: string;
}

export const initialBookingRoleRequest: BookingRoleRequest = {
  account_num: '',
  account_registered_num: '',
  account_type: '',
  b_stt: '',
  bank_cd: '',
  bank_cd_ko: '',
  confirm_by: null,
  create_at: initialCreatedAt,
  create_by: '',
  data_id: '',
  holder_nm: '',
  member_data_id: 0,
  partners_profile_id: 0,
  representative_url: '',
  state: '',
  state_ko: '',
  tax_type: '',
};
