
import {
  defineComponent, PropType, ref, toRefs, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  NextButton, PageBlock, PageItem, PrevButton,
} from '@/helper/pagination.helper';
import { constants } from '../../config/constant.config';
import { BaseModel } from '../../models/base.model';

export default defineComponent({
  name: 'PaginationV2',
  emits: ['dataChanged', 'isLoading'],
  props: {
    dataModel: {
      type: BaseModel,
      required: true,
    },
    filterConditions: {
      type: Object as PropType<any>,
      required: false,
      default() {
        return {};
      },
    },
    orderOptions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    limit: {
      type: Number,
      required: false,
      default: constants().DEFAULT_PERPAGE,
    },
    show_yn: {
      type: Boolean,
      required: false,
      default: true,
    },
  } as any,
  setup: (props, { emit }) => {
    const {
      dataModel, filterConditions, orderOptions, limit, show_yn,
    } = toRefs(props);
    const router = useRouter();
    const route = useRoute();
    const refListData = ref<any>({});
    const totalPage = ref(0);
    const totalPageCount = ref(0);
    const pageBlock = ref(5); // 한 페이지 블록당 몇 개 페이지를 보여줄지
    const currentPage = ref(constants().DEFAULT_PAGE); // 현재 페이지
    let indexCounter = 1;
    const pageItemList = ref<PageItem[]>([]);

    const prevButton = ref<PrevButton>(new PrevButton({
      page: 0,
      totalPageCount: 0,
      clickEventHandler: () => {},
    }));
    // eslint-disable-next-line no-undef
    const nextButton = ref<NextButton>(new NextButton({
      page: 0,
      totalPageCount: 0,
      clickEventHandler: () => {},
    }));

    // onMounted(async () => {
    // Get list data
    // await loadData(currentPage.value); // 상세보기에서 뒤로가기로 새로고침하면 mounth event, watch event가 발생되어 데이터 목록이 깜빡임 발생

    // });

    watch([filterConditions, orderOptions], () => {
      indexCounter = 1;
      currentPage.value = route.query.page ? Number(route.query.page) : currentPage.value;
      loadData(currentPage.value);
    }, {
      deep: true,
    });

    /**
     * 데이터 조회
     * @param page
     */
    async function loadData(page: number) {
      emit('isLoading', true);

      await setQueryString(page);

      const {
        items: dataItems, total: resTotal, page: resPage, size: resPageSize,
      } = await dataModel.value.getAll(filterConditions.value, orderOptions.value, page, limit.value);

      initPagination(resTotal, resPageSize);

      setIndexCounter(page, dataItems);

      refListData.value = dataItems;

      setEmit();
    }

    /**
     * 페이지를 router의 'page' 쿼리스트링에 적용
     * @param page
     */
    async function setQueryString(page: number) {
      const filterQueryObj = Object.fromEntries(filterConditions.value);

      await router.replace({
        query: {
          ...filterQueryObj,
          page,
        },
        force: true,
      });
    }

    /**
     * 페이지네이션 초기화
     * @param resTotal
     * @param resPageSize
     */
    function initPagination(resTotal: number, resPageSize: number) {
      // 총 페이지 개수 셋팅
      setTotalPageCount(resTotal, resPageSize);

      // 페이지 아이템 생성
      makePageItem();

      // prev button 이벤트
      setPrevButtonEvent();

      // next button 이벤트
      setNextButtonEvent();
    }

    /**
     * 총 페이지 수
     * @param resTotal
     * @param resPageSize
     */
    function setTotalPageCount(resTotal: number, resPageSize: number) {
      totalPageCount.value = Math.ceil(resTotal / resPageSize);
    }

    /**
     * 페이지 아이템 생성
     */
    function makePageItem() {
      const block = new PageBlock({
        page: currentPage.value,
        totalPageCount: totalPageCount.value,
        pageCount: pageBlock.value,
      });

      // 총 페이지 개수만큼 페이지 아이템을 추가 합니다.
      pageItemList.value = []; // 배열 비우기
      for (let page = block.getStartBlock(); page <= block.getLastBlock(); page++) {
        pageItemList.value.push(
          new PageItem({
            page,
            totalPageCount: totalPageCount.value,
            clickEventHandler: () => {
              currentPage.value = page;
              loadData(currentPage.value);
            },
          }),
        );
      }
    }

    /**
     * 이전 버튼 이벤트 할당
     */
    function setPrevButtonEvent() {
      prevButton.value = new PrevButton({
        page: currentPage.value,
        totalPageCount: totalPageCount.value,
        clickEventHandler: () => {
          currentPage.value -= pageBlock.value;
          if (currentPage.value <= 1) {
            currentPage.value = 1;
          }
          loadData(currentPage.value);
        },
      });
    }

    /**
     * 다음버튼 이벤트 할당
     */
    function setNextButtonEvent() {
      nextButton.value = new NextButton({
        page: currentPage.value,
        totalPageCount: totalPageCount.value,
        clickEventHandler: () => {
          currentPage.value += pageBlock.value;
          if (currentPage.value > totalPageCount.value) currentPage.value = totalPageCount.value;
          loadData(currentPage.value);
        },
      });
    }

    /**
     * 데이터에 Index Counter 셋팅
     */
    function setIndexCounter(page: number, dataItems: any[]) {
      if (typeof page !== 'undefined') {
        indexCounter = page * limit.value - limit.value + 1;
      }

      dataItems.forEach((value: any, key: any) => {
        dataItems[key].indexCounter = indexCounter++;
      });
    }

    function setEmit() {
      emit('dataChanged', refListData.value);
      emit('isLoading', false);
    }

    return {
      totalPage, currentPage, pageItemList, nextButton, prevButton, show_yn,
    };
  },
});
