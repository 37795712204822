<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>조황 이미지</label>
            <div>
              <img v-for="image in result.image_urls" :src="image+'?w=150'" :key="image" class="rounded img-thumbnail mr-2" alt="">
            </div>
          </div>
          <div class="form-group">
            <label>조황일지 제목</label>
            <input type="text" class="form-control" v-model="result.title" placeholder="제목을 입력하세요" required  :disabled="id && isDetail" >
          </div>
          <div class="form-group">
            <label>파트너스 이름</label>
            <input type="text" class="form-control" v-model="result.partners_profile_nm" placeholder="제목을 입력하세요" required  :disabled="id && isDetail" >
          </div>
          <div class="form-group">
            <label>내용</label>
            <textarea class="form-control" v-model="result.content" placeholder="등록 후 생성됩니다." required disabled></textarea>
          </div>
          <div class="form-group">
            <label>지역</label>
            <input type="text" class="form-control" v-model="result.location_cd" placeholder="등록 후 생성됩니다." required disabled>
          </div>
          <div class="form-group">
            <label>출조일</label>
            <input type="text" class="form-control" v-model="result.fishing_date_view" placeholder="등록 후 생성됩니다." required disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>등록일시</label>
            <input type="text" class="form-control" v-model="result.create_date" disabled>
          </div>
          <div class="form-group">
            <label>어종</label>
            <input type="text" class="form-control" v-model="result.fish" placeholder="등록 후 생성됩니다." required disabled>
          </div>
          <div class="form-group">
            <label>태그</label>
            <input type="text" class="form-control" :value="result.tags.join(',')" placeholder="등록 후 생성됩니다." required disabled>
          </div>
        </div>
      </div>
      <div>
        <div class="footer-btn-wrapper">
          <button type="submit" class="btn btn-lg btn-danger" @click="deleteResult">삭제</button>
          <button type="submit" class="btn btn-lg btn-default" @click="back">목록</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue';
import { ApiService } from '@/services/backend-api/api.services';
import { useRoute, useRouter } from 'vue-router';
import swal from '@/helper/swal.helper';
import { useStore } from 'vuex';
import { key } from '@/store';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore(key);
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const isSubmitting = ref(false);
    const result = ref({
      category_data_id: '',
      member_id: '',
      partners_id: 0,
      partners_profile_nm: '',
      title: '',
      content: '',
      public_yn: true,
      fishing_date_view: '',
      profile_category_cd: '',
      profile_category_nm: '',
      create_date: '',
      tags: [],
    });

    onMounted(async () => {
      store.commit('loading/showLoading', '데이터를 로딩중입니다.');
      const resultData = await getFishingResult(id);
      console.log(resultData);
      store.commit('loading/hideLoading');
      result.value = resultData;
    });
    const getFishingResult = async () => {
      const response = await apiService.get(`/partners/fishing-result/${id}`);
      return response.data.item;
    };

    const back = () => {
      router.back();
    };

    const deleteResult = async () => {
      swal.deleteConfirm(async () => {
        const response = await apiService.delete(`/partners/fishing-result/${id}`);
        if (response.data.statusCode === 201 || response.data.statusCode === 200) {
          swal.deletedConfirm(
            async () => {
              router.push('/partners/fishing-result');
            },
          );
        } else {
          console.error(response);
        }
      });
    };
    return {
      id,
      isDetail,
      result,
      isSubmitting,
      back,
      deleteResult,
    };
  },
});
</script>
<style scoped>
.thumbnail-img {
  width: 335px;
}
</style>
