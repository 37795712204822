
import { BusinessTypes, PartnersProductCategoryTypes, PartnersProductPaidTypes } from '@/config/constant.config';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import PartnersModel from '../../models/partners-member.model';
import CrudModel from '../../models/partnersProduct.model';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter(); const
      route = useRoute();
    const loading = ref<boolean>(false);
    const isSubmitting = ref<boolean>(false);
    const errors = ref<string[]>([]);
    const product = ref<any>({
      status: 0,
    });
    const partners = ref<any>({
      data_id: 0,
      name: '',
    });

    const { business_type_cd, id } = route.params;
    onMounted(async () => {
      loading.value = true;
      const { item } = await CrudModel.findByBusinessTypeCodeAndId(business_type_cd, id);
      product.value = item;

      const partnersResponse = await PartnersModel.findById(item.partners_id);
      partners.value = partnersResponse.item;

      loading.value = false;
    });

    const submitForm = async () => {
      swal.updateConfirm(update);
    };
    const update = async () => {
      isSubmitting.value = true;
      errors.value = [];
      const updateResult = await CrudModel.update(id, product.value);
      if (updateResult.status) {
        isSubmitting.value = false;
        router.replace('/partner-products');
      } else {
        isSubmitting.value = false;
        errors.value.push(updateResult.message);
      }
    };

    return {
      errors,
      partners,
      product,
      submitForm,
      isSubmitting,
      loading,
      BusinessTypes,
      PartnersProductCategoryTypes,
      PartnersProductPaidTypes,
    };
  },
});
