import { ApiService } from '../services/backend-api/api.services';
import { BaseV2Model } from './base-v2.model';

class PartnersProductModel extends BaseV2Model {
    protected collectionName = 'partners/product';

    protected keepCollectionName = true;

    private static _instance: PartnersProductModel;

    public static get Instance() {
      return this._instance || (this._instance = new this());
    }

    public async findByBusinessTypeCodeAndId(business_type_cd: any, id: any) {
      const apiService = new ApiService();
      const result = await apiService.findByBusinessTypeCodeAndId(`${this.collectionName}/${business_type_cd}/${id}`, true);


      if (result.status) {
        return result.data;
      }
      return false;
    }
}

export default PartnersProductModel.Instance;
