import {BaseModel} from "./base.model";

export interface ProPostCategory {
	id: string,
	name: string,
	sub_name: string,
	event_key: string,
	status: boolean,
	order_number: number,
	thumbnail: string
}

class ProPostCategoriesModel extends BaseModel {
	protected collectionName = 'pro_post_categories'

	private static _instance: ProPostCategoriesModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default ProPostCategoriesModel.Instance