<template>
  <section class="admin-main-content">
    <div class="admin-content-header">
      <h2>
        {{ sellerName }} 미발주 내역
      </h2>
      <div>
        <xlsx-workbook>
          <xlsx-sheet
              v-for="sheet in sheets"
              :collection="sheet.data"
              :key="sheet.name"
              :sheet-name="sheet.name"
          />
          <button class="btn btn-primary" @click="download">다운로드</button>
          <xlsx-download :filename="sheetName">
            <button ref="downloadBtn" class="btn btn-primary" style="display: none;">발주서 다운로드</button>
          </xlsx-download>
        </xlsx-workbook>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
              <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
              <div class="input-group-append">
                <button type="submit" class="btn btn-default">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>인덱스</th>
              <th>상품명</th>
              <th>종류/규격</th>
              <th>수량</th>
              <th>정산금액</th>
              <th>수령인명</th>
              <!--              <th>물류사</th>-->
              <!--              <th>물류사코드</th>-->
              <!--              <th>송장번호</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(placingOrder, index) in placingOrderList" :key="`${placingOrder.order_data_id}${index}`">
              <td>{{ index + 1 }}</td>
              <td>{{ placingOrder.product_name }}</td>
              <td>{{ placingOrder.product_kind_desc }}</td>
              <td>1</td>
              <td>{{ getCurruncy(placingOrder.price_usin) }}</td>
              <td>{{ placingOrder.delivery_name }}</td>
              <!--              <td><input style="width: 100px;" type="text" v-model="placingOrder.mul_code"></td>-->
              <!--              <td><input style="width: 100px;" type="text" v-model="placingOrder.mul"></td>-->
              <!--              <td><input style="width: 100px;" type="text" v-model="placingOrder.data"></td>-->
            </tr>
            </tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </section>
</template>

<script>
import {
  defineComponent, ref, onMounted, computed,
} from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue3-xlsx';
import { ApiService } from '@/services/backend-api/api.services';
import commerceUtil from '@/helper/commerceUtil';
import dateTimeHelper from '@/helper/datetime.helper';
import swal from '@/helper/swal.helper';

export default {
  name: 'PartnersFishingResultList',
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  setup: () => {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.5/xlsx.full.min.js',
    );
    document.head.appendChild(recaptchaScript);
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const sellerName = ref('');
    const placingOrderList = ref([
      { seller_name: '' },
    ]);
    const placingOrderAfter = ref([]);
    const downloadBtn = ref(null);
    const sheet = ref([]);
    const sheets = ref([]);
    const sheetName = ref('test.xlsx');
    onMounted(async () => {
      placingOrderList.value = await getPlacingOrder();
      if (placingOrderList.value.length > 0) {
        sellerName.value = placingOrderList.value[0].seller_name;
      }
      // sheets.value.push({
      //   name: 'asef',
      //   data: placingOrderList.value,
      // });
      console.log('list');
      console.log(placingOrderList.value);
      console.log(dateTimeHelper.timestampToDateTime(new Date()));
    });
    const getPlacingOrder = async () => {
      const response = await apiService.get(`/partners-mall/placing-order/before/list/${id}`);
      return response.data.items;
    };
    const getPlacingOrderAfter = async () => {
      const response = await apiService.post(`/partners-mall/placing-order/after/list/${id}`);
      console.log('res', response.data.items);
      setSheet(response.data.items);
      return response.data.items;
    };
    const setSheet = (placingOrderAfterList) => {
      placingOrderAfterList.forEach((order) => {
        const placingOrder = {
          주문번호: order.order_data_id,
          발주번호: order.data_cd,
          상품명: order.product_name,
          규격: order.product_kind_desc,
          상품코드: order.manufacturing_cd,
          주문수량: 1,
          정산금액: order.price_usin,
          수령인명: order.delivery_name,
          수령인연락처: order.delivery_contact,
          우편번호: order.delivery_post_code,
          주소: order.delivery_address,
          배송요청일: order.delivery_req_at,
          택배물류사: '',
          송장번호: '',
        };
        sheet.value.push(placingOrder);
      });
      sheets.value.push({
        name: '시트1',
        data: sheet.value,
      });
      const date = dateTimeHelper.timestampToDateTime(new Date());
      sheetName.value = `${sellerName.value}-${date.split(' ')[0]}.xlsx`;
      console.log('sheets', sheets.value);
      console.log(sheetName);
      console.log(placingOrderAfterList);
    };
    const data = [];
    // const upload = async () => {
    //   let text = await navigator.clipboard.readText();
    //   console.log(text);
    //   text = text.split('\"').join('');
    //   console.log(text);
    //   const data = text.split('\n');
    //
    //   console.log(data);
    //   for (let i = 0; i < data.length; i++) {
    //     const lawdata = data[i].split('\t');
    //     contractList.value[i].mul_code = lawdata[0];
    //     contractList.value[i].mul = lawdata[1];
    //     contractList.value[i].data = lawdata[2];
    //   }
    // };
    const getCurruncy = (price) => commerceUtil.getCurrency(price);
    const download = async () => {
      const response = await getPlacingOrderAfter();

      swal.dowloadXlsx(downloadXlsx);
    };
    const downloadXlsx = () => {
      downloadBtn.value.click();
    };
    return {
      placingOrderList,
      downloadBtn,
      // upload,
      sheets,
      sheetName,
      sellerName,
      // sheets,
      // collection,
      getCurruncy,
      download,
    };
  },
};
</script>

<style scoped>

</style>
