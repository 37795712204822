
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { key } from '../../store'

export default defineComponent({
	name: 'SubmitButton',
	props: {
		text: {
			type: String,
			required: true
		},
		loadingText: {
			type: String,
			required: true
		},
		isSubmitting: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		submitLoading () {
			const store = useStore(key)
			return store.state.components.submitLoading
		},
	}
})
