import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ckeditor = _resolveComponent("ckeditor")

  return (_openBlock(), _createBlock(_component_ckeditor, {
    ref: "customEditor",
    editor: _ctx.editor,
    modelValue: _ctx.contents,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.contents = $event)),
    config: _ctx.editorConfig
  }, null, 8, ["editor", "modelValue", "config"]))
}