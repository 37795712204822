
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import FileUpload from '../../components/ui/FileUpload.vue';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import swal from '../../helper/swal.helper';
import Model, { JinCategory } from '../../models/jinCategories.model';

export default defineComponent({
  components: {
    SubmitButton,
    FileUpload,
  },
  setup() {
    const router = useRouter();
    const status = ref(true);
    const isSubmitting = ref(false);
    const categoryName = ref('');
    const subName = ref('');
    const orderNumber = ref<number>(0);
    const thumbnailPhoneFile = ref<any>(null);
    const thumbnailTabletFile = ref<any>(null);
    const eventKey = ref('');

    async function submitForm() {
      isSubmitting.value = true;
      const order_number_payload = Number(orderNumber.value);
      const category: JinCategory = {
        name: categoryName.value,
        sub_name: subName.value,
        status: status.value,
        order_number: order_number_payload,
        thumbnail_home: await thumbnailPhoneFile.value?.uploadImage(),
        thumbnail_phone: await thumbnailPhoneFile.value?.uploadImage(),
        thumbnail_tablet: await thumbnailTabletFile.value?.uploadImage(),
        deleted_at: null,
      };

      const addResult = await Model.create(category);
      if (addResult.status) {
        router.replace('/jin-categories');
        isSubmitting.value = false;
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      categoryName,
      subName,
      status,
      isSubmitting,
      thumbnailPhoneFile,
      thumbnailTabletFile,
      orderNumber,
      eventKey,
    };
  },
});
