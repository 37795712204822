import {BaseModel} from "./base.model";

export interface PartnerCategory {
	status: boolean,
	name: string,
	created_at: number,
	updated_at: number
}

class PartnerCategoriesModel extends BaseModel {
	protected collectionName = 'partner_categories'

	private static _instance: PartnerCategoriesModel

	public static get Instance() {
		return this._instance || (this._instance = new this())
	}
}

export default  PartnerCategoriesModel.Instance