
import { defineComponent, ref } from 'vue';
import {
  AdminAccount,
  default as CrudModel,
} from '../../models/adminAccount.model';
import dateTimeHelper from '../../helper/datetime.helper';
import { useRouter } from 'vue-router';
import Pagination from '../../components/ui/Pagination.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'AdminAccounts',
  components: { Pagination },
  setup: () => {
    const router = useRouter();
    let loading = ref(false);
    let adminAccounts = ref<AdminAccount[]>([]);
    let orderOptions = ref([['created_at', 'desc']]);

    function isLoading(status: boolean) {
      if (status) {
        loading.value = true;
      } else {
        loading.value = false;
      }
    }

    function updateData(items: AdminAccount[]) {
      loading.value = false;
      adminAccounts.value = items;
    }

    async function deleteRecord(id: any) {
      swal.simpleDeleteConfirm(async function() {
        await CrudModel.delete(id);
        adminAccounts.value = await CrudModel.getAll();
      });
    }

    async function editRecord(id: any) {
      router.push('/admin-account/edit/' + id);
    }

    return {
      loading,
      adminAccounts,
      orderOptions,
      CrudModel,
      dateTimeHelper,
      deleteRecord,
      editRecord,
      isLoading,
      updateData,
    };
  },
});
