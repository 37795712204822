import Repository from '../interfaces/repository';
import { ApiService } from '../services/backend-api/api.services';

export class BaseV2Model implements Repository {
  protected collectionName = ''

  protected keepCollectionName = false

  protected timestamp = false

  protected apiService: any

  constructor() {
    this.apiService = new ApiService();
  }

  public async getAll(conditions: any = [], orderBy: any = [], page = 1, pageSize = 25) {
    const params = this.makeParams(conditions, orderBy, page, pageSize);
    const result = await this.apiService.getListDataByCollection(this.collectionName, params, this.keepCollectionName);
    if (result.status) {
      return result.data;
    }
    return [];
  }

  public async downloadExcel(conditions: any = [], orderBy: any = [], page = 1, pageSize = 25) {
    const params = this.makeParams(conditions, orderBy, page, pageSize);
    const result = await this.apiService.downloadExcel(this.collectionName, params, this.keepCollectionName);
    return [];
  }

  private makeParams(conditions: any, orderBy: any, page: number, pageSize: number) {
    const params: { [key: string]: any } = {};
    if (conditions.length > 0) {
      conditions.forEach((value: Array<string>) => {
        switch (value.length) {
          case 2:
            params[value[0]] = value[1];
            break;
          case 3:
            params[value[0]] = value[2];
            break;
        }
      });
    }
    if (orderBy.length > 0) {
      orderBy.forEach((value: Array<string>) => {
        // todo: apply order
        switch (value.length) {
          case 1:
            params.orderBy = value[0];
            break;
          case 2:
            params.orderBy = value[0];
            params.orderType = value[1];
            break;
        }
      });
    }
    params.page = page;
    params.pageSize = pageSize;
    return params;
  }

  public async findOne(conditions: any) {
    const result = await this.apiService.findOneByCollection(this.collectionName, conditions, this.keepCollectionName);
    if (result.status) {
      return result.data;
    }
    return [];
  }

  /**
   * create record
   * @param {any} data
   */
  public async create(data: any) {
    return await this.apiService.createByCollection(this.collectionName, data, this.keepCollectionName);
  }

  public async delete(id: any) {
    let deleteResult = false;
    const apiResult = await this.apiService.deleteByCollection(this.collectionName, id, this.keepCollectionName);
    if (apiResult.status) {
      deleteResult = true;
    }
    return deleteResult;
  }

  public async findById(id: any) {
    const result = await this.apiService.findByIdAndCollection(this.collectionName, id, this.keepCollectionName);
    if (result.status) {
      return result.data;
    }
    return false;
  }

  public async findByUid(uid: any) {
    const result = await this.apiService.findByUidAndCollection(this.collectionName, uid, this.keepCollectionName);
    if (result.status) {
      return result.data;
    }
    return false;
  }

  /**
   * update record
   */
  public async update(id: any, data: any) {
    return await this.apiService.updateByCollection(this.collectionName, data, id, this.keepCollectionName);
  }
}
