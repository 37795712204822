<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card" :class="{ loading: loading }">
        <div class="card-body">
          <button
            class="btn btn-primary align-bottom mb-3"
            type="button"
            @click="addRecord()"
          >
            포스트 등록
          </button>

          <div class="table-responsive">
            <table
              class="table table-responsive-sm table-bordered table-striped table-sm"
            >
              <thead>
                <tr>
                  <th>대표 이미지</th>
                  <th>제목</th>
                  <th>노출여부</th>
                  <th>카테고리</th>
                  <th>게시일시</th>
                  <th>조회수</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="post in postList" :key="post.data_id">
                  <td class="col-md-3">
                    <img :src="post.thumbnail_url" height="50" />
                  </td>
                  <td>{{ post.title }}</td>
                  <td>
                    <span
                      v-if="post.is_active"
                      class="badge bg-success text-white"
                      >활성</span
                    >
                    <span v-else class="badge bg-danger text-white"
                      >비활성</span
                    >
                  </td>
                  <td>{{ post.category_name }}</td>
                  <td>{{ post.published_at }}</td>
                  <td>{{ post.view_count }}</td>
                  <td>
                    <!--										<button class="btn btn-btn-warning" :disabled="loading" type="button" @click="deleteRecord(category.data_id)">-->
                    <!--											<span :class="{'text-danger': !loading}"><svg class="c-icon c-icon-lg"><use xlink:href="/assets/icons/sprites/free.svg#cil-trash"></use></svg></span>-->
                    <!--										</button>-->
                    <button
                      class="btn btn-btn-warning"
                      :disabled="loading"
                      type="button"
                      @click="editRecord(post.data_id)"
                    >
                      <span :class="{ 'text-danger': !loading }">
                        <svg class="c-icon c-icon-lg">
                          <use
                            xlink:href="/assets/icons/sprites/free.svg#cil-pencil"
                          ></use>
                        </svg>
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constants, IsUserSearchEventAt } from '@/config/constant.config';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'PartnersPostList',
  props: {},
  components: {},
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const userSearchEventFlag =
      ref < IsUserSearchEventAt > IsUserSearchEventAt.N;
    const postList = ref([]);
    const loading = ref(false);
    const orderOptions = ref([[]]);
    const filterConditions = ref([]);
    function updateData(items) {
      loading.value = false;
      console.log(items);
      postList.value = items;
    }
    function isLoading(status) {
      loading.value = status;
    }

    const addRecord = () => {
      router.push({
        path: '/partners-post/add',
        query: route.query,
      });
    };

    async function editRecord(id) {
      router.push(`/partners-post/edit/${id}`);
    }

    onMounted(async () => {
      postList.value = await getPostList();
    });

    const totalPage = ref(0);
    const currentPage = ref(0);
    const pageSize = ref(30);
    const total = ref(0);
    const getPostList = async () => {
      const response = await apiService.get('/partners/post', {
        page: currentPage.value,
        size: pageSize.value,
      });
      console.log(response);
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };

    return {
      postList,
      loading,
      orderOptions,
      filterConditions,
      addRecord,
      updateData,
      isLoading,
      // CrudModel,
      // deleteRecord,
      editRecord,
    };
  },
});
</script>
