
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import SubmitButton from "../../components/ui/SubmitButton.vue";
import { default as AdminRolesModel } from "../../models/adminRoles.model"
import { default as Model } from "../../models/adminAccount.model";
import swal from '../../helper/swal.helper'

export default defineComponent({
	components: {
		SubmitButton,
	},
	setup() {
		const router = useRouter()
		let adminAccount = ref({
			email: String,
			password: String,
			name: String,
			account_type: String,
		})
		let roles = ref()
		let errors = ref<string[]>([])
		let isSubmitting = ref(false)
		let loading = ref(false)

		let id = router.currentRoute.value.params.id
		onMounted(async () => {
			loading.value = true
			adminAccount.value = await Model.findById(id)
			roles.value = await AdminRolesModel.getAll()
			loading.value = false
		});

		async function onSubmitForm() {
			isSubmitting.value = true
			let putData = {
				email: adminAccount.value.email,
				password: adminAccount.value.password,
				name: adminAccount.value.name,
				account_type: adminAccount.value.account_type
			}

			if (putData.account_type.length <= 0) {
				swal.alert('Please select role before saving admin account')
			}

			let result = await Model.update(id, adminAccount.value)
			if (result.status) {
				isSubmitting.value = false
				router.push('/admin-account')
			} else {
				isSubmitting.value = false
				swal.alert(result.message)
			}
		}

		return {
			onSubmitForm,
			errors,
			adminAccount,
			isSubmitting,
			loading,
			roles,
		}
	}
});
