import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import PartnersBooking from '@/views/partners/booking/Index.vue';
import PartnersBookingList from '@/views/partners/booking/schedule/Index.vue';
import PartnersBookingDetail from '@/views/partners/booking/schedule/[id].vue';
import AccessDenied from '../components/pages/403.vue';
import PageNotFound from '../components/pages/404.vue';
import JoinIndex from '../views/partners-join/Index.vue';
import FishingResultIndex from '../views/partners/fishing-result/Index.vue';
import PartnersDashboard from '../views/partners/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/partners/',
    name: 'PartnersDashboard',
    component: PartnersDashboard,
    meta: {
      text: '파트너스 조황일지 대시보드',
      layout: 'partners',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 조황일지',
    },
  },
  {
    path: '/partners/join',
    name: 'PartnersJoin22',
    component: JoinIndex,
    meta: {
      text: '파트너스 가입 목록',
      layout: 'partners',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 가입',
    },
  },
  {
    path: '/partners/fishingResult',
    name: 'FishingResultIndex',
    component: FishingResultIndex,
    meta: {
      text: '파트너스 조황일지 목록',
      layout: 'partners',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 조황일지',
    },
  },
  {
    path: '/partners/calculate-booking',
    name: 'CalculateBookingIndex',
    component: PartnersBooking,
    meta: {
      text: '파트너스 부킹 정산',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 부킹 정산',
    },
  },
  {
    path: '/partners/booking',
    name: 'PartnersBooking',
    component: PartnersBooking,
    meta: {
      text: '파트너스 출조 예약 목록',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 부킹 예약 목록',
    },
  },
  {
    path: '/partners/booking-schedule',
    name: 'PartnersBookingSchedule',
    component: PartnersBookingList,
    meta: {
      text: '파트너스 출조 예약 목록',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 부킹 예약 목록',
    },
  },
  {
    path: '/partners/booking-schedule',
    name: 'PartnersBookingSchedule',
    component: PartnersBookingList,
    meta: {
      text: '파트너스 출조 예약 목록',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 부킹 예약 목록',
    },
  },
  {
    path: '/partners/booking-schedule/:id',
    name: 'PartnersBookingScheduleDetail',
    component: PartnersBookingDetail,
    meta: {
      text: '파트너스 출조 예약 목록',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '파트너스 부킹 예약 목록',
    },
  },
];

export default routes;
