
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import { constants, IsUserSearchEventAt } from '@/config/constant.config';
import {
  AskAnswerStateCssStyle,
  AskShowStateCssStyle,
} from '@/helper/element-style.helper';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import cookieHelper from '../../helper/cookie.helper';
import dateTimeHelper from '../../helper/datetime.helper';
import swal from '../../helper/swal.helper';
import CrudModel, { Ask } from '../../models/ask.model';
import AskCategoriesModel, {
  AskCategory,
} from '../../models/askCategories.model';

export default defineComponent({
  name: 'Ask',
  props: {},
  components: {
    PaginationV2,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const perpage = constants().DEFAULT_PERPAGE;
    const loading = ref(false);
    const asks = ref<Ask[]>([]);
    const orderOptions = ref([['created_at', 'desc']]);
    const askCategories = ref<AskCategory[]>([]);
    const filter = ref({
      nick_name: '',
      title: '',
      category_id: '',
      show: '',
      answer_state: '',
      answer_name: '',
      delete_state: '',
      from_date: '',
      to_date: '',
    });
    const usinUserName = ref(cookieHelper.getCookie('usin_name'));

    type filterKeyType =
      | 'nick_name'
      | 'title'
      | 'category_id'
      | 'show'
      | 'answer_state'
      | 'answer_name'
      | 'delete_state'
      | 'from_date'
      | 'to_date';

    const filterConditions = ref<Array<any>>([]);

    function updateData(items: Ask[]) {
      loading.value = false;
      asks.value = items;
    }
    function isLoading(status: boolean) {
      loading.value = status;
    }
    const editRecord = (id: string) => {
      router.push({
        path: `/ask/edit/${id}`,
        query: route.query,
      });
    };
    async function deleteRecord(id: string) {
      swal.simpleDeleteConfirm(async () => {
        loading.value = true;
        await CrudModel.delete(id);
        asks.value = await CrudModel.getAll();
        loading.value = false;
        await searchHandler();
      });
    }
    async function searchHandler() {
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];

      // 로그인한 사용자가 어신 사용자인 경우 '답변자' 항목을 어신의 이름으로 고정 합니다.
      if (usinUserName.value) filter.value.answer_name = usinUserName.value;

      setSearchFilterCondition('nick_name');
      setSearchFilterCondition('title');
      setSearchFilterCondition('category_id');
      setSearchFilterCondition('show');
      setSearchFilterCondition('answer_state');
      setSearchFilterCondition('answer_name');
      setSearchFilterCondition('delete_state');
      setSearchFilterCondition('from_date');
      setSearchFilterCondition('to_date');
    }

    /**
     * 데이터 검색 조건 셋팅
     * @param filterKey
     * @param replaceFilterValue
     */
    function setSearchFilterCondition(
      filterKey: filterKeyType,
      replaceFilterValue?: string,
    ): void {
      if (filter.value[filterKey].trim() || replaceFilterValue) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? filter.value[filterKey],
        ]);
      } else if (
        // 페이지가 새로고침되었을 때 또는 뒤로가기로 왔을 때 url에 검색 관련 쿼리스트링이 있으면, 검색 조건에 추가 합니다.
        ((!filter.value[filterKey].trim() && route.query[filterKey]?.length) ||
          replaceFilterValue) &&
        userSearchEventFlag.value === IsUserSearchEventAt.N
      ) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? route.query[filterKey],
        ]);
        filter.value[filterKey] =
          replaceFilterValue ?? `${route.query[filterKey]}`;
      }
    }

    /**
     * Reset 버튼을 클릭했을 때 검색 조건을 초기화 합니다.
     */
    async function resetFilter() {
      filter.value.nick_name = '';
      filter.value.title = '';
      filter.value.answer_name = '';
      filter.value.show = '';
      filter.value.answer_state = '';
      filter.value.delete_state = '';
      filter.value.from_date = '';
      filter.value.to_date = '';

      userSearchEventFlag.value = IsUserSearchEventAt.Y;
      await searchHandler();
    }

    const downloadExcel = async () => {
      isLoading(true);

      await CrudModel.downloadExcel(
        filterConditions.value,
        orderOptions.value,
        1,
        10000,
      );

      isLoading(false);
    };

    onMounted(async () => {
      const askCategoriesResponse = await AskCategoriesModel.getAll(
        [],
        [],
        1,
        1000,
      );
      askCategories.value = askCategoriesResponse.items;

      await searchHandler();
    });

    return {
      asks,
      askCategories,
      loading,
      orderOptions,
      perpage,
      filterConditions,
      updateData,
      isLoading,
      CrudModel,
      deleteRecord,
      editRecord,
      AskShowStateCssStyle,
      AskAnswerStateCssStyle,
      dateTimeHelper,
      userSearchEventFlag,
      IsUserSearchEventAt,
      searchHandler,
      filter,
      resetFilter,
      downloadExcel,
      usinUserName,
    };
  },
});
