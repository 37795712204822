<template>
  <section class="admin-main-content">
    <div class="content-header">
      <h2>
        주문 상세
      </h2>
    </div>
    <div class="col-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>주문 코드</label>
                <input type="text" class="form-control" v-model="order.data_cd" disabled>
              </div>
              <div class="form-group">
                <label>주문자</label>
                <div class="d-flex flex-row">
                  <input type="text" class="form-control" v-model="order.customer_nm" disabled>
                  <button class="btn btn-primary admin-btn">바로가기</button>
                </div>
              </div>
              <div class="form-group">
                <label>주문상품</label>
                <div class="d-flex flex-row" v-for="product in order.order_products" :key="product.data_id">
                  <input type="text" class="form-control" v-model="product.name" disabled>
                  <button class="btn btn-primary admin-btn" @click="goProduct(product.data_id)">바로가기</button>
                </div>
                <div class="d-flex flex-row" v-for="product in order.order_products" :key="product.data_id">
                  <input type="text" class="form-control" v-model="product.quantity" disabled>
                  <input type="text" class="form-control" v-model="product.price_order" disabled>
                </div>
              </div>
              <div class="form-group">
                <label>주문금액</label>
                <input type="text" class="form-control" v-model="order.price_order" disabled>
              </div>
              <div class="form-group">
                <label>결제 방법</label>
                <input type="text" class="form-control" v-model="order.payment_method" disabled>
              </div>
              <div class="form-group" v-if="order.payment_method === '신용카드'">
                <label>결제 상태</label>
                <input type="text" class="form-control" v-model="order.payment_status" disabled>
              </div>
              <div class="form-group" v-else>
                <label>결제 상태</label>
                <div class="d-flex flex-row">
                  <input type="text" class="form-control" v-model="order.bank_payment_status" disabled>
                  <button v-if="order.bank_payment_status === '입금대기'" class="btn btn-primary admin-btn" @click="updateBankStatus('COMPLETED')" :disabled="order.bank_payment_status !== '입금대기'">입금확인</button>
                  <button v-if="order.bank_payment_status === '환불요청'" class="btn btn-primary admin-btn" @click="updateBankStatus('CANCEL_END')" :disabled="order.bank_payment_status !== '환불요청'">환불확인</button>
                </div>
              </div>
              <div class="form-group" v-if="order.payment_method !== '신용카드'">
                <label>입금자명</label>
                <input type="text" class="form-control" v-model="order.depositor_name" disabled>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">현금영수증요청</label>
                <select class="form-control" v-model="order.is_cash_receipt_req" disabled>
                  <option :value="true">현금영수증 요청</option>
                  <option :value="false">요청안함</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>배송지정보 - 상호(성명)</label>
                <input type="text" class="form-control" v-model="order.order_delivery.name" disabled>
              </div>
              <div class="form-group">
                <label>배송지정보 - 연락처</label>
                <input type="text" class="form-control" v-model="order.order_delivery.contact" disabled>
              </div>
              <div class="form-group">
                <label>배송지정보 - 우편번호</label>
                <input type="text" class="form-control" placeholder="012-3456-7890" v-model="order.order_delivery.post_code" disabled>
              </div>
              <div class="form-group">
                <label>배송지정보 - 주소</label>
                <input type="text" class="form-control" v-model="order.order_delivery.address" disabled>
              </div>
              <div class="form-group">
                <label>배송지정보 - 주소상세</label>
                <input type="text" class="form-control" v-model="order.order_delivery.address_detail" disabled>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer align-items-center">
          <div class="footer-btn-wrapper">
            <button type="submit" class="btn btn-lg btn-primary" @click="back">뒤로가기</button>
<!--            <button type="submit" class="btn btn-lg btn-default" @click="changeMode(true)">취소</button>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import swal from '@/helper/swal.helper';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const order = ref({
      data_id: '',
      data_cd: '',
      customer_user_id: '',
      customer_nm: '',
      price_order: 0,
      order_at: '',
      cancel_at: '',
      cancel_desc: '',
      payment_status: '',
      toss_payment_key: '',
      delivery_status: '',
      order_products: [
        {
          data_id: '',
          order_data_id: '',
          name: '',
          thumbnail_url: '',
          quantity: 1,
          price_order: 0,
          placing_orders: [
            {
              price_usin: 0,
              placing_order_req_at: '',
              delivery_company_cd: '',
              invoice_number: '',
              delivery_status: '',
              placing_order_res_at: '',
            },
          ],
        },
      ],
      order_delivery: {
        name: '',
        contact: '',
        post_code: '',
        address: '',
        address_detail: '',
      },
    });

    onMounted(async () => {
      const orderData = await getOrder(id);
      order.value = orderData;
    });

    const getOrder = async (id) => {
      const response = await apiService.get(`/partners-mall/order/${id}`);
      console.log(response.data.item);
      return response.data.item;
    };

    const updateBankStatus = (status) => {
      if (status === 'COMPLETED') {
        swal.updateConfirm(async () => {
          const response = await apiService.patch(`/partners-mall/order/${id}/bank-payment-status`, {
            bank_payment_status: status,
          });
          if (response.data.statusCode) {
            swal.successConfirm(async () => {
              const orderData = await getOrder(id);
              order.value = orderData;
            }, '입금이 확인되었습니다.');
          }
        }, '입금 내역을 확인하였습니까?');
      } else if (status === 'CANCEL_END') {
        swal.updateConfirm(async () => {
          const response = await apiService.patch(`/partners-mall/order/${id}/bank-payment-status`, {
            bank_payment_status: status,
          });
          if (response.data.statusCode) {
            swal.successConfirm(async () => {
              const orderData = await getOrder(id);
              order.value = orderData;
            }, '환불처리가 완료되었습니다.');
          }
        }, '환불 절차를 진행하셨습니까?');
      }
    };

    const back = () => {
      router.back();
    };
    const goProduct = (id) => {
      router.push(`/commerce/product/register/${id}`);
    };
    const goPartners = (id) => {
      router.push(`/commerce/product/register/${id}`);
    };
    return {
      id,
      order,
      back,
      goProduct,
      goPartners,
      updateBankStatus,
    };
  },
};
</script>

<style scoped>

</style>
