import { BaseV2Model } from "./base-v2.model";
import {BaseModel} from "./base.model";

export interface User {
    avatar: string,
    date_last_login: any,
    date_signup: any,
    email_address: string,
    fishing_type_sea: Array<string>,
    fishing_type_water: Array<string>,
    my_favorite_spots: Array<any>,
    nick_name: string,
    preferred_type: Array<string>,
    uid: string,
}

class UserModel extends BaseV2Model {
    protected collectionName = 'users'

    private static _instance: UserModel

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default UserModel.Instance