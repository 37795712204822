import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';
import firebase from 'firebase';
import firestore = firebase.firestore;
import Timestamp = firebase.firestore.Timestamp;

dayjs.locale('ko');
dayjs.extend(utc);

export default {
  dateToTimestamp(date: string) {
    return new Date(date).getTime();
  },
  timestampToDate(timestamp: number) {
    return new Date(timestamp * 1000).toISOString().slice(0, 10);
  },
  timestampToDateTime(millisecond: number, format = 'YYYY-MM-DD HH:mm:ss') {
    const date = dayjs(millisecond);
    return date.format(format);
  },
  firebaseToLocalDateTimeInput(timestamp: Timestamp) {
    const date = dayjs(timestamp.toMillis());
    return date.format('YYYY-MM-DDTHH:mm:ss');
  },
  formatFirebaseTimestamp(timestamp: Timestamp, format = 'YYYY-MM-DD HH:mm:ss') {
    const date = dayjs(timestamp.toMillis());
    return date.format(format);
  },
  toFirebaseTimestamp(date: string) {
    const formattedValue = dayjs(date).toDate();
    return firestore.Timestamp.fromDate(formattedValue);
  },
  timestampMilisToDateTimeInput(timestamp: number, format = 'YYYY-MM-DDTHH:mm:ss') {
    const date = dayjs(timestamp);
    return date.format(format);
  },

  /**
   * 서버로부터 날짜 필드가 dayjs의 toString()로 반환되면 아래 포멧으로 변환합니다.
   * @param date
   */
  dayjsStringToString(date: string, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!date) return undefined;

    if (process.env.VUE_APP_BACKEND_API_URI.indexOf('localhost') > 0) return dayjs(date).format(format); // 로컬에서 사용 합니다.
    return dayjs.utc(date).utcOffset(0).format(format); // 개발/운영서버에서 사용합니다.
  },
};
