import swal from 'sweetalert2';
import { ref } from 'vue';

export default {
  createConfirm(onConfirm: any, title = '저장하시겠습니까?') {
    swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '저장',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  },
  updateConfirm(onConfirm: any, title = '수정된 내용을 저장하시겠습니까?') {
    swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '저장',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  },
  deleteConfirm(onConfirm: any, title = '해당 데이터를 삭제하시겠습니까?') {
    swal.fire({
      title,
      icon: 'warning',
      // html: '삭제하려면 사업자이름을 입력해주세요.',
      showCancelButton: true,
      confirmButtonText: '삭제',
      showLoaderOnConfirm: true,
      cancelButtonText: '취소',
      preConfirm: onConfirm,
    });
  },
  deleteTitleConfirm(onConfirm: any, title = '해당 데이터를 삭제하시겠습니까?') {
    swal.fire({
      title,
      html: '삭제하려면 사업자이름을 입력해주세요.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: '삭제',
      showLoaderOnConfirm: true,
      cancelButtonText: '취소',
      preConfirm: onConfirm,
    });
  },
  successConfirm(onConfirm: any, html = '저장되었습니다.') {
    swal.fire(
      '성공',
      html,
      'success',
    ).then(onConfirm);
  },
  deletedConfirm(onConfirm: any) {
    swal.fire(
      '성공',
      '삭제되었습니다.',
      'success',
    ).then(onConfirm);
  },
  deleteFail() {
    swal.fire({
      icon: 'error',
      title: '삭제 실패',
      text: '사업주명이 틀렸습니다.',
    });
  },
  alert(msg: string) {
    swal.fire({
      icon: 'error',
      title: '실패',
      text: msg,
    });
  },
  dowloadXlsx(onConfirm: any, title = '발주신청이 되었습니다. 엑셀을 다운로드합니다.') {
    swal.fire({
      title,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: '저장',
    }).then((result) => {
      onConfirm();
    });
  },
  async partnersApproveConfirm(onConfirm: any, title = '승인하시겠습니까?') {
    const { isConfirmed } = await swal.fire({
      title,
      html: '승인된 파트너스는 가입상태로 변경할 수 없습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '승인',
    });
    if (isConfirmed) {
      onConfirm();
    }
  },

  async partnersSuccessConfirm(onConfirm: any, title = '성공 하였습니다.') {
    const { isConfirmed } = await swal.fire(
      '성공',
      title,
      'success',
    );

    if (isConfirmed) {
      onConfirm();
    }
  },

  async partnersRejectConfirm(onConfirm: any, title = '반려하시겠습니까?') {
    // 반려사유가 '기타' 일 경우 반려사유를 수기 입력할 수 있도록 이벤트를 추가 합니다.
    let textareaElement: any;
    document.addEventListener('change', (e: any) => {
      if (e?.target?.id === 'reject_option' && e?.target?.className === 'swal2-select') {
        if (e?.target?.value === '기타') {
          textareaElement = document.querySelector('#reject_option.swal2-textarea');
          textareaElement?.setAttribute('style', '');
        } else {
          textareaElement?.setAttribute('style', 'display: none;');
        }
      }
    }, false);

    const { isConfirmed, value: reject_remark } = await swal.fire({
      title,
      input: 'select',
      inputAttributes: {
        id: 'reject_option',
      },
      inputOptions: {
        '동일한 가입자 번호가 있습니다.': '동일한 가입자 번호가 있습니다.',
        '사업자 정보가 유효하지 않습니다.': '사업자 정보가 유효하지 않습니다.',
        '제출하신 서류가 유효하지 않습니다.': '제출하신 서류가 유효하지 않습니다.',
        기타: '기타',
      },
      inputPlaceholder: '반려사유를 선택해 주세요.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '반려',
      inputValidator: (value) => new Promise((resolve) => {
        if (value === '') {
          resolve('반려사유를 선택해 주세요.');
        } else if (value === '기타' && textareaElement?.value === '') {
          resolve('반려사유를 입력해 주세요.');
        } else {
          resolve(null);
        }
      }),
    });

    if (isConfirmed) {
      if (reject_remark === '기타') {
        onConfirm(textareaElement.value);
      } else {
        onConfirm(reject_remark);
      }
    }
  },

  success(msg: string) {
    swal.fire({
      icon: 'success',
      title: '성공',
      text: msg,
    });
  },
  simpleDeleteConfirm(onConfirm: any, title = '해당 데이터를 삭제하시겠습니까?') {
    swal.fire({
      title,
      showCancelButton: true,
      confirmButtonText: '삭제',
      showLoaderOnConfirm: true,
      cancelButtonText: '취소',
      preConfirm: onConfirm,
    });
  },
  prizeConfirm(onConfirm: any, title = '청약 추첨을 진행하시겠습니까?') {
    swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '추첨',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  },
  closeConfirm(onConfirm: any, title = '추첨을 마감하시겠습니까?') {
    swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '마감',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  },
  prizeUserConfirm(onConfirm: any, title = '') {
    swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '발송',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  },
  confirm(onConfirm: any, options: any) {
    swal.fire({
      title: options.title,
      text: options.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: options.buttonText,
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  },
  inputConfirm(onConfirm: any, options: any) {
    swal.fire({
      title: options.title,
      text: options.text,
      icon: 'warning',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: options.buttonText,
      cancelButtonText: '취소',
    }).then((result) => {
      const text = result.value;
      if (result.isConfirmed) {
        onConfirm(text);
      }
    });
  },
  htmlConfirm(onConfirm: any, options: any) {
    swal.fire({
      title: options.title,
      html: options.html,
      icon: 'warning',
      // input: 'text',
      showCancelButton: true,
      confirmButtonText: options.buttonText,
      cancelButtonText: '취소',
      didOpen: options.didOpen ?? null,
      preConfirm: options.preConfirm ?? null,
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        onConfirm(result.value);
      }
    });
  },
  message(message: string) {
    swal.fire({
      position: 'center',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1000,
    });
  },
  showValidationMessage(message: string) {
    swal.showValidationMessage(message);
  },
};
