import { BaseV2Model } from './base-v2.model';

class PartnersNoticeModel extends BaseV2Model {
    protected collectionName = 'partners/notice';

    protected keepCollectionName = true;

    private static _instance: PartnersNoticeModel;

    public static get Instance() {
      return this._instance || (this._instance = new this());
    }
}

export default PartnersNoticeModel.Instance;
