<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>포스트 카테고리</label>
            <select
              class="form-control"
              v-model="post.category_data_id"
              required
              :disabled="id && isDetail"
            >
              <option value="">카테고리를 선택하세요.</option>
              <option
                v-for="category in categoryList"
                :key="category.data_id"
                :value="category.data_id"
                >{{ category.name }}</option
              >
            </select>
          </div>
          <div class="form-group">
            <label>포스트 제목</label>
            <input
              type="text"
              class="form-control"
              v-model="post.title"
              placeholder="제목을 입력하세요"
              required
              :disabled="id && isDetail"
            />
          </div>
          <div class="form-group">
            <label>컨텐츠 Url</label>
            <input
              type="text"
              class="form-control"
              v-model="post.contents_url"
              placeholder="등록 후 생성됩니다."
              required
              disabled
            />
          </div>
          <div class="form-group grid">
            <label>내용</label>
            <!-- <editor ref="customEditor"></editor> -->
          </div>
          <editor ref="customEditor" :set-contents="post.content"></editor>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>포스트 썸네일 이미지</label>
            <div class="d-flex flex-row">
              <input
                type="text"
                class="form-control"
                placeholder="File name"
                v-model="post.thumbnail_url"
                disabled
              />
              <input
                style="display: none;"
                ref="thumbnailFile"
                type="file"
                accept="image/*"
                @change="uploadThumbnailFile($event)"
              />
              <button
                class="btn btn-primary admin-btn"
                :disabled="id && isDetail"
                @click="openThumbnailImage"
              >
                파일찾기
              </button>
            </div>
          </div>
          <div class="form-group">
            <label>포스트 썸네일 미리보기</label>
          </div>
          <img
            ref="thumbnailImg"
            class="thumbnail-img"
            v-show="showThumbnailImage"
            :src="post.thumbnail_url + '?w=355'"
            alt=""
          />
          <div class="form-group">
            <label>노출순서</label>
            <input
              type="number"
              class="form-control"
              v-model="post.show_home_idx"
            />
          </div>
          <div class="form-group">
            <label>포스트 게시일시</label>
            <input
              class="form-control"
              v-model="post.published_at"
              type="datetime-local"
              required
            />
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label>이벤트 시작일시</label>
              <input
                class="form-control"
                v-model="post.event_period_from"
                type="datetime-local"
              />
            </div>
            <div class="col-md-6">
              <label>이벤트 종료일시</label>
              <input
                class="form-control"
                v-model="post.event_period_to"
                type="datetime-local"
              />
            </div>
          </div>
          <label>노출여부</label>
          <div class="form-group">
            <label class="c-switch c-switch-pill c-switch-primary">
              <input
                type="checkbox"
                class="c-switch-input"
                v-model="post.is_active"
                :disabled="id && isDetail"
              />
              <span class="c-switch-slider"></span>
            </label>
          </div>
          <div class="form-group">
            <div class="col-sm-9">
              <label>웹 뷰로 열기(Off) / 웹 브라우저 링크로 열기(On)</label>
              <div class="form-group">
                <label class="c-switch c-switch-pill c-switch-primary">
                  <input
                    type="checkbox"
                    class="c-switch-input"
                    :checked="
                      post.view_type === ContentViewTypes.WEB_BROWSER_LINK
                    "
                    @change="toggleViewType"
                    :disabled="id && isDetail"
                  />
                  <span class="c-switch-slider"></span>
                </label>
              </div>
            </div>
          </div>
          <label>조회수 노출여부</label>
          <div class="form-group">
            <label class="c-switch c-switch-pill c-switch-primary">
              <input
                type="checkbox"
                class="c-switch-input"
                v-model="post.is_show_view_count"
                :disabled="id && isDetail"
              />
              <span class="c-switch-slider"></span>
            </label>
          </div>
          <label>홈 화면 노출 여부</label>
          <div class="form-group">
            <label class="c-switch c-switch-pill c-switch-primary">
              <input
                type="checkbox"
                class="c-switch-input"
                v-model="post.is_show_home"
                :disabled="id && isDetail"
              />
              <span class="c-switch-slider"></span>
            </label>
          </div>
          <label>화면 좌우 여백 없애기</label>
          <div class="form-group">
            <label class="c-switch c-switch-pill c-switch-primary">
              <input
                type="checkbox"
                class="c-switch-input"
                v-model="post.is_no_margin_layout"
                :disabled="id && isDetail"
              />
              <span class="c-switch-slider"></span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <div v-if="!id" class="footer-btn-wrapper">
          <button type="submit" class="btn btn-lg btn-primary" @click="submit">
            저장
          </button>
          <button type="submit" class="btn btn-lg btn-default" @click="back">
            취소
          </button>
        </div>
        <div v-else-if="id && isDetail" class="footer-btn-wrapper">
          <button
            type="submit"
            class="btn btn-lg btn-primary"
            @click="changeMode(false)"
          >
            수정
          </button>
          <button
            type="submit"
            class="btn btn-lg btn-danger"
            @click="deletePost"
          >
            삭제
          </button>
          <button type="submit" class="btn btn-lg btn-default" @click="back">
            목록
          </button>
        </div>
        <div v-else class="footer-btn-wrapper">
          <button type="submit" class="btn btn-lg btn-primary" @click="submit">
            저장
          </button>
          <button
            type="submit"
            class="btn btn-lg btn-default"
            @click="changeMode(true)"
          >
            취소
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue';
import { ApiService } from '@/services/backend-api/api.services';
import { useRoute, useRouter } from 'vue-router';
import commerceUtil from '@/helper/commerceUtil';
import { ContentViewTypes } from '../../config/constant.config';
import Editor from '../../components/ui/Editor.vue';
import swal from '../../helper/swal.helper';

export default defineComponent({
  components: {
    Editor,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const { id } = route.params;
    const isDetail = ref(true);
    const isSubmitting = ref(false);
    const thumbnailFile = ref(null);
    const thumbnailImg = ref(null);
    const uploadThumbnailImage = ref(false);
    const showThumbnailImage = ref(false);
    const thumbnailImageInfo = ref({
      extension: '',
      imageName: '',
      imageFile: {},
    });
    const post = ref({
      category_data_id: '',
      title: '',
      content: '',
      published_at: '',
      event_period_from: '',
      event_period_to: '',
      contents_url: '',
      landing_url: '',
      show_home_idx: 1,
      view_type: ContentViewTypes.WEB_VIEW,
      thumbnail_url: '',
      is_active: true,
      is_no_margin_layout: true,
      is_show_home: true,
      is_show_view_count: true,
    });

    const categoryList = ref([]);
    const customEditor = ref();

    const toggleViewType = () => {
      post.value.view_type =
        post.value.view_type === ContentViewTypes.WEB_VIEW
          ? ContentViewTypes.WEB_BROWSER_LINK
          : ContentViewTypes.WEB_VIEW;
    };

    const getPost = async () => {
      const response = await apiService.get(`/partners/post/${id}`);

      return response.data.item;
    };

    const getCategoryList = async () => {
      const response = await apiService.get('/partners/post-category', {
        page: 0,
        size: 100,
        is_active: true,
      });
      return response.data.items;
    };
    const getPresignedUrl = async fileName => {
      const response = await apiService.get(
        `/external/aws/s3/presignedUrl?serviceDomainType=partners&filePath=post&fileNm=${fileName}`,
      );
      return response.data.item.pre_signed_url;
    };
    const uploadImage = async (url, file) =>
      await apiService.uploadImage(url, file);
    const uploadThumbnail = async () => {
      const imageName = `${commerceUtil.getUuid()}.${
        thumbnailImageInfo.value.extension
      }`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(
        presignedUrl,
        thumbnailImageInfo.value.imageFile,
      );
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? 'https://cdn.uhshin.com/partners/post/'
          : 'https://cdn.uhshin.com/development/partners/post/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      post.value.thumbnail_url = `${imageServer}${imageName}`;
    };

    const uploadThumbnailFile = event => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        thumbnailImg.value.src = e?.target?.result;
        showThumbnailImage.value = true;
      };

      reader.readAsDataURL(file);
      uploadThumbnailImage.value = true;

      if (file) {
        post.value.thumbnail_url = file.name;
        thumbnailImageInfo.value.extension = file.name.split('.').reverse()[0];
        thumbnailImageInfo.value.imageName = file.name;
        thumbnailImageInfo.value.imageFile = file;
      }
    };
    const uploadPostImage = async file => {
      const extension = file.name.split('.').reverse()[0];
      const imageName = `${commerceUtil.getUuid()}.${extension}`;
      const presignedUrl = await getPresignedUrl(imageName);
      const res = await uploadImage(presignedUrl, file);
      const imageServer =
        process.env.VUE_APP_MODE === 'production'
          ? 'https://cdn.uhshin.com/partners/post/'
          : 'https://cdn.uhshin.com/development/partners/post/';
      // const imageServer = `https://cdn.uhshin.com/development/partnersMall/product/${product.value.seller_data_cd}/`;
      return `${imageServer}${imageName}`;
    };

    const checkValidate = () => {
      if (!post.value) {
        swal.alert('포스트 데이터가 유효하지 않습니다.');
        return false;
      }

      if (!post.value.title || post.value.title.trim() === '') {
        swal.alert('포스트 제목이 입력되지 않았습니다.');
        return false;
      }

      if (
        !post.value.category_data_id ||
        post.value.category_data_id.trim() === ''
      ) {
        swal.alert('포스트 카테고리가 선택되지 않았습니다.');
        return false;
      }

      if (post.value.content.trim() == '') {
        swal.alert('포스트 내용이 입력되지 않았습니다.');
        return false;
      }

      if (!post.value.thumbnail_url || post.value.thumbnail_url.trim() === '') {
        swal.alert('썸네일 이미지가 선택되지 않았습니다.');
        return false;
      }

      post.value.show_home_idx = Number(post.value.show_home_idx);
      if (post.value.show_home_idx <= 0) {
        swal.alert('노출순서는 0보다 커야합니다.');
        return false;
      }

      if (!post.value.published_at || post.value.published_at.trim() === '') {
        swal.alert('포스트 게시일자가 입력되지 않았습니다.');
        return false;
      }

      return true;
    };

    onMounted(async () => {
      if (id) {
        try {
          const postData = await getPost(id);
          showThumbnailImage.value = true;

          // 비동기 내용 처리
          postData.content = await postData.content;

          post.value = postData;
        } catch (error) {
          console.error('getPost 호출 중 오류 발생:', error);
        }
      }

      try {
        categoryList.value = await getCategoryList();
      } catch (error) {
        console.error('getCategoryList 호출 중 오류 발생:', error);
      }
    });

    const openThumbnailImage = () => {
      thumbnailFile.value.click();
    };
    const back = () => {
      router.back();
    };
    const changeMode = mode => {
      isDetail.value = mode;
    };

    const submit = async () => {
      post.value.content = await customEditor.value.getContents();

      if (!checkValidate()) {
        return false;
      }

      if (!id) {
        swal.createConfirm(create);
      } else {
        swal.updateConfirm(update);
      }
    };

    const create = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      const response = await apiService.post('/partners/post/', post.value);
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        swal.successConfirm(async () => {
          router.replace('/partners-post');
        });
      } else {
        console.error(response);
      }
    };
    const update = async () => {
      if (uploadThumbnailImage.value) {
        await uploadThumbnail();
      }
      const response = await apiService.put(`/partners/post/${id}`, post.value);
      if (
        response.data.statusCode === 201 ||
        response.data.statusCode === 200
      ) {
        swal.successConfirm(async () => {
          router.push('/partners-post');
        });
      } else {
        console.error(response);
      }
    };
    const deletePost = async () => {
      swal.deleteConfirm(async () => {
        const response = await apiService.delete(`/partners/post/${id}`);
        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          swal.deletedConfirm(async () => {
            router.push('/partners-post');
          });
        } else {
          console.error(response);
        }
      });
    };
    return {
      id,
      isDetail,
      post,
      isSubmitting,
      thumbnailFile,
      thumbnailImg,
      showThumbnailImage,
      submit,
      uploadThumbnailFile,
      openThumbnailImage,
      back,
      changeMode,
      customEditor,
      categoryList,
      deletePost,
      ContentViewTypes,
      toggleViewType,
    };
  },
});
</script>
<style scoped>
.thumbnail-img {
  width: 335px;
}
</style>
