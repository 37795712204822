import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import UsinMain from '@/views/usin/Index.vue';
import UsinSubscription from '@/views/usin/subscription/Index.vue';
import UsinSubscriptionAdd from '@/views/usin/subscription/Add.vue';
import UsinSubscriptionDetail from '@/views/usin/subscription/Detail.vue';
import AccessDenied from '../components/pages/403.vue';
import PageNotFound from '../components/pages/404.vue';
import PartnersDashboard from '../views/partners/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/usin',
    name: 'UsinMain',
    component: UsinMain,
    meta: {
      text: '어신 커머스 메인', layout: 'usin', middleware: guest, serverRoute: '', group: '어신 관리',
    },
  },
  {
    path: '/usin/subscription',
    name: 'UsinSubscription',
    component: UsinSubscription,
    meta: {
      text: '어신 청약 목록',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '어신 청약몰',
    },
  },
  {
    path: '/usin/subscription/detail/:id',
    name: 'UsinSubscriptionDetail',
    component: UsinSubscriptionDetail,
    meta: {
      text: '어신 청약 상세',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '어신 청약몰',
    },
  },
  {
    path: '/usin/subscription/add/:id',
    name: 'UsinSubscriptionAdd',
    component: UsinSubscriptionAdd,
    meta: {
      text: '어신 청약 등록/수정',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '어신 청약몰',
    },
  },
  {
    path: '/usin/subscription/edit/:id',
    name: 'UsinSubscriptionEdit',
    component: UsinSubscriptionAdd,
    meta: {
      text: '어신 청약 등록/수정',
      layout: 'default',
      middleware: guest,
      serverRoute: '',
      group: '어신 청약몰',
    },
  },
];

export default routes;
