
import PaginationV2 from '@/components/ui/Pagination-V2.vue';
import ElementStyleHelper, {
  UserJoinStateCssStyle,
  UserPaidCssStyle,
} from '@/helper/element-style.helper';
import numberHelper from '@/helper/number.helper';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  BooleanTypes,
  BusinessTypes,
  constants,
  IsUserSearchEventAt,
  UserJoinStateCode,
  UserPaidCode,
} from '../../config/constant.config';
import dateTimeHelper from '../../helper/datetime.helper';
import CrudModel from '../../models/partnersProduct.model';
import { ApiService } from '../../services/backend-api/api.services';

export default defineComponent({
  name: 'PartnersProductList',
  props: {},
  components: {
    PaginationV2,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const products = ref<Array<any>>([]);
    const apiService = new ApiService();
    const loading = ref(false);
    const userSearchEventFlag = ref<IsUserSearchEventAt>(IsUserSearchEventAt.N);
    const filter = ref({
      business_type_cd: '',
      product_name: '',
      partnersId: '',
      partners_name: '',
    });
    type filterKeyType =
      | 'business_type_cd'
      | 'product_name'
      | 'partnersId'
      | 'partners_name';
    const perpage = constants().DEFAULT_PERPAGE;

    const orderOptions = ref([['create_at', 'desc']]);
    const filterConditions = ref<Array<any>>([]);

    onMounted(async () => {
      await searchHandler();
    });
    const updateData = (items: Array<any>) => {
      loading.value = false;
      products.value = items;
    };
    const isLoading = (value: boolean) => {
      loading.value = value;
    };

    const editRecord = (params: {
      data_id: number;
      business_type_cd: string;
    }) => {
      router.push({
        path: `/partners-product/edit/${params.business_type_cd}/${params.data_id}`,
        query: route.query,
      });
    };

    async function searchHandler() {
      if (userSearchEventFlag.value === IsUserSearchEventAt.Y) {
        route.query.page = '1';
      }
      filterConditions.value = [];

      setSearchFilterCondition('business_type_cd');
      setSearchFilterCondition('product_name');
      setSearchFilterCondition('partnersId');
      setSearchFilterCondition('partners_name');
    }

    /**
     * 데이터 검색 조건 셋팅
     * @param filterKey
     * @param replaceFilterValue
     */
    function setSearchFilterCondition(
      filterKey: filterKeyType,
      replaceFilterValue?: string,
    ): void {
      if (filter.value[filterKey].trim() || replaceFilterValue) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? filter.value[filterKey],
        ]);
      } else if (
        // 페이지가 새로고침되었을 때 또는 뒤로가기로 왔을 때 url에 검색 관련 쿼리스트링이 있으면, 검색 조건에 추가 합니다.
        ((!filter.value[filterKey].trim() && route.query[filterKey]?.length) ||
          replaceFilterValue) &&
        userSearchEventFlag.value === IsUserSearchEventAt.N
      ) {
        filterConditions.value.push([
          [filterKey],
          replaceFilterValue ?? route.query[filterKey],
        ]);
        filter.value[filterKey] =
          replaceFilterValue ?? `${route.query[filterKey]}`;
      }
    }

    /**
     * Reset 버튼을 클릭했을 때 검색 조건을 초기화 합니다.
     */
    async function resetFilter() {
      filter.value.business_type_cd = '';
      filter.value.product_name = '';
      filter.value.partnersId = '';
      filter.value.partners_name = '';

      userSearchEventFlag.value = IsUserSearchEventAt.Y;
      await searchHandler();
    }

    return {
      products,
      editRecord,
      dateTimeHelper,
      numberHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      CrudModel,
      searchHandler,
      resetFilter,
      IsUserSearchEventAt,
      userSearchEventFlag,
      BooleanTypes,
      BusinessTypes,
      UserJoinStateCode,
      UserPaidCode,
      UserPaidCssStyle,
      UserJoinStateCssStyle,
      ElementStyleHelper,
    };
  },
});
