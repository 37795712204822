const state = () => ({
    submitLoading: false,
    disablePrev: false,
    disableNext: false,
    currentPage: 1
})

// getters
const getters = {
    
}

// actions
const actions = {
    setSubmitLoading (context: any, requestData: any) {
        context.commit('setSubmitLoading', requestData);
    },

    setDisablePrev (context: any, requestData: any) {
        context.commit('setDisablePrev', requestData);
    },

    setDisableNext (context: any, requestData: any) {
        context.commit('setDisableNext', requestData);
    },

    setCurrentPage (context: any, requestData: any) {
        context.commit('setCurrentPage', requestData);
    },
}

// mutations
const mutations = {
    setSubmitLoading (state: any, submitLoading: any) {
        state.submitLoading = submitLoading
    },

    setDisablePrev (state: any, disablePrev: any) {
        state.disablePrev = disablePrev
    },

    setDisableNext (state: any, disableNext: any) {
        state.disableNext = disableNext
    },

    setCurrentPage (state: any, currentPage: any) {
        state.currentPage = currentPage
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};