
import { defineComponent, ref } from 'vue';
import {
  default as FishingTubesModel,
  FishingTubes,
} from '../../models/fishingTubes.model';
import dateTimeHelper from '../../helper/datetime.helper';
import numberHelper from '../../helper/number.helper';
import { useRouter } from 'vue-router';
import Pagination from '../../components/ui/Pagination.vue';
import { constants } from '../../config/constant.config';
import swal from '../../helper/swal.helper';

export default defineComponent({
  name: 'FishingTubesList',
  props: {},
  components: {
    Pagination,
  },
  setup: () => {
    const router = useRouter();
    let fishingTubes = ref<FishingTubes[]>([]);
    let categories = ref<any>([]);
    let loading = ref(false);
    let filter = ref({
      name: '',
      show: null,
      category: null,
      created: '',
    });
    const perpage = constants().DEFAULT_PERPAGE;

    let orderOptions = ref([['created_at', 'desc']]);
    let filterConditions = ref<Array<any>>([]);
    function updateData(items: FishingTubes[]) {
      loading.value = false;
      fishingTubes.value = items;
    }
    function isLoading(value: boolean) {
      loading.value = value;
    }

    async function deleteTube(id: any) {
      swal.simpleDeleteConfirm(async function() {
        await FishingTubesModel.delete(id);
        fishingTubes.value = await FishingTubesModel.getAll();
      });
    }

    async function editTube(id: any) {
      router.push('/fishing-tubes/edit/' + id);
    }

    async function searchHandler() {
      filterConditions.value = [];
      if (filter.value.name && filter.value.name.length > 0) {
        filterConditions.value.push(['name', filter.value.name]);
      }

      if (filter.value.show !== null) {
        filterConditions.value.push(['show', filter.value.show]);
      }

      if (filter.value.created !== null && filter.value.created.length > 0) {
        filterConditions.value.push([
          'created_at',
          dateTimeHelper.dateToTimestamp(filter.value.created),
        ]);
      }
    }

    return {
      fishingTubes,
      deleteTube,
      editTube,
      dateTimeHelper,
      loading,
      filter,
      orderOptions,
      filterConditions,
      updateData,
      isLoading,
      perpage,
      FishingTubesModel,
      searchHandler,
      categories,
      numberHelper,
    };
  },
});
