
import { defineComponent, ref, onMounted, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import commerceUtil from '@/helper/commerceUtil';
import { Product } from '@/models/commercePromotion.model';
// import Pagination from '@/components/ui/NewPagination.vue';

export default {
  name: 'PartnersMallPromotionList',
  components: {
    // Pagination,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const productList: Ref<Product[]> = ref([]);
    const { page = 0 } = route.query;
    const pageSize = ref(10);
    const total = ref(0);
    const totalPage = ref(0);
    const currentPage = ref(page);
    onMounted(async () => {
      productList.value = await getProductList();
    });
    const getProductList = async () => {
      const response = await apiService.get(
        '/partners-mall/product-promotion',
        {
          page: currentPage.value,
          size: pageSize.value,
        },
      );
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };
    const viewDetail = (id: string) => {
      router.push(`/commerce/promotion/register/${id}`);
    };
    const goResgiter = () => {
      router.push('/commerce/promotion/register/new');
    };
    const loadPage = async (page: number) => {
      currentPage.value = page;
      productList.value = await getProductList();
      router.push({
        path: '/commerce/promotion',
        params: {
          page,
        },
      });
    };
    const getCurruncy = (price: number) => commerceUtil.getCurrency(price);
    const checkActive = (dataId: string, e: any) => {};
    return {
      currentPage,
      pageSize,
      totalPage,
      productList,
      viewDetail,
      goResgiter,
      getCurruncy,
      loadPage,
      checkActive,
    };
  },
};
