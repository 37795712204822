export interface CreatedAt {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
}

export const initialCreatedAt: CreatedAt = {
  year: '',
  month: '',
  day: '',
  hour: '',
  minute: '',
};
