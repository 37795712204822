
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SubmitButton from '../../components/ui/SubmitButton.vue';
import { BooleanTypes } from '../../config/constant.config';
import swal from '../../helper/swal.helper';
import Model from '../../models/partnersCommonCode.model';

export default defineComponent({
  components: {
    SubmitButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const use_yn_bool = ref(true);
    const isSubmitting = ref(false);
    const code = ref<string>('');
    const code_type = ref<string>('');
    const code_kor_name = ref('');
    const order_idx = ref<number>(0);
    const saved_code_types = ref<string[]>([
      '',
    ]);

    onMounted(async () => {
      const response = await Model.getCommonCodeTypes();
      if (response.status !== 200) { console.log(response); return undefined; }
      saved_code_types.value = response.data.item.code_types;
    });

    async function submitForm() {
      isSubmitting.value = true;

      const addResult = await Model.bulkUpdate([{
        code: code.value,
        code_type: code_type.value,
        code_kor_name: code_kor_name.value,
        use_yn: use_yn_bool.value ? BooleanTypes.Y : BooleanTypes.N,
        delete_yn: BooleanTypes.N,
        order_idx: +order_idx.value,
      }]);
      if (addResult.status) {
        router.push({
          path: '/partners-common-code',
          query: route.query,
        });
        isSubmitting.value = false;
      } else {
        swal.alert(addResult.message);
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      code,
      code_type,
      code_kor_name,
      use_yn_bool,
      isSubmitting,
      order_idx,
      saved_code_types,
    };
  },
});
