import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Test from '../views/default/index.vue';
import auth from '../middleware/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/default',
    name: 'Default',
    component: Test,
    meta: {
      text: 'Default', layout: 'new', middleware: auth, serverRoute: '', group: 'Default',
    },
  },
];

export default routes;
