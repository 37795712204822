import {BaseModel} from "./base.model";

class NotificationModel extends BaseModel {
    protected collectionName = 'notifications'
    protected timestamp = true

    private static _instance: NotificationModel

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default NotificationModel.Instance