
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiService } from '@/services/backend-api/api.services';
import commerceUtil from '@/helper/commerceUtil';

export default {
  name: 'PartnersFishingResultList',
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const apiService = new ApiService();
    const orderList = ref(null);
    const { page = 0 } = route.query;
    const pageSize = ref(30);
    const total = ref(0);
    const totalPage = ref(0);
    const currentPage = ref(page);
    onMounted(async () => {
      orderList.value = await getOrderList();
      console.log(orderList.value);
    });
    const getOrderList = async () => {
      const response = await apiService.get('/partners-mall/order', {
        page: currentPage.value,
        size: pageSize.value,
      });
      total.value = response.data.total;
      totalPage.value = Math.ceil(total.value / pageSize.value);
      return response.data.items;
    };
    const viewDetail = (id: string) => {
      console.log(id);
      router.push(`/commerce/order/detail/${id}`);
    };
    const loadPage = async (page: number) => {
      console.log('loadPage', page);
      currentPage.value = page;
      orderList.value = await getOrderList();
      router.push({
        path: '/commerce/order',
        query: {
          page,
        },
      });
    };
    const getCurruncy = (price: number) => commerceUtil.getCurrency(price);
    return {
      orderList,
      viewDetail,
      getCurruncy,
      currentPage,
      pageSize,
      totalPage,
      loadPage,
    };
  },
};
