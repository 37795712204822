
import { defineComponent } from "vue";
import exAxios from "../../services/maps/exios.services";
import Loading from "../../components/ui/Loading.vue";
import mapManager from "../../services/maps/mapManager.services";
declare let naver: any;

export default defineComponent({
	name: "Map",
	components: { Loading },
	data() {
		return {
			observers: [
				{ value: "", label: "조회" },
				{ value: "ALL", label: "전체" },
				{ value: "SGMA_POINT", label: "SGMA POINT" },
				{ value: "DIFF", label: "개정수" },
			],
			windowHeight: 0,
			isLoading: false,
			type: "",
			map: {} as any,
			searchResults: [],
			totalPointsCount: 0,
			mapManagerController: {} as any
		};
	},
	computed: {
		fullHeight(): string {
			return `height:${this.windowHeight}px`;
		},
		checkTotalPoints(): any {
			return (
				this.mapManagerController != null &&
				this.mapManagerController.getSpots() != null &&
				this.mapManagerController.getSpots().length > 0
			);
		},
	},
	mounted() {
    console.log('mounted');
		this.checkWindowHeight();
		this.loadNaverMapFromCDN();
		this.loadSpots();
	},
	methods: {
		gotoPoint(gotoPoint: any) {
			this.mapManagerController.gotoPoint(gotoPoint);
		},
		changeSearchKey(searchKey: any) {
      const str = searchKey.target.value;
			if (this.checkTotalPoints) {
				this.searchResults = this.mapManagerController
					.getSpots()
					.filter((spot: { name: string|any[]; }) => spot.name.indexOf(str) != -1);
			}
		},
		loadNaverMapFromCDN() {
      const scriptTag = document.createElement("script");
      scriptTag.src =
          "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=rsqbygeix2";
      scriptTag.onload = this.loadMap;
      document.getElementsByTagName("body")[0].appendChild(scriptTag);
		},
		checkWindowHeight() {
			this.windowHeight = document.body.offsetHeight;
			this.windowHeight = this.windowHeight - 50;
		},
		loadMap() {
			const mapOptions = {
				useStyleMap: true,
				center: new naver.maps.LatLng(33.38, 126.55),
				zoom: 11,
			};
			this.map = new naver.maps.Map("mapContainer", mapOptions);
			this.mapManagerController = mapManager(this.map, this);
			this.mapManagerController.mapClickEvent();
		},
		removeMarkerAll() {
			this.mapManagerController.removeMarkerAll();
		},
		loadingAction(ck: boolean) {
			this.isLoading = ck;
		},
		loadSpots() {
			this.isLoading = true;
			this.map.m;
			// this.mapManagerController = new mapManager(this.map, this);
			exAxios.get(
				"/api/ocean/port",{},
				(res: { status: number; data: { result: string|any[]; }; }) => {
          console.log('res', res);
					if (
						res.status == 200 &&
						res.data &&
						res.data.result &&
						res.data.result.length > 0
					) {
            console.log(this.mapManagerController);
						this.mapManagerController.mapClickEvent();
						this.mapManagerController.makeMarkerFromSpots(res.data.result);
						this.totalPointsCount = res.data.result.length;
						this.isLoading = false;
					}
				},
				(error: any) => {
					console.log(error);
				}
			);
		},
	},
});
