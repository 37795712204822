import { ContentViewTypes } from '../config/constant.config';
import { BaseModel } from './base.model';

export interface Post {
	category_id: string,
	id: string,
	title: string,
	desc: string,
	contents_url: string,
	landing_url: string,
	pin: boolean,
	show: boolean,
	thumbnail: string,
	home_thumbnail: string,
	published_at: number,
	created_at: number,
	updated_at: number,
	order: number,
	view_count: number,
	show_view_count: boolean,
	show_home: boolean,
	show_home_order: number,
	show_more: boolean,
	show_more_order: number,
	event_period_from: number,
	event_period_to: number,
	no_margin_layout: boolean,
  view_type: ContentViewTypes,
}

export interface AppPushServicePostParams {
page: number,
pageSize: number,
show_home: boolean,
}

class PostModel extends BaseModel {
	protected collectionName = 'posts'

	private static _instance: PostModel

	public static get Instance() {
	  return this._instance || (this._instance = new this());
	}
}

export default PostModel.Instance;
